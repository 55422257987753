import ActionModal from "components/Common/Modal/ActionModal";
import { Body1Medium } from "components/TextStyle";
import React from "react";

interface TemplateDeleteModalProps {
  closeModal: () => void;
  handleDelete: () => void;
  deleteCount: number;
}

const TemplateDeleteModal = ({
  closeModal,
  handleDelete,
  deleteCount,
}: TemplateDeleteModalProps) => {
  return (
    <ActionModal
      title="삭제하기"
      handleModalClose={closeModal}
      handleCancelButton={closeModal}
      handleConfirmButton={handleDelete}
    >
      <div>
        <Body1Medium>선택한 {deleteCount}개의 템플릿을 삭제합니다.</Body1Medium>
        <Body1Medium
          style={{
            color: "#DB5257",
          }}
        >
          삭제 후 복구가 불가하오니 주의바랍니다.
        </Body1Medium>
      </div>
    </ActionModal>
  );
};

export default TemplateDeleteModal;

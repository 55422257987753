import { JWT_KEY, JWT_REFRESH_KEY } from "config/constant";

interface saveJwtTokenProps {
  accessJwt: string;
  refreshJwt: string;
}
export const getJWTToken = () => {
  const isAutoLogin = window.localStorage.getItem("autoLogin");
  if (isAutoLogin) {
    return {
      accessJwt: window.localStorage.getItem(JWT_KEY),
      refreshJwt: window.localStorage.getItem(JWT_REFRESH_KEY),
    };
  } else {
    return {
      accessJwt: window.sessionStorage.getItem(JWT_KEY),
      refreshJwt: window.sessionStorage.getItem(JWT_REFRESH_KEY),
    };
  }
};

export const saveJWTToken = ({ accessJwt, refreshJwt }: saveJwtTokenProps) => {
  const isAutoLogin = window.localStorage.getItem("autoLogin");
  if (isAutoLogin) {
    window.localStorage.setItem(JWT_KEY, accessJwt);
    window.localStorage.setItem(JWT_REFRESH_KEY, refreshJwt);
  } else {
    window.sessionStorage.setItem(JWT_KEY, accessJwt);
    window.sessionStorage.setItem(JWT_REFRESH_KEY, refreshJwt);
  }
};

export const clearJWTToken = () => {
  window.localStorage.removeItem(JWT_KEY);
  window.localStorage.removeItem(JWT_REFRESH_KEY);
  window.sessionStorage.removeItem(JWT_KEY);
  window.sessionStorage.removeItem(JWT_REFRESH_KEY);
};

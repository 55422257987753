import { Body1Regular } from "components/TextStyle";
import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const RadioButton = ({
  label,
  id,
  value,
  checked,
  ...rest
}: RadioButtonProps) => {
  return (
    <RadioButtonWrapper>
      <Radio type="radio" id={id} value={value} {...rest} checked={checked} />
      {label && (
        <Body1Regular as="label" htmlFor={id}>
          {label}
        </Body1Regular>
      )}
    </RadioButtonWrapper>
  );
};

export default RadioButton;

export const RadioButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  label {
    cursor: pointer;
    margin-top: 0.3rem;
  }
`;

export const Radio = styled.input`
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid #c6c8cd;
  cursor: pointer;
  border-radius: 50%;
  appearance: none;
  -webkit-appearance: none;

  &:checked {
    border: 6px solid #1b65e8;
  }
`;

import { Field } from "pages/contract/send";
import {
  ALLOWED_FILE_TYPES,
  ATTACHMENTS,
  IMAGE_TYPES,
  JWT_KEY,
  MAX_FILE_SIZE,
} from "../config/constant";
import { differenceInCalendarDays } from "date-fns";

/**
 * @param {string} email 이메일 정규식 유효성 검사
 * @returns {boolean}
 */
export const isValidEmail = (email: string) => {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi.test(email);
};

export const isValidPassword = (password: string) => {
  return password.match(/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/);
};

/**
 * @param {string} phoneNumber 전화번호 정규식 유효성 검사
 * @returns {boolean}
 */
export const isValidPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.includes("-")) {
    // -가 포함된 경우
    return phoneNumber.match(/^01(?:0|1|[6-9])-(?:\d{4})-\d{4}$/);
  } else {
    // -가 포함되지 않은 경우
    return phoneNumber.match(/^01(?:0|1|[6-9])(?:\d{8})$/);
  }
};

/**
 * @param {string} input 전화번호 형식으로 정규식 변환
 * @returns {string|undefined}
 */
export const formatPhoneNumber = (input?: string) => {
  if (!input) return;

  const str = input?.replace(/[^0-9]/g, "");

  if (str.length === 9)
    return str
      .replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/\-{1,2}$/g, "");
  if (str.length === 10)
    return str
      .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/\-{1,2}$/g, "");
  if (str.length === 11)
    return str
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/\-{1,2}$/g, "");

  return str;
};

export const getJwt = () => window.localStorage.getItem(JWT_KEY) ?? "";

export const saveJwt = (jwt: string) => {
  window.localStorage.setItem(JWT_KEY, jwt);
};

export const clearJwt = () => {
  window.localStorage.removeItem(JWT_KEY ?? "");
};

/*export const saveUser = (user: GetUsersUserIdResponse) => {
  window.localStorage.setItem(
    process.env.REACT_APP_LOGIN_INFO ?? "",
    JSON.stringify(user) ?? ""
  );
};*/

export const clearUser = () => {
  window.localStorage.removeItem(process.env.REACT_APP_LOGIN_INFO ?? "");
};

export const getUserEmail = () => {
  const loginInfoValue = window.localStorage.getItem(
    process.env.REACT_APP_LOGIN_INFO ?? ""
  );
  if (loginInfoValue === null) {
    return "";
  } else {
    const loginInfo = JSON.parse(loginInfoValue);
    return loginInfo.email ?? "";
  }
};

export const getUserName = (): string => {
  const loginInfoValue = window.localStorage.getItem(
    process.env.REACT_APP_LOGIN_INFO ?? ""
  );
  if (loginInfoValue === null) {
    return "";
  } else {
    const loginInfo = JSON.parse(loginInfoValue);
    return loginInfo.name ?? "";
  }
};

export const isValidFileType = (type: string) => {
  const FILETYPE = type.split("/")[1];
  if (!FILETYPE || !ALLOWED_FILE_TYPES.includes(FILETYPE.toLowerCase()))
    return false;
  else return true;
};

export const isValidFileSize = (size: number) => {
  if (size > MAX_FILE_SIZE) return false;
  else return true;
};

export const isValidImage = (type: string) => {
  if (!IMAGE_TYPES.includes(type)) return false;
  return true;
};

export const isValidAttachment = (type: string) => {
  if (!ATTACHMENTS.includes(type)) return false;
  return true;
};

export const getDifferencesInDates = (dateTime: Date) => {
  return differenceInCalendarDays(dateTime, new Date()).toLocaleString("ko-kr");
};

export const getDefaultSizeOfFields = (fieldType: Field) => {
  switch (fieldType) {
    case "SIGN":
      return [160, 80];
    case "IMAGE":
      return [240, 140];
    case "CHECKBOX":
      return [44, 44];
    default:
      return [160, 44];
  }
};

export const extractNumbersFromStrings = (
  names: string[],
  originalName: string
) => {
  const numbers = names.map((name) => +name.replace(originalName, ""));

  let i = 1;

  for (const _ of numbers) {
    if (!numbers.includes(i)) break;
    i++;
  }

  return i;
};

/**
 * @param {string} name 이름 정규식 유효성 검사 및 포맷팅
 * @returns {string} 포맷팅된 이름
 */
export const formatNameInput = (name: string) => {
  return name
    .replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "") // 이모티콘 제거
    .replace(/\s/g, "") // 공백 제거
    .slice(0, 30); // 30자 제한
};

/**
 * @param {string} email 이메일 포맷팅
 * @returns {string} 포맷팅된 이메일
 */
export const formatEmailInput = (email: string) => {
  return email
    .replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "") // 이모티콘 제거
    .replace(/\s/g, "") // 공백 제거
    .slice(0, 50); // 50자 제한
};

/**
 * @param {string} input 전화번호 형식으로 정규식 변환
 * @returns {string|undefined}
 */
export const formatPhoneNumberInput = (input?: string) => {
  if (!input) return;

  // 숫자만 추출
  const numbers = input.replace(/[^0-9]/g, "");

  // 13자리까지만 허용
  const limitedNumbers = numbers.slice(0, 13);

  // 자릿수에 따른 포맷팅
  switch (limitedNumbers.length) {
    case 9:
      return limitedNumbers.replace(/^(\d{2})(\d{3})(\d{4})$/, "$1-$2-$3");
    case 10:
      return limitedNumbers.replace(/^(\d{3})(\d{3})(\d{4})$/, "$1-$2-$3");
    case 11:
      return limitedNumbers.replace(/^(\d{3})(\d{4})(\d{4})$/, "$1-$2-$3");
    default:
      return limitedNumbers;
  }
};

/**
 * @param {string} phoneNumber 전화번호 유효성 검사
 * @returns {boolean}
 */
export const isValidPhoneNumberInput = (phoneNumber: string) => {
  const numbers = phoneNumber.replace(/[^0-9]/g, "");
  const length = numbers.length;

  // 9~11자리가 아닌 경우 invalid
  if (length < 9 || length > 11) return false;

  if (phoneNumber.includes("-")) {
    // -가 포함된 경우
    return phoneNumber.match(/^\d{2,3}-\d{3,4}-\d{4}$/);
  } else {
    // -가 포함되지 않은 경우
    return phoneNumber.match(/^\d{9,11}$/);
  }
};

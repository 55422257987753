import { OptionProps } from "interfaces/contract";
import React from "react";
import { Label } from "../CreateContractRight";
import Tooltip from "components/Common/Tooltip";
import { Body3Regular } from "components/TextStyle";
import Input from "components/Common/Input/Input";
import { ReactComponent as InfoIcon } from "assets/common/20px/icon_information_small.svg";

export default function DescriptionOption({
  fields,
  setPages,
  selectedPageId,
  selectedFieldId,
  noToolTip,
  selection,
}: OptionProps) {
  return (
    <>
      <Label>
        필드 설명
        {!noToolTip && (
          <Tooltip side="top" trigger={<InfoIcon />}>
            <Body3Regular>
              {selection
                ? `커서를 올려 놓았을 때 작성한 문구를 표시합니다.`
                : `필드 배경에 작성한 문구를 표시합니다.\n글자 크기가 28 이상이면 필드 설명은 표시되지 않습니다.`}
            </Body3Regular>
          </Tooltip>
        )}
      </Label>
      <Input
        value={
          fields?.find(
            (field) => `${field.fieldType}:${field.id}` === selectedFieldId
          )?.description
        }
        setValue={(description: string) =>
          setPages((pages) =>
            pages.map((page) => {
              if (page.id === selectedPageId) {
                return {
                  ...page,
                  fields: page.fields.map((field) => {
                    if (`${field.fieldType}:${field.id}` === selectedFieldId) {
                      return { ...field, description };
                    } else return { ...field };
                  }),
                };
              } else return { ...page };
            })
          )
        }
        placeholder="필드 설명"
        maxLength={200}
      />
    </>
  );
}

import React from "react";
import { OptionProps } from "interfaces/contract";
import { Body3Regular } from "components/TextStyle";
import Toggle from "components/Common/Toggle/Toggle";
import CheckBox from "components/Common/CheckBox/CheckBox";
import { Label } from "../CreateContractRight";

export default function RequiredOption({
  side,
  fields,
  setPages,
  selectedPageId,
  selectedFieldId,
}: OptionProps) {
  const handleToggle = () => {
    setPages((prev) =>
      prev.map((page) => {
        if (page.id === selectedPageId) {
          return {
            ...page,
            fields: page.fields.map((field) => {
              if (`${field.fieldType}:${field.id}` === selectedFieldId) {
                return { ...field, required: !field.required };
              } else return { ...field };
            }),
          };
        } else return { ...page };
      })
    );
  };

  return side ? (
    <>
      <Label>필수 입력</Label>
      <div className="checkbox">
        <CheckBox
          checked={
            !!fields?.find(
              (field) => `${field.fieldType}:${field.id}` === selectedFieldId
            )?.required
          }
          onChange={() => {
            setPages((pages) =>
              pages.map((page) => {
                if (page.id === selectedPageId) {
                  return {
                    ...page,
                    fields: page.fields.map((field) => {
                      if (
                        `${field.fieldType}:${field.id}` === selectedFieldId
                      ) {
                        return { ...field, required: !field.required };
                      } else return { ...field };
                    }),
                  };
                } else return { ...page };
              })
            );
          }}
        />
        필수 입력 필드로 지정
      </div>
    </>
  ) : (
    <>
      <Body3Regular>필수 설정</Body3Regular>
      <Toggle
        isSelected={
          !!fields.find(
            (field) => `${field.fieldType}:${field.id}` === selectedFieldId
          )?.required
        }
        handleToggle={handleToggle}
      />
    </>
  );
}

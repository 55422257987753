import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postSignaturesByIdFavorites } from "apis/signature";
import { queries } from "config/queryKey";

const useSignaturesFavoritesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postSignaturesByIdFavorites,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.signature.list.queryKey,
      });
      toast("즐겨찾기에 추가되었습니다.");
    },
  });
};

export default useSignaturesFavoritesMutation;

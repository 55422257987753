import { Header1 } from "components/TextStyle";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import {
  LayoutContainer,
  LayoutContentContainer,
  LayoutTitleContainer,
} from "./SettingLayoutStyledComponents";
import BackIcon from "assets/common/24px/icon_left_default.svg";

type SettingType = "member" | "payment" | "support" | "setting" | "convenient";
type MenuItemType = {
  state: string;
  value: string;
};
type SettingMenuType = {
  [key in SettingType]: MenuItemType[];
};

const SETTING_MENU: SettingMenuType = {
  member: [
    { state: "member", value: "멤버 관리" },
    { state: "group", value: "그룹 관리" },
  ],
  payment: [
    { state: "dashboard", value: "요금제" },
    { state: "history", value: "결제 내역" },
  ],
  support: [
    { state: "support", value: "문의하기" },
    { state: "faq", value: "자주 묻는 질문" },
  ],
  setting: [
    { state: "organization", value: "조직 설정" },
    { state: "service", value: "서비스 설정" },
  ],
  convenient: [
    { state: "contact", value: "연락처" },
    { state: "list-box-db", value: "드롭다운 리스트" },
    { state: "mail", value: "메일 템플릿" },
  ],
};

interface SettingLayoutProps {
  type?: SettingType;
  state: string;
  children: ReactNode;
  isBg?: boolean;
  isBackIcon?: boolean;
  handleBackIcon?: () => void;
}

const SettingLayout = ({
  type,
  state,
  children,
  isBg = true,
  isBackIcon = false,
  handleBackIcon,
}: SettingLayoutProps) => {
  const navigate = useNavigate();

  const handleNavigate = (state: string) => {
    const navigateTo = type === "payment" ? `${type}/${state}` : state;
    navigate(`/setting/${navigateTo}`);
  };

  return (
    <LayoutContainer>
      <LayoutTitleContainer>
        {isBackIcon && (
          <img
            src={BackIcon}
            className="backIcon"
            alt="backIcon"
            onClick={() => handleBackIcon && handleBackIcon()}
          />
        )}
        {type ? (
          <>
            {SETTING_MENU[type].map((e, idx) => {
              return (
                <div className="titleItemContainer" key={idx}>
                  <Header1
                    className={`title ${state === e.state ? "active" : ""}`}
                    onClick={() => {
                      if (state !== e.state) handleNavigate(e.state);
                    }}
                  >
                    {e.value}
                  </Header1>
                  <div className="line" />
                </div>
              );
            })}
          </>
        ) : (
          <Header1 className={`title active`}>{state}</Header1>
        )}
      </LayoutTitleContainer>
      {isBg ? (
        <LayoutContentContainer>{children}</LayoutContentContainer>
      ) : (
        <>{children}</>
      )}
    </LayoutContainer>
  );
};

export default SettingLayout;

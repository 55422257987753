import { css } from "styled-components";

export const primaryButtonState = {
  default: css`
    color: var(--bg-white);
    background: var(--primary-500);

    &:hover {
      background: var(--primary-600);
    }

    &:disabled {
      color: var(--grey-400);
      background: var(--grey-300);
    }
  `,
  negative: css``,
};

export const secondaryButtonState = {
  default: css`
    background: var(--grey-200);
    color: var(--grey-600);

    &:hover {
      background: var(--grey-300);
    }

    &:disabled {
      background: var(--grey-300);
      color: var(--grey-400);
    }
  `,

  negative: css``,
};

export const tertiaryButtonState = {
  default: css`
    border: 1px solid var(--grey-400);
    background-color: var(--bg-white);
    color: var(--grey-600);

    &:hover {
      border: 1px solid var(--grey-300);
      color: var(--grey-500);
    }

    &:disabled {
      border: 1px solid var(--grey-300);
      color: var(--grey-400);
    }
  `,

  negative: css`
    border: 1px solid var(--red-100);
    background-color: var(--bg-white);
    color: #fa4d56;

    &:hover {
      border: 1px solid var(--red-100);
      color: #fa4d56;

      .buttonText {
        opacity: 0.7;
      }
    }

    &:disabled {
      border: 1px solid var(--grey-300);
      color: var(--grey-400);
    }
  `,
};

export const ghostButtonState = {
  default: css`
    background-color: var(--bg-white);
    color: var(--grey-600);

    &:hover {
      color: var(--grey-500);
    }

    &:disabled {
      color: var(--grey-400);
    }
  `,

  negative: css`
    background-color: var(--bg-white);
    color: #fa4d56;

    &:hover {
      color: #fa4d56;

      .buttonText {
        opacity: 0.7;
      }
    }

    &:disabled {
      color: var(--grey-400);
    }
  `,
};

export const darkButtonState = {
  default: css`
    color: var(--bg-white);
    background: var(--grey-800);

    &:hover {
      background: var(--grey-600);
    }

    &:disabled {
      color: var(--grey-400);
      background: var(--grey-500);
    }
  `,
  negative: css``,
};

export const blackButtonState = {
  default: css`
    color: var(--bg-white);
    background: var(--text-default);
  `,
  negative: css``,
};

export const buttonType = {
  primary: primaryButtonState,
  secondary: secondaryButtonState,
  tertiary: tertiaryButtonState,
  ghost: ghostButtonState,
  dark: darkButtonState,
  black: blackButtonState,
};

export const buttonSize = {
  small: css`
    padding: 0.6rem 1.6rem;

    .buttonText {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem; /* 142.857% */
      letter-spacing: -0.42px;
    }
  `,
  medium: css`
    padding: 0.8rem 1.6rem;

    .buttonText {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.45px;
    }
  `,
  large: css`
    padding: 1.2rem 3rem;

    .buttonText {
      line-height: 2.4rem;
      font-size: 1.6rem;
      font-weight: 600;
      letter-spacing: -0.48px;
    }
  `,
};

import styled from "styled-components";
import { SearchInputContainer } from "components/Mobile/Common/Input/SearchInput";
export const FaqContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 4rem;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;

  .faqItemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  ${SearchInputContainer} {
    width: 100%;
    background-color: var(--bg-white);
  }
`;

export const FaqItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding-bottom: 1rem;
  cursor: pointer;

  &:last-child {
    border: none;
  }

  .faqTitleContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .faqTitle {
      color: var(--text-default);
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 2.2rem;
      letter-spacing: -0.03rem;
    }
  }

  .faqContent {
    color: var(--text-secondary);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.03rem;
  }
`;

import { useMutation } from "@tanstack/react-query";
import { patchNotifications } from "apis/notification";

const useNotificationsReadUpdate = () => {
  return useMutation({
    mutationFn: patchNotifications,
  });
};

export default useNotificationsReadUpdate;

import styled from "styled-components";
import { DropdownContainer } from "components/Mobile/Common/Dropdown/DropdownStyledComponents";

export const SupportContainer = styled.form`
  display: flex;
  gap: 4rem;
  flex-direction: column;
  align-items: center;

  .inputContainer {
    width: 100%;
  }
`;

export const SupportContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.1rem;

  .checkboxContainer {
    display: flex;
    gap: 1.2rem;
    margin-top: -1rem;

    .checkboxText {
      color: var(--grey-600);

      a {
        cursor: pointer;
        text-decoration-line: underline;
      }
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${DropdownContainer} {
    background-color: var(--bg-white);
  }
`;

import { Body3Regular } from "components/TextStyle";
import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as CheckIcon } from "assets/common/16px/icon_check_arrow.svg";
import { useLocation } from "react-router-dom";

const TEMPLATE_SEND_STEP = [
  {
    path: "config",
    title: "수신자 설정",
    include: ["config"],
  },
  {
    path: "preview",
    title: "전송",
    include: ["preview"],
  },
];

const TemplateSendLayout = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();
  return (
    <>
      <TemplateSendHeader>
        {TEMPLATE_SEND_STEP.map((step, index) => {
          const isSelected = step.include.some((path) =>
            pathname.includes(path)
          );

          return (
            <>
              <Chip isSelected={isSelected} key={step.path}>
                {isSelected && <CheckIcon />}
                <Body3Regular>{step.title}</Body3Regular>
              </Chip>
              {index !== TEMPLATE_SEND_STEP.length - 1 && isSelected && <hr />}
            </>
          );
        })}
      </TemplateSendHeader>
      <ContentLayout>{children}</ContentLayout>
    </>
  );
};

export default TemplateSendLayout;

const ContentLayout = styled.main`
  width: 100%;
  min-height: 100%;
  position: relative;
  background-color: var(--grey-200);
`;

const TemplateSendHeader = styled.header`
  height: 6.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  border-bottom: 0.1rem solid var(--stroke-light);
  background-color: var(--bg-white);
  box-sizing: border-box;

  hr {
    width: 2.6rem;
    height: 0.1rem;
    margin: 0;
    background-color: #d3d3d3;
    border: none;
  }
`;

const Chip = styled.div<{ isSelected: boolean }>`
  border-radius: 10rem;
  padding: 0.8rem 1.2rem;
  display: flex;
  gap: 0.4rem;
  align-items: center;

  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: var(--blue-900);
          color: var(--bg-white);
        `
      : css`
          background-color: #f2f4f8;
          color: var(--text-secondary);
        `}
`;

import React from "react";
import ActionModal from "components/Common/Modal/ActionModal";
import { Body1Medium } from "components/TextStyle";
import useUserState from "hooks/recoil/useUserState";
import usePhoneAuthMutation from "hooks/mutations/auth/usePhoneAuthMutation";

interface AnnualAuthModalProps {
  closeModal: () => void;
}

function AnnualAuthModal({ closeModal }: AnnualAuthModalProps) {
  const [userState] = useUserState();
  const { mutate: phoneAuthMutate } = usePhoneAuthMutation();
  const jwt =
    window.localStorage.getItem("jwt") ||
    window.sessionStorage.getItem("jwt") ||
    "";

  const handleConfirmButton = () => {
    phoneAuthMutate({
      callbackUrl: `${process.env.REACT_APP_PHONE_AUTH_CALLBACK_URI}`,
      parameterOption1: userState.uuid,
      parameterOption2: jwt,
    });
    closeModal();
  };

  return (
    <ActionModal
      handleModalClose={closeModal}
      title="본인 인증"
      confirmText="인증 진행"
      handleConfirmButton={handleConfirmButton}
      handleCancelButton={closeModal}
    >
      <Body1Medium>
        이폼에서는 더욱 안전하고 신뢰할 수 있는 계약을 위해 발신자의 휴대폰 본인
        인증을 연 1회 실시하고 있습니다. 회사 계정의 경우, 대표자 또는 담당자의
        휴대폰으로 본인 인증을 진행하실 것을 권장드립니다.
      </Body1Medium>
    </ActionModal>
  );
}

export default AnnualAuthModal;

import React from "react";
import { Label } from "../CreateContractRight";
import Tooltip from "components/Common/Tooltip";
import { Body3Regular } from "components/TextStyle";
import CheckBox from "components/Common/CheckBox/CheckBox";
import { ReactComponent as InfoIcon } from "assets/common/20px/icon_information_small.svg";
import { OptionProps } from "interfaces/contract";

export default function CopyOption({
  label,
  fields,
  setPages,
  selectedPageId,
  selectedFieldId,
}: OptionProps) {
  return (
    <>
      <Label>
        내용 복사
        <Tooltip side="top" trigger={<InfoIcon />}>
          <Body3Regular>복사할 입력란을 선택</Body3Regular>
        </Tooltip>
      </Label>
      <div className="checkbox">
        <CheckBox
          checked={
            !!fields?.find(
              (field) => `${field.fieldType}:${field.id}` === selectedFieldId
            )?.copy
          }
          onChange={() => {
            setPages((pages) =>
              pages.map((page) => {
                if (page.id === selectedPageId) {
                  return {
                    ...page,
                    fields: page?.fields?.map((field) => {
                      if (`${field.fieldType}:${field.id}` === selectedFieldId)
                        return {
                          ...field,
                          copy: !field.copy,
                        };
                      else return { ...field };
                    }),
                  };
                } else return { ...page };
              })
            );
          }}
        />
        {label || "현재 만들어진 텍스트"}
      </div>
    </>
  );
}

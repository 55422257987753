import Input from "components/Common/Input/Input";
import {
  Body1Regular,
  Body1SemiBold,
  Body3Regular,
} from "components/TextStyle";
import React from "react";
import ToggleController from "../ToggleController";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { REGEX } from "config/regex";
import DatePickerModal from "components/Common/DatePicker/DatePickerModal";
import { getDifferencesInDates } from "utils/utility";
import { ContractSendType } from "models/Contract";
import Toggle from "components/Common/Toggle/Toggle";
export interface ContractConfigMobileFormData {
  contractClass: "NORMAL" | "GANIN" | "DIRECT_URL";
  ganinImageUrl?: string;
  contractName: string;
  contractType: ContractSendType;
  password: {
    password: string;
    isUsed: boolean;
  };
  isPhoneNumberAuth: {
    isUsed: boolean;
  };
  expired: {
    expired: boolean;
    expiredAt: Date;
  };
}

const ContractConfigMobileForm = ({
  receiverCount,
}: {
  receiverCount: number;
}) => {
  const {
    watch,
    getValues,
    setValue,
    register,
    control,
    formState: { errors },
  } = useFormContext<ContractConfigMobileFormData>();

  return (
    <>
      <ContractConfigLayout>
        <Body1SemiBold>계약명</Body1SemiBold>
        <Input
          {...register("contractName")}
          placeholder="계약명을 입력하세요"
        />
      </ContractConfigLayout>
      <ContractConfigLayout>
        <div>
          <Body1SemiBold>인증방식</Body1SemiBold>
          <ConfigContentDescription>
            전체 수신자에게 적용되는 인증 방식을 설정합니다.
          </ConfigContentDescription>
        </div>
        <div>
          <Body1Regular className="config-subtitle config-controller">
            암호 사용
            <ToggleController control={control} name="password.isUsed" />
          </Body1Regular>
          <Input
            minLength={4}
            maxLength={15}
            {...register("password.password", {
              pattern: {
                value: REGEX.contract.password,
                message:
                  "암호는 영문, 숫자, 특수문자를 포함한 4~15자여야 합니다.",
              },
            })}
            isError={!!errors.password?.password}
            errorMessage={errors.password?.password?.message}
            placeholder="암호 입력(4~15자)"
            disabled={!getValues("password.isUsed")}
          />
        </div>
        <div>
          <Body1Regular className="config-subtitle config-controller">
            휴대폰 본인 인증
            <ToggleController
              control={control}
              name="isPhoneNumberAuth.isUsed"
            />
          </Body1Regular>
          <ConfigContentDescription>
            휴대폰 본인인증은 휴대전화번호가 입력된 수신자에게만 적용됩니다.
          </ConfigContentDescription>
        </div>
      </ContractConfigLayout>
      <ContractConfigLayout>
        <Body1SemiBold className="config-controller">
          만료기한
          <Toggle
            isSelected={watch("expired.expired")}
            handleToggle={() =>
              setValue("expired.expired", !getValues("expired.expired"))
            }
          />
        </Body1SemiBold>
        {receiverCount > 1 && (
          <ConfigContentDescription>
            만료 기한 설정 시 해당 기간 이후에는 계약 서명이 제한됩니다.
          </ConfigContentDescription>
        )}
        <div className="input">
          {watch("expired.expired") ? (
            <>
              <div className="datepicker-container">
                <DatePickerModal
                  selected={getValues("expired.expiredAt")}
                  onChangeHandler={(date) => {
                    if (date) setValue("expired.expiredAt", date);
                  }}
                  onSelectHandler={(date) => {
                    if (date) setValue("expired.expiredAt", date);
                  }}
                />
              </div>
              <Body3Regular className="guide">
                {getValues("expired.expiredAt")
                  ? getDifferencesInDates(getValues("expired.expiredAt"))
                  : 0}
                일 뒤 만료
              </Body3Regular>
            </>
          ) : (
            <Input disabled placeholder="만료 기한 없음" />
          )}
        </div>
      </ContractConfigLayout>
    </>
  );
};

export default ContractConfigMobileForm;

const ContractConfigLayout = styled.div`
  width: 100%;
  margin-bottom: 4rem;

  .guide {
    margin-top: 0.8rem;
    color: var(--red-600);
  }
`;

const ConfigContentDescription = styled(Body3Regular)`
  margin-top: 0.8rem;
  color: var(--text-secondary);
  font-size: 1.3rem;
`;

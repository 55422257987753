import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useMailTemplateDetailQuery = (mailTemplateUuid?: string) => {
  return useQuery({
    ...queries.mailTemplate.detail(mailTemplateUuid),
    enabled: !!mailTemplateUuid,
  });
};

export default useMailTemplateDetailQuery;

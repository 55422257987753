import useOrganizationListQuery from "hooks/queries/organization/useOrganizationListQuery";
import useOrganizationGroupListQuery from "hooks/queries/organizationGroup/useOrganizationGroupListQuery";
import useUserDetailQuery from "hooks/queries/user/useUserDetailQuery";
import { atom, useRecoilState } from "recoil";

export const OrganizationIndexState = atom({
  key: "OrganizationIndexState",
  default: 0,
});

export const OrganizationGroupIndexState = atom({
  key: "OrganizationGroupIndexState",
  default: 0,
});

/**
 * @description 현재 사용자의 정보를 관리하는 전역 상태
 */
const useUserState = () => {
  const [organizationIndex, setOrganizationIndex] = useRecoilState(
    OrganizationIndexState
  );
  const [organizationGroupIndex, setOrganizationGroupIndex] = useRecoilState(
    OrganizationGroupIndexState
  );

  const { data: organizationList } = useOrganizationListQuery();
  const { data: organizationGroupList } = useOrganizationGroupListQuery(
    organizationList.organizationList[organizationIndex].organizationUuid
  );

  const { data: userData } = useUserDetailQuery();

  const state = {
    uuid: userData.userUuid,
    organization:
      organizationList.organizationList[organizationIndex].organizationUuid,
    organization_permission:
      organizationList.organizationList[organizationIndex].memberPermission,
    organizationGroup:
      organizationGroupList?.organizationGroupList[organizationGroupIndex]
        .organizationGroupUuid,
    organizationGroup_permission:
      organizationGroupList?.organizationGroupList[organizationGroupIndex].role,
  };

  return [state, setOrganizationIndex, setOrganizationGroupIndex] as const;
};

export default useUserState;

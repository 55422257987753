import React, { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import { buttonSize, buttonType } from "./ButtonStyledComponents";

type ButtonStyleProps = {
  state?: "default" | "negative";
  colorType: "primary" | "secondary" | "tertiary" | "ghost" | "dark" | "black";
  size: "small" | "medium" | "large";
};

interface ButtonProps
  extends ButtonStyleProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  isDisabled?: boolean;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
}

const Button = ({
  isDisabled = false,
  handleClick,
  children,
  state,
  colorType,
  size,
  ...args
}: ButtonProps) => {
  return (
    <ButtonContainer
      state={state}
      colorType={colorType}
      size={size}
      onClick={handleClick}
      disabled={isDisabled}
      {...args}
    >
      <div className="buttonText">{children}</div>
    </ButtonContainer>
  );
};

export default Button;

const ButtonContainer = styled.button<ButtonStyleProps>`
  border-radius: 5px;
  width: 100%;

  ${(props) => buttonSize[props.size]};
  ${(props) => buttonType[props.colorType]?.[props.state || "default"]}

  .buttonText {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${(props) => (props.size === "large" ? "0.4rem" : "0.2rem")};
  }
`;

import Button from "components/Common/Button/Button";
import NoticeNavigation from "components/Notice/NoticeNavigation";
import { Board } from "pages/dashboard/style";
import React from "react";
import styled from "styled-components";
import { ReactComponent as RightArrowIcon } from "assets/common/24px/icon_right_default.svg";
import { useLocation } from "react-router-dom";
import useNoticeDetailQuery from "hooks/queries/notice/useNoticeDetailQuery";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import MarkdownViewer from "./MarkdownViewer";

const NoticeDashboardDetailPage = () => {
  const { pathname } = useLocation();
  const id = pathname.split("/").pop() ?? "";
  //TODO: 추후 페이지 접속 예외처리 loader단에서 필요

  const { data } = useNoticeDetailQuery({ id });
  const notice = data?.notice;

  if (!notice) return;

  return (
    <>
      <NoticeNavigation />
      <NoticeDetailBoard>
        <NoticeDetailLayout>
          <h2>{notice.title}</h2>
          <time>{format(notice.date, "yyyy.MM.dd")}</time>
          <hr />
          <MarkdownViewer markdown={notice.content} />

          <Link to="/notice/dashboard">
            <GoListButton colorType="tertiary" size="small">
              목록으로 가기 <RightArrowIcon />
            </GoListButton>
          </Link>
        </NoticeDetailLayout>
      </NoticeDetailBoard>
    </>
  );
};

export default NoticeDashboardDetailPage;

const NoticeDetailLayout = styled.div`
  margin: auto;
  max-width: 100rem;
  width: 100%;

  h2 {
    margin: 0;

    color: var(--Text-Color-Text-Default, #171d23);
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3rem; /* 136.364% */
    letter-spacing: -0.66px;
  }

  time {
    color: var(--Text-Color-Text-Secondary, #666f7b);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.54px;
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: var(--stroke-light);
    border: none;
    margin: 0;

    margin-top: 5.4rem;
    margin-bottom: 4rem;
  }
`;

const NoticeDetailBoard = styled(Board)`
  width: 100%;
  padding: 10rem 0;
`;

const GoListButton = styled(Button)`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.2rem;

  > svg {
    height: 2rem;
    width: 2rem;
  }
`;

import axios, { AxiosResponse } from "axios";
import {
  DeleteContractRequest,
  GetContractByIdRemainCreditResponse,
  GetContractByIdResponse,
  GetContractCertificateRequest,
  GetContractCopiesRequest,
  GetContractCreationInfoRequest,
  GetContractCreationInfoResponse,
  GetContractInputsResponse,
  GetContractParticipantInfoRequest,
  GetContractParticipantInfoResponse,
  GetContractParticipantsInfoRequest,
  GetContractParticipantsInfoResponse,
  GetContractsDocumentHistoriesRequest,
  GetContractsDocumentHistoriesResponse,
  GetContractsGaninRequest,
  GetContractsGaninResponse,
  GetContractsRequest,
  GetContractsResponse,
  GetContractStatusResponse,
  GetDirectUrlByIdResponse,
  GetDirectUrlManagementRequest,
  GetDirectUrlManagementResponse,
  GetDirectUrlParticipantsInfoRequest,
  GetDirectUrlParticipantsInfoResponse,
  GetDirectUrlsRequest,
  GetDirectUrlsResponse,
  PatchContractCancelData,
  PatchContractCancelRequest,
  PatchDirectUrlData,
  PatchDirectUrlExpirationRequest,
  PatchDirectUrlRequest,
  PostContractResendRequest,
  PostContractsDocumentResponse,
  PostContractSendRequest,
  PostDirectURLRequest,
  PostDirectURLResponse,
} from "models/Contract";
import { Organization } from "models/Organization";
import getAPIResponseData from "utils/getAPIResponse";

// 계약 삭제 API
export const deleteContractById = ({
  organizationId,
  contractId,
  organizationGroupId,
}: DeleteContractRequest) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : {};

  return getAPIResponseData({
    url: `/contracts/${organizationId}/${contractId}`,
    method: "DELETE",
    params,
  });
};

// 일반 계약 목록 조회 API
export const getContracts = ({
  organizationId,
  organizationGroupId,
  page,
  size,
  keyword,
  searchType,
  contractStatus,
}: GetContractsRequest) => {
  const params: {
    organizationGroupUuid?: string;
    page: number;
    size: number;
    keyword?: string;
    searchType: string;
    contractStatus: string;
  } = {
    page,
    size,
    keyword,
    searchType,
    contractStatus,
  };
  if (organizationGroupId) {
    params.organizationGroupUuid = organizationGroupId;
  }
  return getAPIResponseData<GetContractsResponse>({
    url: `/contracts/${organizationId}/lists`,
    method: "GET",
    params,
  });
};

// 계약 서명 참여자 보기 (1:N) API
export const getContractParticipantsInfo = ({
  organizationId,
  organizationGroupId,
  contractId,
}: GetContractParticipantsInfoRequest) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : {};
  return getAPIResponseData<GetContractParticipantsInfoResponse>({
    url: `/contracts/${organizationId}/${contractId}/participants-info`,
    method: "GET",
    params,
  });
};

// 계약 서명 참여자 보기 (1:1) API
export const getContractParticipantInfo = ({
  organizationId,
  organizationGroupId,
  contractId,
}: GetContractParticipantInfoRequest) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : {};
  return getAPIResponseData<GetContractParticipantInfoResponse>({
    url: `/contracts/${organizationId}/${contractId}/participant-info`,
    method: "GET",
    params,
  });
};

// 계약 작성 정보 보기 API
export const getContractCreationInfo = ({
  organizationId,
  organizationGroupId,
  contractId,
}: GetContractCreationInfoRequest) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : {};
  return getAPIResponseData<GetContractCreationInfoResponse>({
    url: `/contracts/${organizationId}/${contractId}/creation-info`,
    method: "GET",
    params,
  });
};

export const getContractCopies = ({
  organizationId,
  organizationGroupId,
  contractId,
}: GetContractCopiesRequest) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : {};
  return getAPIResponseData<AxiosResponse<Blob>>({
    url: `/contracts/${organizationId}/${contractId}/copies`,
    method: "GET",
    responseType: "blob",
    params,
    headers: {
      Accept: "application/pdf",
    },
  });
};

export const getContractCertificate = ({
  organizationId,
  organizationGroupId,
  contractId,
}: GetContractCertificateRequest) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : {};
  return getAPIResponseData<AxiosResponse<Blob>>({
    url: `/contracts/${organizationId}/${contractId}/certificate`,
    method: "GET",
    responseType: "blob",
    params,
    headers: {
      Accept: "application/pdf",
    },
  });
};

// 조직 전송 잔여 건수 조회 API
export const getContractByIdRemainCredit = (id: Organization["uuid"]) => {
  return getAPIResponseData<GetContractByIdRemainCreditResponse>({
    url: `/contracts/${id}/remain-credits`,
    method: "GET",
  });
};

// 사용자 계약 현황 조회 API
export const getContractStatus = (id: Organization["uuid"]) => {
  return getAPIResponseData<GetContractStatusResponse>({
    url: `/contracts/${id}/contract-status`,
    method: "GET",
  });
};

// 계약서 조회 API
export const getContractById = (contractId: Organization["uuid"]) => {
  return getAPIResponseData<GetContractByIdResponse>({
    url: `/contracts/${contractId}`,
    method: "GET",
  });
};

// Direct URL 계약 목록 조회 API
export const getDirectUrls = ({
  organizationId,
  organizationGroupId,
  keyword,
  page,
  size,
  searchType,
  contractStatus,
}: GetDirectUrlsRequest) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : { keyword, page, size, searchType, contractStatus };
  return getAPIResponseData<GetDirectUrlsResponse>({
    url: `/contracts/direct-urls/${organizationId}`,
    method: "GET",
    params,
  });
};
// Direct URL 작성자 보기 API
export const getDirectUrlParticipantsInfo = ({
  organizationId,
  organizationGroupId,
  contractDirectUrlId,
  receivedUserName,
  page,
  size,
}: GetDirectUrlParticipantsInfoRequest) => {
  const params: {
    organizationGroupUuid?: string;
    receivedUserName?: string;
    page: number;
    size: number;
  } = {
    page,
    size,
  };

  if (organizationGroupId) {
    params.organizationGroupUuid = organizationGroupId;
  }

  if (receivedUserName) {
    params.receivedUserName = receivedUserName;
  }

  return getAPIResponseData<GetDirectUrlParticipantsInfoResponse>({
    url: `/contracts/direct-urls/${organizationId}/${contractDirectUrlId}/participants-info`,
    method: "GET",
    params,
  });
};

// Direct Url 관리 정보 조회 API
export const getDirectUrlManagement = ({
  organizationId,
  organizationGroupId,
  contractDirectUrlId,
}: GetDirectUrlManagementRequest) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : {};
  return getAPIResponseData<GetDirectUrlManagementResponse>({
    url: `/contracts/direct-urls/${organizationId}/${contractDirectUrlId}/managements`,
    method: "GET",
    params,
  });
};
// Direct URL 수정 API
export const patchDirectUrl = ({
  organizationId,
  contractDirectUrlId,
  organizationGroupId,

  urlName,
  urlUsageCount,
  expiredAt,
}: PatchDirectUrlRequest & PatchDirectUrlData) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : {};
  return getAPIResponseData<GetDirectUrlByIdResponse, PatchDirectUrlData>({
    url: `/contracts/direct-urls/${organizationId}/${contractDirectUrlId}`,
    method: "PATCH",
    params,
    data: {
      urlName,
      urlUsageCount,
      expiredAt,
    },
  });
};

export const postDirectUrl = ({
  organizationId,
  organizationGroupId = "",
  data,
}: {
  organizationId: string;
  organizationGroupId?: string;
  data: PostDirectURLRequest;
}) => {
  return getAPIResponseData<PostDirectURLResponse, PostDirectURLRequest>({
    url: `/contracts/direct-urls/${organizationId}/send-contract${
      organizationGroupId ? `?organizationGroupId=${organizationGroupId}` : ""
    }`,
    method: "POST",
    data,
  });
};

export const patchDirectUrlExpiration = ({
  organizationId,
  contractDirectUrlId,
  organizationGroupId,
}: PatchDirectUrlExpirationRequest) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : {};
  return getAPIResponseData({
    url: `/contracts/direct-urls/${organizationId}/${contractDirectUrlId}/expirations`,
    method: "PATCH",
    params,
  });
};

// 계약서 관리 - 계약서 수신자 설정 / 필드 배치 / 계약서 전송 API
export const postContractSend = ({
  organizationUuid,
  data,
  organizationGroupUuid,
}: {
  organizationUuid: string;
  data: PostContractSendRequest;
  organizationGroupUuid?: string;
}) => {
  const params = organizationGroupUuid ? { organizationGroupUuid } : {};
  return getAPIResponseData({
    url: `/contracts/${organizationUuid}/send-contract`,
    method: "POST",
    data,
    params,
  });
};

export const getContractsPresignedUrl = async (file: File) => {
  const url = await getAPIResponseData<string>({
    url: `/contracts/presigned-url`,
    method: "GET",
  });

  try {
    await axios({
      url,
      method: "PUT",
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
  } catch (err) {
    throw err;
  } finally {
    return url;
  }
};

export const getContractsAttachmentsPresignedUrl = async ({
  file,
  fileName,
}: {
  file: File;
  fileName: string;
}) => {
  const url = await getAPIResponseData<string>({
    url: `/contracts/attachments/presigned-url?fileName=${fileName}`,
    method: "GET",
  });

  try {
    await axios({
      url,
      method: "PUT",
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
  } catch (err) {
    throw err;
  } finally {
    return url;
  }
};

export const patchContractCancel = ({
  organizationId,
  contractUuid,
  organizationGroupId,
  cancelReason,
}: PatchContractCancelData & PatchContractCancelRequest) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : {};
  return getAPIResponseData({
    url: `/contracts/${organizationId}/${contractUuid}/cancel`,
    method: "PATCH",
    params,
    data: {
      cancelReason,
    },
  });
};

export const postContractResend = ({
  organizationId,
  contractUuid,
  organizationGroupId,
}: PostContractResendRequest) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : {};
  return getAPIResponseData({
    url: `/contracts/${organizationId}/${contractUuid}/resend`,
    method: "POST",
    params,
  });
};

export const postContractSendSingleMultiple = ({
  organizationUuid,
  data,
  organizationGroupUuid,
}: {
  organizationUuid: string;
  data: PostContractSendRequest;
  organizationGroupUuid?: string;
}) => {
  const params = organizationGroupUuid ? { organizationGroupUuid } : {};
  return getAPIResponseData({
    url: `/contracts/${organizationUuid}/send-single-multiple-contract`,
    method: "POST",
    data,
    params,
  });
};

export const getContractsGanin = ({
  organizationUuid,
  organizationGroupUuid,
}: GetContractsGaninRequest) => {
  const params = organizationGroupUuid ? { organizationGroupUuid } : {};
  return getAPIResponseData<GetContractsGaninResponse>({
    url: `/contracts/${organizationUuid}/ganin`,
    method: "GET",
    params,
  });
};

export const getContractDocumentHistories = ({
  organizationId,
  organizationGroupId,
  contractId,
  page,
}: GetContractsDocumentHistoriesRequest) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId, page, size: 5 }
    : { page, size: 5 };
  return getAPIResponseData<GetContractsDocumentHistoriesResponse>({
    url: `/contracts/${organizationId}/${contractId}/document-histories`,
    method: "GET",
    params,
  });
};

export const postContractsDocument = ({
  organizationId,
  organizationGroupId,
  contractId,
  receiverEmailList,
}: GetContractCreationInfoRequest & { receiverEmailList: string[] }) => {
  const params = organizationGroupId
    ? { organizationGroupUuid: organizationGroupId }
    : {};
  return getAPIResponseData<
    PostContractsDocumentResponse,
    { receiverEmailList: string[] }
  >({
    url: `/contracts/${organizationId}/${contractId}/document`,
    method: "POST",
    params,
    data: { receiverEmailList },
  });
};
export const getContractInputs = (contractUuid: string) => {
  return getAPIResponseData<GetContractInputsResponse>({
    url: `/contracts/${contractUuid}/inputs`,
    method: "GET",
  });
};

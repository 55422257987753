import { Body1SemiBold } from "components/TextStyle";
import React from "react";
import styled from "styled-components";
import OrganizationItem from "./OrganizationItem";
import useOrganizationListQuery from "hooks/queries/organization/useOrganizationListQuery";

const ProfileInvitedOrganizationMobilePage = () => {
  const { data } = useOrganizationListQuery();

  return (
    <InvitedOrganizationLayout>
      <Body1SemiBold>초대받은 조직</Body1SemiBold>
      <ul>
        {data?.organizationList.map((organization, index) => (
          <div key={organization.organizationUuid}>
            <OrganizationItem
              key={organization.organizationUuid}
              index={index + 1}
              id={organization.organizationUuid}
              title={organization.companyName + " 조직"}
            />
            <hr />
          </div>
        ))}
      </ul>
    </InvitedOrganizationLayout>
  );
};

export default ProfileInvitedOrganizationMobilePage;

const InvitedOrganizationLayout = styled.div`
  padding: 3rem;

  hr {
    width: 100%;
    margin: 0;
    height: 0.1rem;
    border: none;
    background-color: var(--stroke-light);
  }

  > ul {
    background-color: var(--bg-white);
    border-radius: 2rem;

    > div:last-child hr {
      display: none;
    }
  }
`;

type FileOptions = {
  fileName?: string;
};

type BlobOptions = {
  fileName: string;
};

// Blob을 위한 함수 오버로딩: options는 필수
function saveFile(file: Blob, options: BlobOptions): void;
// File을 위한 함수 오버로딩: options는 선택적
function saveFile(file: File, options?: FileOptions): void;

// 구현
function saveFile(file: File | Blob, options?: BlobOptions | FileOptions) {
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");
  a.href = url;
  // options?.fileName이 제공되지 않은 경우 File이면 file.name 사용, Blob이면 "default-filename" 사용
  a.download =
    options?.fileName ||
    (file instanceof File ? file.name : "default-filename");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export default saveFile;

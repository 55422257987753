import React from "react";
import { Body3Regular } from "components/TextStyle";
import { LNBSubItemContainer, LabelContainer } from "./style";
import { MenuItems } from ".";
import { PickArrayItem } from "utils/types";

interface MenuItemProps {
  isSelected: boolean;
  handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  menuItem: MenuItems;
}

interface SubMenuItemProps {
  isSelected: boolean;
  handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  subMenuItem: PickArrayItem<Pick<MenuItems, "subMenu">["subMenu"]>;
}

export const MenuItem = ({
  isSelected,
  handleClick,
  menuItem,
}: MenuItemProps) => {
  const { label, icon, activeIcon } = menuItem;

  return (
    <LabelContainer $isSelected={isSelected} onClick={handleClick}>
      <img
        alt="menuIcon"
        src={isSelected ? activeIcon : icon}
        className="menuIcon"
      />
      <Body3Regular className="label">{label}</Body3Regular>
    </LabelContainer>
  );
};

export const SubMenuItem = ({
  isSelected,
  handleClick,
  subMenuItem,
}: SubMenuItemProps) => {
  if (!subMenuItem) return;
  return (
    <LNBSubItemContainer onClick={handleClick} $isSelected={isSelected}>
      <img alt="subMenuIcon" src={subMenuItem.icon} />
      <Body3Regular className="label">{subMenuItem.label}</Body3Regular>
    </LNBSubItemContainer>
  );
};

import styled from "styled-components";

export const EditorStyled = styled.div`
  flex-grow: 1;
  max-width: calc(100dvw - 48rem);
  max-height: calc(100dvh - 13.6rem);
  position: relative;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  background-color: var(--grey-200);
  row-gap: 2rem;
  box-sizing: border-box;
  padding: 2rem;

  .pdf {
    width: max-content;
    overflow: hidden;
  }

  .page-container {
    position: relative;
    background-color: transparent !important;
    margin: 0 auto;

    .canvas {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }

  .page {
    background-color: transparent !important;
    box-sizing: border-box;

    & > canvas {
      width: 100%;
      height: 100%;
      inset: 0;
    }
  }
`;

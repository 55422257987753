import { ChangeEvent, useRef, useState } from "react";
import { toast } from "react-toastify";

interface FileUploadProps {
  types: string[];
  size?: number;
  maxFileCount?: number;
  limitNameLength?: number;
  onChange?: (files: File[]) => void;
}

/**
 * @description 파일 업로드 커스텀 훅
 * @param types - 허용할 파일 타입
 * @param size - 허용할 파일 사이즈 (MB)
 * @param maxFileCount - 허용할 파일 갯수
 * @param limitNameLength - 파일 이름 길이 제한
 * @param onChange - 파일 변경 시 호출할 콜백
 *
 * @returns files - 업로드된 파일 목록
 * @returns fileInputProps - input에 바인딩
 * @returns inputFor - input을 대체할 엘리먼트에 바인딩
 *
 */
const useFileUpload = ({
  types,
  size = 10,
  maxFileCount = 1,
  limitNameLength,
  onChange,
}: FileUploadProps) => {
  const [files, setFiles] = useState<File[] | null>(null);
  const [isError, setIsError] = useState({
    size: false,
    type: false,
    count: false,
    nameLength: false,
  });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const maxFileSize = size * 1024 * 1024;

  const validateFileType = (type: string) => {
    const isValidate = types.includes(type);
    setIsError((pre) => ({ ...pre, type: !isValidate }));
    return isValidate;
  };
  const validateFileSize = (fileSize: number) => {
    const isValidate = fileSize <= maxFileSize;
    if (!isValidate)
      toast(
        `지원하지 않는 파일 크기입니다. ${size}MB 이하의 이미지 파일을 적용해주세요.`
      );
    setIsError((pre) => ({ ...pre, size: !isValidate }));
    return isValidate;
  };

  const validateFileCount = (count: number) => {
    const isValidate = count <= maxFileCount;
    setIsError((pre) => ({ ...pre, count: !isValidate }));
    return isValidate;
  };

  const validateFileNameLength = (name: string) => {
    const isValidate = !limitNameLength || name.length <= limitNameLength;
    setIsError((pre) => ({ ...pre, nameLength: !isValidate }));
    return isValidate;
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (!selectedFiles) return;

    const validFiles: File[] = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      if (
        validateFileType(selectedFiles[i].type) &&
        validateFileSize(selectedFiles[i].size) &&
        validateFileNameLength(selectedFiles[i].name) &&
        validateFileCount(i)
      ) {
        validFiles.push(selectedFiles[i]);
      }
    }

    setFiles(validFiles);
    onChange && onChange(validFiles);
  };

  const removeFile = (index: number) => {
    setFiles((pre) => {
      if (!pre) return null;
      return pre.filter((_, i) => i !== index);
    });
  };

  const targetClick = () => {
    fileInputRef.current?.click();
  };

  return {
    files,
    fileInputProps: {
      onChange: handleFileChange,
      accept: types.join(","),
      multiple: maxFileCount > 1,
      ref: fileInputRef,
    },
    inputFor: {
      onClick: targetClick,
    },
    removeFile,
    isError,
  };
};

export default useFileUpload;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  getGeneralSetting,
  getGeneralSettingServices,
} from "apis/organization/generalSettings";

export const generalSettingQueryKey = createQueryKeys("generalSetting", {
  service: (organization?: string) => ({
    queryKey: [organization],
    queryFn: () => getGeneralSettingServices(organization),
  }),
  organization: (organization?: string) => ({
    queryKey: [organization],
    queryFn: () => getGeneralSetting(organization),
  }),
});

import React, { useState } from "react";
import styled from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";
import { ReactComponent as UpIcon } from "assets/common/24px/icon_up.svg";
import { ReactComponent as DownIcon } from "assets/common/24px/icon_down_default.svg";
import { Body3Regular } from "components/TextStyle";

export type AccordionItemType = {
  label: string;
  value: string;
};

interface AccordionListProps {
  list: AccordionItemType[];
  onSelect?: (value: string) => void;
}

const AccordionList = ({ list, onSelect }: AccordionListProps) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!list || list.length === 0) return null;
  const title = list[0].label;

  return (
    <AccordionRoot type="single" collapsible>
      <Accordion.Item value={title}>
        <AccordionTrigger onClick={() => setIsOpen(!isOpen)}>
          <Body3Regular>{title}</Body3Regular>
          {isOpen ? <UpIcon /> : <DownIcon />}
        </AccordionTrigger>
        <AccordionContent>
          {list.map((item) => (
            <ListItem
              key={item.value}
              onClick={() => {
                onSelect?.(item.value);
              }}
            >
              {item.label}
            </ListItem>
          ))}
        </AccordionContent>
      </Accordion.Item>
    </AccordionRoot>
  );
};

export default AccordionList;

const AccordionRoot = styled(Accordion.Root)`
  div {
    button {
      display: flex;
      flex-direction: row;
      justify-content: start;

      width: 100%;
      padding: 1.2rem 2rem;
      margin: 0;

      gap: 1rem;

      color: var(--text-default);
    }
  }
`;

const AccordionTrigger = styled(Accordion.Trigger)`
  all: unset;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AccordionContent = styled(Accordion.Content)`
  display: flex;
  flex-direction: column;

  div {
    padding: 0.8rem 2.8rem;
    color: var(--text-secondary);
  }
`;

const ListItem = styled(Body3Regular)`
  cursor: pointer;
  &:hover {
    background-color: var(--background-hover);
  }
`;

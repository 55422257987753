import Button from "components/Common/Button/Button";
import Input from "components/Mobile/Common/Input/Input";
import {
  Body1Medium,
  Body1Regular,
  Body1SemiBold,
  Body2Medium,
  Body3Regular,
} from "components/TextStyle";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFieldArray, useFormContext } from "react-hook-form";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";
import ActionModal from "components/Mobile/Common/Modal/ActionModal";
import Toggle from "components/Common/Toggle/Toggle";
import { VerificationModalStyled } from "components/Contract/VerificationModal";
import useUserState from "hooks/recoil/useUserState";
import useTemplateDetailInfoQuery from "hooks/queries/template/useTemplateDetailInfoQuery";
import { useNavigate, useParams } from "react-router";
import {
  formatPhoneNumber,
  isValidEmail,
  isValidPhoneNumber,
} from "utils/utility";
export interface SingleReceiverConfigMobileFormData {
  receivers: {
    name: string;
    email: string;
    phoneNumber: string;
    isEmailError?: boolean;
    verification: {
      password: {
        isUsed: boolean;
        password: string;
      };
      phoneNumber: {
        isUsed: boolean;
      };
    };
  }[];
}

const SingleReceiverConfigMobileForm = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const [user] = useUserState();
  const [isError, setIsError] = useState(false);
  const [verificationModal, setVerificationModal] = useState({
    opened: false,
    index: 99,
    prevData: {
      password: {
        isUsed: false,
        password: "",
      },
      phoneNumber: {
        isUsed: false,
      },
    },
  });

  const { control, register, getValues, setValue } =
    useFormContext<SingleReceiverConfigMobileFormData>();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "receivers",
  });

  const { data: remainContractsCreditDto, refetch } =
    useContractRemainCreditsQuery();

  const { data: templateDetailInfo } = useTemplateDetailInfoQuery({
    organizationId: user?.organization,
    templateId,
  });

  const isPossibleToAddReceiver = () => {
    if (
      (remainContractsCreditDto?.remainContractCredit.remainCredits as number) -
        getValues("receivers").length >
      0
    )
      return true;
    else return false;
  };

  const isConfirmButtonActive = () => {
    if (
      (fields[verificationModal.index].verification.password.isUsed &&
        fields[verificationModal.index].verification.password.password.length <
          4) ||
      fields[verificationModal.index].verification.password.password.length > 12
    ) {
      return false;
    } else return true;
  };

  const handleAddReceiverClick = () => {
    if (!remainContractsCreditDto?.remainContractCredit.remainCredits) {
      refetch();
      return;
    } else if (!isPossibleToAddReceiver()) return;

    append({
      name: "",
      email: "",
      phoneNumber: "",
      verification: {
        password: {
          isUsed: false,
          password: "",
        },
        phoneNumber: {
          isUsed: false,
        },
      },
    });
  };

  const onCloseHandler = () => {
    update(verificationModal.index, {
      ...fields[verificationModal.index],
      verification: verificationModal.prevData,
    });

    setVerificationModal((prev) => ({ ...prev, opened: false, index: 99 }));
  };

  useEffect(() => {
    if (
      (templateDetailInfo?.contractTemplateDetails.receiverCnt as number) > 1
    ) {
      navigate(`/template/send/${templateId}/config/many`);
    }
  }, [templateDetailInfo?.contractTemplateDetails.receiverCnt]);

  useEffect(() => {
    if (verificationModal.opened) {
      setVerificationModal((prev) => ({
        ...prev,
        prevData: fields[verificationModal.index].verification,
      }));
    }
  }, [verificationModal.opened]);

  const handlePhoneNumberChange = (index: number, value: string) => {
    const formatted = formatPhoneNumber(value);
    setIsError(!isValidPhoneNumber(formatted || "") && value !== "");
    setValue(`receivers.${index}.phoneNumber`, formatted || "");
  };

  const handleEmailChange = (index: number, value: string) => {
    setValue(`receivers.${index}.email`, value);
    setValue(
      `receivers.${index}.isEmailError`,
      value !== "" && !isValidEmail(value) ? true : false
    );
  };

  return (
    <>
      <SingleReceiverLayout>
        <div className="single-receiver-form-title">
          <Body1SemiBold>수신자</Body1SemiBold>
          <LeftSendTitle>
            잔여건수:{" "}
            {(
              (remainContractsCreditDto?.remainContractCredit
                .remainCredits as number) || 0
            ).toLocaleString("ko")}
            건
          </LeftSendTitle>
        </div>
        {((remainContractsCreditDto?.remainContractCredit.remainCredits ||
          0) === 0
          ? []
          : fields
        ).map((field, index) => (
          <div key={field.id} className="single-receiver-form-from">
            <FormList>
              <Input
                width="100%"
                placeholder="이름"
                {...register(`receivers.${index}.name` as const)}
              />

              <Input
                width="100%"
                placeholder="이메일"
                {...register(`receivers.${index}.email` as const, {
                  onChange: (e) => handleEmailChange(index, e.target.value),
                })}
                isError={getValues(`receivers.${index}.isEmailError`)}
                errorMessage={"이메일 형식이 올바르지 않습니다."}
              />
              <Input
                width="100%"
                placeholder="휴대폰 번호"
                {...register(`receivers.${index}.phoneNumber` as const, {
                  onChange: (e) =>
                    handlePhoneNumberChange(index, e.target.value),
                })}
                isError={isError}
                errorMessage="휴대폰 번호 형식이 올바르지 않습니다."
              />
              <div
                style={{
                  marginLeft: "2.4rem",
                }}
              >
                <Body1Medium>
                  {field.verification.password.isUsed &&
                  field.verification.password.password
                    ? "암호 "
                    : field.verification.phoneNumber.isUsed
                    ? "보안"
                    : `개별`}
                  인증:{" "}
                </Body1Medium>
                <Button
                  colorType="tertiary"
                  size="small"
                  onClick={() =>
                    setVerificationModal((prev) => ({
                      ...prev,
                      opened: true,
                      index,
                    }))
                  }
                >
                  {(field.verification.password.isUsed &&
                    field.verification.password.password) ||
                  field.verification.phoneNumber.isUsed
                    ? "변경"
                    : "설정"}
                  하기
                </Button>
              </div>
            </FormList>
          </div>
        ))}
      </SingleReceiverLayout>
      <ButtonContainer>
        <Button
          colorType="tertiary"
          size="small"
          onClick={() => remove(fields.length - 1)}
          disabled={fields.length <= 1}
        >
          <Body3Regular
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            수신자 삭제하기
          </Body3Regular>
        </Button>
        <Button
          colorType="primary"
          size="small"
          onClick={handleAddReceiverClick}
          disabled={!isPossibleToAddReceiver()}
        >
          <Body3Regular
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            수신자 추가하기
          </Body3Regular>
        </Button>
      </ButtonContainer>

      {verificationModal.opened && (
        <ActionModal
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "16px auto",
              }}
            >
              인증 수단 추가
            </div>
          }
          disableCloseWithOutsideClickOrEsc
          handleModalClose={onCloseHandler}
          handleCancelButton={onCloseHandler}
          handleConfirmButton={() =>
            setVerificationModal((prev) => ({
              ...prev,
              opened: false,
              index: 99,
            }))
          }
          wideBtn
          disabledConfirmButton={!isConfirmButtonActive()}
        >
          <VerificationModalStyled>
            <div className="password">
              <div className="label">
                <Toggle
                  isSelected={
                    fields[verificationModal.index].verification.password
                      .isUsed as boolean
                  }
                  handleToggle={() => {
                    setValue(
                      `receivers.${verificationModal.index}.verification.password.isUsed`,
                      !getValues(
                        `receivers.${verificationModal.index}.verification.password.isUsed`
                      )
                    );

                    if (
                      !getValues(
                        `receivers.${verificationModal.index}.verification.password.isUsed`
                      )
                    ) {
                      setValue(
                        `receivers.${verificationModal.index}.verification.password.password`,
                        ""
                      );
                    }

                    if (
                      getValues(
                        `receivers.${verificationModal.index}.verification.phoneNumber.isUsed`
                      ) &&
                      getValues(
                        `receivers.${verificationModal.index}.verification.password.isUsed`
                      )
                    ) {
                      setValue(
                        `receivers.${verificationModal.index}.verification.phoneNumber.isUsed`,
                        false
                      );
                    }
                  }}
                />
                <Body2Medium>암호 인증</Body2Medium>
              </div>
              <Input
                type="text"
                placeholder="암호 입력 (4~12자)"
                minLength={4}
                maxLength={12}
                value={
                  fields[verificationModal.index].verification.password.password
                }
                setValue={(password: string) =>
                  setValue(
                    `receivers.${verificationModal.index}.verification.password.password`,
                    password
                  )
                }
                isDisabled={
                  !fields[verificationModal.index].verification.password.isUsed
                }
              />
            </div>
            <div className="secure">
              <div className="label">
                <Toggle
                  isSelected={
                    fields[verificationModal.index].verification.phoneNumber
                      .isUsed
                  }
                  handleToggle={() => {
                    setValue(
                      `receivers.${verificationModal.index}.verification.phoneNumber.isUsed`,
                      !getValues(
                        `receivers.${verificationModal.index}.verification.phoneNumber.isUsed`
                      )
                    );

                    if (
                      getValues(
                        `receivers.${verificationModal.index}.verification.password.isUsed`
                      ) &&
                      getValues(
                        `receivers.${verificationModal.index}.verification.phoneNumber.isUsed`
                      )
                    ) {
                      setValue(
                        `receivers.${verificationModal.index}.verification.password.isUsed`,
                        false
                      );
                      setValue(
                        `receivers.${verificationModal.index}.verification.password.password`,
                        ""
                      );
                    }
                  }}
                />
                <Body2Medium>보안 인증</Body2Medium>
              </div>
              <div>
                <div
                  className={`message${
                    fields[verificationModal.index].verification.phoneNumber
                      .isUsed
                      ? " selected"
                      : ""
                  }`}
                >
                  <Body1SemiBold>휴대폰 본인인증</Body1SemiBold>
                  <Body1Regular>
                    휴대폰 본인인증은 전화번호가 입력된
                    <br />
                    수신자에게만 적용할 수 있습니다.
                  </Body1Regular>
                </div>
              </div>
            </div>
          </VerificationModalStyled>
        </ActionModal>
      )}
    </>
  );
};

export default SingleReceiverConfigMobileForm;

const SingleReceiverLayout = styled.div`
  margin-bottom: 3.2rem;
  .single-receiver-form-title {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 2rem;
  }

  .single-receiver-form-from {
    display: flex;
    justify-content: space-between;
    gap: 0.8rem;
    align-items: center;
    margin-bottom: 1rem;

    > div {
      display: flex;
      width: 100%;
      gap: 1.2rem;
      align-items: center;
    }

    > div > div {
      display: flex;
      gap: 1.2rem;
      align-items: center;
    }

    svg {
      cursor: pointer;
    }

    input {
      margin-top: 0;
      margin-left: 0;
    }
  }
`;

const FormList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  & > * {
    width: 100%;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`;

const LeftSendTitle = styled(Body1Medium)`
  color: var(--text-emphasis);
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 0.8rem;
  margin: 1.6rem auto 3.8rem;
  & > button {
    flex-grow: 1;
  }
`;

import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";
import { GetTemplateDetailsByIdRequest } from "models/Template";

const useTemplateDetailsById = ({
  organizationUuid = "",
  templateUuid = "",
}: Partial<GetTemplateDetailsByIdRequest>) => {
  return useQuery({
    ...queries.template.detail({ organizationUuid, templateUuid }),
    enabled: !!organizationUuid && !!templateUuid,
  });
};

export default useTemplateDetailsById;

import { Body1Medium, SubTitle } from "components/TextStyle";
import { Board } from "pages/dashboard/style";
import styled from "styled-components";

export const ConfigNavigation = styled.nav`
  background-color: var(--bg-white);
  padding: 0 2rem;
  width: 100%;
  height: 6.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.05);
  box-sizing: border-box;

  .prev {
    cursor: pointer;
  }
`;

export const ConfigTitle = styled(SubTitle)`
  color: var(--text-secondary);
`;

export const NextButton = styled(Body1Medium)`
  padding: 0rem 0.4rem 0 0;
  color: var(--text-emphasis);
`;

export const ConfigContent = styled(Board)`
  margin: auto;
  padding: 8rem;

  input {
    margin-top: 1.2rem;
  }
  .config-subtitle {
    margin-top: 2rem;
  }
  .config-controller {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 25rem;
  }
`;

export const ContentLayout = styled.main`
  width: 100%;
  max-width: 148rem;
  min-width: 71.25rem;
  min-height: 100%;
  padding: 3rem 0;
  margin: 0 auto;
`;

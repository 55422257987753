import { Board } from "pages/dashboard/style";
import styled from "styled-components";

export const ConfigMobileNavigation = styled.nav`
  background-color: var(--bg-white);
  padding: 0 4rem;
  width: 100%;
  height: 6.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.05);
  box-sizing: border-box;

  button {
    width: 8.4rem;
    height: 3.8rem;
  }

  & > div {
    color: var(--text-secondary);
    cursor: pointer;
  }
`;

export const ConfigMobileContent = styled(Board)`
  margin: auto;
  padding: 1.6rem;

  input {
    margin-top: 1.2rem;
  }
  .config-subtitle {
    margin-top: 2rem;
  }
  .config-controller {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 25rem;
  }
`;

export const ContentMobileLayout = styled.main`
  width: 100%;
  min-height: 100%;
  padding: 1rem 0;
  margin: 0 auto;
`;

import { IPage } from "interfaces/contract";
import React, { useEffect, useState } from "react";
import Signature from "./Signature";
import LongText from "./LongText";
import Number from "./Number";
import Date from "./Date";
import Address from "./Address";
import ImageField from "./Image";
import DropdownList from "./DropdownList";
import Selection from "./Selection";
import { Field, History } from "pages/contract/send";
import Text from "./Text";
import { TextAlign } from "models/Contract";
import { getDefaultSizeOfFields } from "utils/utility";
import { getAllFields } from "utils/getAllFields";

interface ItemProps extends React.HTMLAttributes<HTMLDivElement> {
  newField?: boolean;
  template?: boolean; // 템플릿 만들기의 경우
  edit?: boolean;
  preview?: boolean;
  complete?: boolean;
  field: {
    id: number;
    label: string;
    value: Field;
    icon: React.ReactNode;
  };
  id: string;
  optionId?: string;
  type: "sender" | "receiver";
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  selectedPageId: string;
  setSelectedFieldType?: React.Dispatch<React.SetStateAction<Field>>;
  selectedFieldId?: string;
  setSelectedFieldId?: React.Dispatch<React.SetStateAction<string>>;
  handleDragStart?: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDrag?: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDragEnd?: (
    e: React.DragEvent<HTMLDivElement>,
    optionId?: string
  ) => void;
  selectField?: (id: string, type: Field) => void;
  scale: number;
  setSignatureSelectionModal?: React.Dispatch<React.SetStateAction<boolean>>;
  editDropdown?: (contents: string) => void;
  align?: ({
    type,
    align,
    all,
  }: {
    type: Field;
    align?: TextAlign | undefined;
    all?: boolean | undefined;
  }) => void;
  openModal?: () => void;
  pageContainerRef?: null | HTMLDivElement;
  width?: number;
  height?: number;
  saveToHistory?: (current: History) => void;
  templateSend?: boolean; // 템플릿 전송 미리보기
  isMobile?: boolean;
  saveLastSize?: (
    field: Field,
    size: {
      w: number;
      h: number;
    }
  ) => void;
  lastFieldSize?: { [key: string]: number[] };
}

export default function Item({
  newField = false,
  template,
  edit = false,
  preview = false,
  complete = false,
  field,
  id,
  optionId,
  type,
  pages,
  setPages,
  selectedPageId,
  setSelectedFieldType,
  selectedFieldId,
  setSelectedFieldId,
  handleDragStart,
  handleDrag,
  handleDragEnd,
  selectField,
  scale,
  setSignatureSelectionModal,
  editDropdown,
  align,
  openModal,
  pageContainerRef,
  width,
  height,
  saveToHistory,
  templateSend,
  isMobile,
  saveLastSize,
  lastFieldSize,
  ...args
}: ItemProps) {
  const [isResizing, setIsResizing] = useState(false);
  const [initial, setInitial] = useState({ direction: "", x: 0, y: 0 });
  const [currentSize, setCurrentSize] = useState({
    w:
      lastFieldSize && field.value
        ? lastFieldSize?.[field.value][0]
        : width ||
          (pages
            .find((page) => page.id === selectedPageId)
            ?.fields.find(
              (FIELD) =>
                `${FIELD.fieldType}:${FIELD.id}` === `${field.value}:${id}`
            )?.width as number),
    h:
      lastFieldSize && field.value
        ? lastFieldSize?.[field.value][1]
        : height ||
          (pages
            .find((page) => page.id === selectedPageId)
            ?.fields.find(
              (FIELD) =>
                `${FIELD.fieldType}:${FIELD.id}` === `${field.value}:${id}`
            )?.height as number),
  });

  const onChangeHandler = (id: string, value: string) => {
    setPages((prev) =>
      prev.map((page) => {
        if (isMobile) {
          if (page.order.toString() === selectedPageId) {
            return {
              ...page,
              fields: page.fields.map((field) => {
                if (`${field.fieldType}:${field.id}` === id) {
                  return {
                    ...field,
                    value: value,
                  };
                } else return { ...field };
              }),
            };
          } else return { ...page };
        } else {
          if (page.id === selectedPageId) {
            return {
              ...page,
              fields: page.fields.map((field) => {
                if (`${field.fieldType}:${field.id}` === id) {
                  return {
                    ...field,
                    value: value,
                  };
                } else return { ...field };
              }),
            };
          }
          return { ...page };
        }
      })
    );
  };

  const onDeleteHandler = (id: string, optionId?: string) => {
    setPages((prev) => {
      const NEW_PAGES = prev.map((page) => {
        if (isMobile) {
          if (page.order.toString() === selectedPageId) {
            return {
              ...page,
              fields: optionId
                ? page.fields.find(
                    (field) => `${field.fieldType}:${field.id}` === id
                  )?.selectCount?.options?.length === 1
                  ? page.fields.filter(
                      (field) => `${field.fieldType}:${field.id}` !== id
                    )
                  : page.fields.map((field) => {
                      if (`${field.fieldType}:${field.id}` === id) {
                        return {
                          ...field,
                          buttonsCount: (field.buttonsCount as number) - 1,
                          selectCount: {
                            ...field.selectCount,
                            options: field.selectCount?.options?.filter(
                              (option) =>
                                `${field.fieldType}:${option.id}` !== optionId
                            ),
                          },
                        };
                      } else return { ...field };
                    })
                : page.fields.filter(
                    (field) => `${field.fieldType}:${field.id}` !== id
                  ),
            };
          } else return { ...page };
        } else {
          if (page.id === selectedPageId) {
            return {
              ...page,
              fields: optionId
                ? page.fields.find(
                    (field) => `${field.fieldType}:${field.id}` === id
                  )?.selectCount?.options?.length === 1
                  ? page.fields.filter(
                      (field) => `${field.fieldType}:${field.id}` !== id
                    )
                  : page.fields.map((field) => {
                      if (`${field.fieldType}:${field.id}` === id) {
                        return {
                          ...field,
                          buttonsCount: (field.buttonsCount as number) - 1,
                          selectCount: {
                            ...field.selectCount,
                            options: field.selectCount?.options?.filter(
                              (option) =>
                                `${field.fieldType}:${option.id}` !== optionId
                            ),
                          },
                        };
                      } else return { ...field };
                    })
                : page.fields.filter(
                    (field) => `${field.fieldType}:${field.id}` !== id
                  ),
            };
          } else return { ...page };
        }
      });

      saveToHistory &&
        saveToHistory({
          pages: NEW_PAGES,
          selectedFieldId: "",
          selectedFieldType: null,
          isCurrent: true,
        });

      return NEW_PAGES;
    });

    setSelectedFieldId && setSelectedFieldId("");
    setSelectedFieldType && setSelectedFieldType(null);
  };

  const handleMouseDown = (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>,
    direction: string
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!selectedFieldId) return;
    setInitial({ direction, x: e.screenX, y: e.screenY });

    setIsResizing(true);
  };

  const handleMouseUp = () => {
    const fieldType = field.value;

    setPages((prev) => {
      const NEW_PAGES = prev.map((page) => {
        if (isMobile) {
          if (page.order.toString() === selectedPageId) {
            return {
              ...page,
              fields: page.fields.map((field) => {
                if (field.id === id) {
                  return {
                    ...field,
                    width: currentSize.w,
                    height: currentSize.h,
                  };
                } else return { ...field };
              }),
            };
          } else return { ...page };
        } else {
          if (page.id === selectedPageId) {
            return {
              ...page,
              fields: page.fields.map((field) => {
                if (field.id === id) {
                  return {
                    ...field,
                    width: currentSize.w,
                    height: currentSize.h,
                  };
                } else return { ...field };
              }),
            };
          } else return { ...page };
        }
      });

      saveLastSize &&
        saveLastSize(field.value, { w: currentSize.w, h: currentSize.h });

      saveToHistory &&
        saveToHistory({
          pages: NEW_PAGES,
          selectedFieldId: `${fieldType}:${id}`,
          selectedFieldType: fieldType,
          isCurrent: true,
        });

      return NEW_PAGES;
    });

    setIsResizing(false);
  };

  const handleMouseMove = (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>
  ) => {
    if (!isResizing) return;
    const boundary = pageContainerRef?.getBoundingClientRect();
    const [minWidth, minHeight] = getDefaultSizeOfFields(field.value);
    const { clientX, clientY } = e;

    if (!boundary) return;

    let resizedSize = {
      w: 0,
      h: 0,
    };

    if (initial.direction === "top left") {
      resizedSize = {
        w:
          currentSize.w -
          (Math.min(e.screenX, boundary.right) - initial.x) / scale,
        h:
          currentSize.h -
          (Math.min(e.screenY, boundary.bottom + 132) - initial.y) / scale,
      };

      setPages((pages) =>
        pages.map((page) => {
          if (isMobile) {
            if (page.order.toString() === selectedPageId) {
              return {
                ...page,
                fields: page.fields.map((field) => {
                  if (field.id === id) {
                    return {
                      ...field,
                      position: {
                        ...field.position,
                        x:
                          resizedSize.w <= minWidth
                            ? field.position.x
                            : (clientX - boundary.left) / scale,
                        y:
                          resizedSize.h <= minHeight
                            ? field.position.y
                            : (clientY - boundary.top) / scale,
                      },
                    };
                  } else return { ...field };
                }),
              };
            } else return { ...page };
          } else {
            if (page.id === selectedPageId) {
              return {
                ...page,
                fields: page.fields.map((field) => {
                  if (field.id === id) {
                    return {
                      ...field,
                      position: {
                        ...field.position,
                        x:
                          resizedSize.w <= minWidth
                            ? field.position.x
                            : (clientX - boundary.left) / scale,
                        y:
                          resizedSize.h <= minHeight
                            ? field.position.y
                            : (clientY - boundary.top) / scale,
                      },
                    };
                  } else return { ...field };
                }),
              };
            } else return { ...page };
          }
        })
      );
    } else if (initial.direction === "top right") {
      resizedSize = {
        w:
          currentSize.w +
          (Math.min(e.screenX, boundary.right) - initial.x) / scale,
        h:
          currentSize.h -
          (Math.min(e.screenY, boundary.bottom + 132) - initial.y) / scale,
      };

      setPages((pages) =>
        pages.map((page) => {
          if (isMobile) {
            if (page.order.toString() === selectedPageId) {
              return {
                ...page,
                fields: page.fields.map((field) => {
                  if (field.id === id) {
                    return {
                      ...field,
                      position: {
                        ...field.position,
                        y:
                          resizedSize.h <= minHeight
                            ? field.position.y
                            : (clientY - boundary.top) / scale,
                      },
                    };
                  } else return { ...field };
                }),
              };
            } else return { ...page };
          } else {
            if (page.id === selectedPageId) {
              return {
                ...page,
                fields: page.fields.map((field) => {
                  if (field.id === id) {
                    return {
                      ...field,
                      position: {
                        ...field.position,
                        y:
                          resizedSize.h <= minHeight
                            ? field.position.y
                            : (clientY - boundary.top) / scale,
                      },
                    };
                  } else return { ...field };
                }),
              };
            } else return { ...page };
          }
        })
      );
    } else if (initial.direction === "bottom left") {
      resizedSize = {
        w:
          currentSize.w -
          (Math.min(e.screenX, boundary.right) - initial.x) / scale,
        h:
          currentSize.h +
          (Math.min(e.screenY, boundary.bottom + 132) - initial.y) / scale,
      };

      setPages((pages) =>
        pages.map((page) => {
          if (isMobile) {
            if (page.order.toString() === id) {
              return {
                ...page,
                fields: page.fields.map((field) => {
                  if (field.id === id) {
                    return {
                      ...field,
                      position: {
                        ...field.position,
                        x:
                          resizedSize.w <= minWidth
                            ? field.position.x
                            : (clientX - boundary.left) / scale,
                      },
                    };
                  } else return { ...field };
                }),
              };
            } else return { ...page };
          } else {
            if (page.id === selectedPageId) {
              return {
                ...page,
                fields: page.fields.map((field) => {
                  if (field.id === id) {
                    return {
                      ...field,
                      position: {
                        ...field.position,
                        x:
                          resizedSize.w <= minWidth
                            ? field.position.x
                            : (clientX - boundary.left) / scale,
                      },
                    };
                  } else return { ...field };
                }),
              };
            } else return { ...page };
          }
        })
      );
    } else {
      resizedSize = {
        w:
          currentSize.w +
          (Math.min(e.screenX, boundary.right) - initial.x) / scale,
        h:
          currentSize.h +
          (Math.min(e.screenY, boundary.bottom + 132) - initial.y) / scale,
      };
    }

    setCurrentSize({
      w: resizedSize.w,
      h: resizedSize.h,
    });
  };

  useEffect(() => {
    newField &&
      setCurrentSize((prev) => ({
        ...prev,
        w: lastFieldSize?.[field.value as string][0] as number,
        h: lastFieldSize?.[field.value as string][1] as number,
      }));
  }, [lastFieldSize]);

  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  return (
    <>
      {field.value === "SIGN" && (
        <Signature
          isMobile={isMobile}
          template={template}
          edit={edit}
          complete={complete}
          draggable={!preview}
          id={`${field.value}:${id}`}
          type={type}
          fields={getAllFields(pages)}
          pages={pages}
          setPages={setPages}
          selectedFieldId={selectedFieldId}
          selectedPageId={selectedPageId}
          setSelectedFieldId={setSelectedFieldId}
          onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          onDeleteHandler={onDeleteHandler}
          selectField={selectField}
          scale={scale}
          setSignatureSelectionModal={setSignatureSelectionModal}
          templateSend={templateSend}
          currentSize={currentSize}
          handleMouseDown={handleMouseDown}
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          newField={newField}
          {...args}
        />
      )}
      {field.value === "TEXT" && (
        <Text
          edit={edit}
          complete={complete}
          draggable={!preview}
          id={`${field.value}:${id}`}
          type={type}
          fields={getAllFields(pages)}
          pages={pages}
          setPages={setPages}
          selectedPageId={selectedPageId}
          selectedFieldId={selectedFieldId}
          setSelectedFieldId={setSelectedFieldId}
          onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          onDeleteHandler={onDeleteHandler}
          selectField={selectField}
          scale={scale}
          align={align}
          onChangeHandler={onChangeHandler}
          currentSize={currentSize}
          handleMouseDown={handleMouseDown}
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          newField={newField}
          isMobile={isMobile}
          {...args}
        />
      )}
      {field.value === "LONGTEXT" && (
        <LongText
          edit={edit}
          complete={complete}
          draggable={!preview}
          id={`${field.value}:${id}`}
          type={type}
          fields={getAllFields(pages)}
          pages={pages}
          setPages={setPages}
          selectedPageId={selectedPageId}
          selectedFieldId={selectedFieldId}
          setSelectedFieldId={setSelectedFieldId}
          onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          onDeleteHandler={onDeleteHandler}
          selectField={selectField}
          scale={scale}
          align={align}
          pageContainerRef={pageContainerRef}
          currentSize={currentSize}
          handleMouseDown={handleMouseDown}
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          newField={newField}
          onChangeHandler={onChangeHandler}
          isMobile={isMobile}
          {...args}
        />
      )}
      {field.value === "NUMBER" && (
        <Number
          edit={edit}
          complete={complete}
          draggable={!preview}
          id={`${field.value}:${id}`}
          type={type}
          fields={getAllFields(pages)}
          pages={pages}
          setPages={setPages}
          selectedPageId={selectedPageId}
          selectedFieldId={selectedFieldId}
          setSelectedFieldId={setSelectedFieldId}
          onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          onDeleteHandler={onDeleteHandler}
          selectField={selectField}
          scale={scale}
          align={align}
          pageContainerRef={pageContainerRef}
          currentSize={currentSize}
          handleMouseDown={handleMouseDown}
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          newField={newField}
          onChangeHandler={onChangeHandler}
          isMobile={isMobile}
          {...args}
        />
      )}
      {field.value === "DATE" && (
        <Date
          edit={edit}
          complete={complete}
          draggable={!preview}
          id={`${field.value}:${id}`}
          type={type}
          fields={getAllFields(pages)}
          pages={pages}
          setPages={setPages}
          selectedPageId={selectedPageId}
          selectedFieldId={selectedFieldId}
          setSelectedFieldId={setSelectedFieldId}
          onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          onDeleteHandler={onDeleteHandler}
          selectField={selectField}
          scale={scale}
          align={align}
          pageContainerRef={pageContainerRef}
          currentSize={currentSize}
          handleMouseDown={handleMouseDown}
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          newField={newField}
          isMobile={isMobile}
          {...args}
        />
      )}
      {field.value === "ADDRESS" && (
        <Address
          edit={edit}
          complete={complete}
          draggable={!preview}
          id={`${field.value}:${id}`}
          type={type}
          fields={getAllFields(pages)}
          pages={pages}
          setPages={setPages}
          selectedPageId={selectedPageId}
          selectedFieldId={selectedFieldId}
          onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          onDeleteHandler={onDeleteHandler}
          selectField={selectField}
          scale={scale}
          align={align}
          pageContainerRef={pageContainerRef}
          currentSize={currentSize}
          handleMouseDown={handleMouseDown}
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          newField={newField}
          isMobile={isMobile}
          {...args}
        />
      )}
      {field.value === "IMAGE" && (
        <ImageField
          edit={edit}
          complete={complete}
          draggable={!preview}
          id={`${field.value}:${id}`}
          type={type}
          fields={getAllFields(pages)}
          pages={pages}
          setPages={setPages}
          selectedPageId={selectedPageId}
          selectedFieldId={selectedFieldId}
          onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          onDeleteHandler={onDeleteHandler}
          selectField={selectField}
          scale={scale}
          pageContainerRef={pageContainerRef}
          currentSize={currentSize}
          handleMouseDown={handleMouseDown}
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          newField={newField}
          isMobile={isMobile}
          {...args}
        />
      )}
      {field.value === "DROPDOWN" && (
        <DropdownList
          edit={edit}
          complete={complete}
          draggable={!preview}
          id={`${field.value}:${id}`}
          type={type}
          fields={getAllFields(pages)}
          pages={pages}
          setPages={setPages}
          selectedPageId={selectedPageId}
          selectedFieldId={selectedFieldId}
          onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          onDeleteHandler={onDeleteHandler}
          selectField={selectField}
          scale={scale}
          align={align}
          editDropdown={editDropdown}
          openModal={openModal}
          pageContainerRef={pageContainerRef}
          currentSize={currentSize}
          handleMouseDown={handleMouseDown}
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          newField={newField}
          isMobile={isMobile}
          {...args}
        />
      )}
      {field.value === "CHECKBOX" && (
        <Selection
          edit={edit}
          complete={complete}
          draggable={!preview}
          id={`${field.value}:${id}`}
          optionId={`${field.value}:${optionId}`}
          type={type}
          fields={getAllFields(pages)}
          pages={pages}
          setPages={setPages}
          selectedPageId={selectedPageId}
          selectedFieldId={selectedFieldId}
          onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={(e) => handleDragEnd && handleDragEnd(e, optionId)}
          onDeleteHandler={onDeleteHandler}
          selectField={selectField}
          scale={scale}
          align={align}
          newField={newField}
          isMobile={isMobile}
          {...args}
        />
      )}
    </>
  );
}

import { useMutation } from "@tanstack/react-query";
import { postTemplateCopy } from "apis/template";

const usePostTemplateCopy = () => {
  return useMutation({
    mutationFn: postTemplateCopy,
  });
};

export default usePostTemplateCopy;

import Button from "components/Common/Button/Button";
import { Body1SemiBold } from "components/TextStyle";
import useBodyScrollLock from "hooks/useBodyScrollLock";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function Expired({
  date,
  prev,
}: {
  date: string;
  prev?: boolean;
}) {
  const navigate = useNavigate();
  const { lockScroll, openScroll } = useBodyScrollLock();

  useEffect(() => {
    lockScroll();

    return () => {
      openScroll();
    };
  }, []);

  return (
    <ExpiredStyled>
      <div>
        <div className="title">작성기한이 만료된 문서입니다.</div>
        <div className="expiredAt">작성기한: {date}</div>
      </div>
      <div>
        <Button
          colorType="primary"
          size="large"
          onClick={() => {
            if (prev) navigate("/contract/dashboard/normal", { replace: true });
            else navigate("/", { replace: true });
          }}
          style={{ width: "24rem", height: "5.6rem" }}
        >
          <Body1SemiBold>나가기</Body1SemiBold>
        </Button>
      </div>
    </ExpiredStyled>
  );
}

const ExpiredStyled = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 6rem;

  & > div {
    display: flex;
    flex-direction: column;
    row-gap: 4rem;

    .title {
      font-size: 4.6rem;
      font-weight: 700;
      line-height: 3.6rem;
      letter-spacing: -0.03em;
      color: white;
    }

    .expiredAt {
      font-size: 1.8rem;
      line-height: 2.6rem;
      letter-spacing: -0.03em;
      color: var(--text-disabled);
      text-align: center;
    }
  }
`;

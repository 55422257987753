import { Body3SemiBold } from "components/TextStyle";
import styled from "styled-components";

const TitleTabLayout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.8rem;
  margin-bottom: 3rem;

  svg {
    cursor: pointer;
    width: 3.6rem;
    height: 3.6rem;
  }

  hr {
    width: 1px;
    height: 1.2rem;
    outline: none;
    border: none;
    background-color: var(--grey-300);
    margin: 0;
  }
`;

const TitleTabItem = styled(Body3SemiBold)<{
  isSelected?: boolean;
}>`
  display: inline-block;
  color: ${({ isSelected }) =>
    isSelected ? "inherit" : "var(--text-tertiary)"};

  .line {
    background-color: #161616;
    width: 100%;
    height: 0.3rem;
    border-radius: 0.2rem 0.2rem 0 0;
    opacity: 0;
  }

  .line.active {
    opacity: 1;
  }
`;

const TitleTab = {
  Layout: TitleTabLayout,
  Item: TitleTabItem,
};

export default TitleTab;

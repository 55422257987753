export const REGEX = {
  password: /^(?=\S+$)(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/,
  contract: {
    password: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{4,15}$/,
  },
  template: {
    password: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{4,15}$/,
  },
  directUrl: {
    password: /^[a-zA-Z0-9]{4,12}$/,
  },
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  name: /^[a-zA-Z0-9가-힣]*$/,
  phoneNumber: /^\d{3}-\d{3,4}-\d{4}$/,
};

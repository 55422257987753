import { useMutation } from "@tanstack/react-query";
import { BaseResponse } from "apis/core";
import { postSignVerify } from "apis/sign";
import { AxiosError } from "axios";
import { PostSignVerifyRequest, ReceiverVerifyType } from "models/Sign";

const useSignVerifyMutation = () => {
  return useMutation({
    mutationFn: (data: {
      verifyType: ReceiverVerifyType;
      data: PostSignVerifyRequest;
    }) => postSignVerify(data.verifyType, data.data),
    onError: (error: AxiosError<BaseResponse>) => {
      return error;
    },
  });
};

export default useSignVerifyMutation;

import { breakpoints } from "components/styles";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet, RouteObject, createBrowserRouter } from "react-router-dom";
import pxToNumber from "utils/pxToNumber";
import { NthParameter } from "utils/types";

// Screen Context 타입
interface ScreenContextValue {
  isMobile: boolean;
}

interface ResponsiveRoute extends Omit<RouteObject, "children"> {
  mobile?: ReactNode;
  children?: ResponsiveRoute[];
}
// Context 관련 타입
const ScreenContext = createContext<ScreenContextValue | undefined>(undefined);

export const useScreenType = (): ScreenContextValue => {
  const context = useContext(ScreenContext);
  if (!context) {
    throw new Error("useScreenType must be used within a ScreenProvider");
  }
  return context;
};

const ScreenProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth <= pxToNumber(breakpoints.lg)
  );

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.innerWidth <= pxToNumber(breakpoints.lg));
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ScreenContext.Provider value={{ isMobile }}>
      {children}
    </ScreenContext.Provider>
  );
};

export const createResponsiveRouter = (
  routesConfig: ResponsiveRoute[],
  opts?: NthParameter<typeof createBrowserRouter, 1>
) => {
  const wrapRoutes = (routes: ResponsiveRoute[]): RouteObject[] =>
    routes.map(({ mobile, element, children, index, ...rest }) => {
      // 가장 상위의 라우트일 경우 provider를 설정해서 하위 라우터의 스크린 타입을 사용할 수 있도록 한다.
      if (rest.path === "/") {
        return {
          ...rest,
          element: <ScreenProvider>{element}</ScreenProvider>,
          children: children ? wrapRoutes(children) : undefined,
        };
      }
      // index라우트의 경우의 타입 처리
      if (index === true) {
        return {
          index: true,
          element: <ResponsiveElement mobile={mobile} web={element} />,
        };
      }

      // index라우트가 아닐 경우의 타입 처리
      return {
        ...rest,
        element: <ResponsiveElement mobile={mobile} web={element} />,
        children: children ? wrapRoutes(children) : undefined,
      };
    });

  const routes = wrapRoutes(routesConfig);

  return createBrowserRouter(routes, opts);
};

const ResponsiveElement: React.FC<{
  mobile?: ReactNode;
  web?: ReactNode; // web을 선택적으로 변경
}> = ({ mobile, web }) => {
  const { isMobile } = useScreenType();

  return <>{isMobile && mobile ? mobile : web ?? <Outlet />}</>;
};

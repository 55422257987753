import styled from "styled-components";

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem 2.4rem;
  border-radius: 2rem;
  box-sizing: border-box;
  background: var(--bg-white);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  height: fit-content;

  .modalContentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const DefaultModalContainer = styled(ModalContainer)`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;

  padding: 0rem;
  border-radius: 0;

  .closeIcon {
    align-self: flex-end;
    width: 3.2rem;
    margin: 1rem;
    cursor: pointer;
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding: 0 2rem;

    gap: 0.5rem;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: start;

    overflow-y: scroll;

    padding: 0 2rem;

    -ms-overflow-style: none; /*IE, Edge*/
    scrollbar-width: none; /*Firefox*/
    ::-webkit-scrollbar {
      display: none; /*Chrome, Safari, Opera*/
      width: 0px;
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    padding: 0 1rem 2rem 1rem;
    margin-top: auto;
  }
`;

export const ActionModalContainer = styled(ModalContainer)<{
  $widthFull: boolean;
  $wideBtn?: boolean;
  $isButtonRow?: boolean;
}>`
  pointer-events: auto;
  width: ${({ $widthFull }) => ($widthFull ? "content-fit" : "46rem")};

  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.6rem;

    .logo {
      width: 8.4rem;
      height: auto;
    }

    .closeIcon {
      width: 2.6rem;
      align-self: flex-end;
      cursor: pointer;
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: ${({ $isButtonRow }) => ($isButtonRow ? "row" : "column")};
    margin-top: 3.6rem;

    width: ${({ $wideBtn }) => $wideBtn && "100%"};
    gap: 1rem;
    align-items: center;
    justify-content: center;

    button {
      width: 100%;
      height: 4.8rem;
    }
  }
`;

export const ContentModalContainer = styled(ModalContainer)<{
  $size: "large" | "medium";
  $maxHeight?: string;
}>`
  width: ${(props) => (props.$size === "large" ? "108rem" : "74rem")};
  max-height: ${({ $maxHeight }) => $maxHeight || "65rem"};
  overflow-y: scroll;
  border-radius: 0.5rem;
  row-gap: 3.2rem;
  margin: 0 1rem;

  .contentContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-button {
    display: none;
  }
`;

export const ContentModalTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  &:has(.prevIcon) {
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .modalIcon {
    cursor: pointer;
    width: 3.2rem;
    height: 3.2rem;
  }
`;

import Button from "components/Mobile/Common/Button/Button";
import {
  Body1Regular,
  Body1SemiBold,
  Body3Regular,
  Body3SemiBold,
} from "components/TextStyle";
import useFileUpload from "hooks/useFileUpload";
import React from "react";
import styled from "styled-components";
import { ReactComponent as ImageIcon } from "assets/common/20px/icon_image.svg";
import { Layer, Stage } from "react-konva";
import * as Slider from "@radix-ui/react-slider";
import { ReactComponent as RefreshIcon } from "assets/common/30px/icon_refresh.svg";
import useSignaturesMutation from "hooks/mutations/signature/useSignaturesMutation";
import { tertiaryButtonState } from "components/Common/Button/ButtonStyledComponents";
import useImageStamp from "hooks/useImageStamp";
import ImagePreview from "./ImagePreview";
interface UploadStampContentProps {
  closeModal: () => void;
  sign?: (file: File) => void;
}

const UploadStampContent = ({ closeModal, sign }: UploadStampContentProps) => {
  const { mutate } = useSignaturesMutation();
  const { files, fileInputProps, inputFor, removeFile, isError } =
    useFileUpload({
      types: ["image/jpeg", "image/png", "image/jpg"],
      size: 2,
      maxFileCount: 1,
      limitNameLength: 40,
    });

  const {
    contrast,
    setContrast,
    rotation,
    rotateRight,
    stageRef,
    resetEdit,
    createStamp,
  } = useImageStamp();

  const file = files?.[0];
  const isFileSelected = !!file;

  const handleSaveClick = async () => {
    const file = await createStamp();
    if (!file) return;

    if (sign) {
      sign(file);
      closeModal();
    } else
      mutate(file, {
        onSuccess: () => {
          closeModal();
        },
      });
  };

  const handleResetImageClick = () => {
    removeFile(0);
    resetEdit();
  };

  return (
    <>
      <Body1Regular>
        실제 도장을 찍은 사진을 업로드 해 주세요. <br />
        (2MB이하의 이미지 파일)
      </Body1Regular>
      {!isFileSelected ? (
        <UploadButton
          colorType={"tertiary"}
          size="medium"
          {...(!isFileSelected ? inputFor : {})}
        >
          <input type="file" {...fileInputProps} />
          <UploadName>도장 이미지 업로드 하기</UploadName>
        </UploadButton>
      ) : (
        <ResetUpload>
          <UploadName>{file.name}</UploadName>
          {isFileSelected && <a onClick={handleResetImageClick}>취소</a>}
        </ResetUpload>
      )}

      <EmptyImagePreview
        isError={Object.values(isError).some((value) => value === true)}
      >
        {isFileSelected ? (
          <Stage width={150} height={150} ref={stageRef}>
            <Layer>
              <ImagePreview
                file={file}
                contrast={contrast}
                rotation={rotation}
              />
            </Layer>
          </Stage>
        ) : (
          <ImageIcon width={42} height={42} />
        )}
      </EmptyImagePreview>
      {isError.nameLength && (
        <Body3Regular
          style={{
            marginTop: "0.6rem",
            color: "#FA4D56",
          }}
        >
          이미지 파일명이 길어 업로드가 어렵습니다. 파일명 변경 후 다시
          적용해주세요
        </Body3Regular>
      )}
      {isError.size && (
        <Body3Regular
          style={{
            marginTop: "0.6rem",
            color: "#FA4D56",
          }}
        >
          지원하지 않는 파일 크기입니다. 2MB 이하의 이미지 파일을 적용해주세요.
        </Body3Regular>
      )}
      <ImageEditFooter>
        <RotateButton
          colorType="ghost"
          size="medium"
          disabled={!isFileSelected}
          onClick={rotateRight}
          style={{
            width: "fit-content",
          }}
        >
          <RefreshIcon />
          이미지 회전하기
        </RotateButton>
        {isFileSelected && (
          <div>
            <Body3SemiBold>약한 대비</Body3SemiBold>
            <SliderRoot
              className="SliderRoot"
              defaultValue={[0]}
              max={100}
              min={-100}
              step={1}
              onValueChange={(value) => {
                setContrast(value[0]);
              }}
            >
              <Slider.Track className="SliderTrack">
                <Slider.Range className="SliderRange" />
              </Slider.Track>
              <Slider.Thumb className="SliderThumb" aria-label="Volume" />
            </SliderRoot>
            <Body3SemiBold>강한 대비</Body3SemiBold>
          </div>
        )}
      </ImageEditFooter>
      <ModalFooter>
        <Button colorType="tertiary" size="large" onClick={closeModal}>
          취소 하기
        </Button>
        <Button
          colorType="primary"
          size="large"
          onClick={handleSaveClick}
          disabled={!isFileSelected}
        >
          업로드 하기
        </Button>
      </ModalFooter>
    </>
  );
};

export default UploadStampContent;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;

  gap: 1rem;
  margin-top: 2rem;
`;

const ImageEditFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  margin: 1rem 0;
  gap: 1rem;

  > div {
    width: 100%;
    color: var(--text-secondary);
  }

  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 2rem;
  }

  > button {
    margin-left: auto;
  }
`;

const EmptyImagePreview = styled.div<{ isError: boolean }>`
  background-color: ${({ isError }) =>
    isError ? "#FFF9F9" : "var(--primary-100)"};

  width: 100%;
  height: 25rem;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ isError }) =>
    isError
      ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23DB5257FF' stroke-width='1' stroke-dasharray='2' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
      : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%235B96FFFF' stroke-width='1' stroke-dasharray='2' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`};

  svg {
    color: ${({ isError }) => (isError ? "#DB5257" : "#5b96ff")};
    opacity: 0.2;
  }
`;

const UploadButton = styled(Button)`
  input {
    display: none;
  }
`;

const RotateButton = styled(Button)`
  display: flex;
  margin: 0;
  padding: 0;

  svg {
    path {
      stroke: var(--grey-400);
    }
  }
`;

const UploadName = styled(Body1SemiBold)`
  display: flex;
  gap: 1rem;
`;

const SliderRoot = styled(Slider.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 13.8rem;
  height: 1.2rem;

  .SliderTrack {
    background-color: #f2f4f8;
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    height: 0.6rem;
  }

  .SliderThumb {
    display: block;
    width: 3rem;
    height: 1.2rem;
    background-color: var(--blue-500);
    border-radius: 10px;
  }
`;

const ResetUpload = styled.div`
  ${tertiaryButtonState.default}
  display: flex;
  justify-content: space-between;

  padding: 0.8rem 1.6rem;
  border-radius: 5px;
  margin-top: 2rem;

  &:hover {
    border: 1px solid var(--grey-400);
    background-color: var(--bg-white);
    color: var(--grey-600);
  }

  a {
    text-decoration: underline;
    font-weight: 400;
    color: var(--text-secondary);
  }
`;

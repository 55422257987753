import { breakpoints } from "components/styles";
import styled from "styled-components";

export const SendStyled = styled.div`
  width: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  width: 100%;
  height: 6.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.8rem;
  background-color: var(--bg-white);
  box-sizing: border-box;
  border-bottom: 0.1rem solid var(--stroke-light);

  .bar {
    width: 2.6rem;
    height: 0;
    border-bottom: 0.1rem solid #d3d3d3;
  }
`;

export const Main = styled.div`
  flex-grow: 1;
  display: flex;
  max-height: calc(100dvh - 13.6rem);

  .pdf {
    display: flex;
    flex-grow: 1;
  }
`;

export const DropdownStyled = styled.div`
  width: max-content;
  position: relative;
  color: var(--text-secondary);

  .dimmed {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
  }

  .selected {
    cursor: pointer;
    padding: 1.6rem 2.4rem 1.6rem 0;
    display: flex;
    align-items: center;
    column-gap: 0.4rem;

    & > div {
      display: flex;
      align-items: center;
      column-gap: 0.8rem;
    }
  }

  .indicator {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
  }

  .options {
    position: absolute;
    z-index: 20;
    top: 100%;
    background-color: var(--bg-white);
    border-radius: 1.2rem;

    .option {
      cursor: pointer;
      width: 13rem;
      display: flex;
      align-items: center;
      column-gap: 0.8rem;
      padding: 1.2rem 0 1.2rem 1.6rem;
    }
  }
`;

export const Fields = styled.div`
  min-width: 22rem;
  max-width: 22rem;
  background-color: var(--bg-white);
  display: flex;
  flex-direction: column;
  padding: 3.163rem 1rem;
  box-sizing: border-box;
  row-gap: 3.2rem;
  overflow-x: hidden;
  overflow-y: scroll;
  border-right: 0.1rem solid var(--stroke-light);

  .participants {
    padding-left: 2rem;
    display: flex;
    flex-direction: column;

    .label {
      display: flex;
      align-items: center;

      svg {
        cursor: pointer;
      }
    }
  }

  .fields {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;

    button {
      width: 100%;
    }

    .field {
      width: 100%;
      position: relative;
      cursor: pointer;
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      column-gap: 0.8rem;
      color: var(--text-secondary);
      user-select: none;
      border-radius: 0.8rem;
      overflow: hidden;

      &:hover,
      &:active,
      &.selected {
        background-color: var(--bg-dark);
      }

      &:has(.dragging) {
        overflow: visible;
      }
    }
  }
`;

export const PreviewStyled = styled.div`
  display: flex;
  width: 100dvw;
  height: calc(100dvh - 20.5rem);

  .pdf {
    display: flex;
    flex-grow: 1;
  }

  .left {
    width: 36.4rem;
    min-width: 36.4rem;
    max-width: 36.4rem;
    background-color: var(--bg-white);
    padding: 3rem;
    box-sizing: border-box;
    overflow: scroll;

    .pages {
      display: flex;
      flex-direction: column;
      row-gap: 4rem;

      .page {
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;

        &.current .thumbnail {
          border: 0.1rem solid var(--grey-700);
        }

        .label {
          display: flex;
          align-items: center;
          column-gap: 0.4rem;

          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
        }

        .thumbnail {
          cursor: pointer;
          border-radius: 1.2rem;
          width: 100%;
          overflow: hidden;
          height: 20rem;
          background-color: var(--grey-200);
          border: 0.1rem solid transparent;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            border: 0.1rem solid var(--grey-700);
          }

          .react-pdf__Thumbnail__page {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .functions {
          display: flex;
          align-items: center;
          column-gap: 1.2rem;

          svg {
            cursor: pointer;
          }
        }

        .attached {
          height: 20rem;
          border-radius: 2rem;
          background-color: var(--grey-200);
        }
      }
    }
  }

  .main {
    flex-grow: 1;
    max-width: calc(100dvw - 36.4rem);
    position: relative;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    background-color: var(--grey-200);
    row-gap: 2rem;
    box-sizing: border-box;
    padding: 2rem;
    @media (max-width: ${breakpoints.md}) {
      max-width: 100%;
      padding: 0;
    }

    .pdf-container {
      position: relative;
      margin: auto;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
    }
  }

  .page-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    margin: auto 0;
  }
`;

export const SenderSignatureModalStyled = styled.div<{
  noSignatures: boolean;
}>`
  padding: 3.6rem 4rem 4rem;
  background-color: white;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: ${({ noSignatures }) => (noSignatures ? "2rem" : "0.5rem")};
  min-width: 46rem;
  max-width: 74rem;
  height: max-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  svg {
    cursor: pointer;
  }

  .main {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .desc {
      color: var(--text-secondary);
    }

    button {
      width: 100%;
    }
  }

  .refresh {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .signatures {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;

    .signatures-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 3rem;
      column-gap: 1.5rem;
      max-height: 43rem;
      overflow-y: scroll;

      .signature {
        cursor: pointer;
        width: 21rem;
        height: 20rem;
        border: 0.15rem solid transparent;
        border-radius: 1.2rem;
        box-sizing: border-box;
        padding: 6rem;
        background-color: var(--grey-100);

        &.selected {
          border-color: var(--stroke-primary);
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    .make-signatures {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 3.6rem;
    }
  }

  .footer {
    .buttons-container {
      display: flex;
      align-items: center;
      justify-content: ${({ noSignatures }) =>
        noSignatures ? "flex-end" : "center"};
      column-gap: 1rem;

      button {
        width: ${({ noSignatures }) => (noSignatures ? "10rem" : "24rem")};
        height: ${({ noSignatures }) => (noSignatures ? "4.8rem" : "5.6rem")};

        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    max-width: 100%;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    .signatures {
      .signatures-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.6rem;

        .signature {
          width: 100%;
          height: 23rem;
          aspect-ratio: 1 / 1;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2rem;

          img {
            width: 10rem;
            height: 10rem;
            object-fit: contain;
          }
        }
      }

      .make-signatures {
        margin-bottom: 2.4rem;
      }
    }

    .footer {
      .buttons-container {
        button {
          flex: 1;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    min-width: 30rem;
    max-width: 100%;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    .signatures {
      .signatures-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.6rem;

        .signature {
          width: 100%;
          height: 15rem;
          aspect-ratio: 1 / 1;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2rem;

          img {
            width: 10rem;
            height: 10rem;
            object-fit: contain;
          }
        }
      }

      .make-signatures {
        margin-bottom: 2.4rem;
      }
    }

    .footer {
      .buttons-container {
        button {
          flex: 1; // 버튼 동일한 너비로
        }
      }
    }
  }
`;

export const GaninLoadingStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background-color: #0000004d;
  width: 100dvw;
  height: 100dvh;
  z-index: 100;
  text-align: center;
  color: white;

  img {
    width: 30rem;
    height: 30rem;
  }
`;

import axios from "axios";
import {
  GetGeneralSettingServicesResponse,
  GetGeneralSettingsResponse,
  PostGeneralSettingsPresignedUrlResponse,
  PostGeneralSettingsRequest,
  PostGeneralSettingsServicesFooterRequest,
  PostGeneralSettingsServicesNameRequest,
  PostGeneralSettingsServicesUsePersonalInfoRequest,
} from "models/organization/generalSettings";
import getAPIResponseData from "utils/getAPIResponse";

export const postGeneralSettings = (data: PostGeneralSettingsRequest) => {
  return getAPIResponseData({
    url: "/organizations/general-settings",
    method: "POST",
    data: data,
  });
};

export const postGeneralSettingsPresignedUrl = async ({
  file,
}: {
  file: File;
}) => {
  const { generatePresignedUrlDto } = await getAPIResponseData<
    PostGeneralSettingsPresignedUrlResponse,
    { fileName: string }
  >({
    url: "/organizations/general-settings/presigned-url",
    method: "POST",
    data: { fileName: file.name },
  });

  const { presignedUrl } = generatePresignedUrlDto;

  try {
    await axios({
      url: presignedUrl,
      method: "PUT",
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
  } catch (err) {
    throw err;
  }

  return presignedUrl;
};

export const postGeneralSettingsServiceName = (
  data: PostGeneralSettingsServicesNameRequest
) => {
  return getAPIResponseData({
    url: "/organizations/general-settings/services/name",
    method: "POST",
    data: data,
  });
};

export const postGeneralSettingsServiceFooter = (
  data: PostGeneralSettingsServicesFooterRequest
) => {
  return getAPIResponseData({
    url: "/organizations/general-settings/services/footer",
    method: "POST",
    data: data,
  });
};

export const postGeneralSettingsServiceUsePersonalInfo = (
  data: PostGeneralSettingsServicesUsePersonalInfoRequest
) => {
  return getAPIResponseData({
    url: "/organizations/general-settings/services/use-personal-info",
    method: "POST",
    data: data,
  });
};

export const getGeneralSettingServices = (organizationUuid?: string) => {
  return getAPIResponseData<GetGeneralSettingServicesResponse>({
    url: `/organizations/general-settings/services/${organizationUuid}`,
    method: "GET",
  });
};

export const getGeneralSetting = (organizationUuid?: string) => {
  return getAPIResponseData<GetGeneralSettingsResponse>({
    url: `/organizations/general-settings/${organizationUuid}`,
    method: "GET",
  });
};

import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import { CustomToastContainer, GlobalStyle } from "./components/styles";
import { RouterProvider } from "react-router-dom";
import routes from "routes";
import "react-toastify/dist/ReactToastify.css";
import { pdfjs } from "react-pdf";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// 이외에 필요한 스타일 파일이 있다면 import 해주세요.

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

const queryClient = new QueryClient();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <GlobalStyle />
      <CustomToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
        limit={1}
        closeButton={false}
      />

      <RouterProvider router={routes} />
    </RecoilRoot>
    <ReactQueryDevtools />
  </QueryClientProvider>
);

import Button from "components/Mobile/Common/Button/Button";
import { KonvaEventObject } from "konva/lib/Node";
import React, { useEffect, useRef, useState } from "react";
import { Layer, Line, Stage } from "react-konva";
import styled from "styled-components";
import { Stage as StageRefType } from "konva/lib/Stage";
import useSignaturesMutation from "hooks/mutations/signature/useSignaturesMutation";
import resizeImage from "utils/resizeImage";
import konvaToImage from "utils/konvaToImage";

interface DrawLine {
  points: number[];
  lineWidth: number;
}

interface DrawSignContentProps {
  closeModal: () => void;
  signMode?: boolean;
  sign?: (file: File) => void;
}

const DrawSignContent = ({ closeModal, sign }: DrawSignContentProps) => {
  const { mutate } = useSignaturesMutation();
  const [lines, setLines] = useState<DrawLine[]>([]);

  const isDrawing = useRef(false);
  const stageRef = useRef<StageRefType | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });

  const handleMouseDown = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    isDrawing.current = true;
    const pos = e.target.getStage()?.getPointerPosition();
    if (!pos) return;
    setLines([...lines, { points: [pos.x, pos.y], lineWidth: 3 }]);
  };

  const handleMouseMove = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    if (!isDrawing.current) {
      return;
    }
    const pos = e.target.getStage()?.getPointerPosition();
    if (!pos) return;
    const lastLine = lines[lines.length - 1];

    lastLine.points = lastLine.points.concat([pos.x, pos.y]);

    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const handleCleanClick = () => {
    setLines([]);
  };

  const handleSaveClick = async () => {
    if (!stageRef.current) return;

    const scaleSize = resizeImage(
      {
        width: stageRef.current.width(),
        height: stageRef.current.height(),
      },
      {
        width: 200,
        height: 70,
      }
    );

    const file = await konvaToImage(stageRef.current, {
      width: 200,
      height: 70,
      ...scaleSize,
    });

    if (sign) {
      sign(file);
      closeModal();
    } else
      mutate(file, {
        onSuccess: () => {
          closeModal();
        },
      });
  };

  useEffect(() => {
    const updateStageSize = () => {
      if (containerRef.current) {
        setStageSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetWidth / (1000 / 350),
        });
      }
    };

    updateStageSize(); // 초기 크기 설정
    window.addEventListener("resize", updateStageSize); // 리사이즈 대응
    return () => window.removeEventListener("resize", updateStageSize);
  }, []);

  return (
    <div ref={containerRef}>
      <Stage
        ref={stageRef}
        width={stageSize.width}
        height={stageSize.height}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
        style={{
          backgroundColor: "#F2F7FF",
        }}
      >
        <Layer>
          {lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke="#000000"
              strokeWidth={line.lineWidth}
              tension={0.5}
              lineCap="round"
              lineJoin="round"
              globalCompositeOperation={"source-over"}
            />
          ))}
        </Layer>
      </Stage>
      <DrawFooter>
        <Button
          colorType="tertiary"
          size="medium"
          onClick={handleCleanClick}
          style={{
            width: "fit-content",
          }}
        >
          지우기
        </Button>
      </DrawFooter>
      <ModalFooter>
        <Button colorType="tertiary" size="large" onClick={closeModal}>
          취소
        </Button>
        <Button colorType="primary" size="large" onClick={handleSaveClick}>
          확인
        </Button>
      </ModalFooter>
    </div>
  );
};

export default DrawSignContent;

const DrawFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  margin: 2rem 0rem 3rem 0rem;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
`;

import React, { useState } from "react";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { ReactComponent as BellActiveIcon } from "assets/common/24px/icon_bell_active.svg";
import { ReactComponent as BellIcon } from "assets/common/24px/icon_bell.svg";
import { device } from "components/styles";
import styled from "styled-components";
import NotificationDropDownItem, {
  NotificationDropDownEmptyMenuItem,
} from "./NotificationDropDownMenuItem";
import { Link } from "react-router-dom";
import useNotificationToday from "hooks/queries/notification/useNotificationToday";
import { format } from "date-fns";
import { NOTIFICATION_TYPE_MAP } from "config/notification";
import Button from "components/Mobile/Common/Button/Button";
import useNotificationReadUpdate from "hooks/mutations/notification/useNotificationReadUpdate";

const NotificationDropDownMenu = () => {
  const { data } = useNotificationToday();
  const { mutate: notificationReadUpdate } = useNotificationReadUpdate();

  const [isOpen, setIsOpen] = useState(false);

  const hasUnreadNotifications = data.notificationList.some(
    (notification) => !notification.isRead
  );

  const handleNotificationReadUpdate = (notificationUuid: string) => {
    notificationReadUpdate({ id: notificationUuid });
    setIsOpen(false);
  };

  return (
    <DropDownRoot open={isOpen} onOpenChange={setIsOpen}>
      <DropDownTrigger asChild>
        <button>
          {hasUnreadNotifications ? <BellActiveIcon /> : <BellIcon />}
        </button>
      </DropDownTrigger>
      {isOpen && <Backdrop onClick={() => setIsOpen(false)} />}
      <DropDownContent align="end" alignOffset={-120} sideOffset={30}>
        <div className="notification-list">
          {data.notificationList.map((notification) => (
            <Link
              to={NOTIFICATION_TYPE_MAP[notification.notificationType]?.link}
              key={notification.notificationUuid}
              onClick={() =>
                handleNotificationReadUpdate(notification.notificationUuid)
              }
            >
              <NotificationDropDownItem
                time={format(notification.createdAt, "yyyy-MM-dd hh:mm")}
                title={
                  NOTIFICATION_TYPE_MAP[notification.notificationType]?.text
                }
                content={notification.content}
                isRead={notification.isRead}
              />
              <hr />
            </Link>
          ))}
          {data.notificationList.length === 0 && (
            <NotificationDropDownEmptyMenuItem>
              <p>신규 알림이 없습니다.</p>
            </NotificationDropDownEmptyMenuItem>
          )}
        </div>
        <Link to={"/alert/dashboard"}>
          <Button
            colorType="secondary"
            size="medium"
            onClick={() => setIsOpen(false)}
          >
            전체 알림 확인하기
          </Button>
        </Link>
      </DropDownContent>
    </DropDownRoot>
  );
};

export default NotificationDropDownMenu;

const DropDownRoot = styled(Dropdown.Root)`
  position: relative;
  padding: 1.1rem 1.4rem;
  outline: none;
`;

const DropDownTrigger = styled(Dropdown.Trigger)`
  position: relative;
  padding: 1.1rem 1.4rem;
  display: flex;
  white-space: nowrap;
`;

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 102;
`;

const DropDownContent = styled(Dropdown.Content)`
  min-width: 28.4rem;
  height: fit-content;
  overflow-y: auto;
  padding: 0.8rem;
  gap: 1rem;
  border-radius: 0.4rem;
  z-index: 103;

  background: var(--bg-white);

  @media ${device.md}, ${device.sm} {
    width: 24rem;
  }

  @media ${device.xs} {
    width: 16rem;
  }

  .notification-list {
    max-height: 36rem;
    overflow-y: auto;

    hr {
      background-color: var(--stroke-light);
      height: 0.1rem;
      outline: none;
      border: none;
    }

    > a:last-child hr {
      display: none;
    }
  }
`;

import { styled } from "styled-components";

export const PdfPreviewLayout = styled.div`
  max-height: calc(100vh - var(--header-height) - 10rem);
  overflow-y: auto;

  .pdf-document {
    border-radius: 2rem;
    margin: 1rem;
    overflow: hidden;
    width: 18rem;

    display: flex;
    justify-content: center;
  }

  .isActive {
    border: 1px solid var(--grey-700);
  }
`;

import { Board } from "components/Common/Board/Board";
import { Body1SemiBold, Body3Regular } from "components/TextStyle";
import React from "react";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/common/32px/icon_plus_large.svg";
import SignItem from "./SignItem";
import useSignatureQuery from "hooks/queries/signature/useSignatureQuery";
import useBottomSheet from "hooks/Mobile/useBottomSheet";
import CreateBottomSheet from "./CreateSignBottomSheet";

const ProfileSignMobilePage = () => {
  const { isOpen, openBottomSheet, closeBottomSheet } = useBottomSheet();
  const { data } = useSignatureQuery();
  return (
    <SignLayout>
      <Body1SemiBold>내 서명</Body1SemiBold>
      <SignBoard>
        <ul>
          <AddSignField onClick={openBottomSheet}>
            <PlusIcon color="#1B65E8" />
            <Body3Regular>내 서명 추가하기</Body3Regular>
          </AddSignField>

          {data?.signatureList.map((signature, index) => (
            <SignItem
              key={index}
              id={signature.signatureUuid}
              url={signature.signatureUrl}
              isFavorite={signature.isFavorite}
            />
          ))}
        </ul>
      </SignBoard>
      <CreateBottomSheet isOpen={isOpen} closeBottomSheet={closeBottomSheet} />
    </SignLayout>
  );
};

export default ProfileSignMobilePage;

const SignLayout = styled.div`
  padding: 3rem;
`;

const SignBoard = styled(Board)`
  padding: 2rem 0;
  background-color: var(--bg-dark);

  > ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-content: center;
  }

  > ul > li {
    aspect-ratio: 1;
    width: 100%;
  }
`;

const AddSignField = styled.li`
  background-color: var(--primary-100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%235B96FFFF' stroke-width='2' stroke-dasharray='8%2c8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 2rem;
  gap: 1.2rem;
  cursor: pointer;
  > div {
    color: var(--text-secondary);
  }
`;

import { Board } from "components/Common/Board/Board";
import { Body3Regular, Header1 } from "components/TextStyle";
import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/common/32px/icon_plus_large.svg";
import SignItem from "./SignItem";
import useModal from "hooks/useModal";
import CreateSignModal from "./CreateSignModal";
import useSignatureQuery from "hooks/queries/signature/useSignatureQuery";
import { useSearchParams } from "react-router-dom";

const ProfileSignPage = () => {
  const [query] = useSearchParams();
  const { openModal, closeModal, isOpen } = useModal();
  const { data } = useSignatureQuery();

  useEffect(() => {
    if (query.get("openModal")) {
      openModal();
    }
  }, [query]);

  return (
    <>
      <CreateSignModal isOpen={isOpen} closeModal={closeModal} />
      <Header1>내 서명</Header1>
      <SignLayout>
        <ul>
          <AddSignField onClick={openModal}>
            <PlusIcon color="#1B65E8" />
            <Body3Regular>내 서명 추가하기</Body3Regular>
          </AddSignField>

          {data?.signatureList.map((signature, index) => (
            <SignItem
              key={index}
              id={signature.signatureUuid}
              url={signature.signatureUrl}
              isFavorite={signature.isFavorite}
            />
          ))}
        </ul>
      </SignLayout>
    </>
  );
};

export default ProfileSignPage;

const SignLayout = styled(Board)`
  padding: 4rem;
  margin-top: 2rem;
  > ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, 22rem);
    gap: 4rem;
  }
`;

const AddSignField = styled.li`
  height: 22rem;
  background-color: var(--primary-100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%235B96FFFF' stroke-width='2' stroke-dasharray='8%2c8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 2rem;
  gap: 1.2rem;
  cursor: pointer;
  > div {
    color: var(--text-secondary);
  }
`;

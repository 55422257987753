import * as Tabs from "@radix-ui/react-tabs";
import React from "react";
import { Header1, Title1 } from "components/TextStyle";
import ContentModal from "components/Common/Modal/ContentModal";
import styled from "styled-components";
import DrawSignContent from "./DrawSignContent";
import UploadStampContent from "./(uploadImageStamp)/UploadStampContent";
import StampContent from "./(textStamp)/StampContent";

interface CreateSignModalProps {
  closeModal: () => void;
  isOpen: boolean;
  signMode?: boolean;
  sign?: (file: File) => void;
}

const CreateSignModal = ({
  closeModal,
  isOpen,
  signMode = false,
  sign,
}: CreateSignModalProps) => {
  if (!isOpen) return;
  return (
    <ContentModal
      disableCloseWithOutsideClickOrEsc
      handleModalClose={closeModal}
      size="large"
      title={
        signMode ? (
          <></>
        ) : (
          <Header1
            style={{
              marginBottom: "-4rem",
            }}
          >
            서명 추가
          </Header1>
        )
      }
      style={{
        maxHeight: "none",
      }}
      isCloseIcon
    >
      <Tabs.Root defaultValue="draw">
        <TabList signMode={signMode}>
          <Tabs.Trigger className="TabsTrigger" value="draw">
            <Title1>그리기</Title1>
          </Tabs.Trigger>
          <Tabs.Trigger className="TabsTrigger" value="stamp">
            <Title1>도장</Title1>
          </Tabs.Trigger>
          <Tabs.Trigger className="TabsTrigger" value="upload">
            <Title1>업로드</Title1>
          </Tabs.Trigger>
        </TabList>
        <Tabs.Content value="draw">
          <DrawSignContent
            closeModal={closeModal}
            signMode={signMode}
            sign={sign}
          />
        </Tabs.Content>
        <Tabs.Content value="stamp">
          <StampContent
            closeModal={closeModal}
            signMode={signMode}
            sign={sign}
          />
        </Tabs.Content>
        <Tabs.Content value="upload">
          <UploadStampContent
            closeModal={closeModal}
            signMode={signMode}
            sign={sign}
          />
        </Tabs.Content>
      </Tabs.Root>
    </ContentModal>
  );
};

export default CreateSignModal;

const TabList = styled(Tabs.List)<{ signMode?: boolean }>`
  display: flex;
  gap: 2rem;
  margin-top: ${({ signMode }) => (signMode ? "0" : "2rem")};
  margin-bottom: 3rem;

  .TabsTrigger {
    color: var(--stroke-dark);
  }

  .TabsTrigger[data-state="active"] {
    color: var(--text-default);
  }
`;

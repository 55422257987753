import {
  Body1SemiBold,
  Body2SemiBold,
  Body3Regular,
} from "components/TextStyle";
import * as Dropdown from "@radix-ui/react-dropdown-menu";

import styled from "styled-components";
import React from "react";

interface DropDownNotificationItemProps {
  title: string;
  time: string;
  content: string;
  isRead: boolean;
}

const NotificationDropDownItem = ({
  title,
  time,
  content,
  isRead,
}: DropDownNotificationItemProps) => {
  return (
    <DropDownItem $isRead={isRead}>
      <div className="notification-header">
        <Body1SemiBold className="notification-title">{title}</Body1SemiBold>
        <Body3Regular className="notification-time">{time}</Body3Regular>
      </div>
      <Body2SemiBold>{content}</Body2SemiBold>
    </DropDownItem>
  );
};

export default NotificationDropDownItem;

const DropDownItem = styled(Dropdown.Item)<{ $isRead: boolean }>`
  padding: 1.2rem 0.8rem;
  cursor: pointer;
  color: var(--text-secondary);

  .notification-header {
    display: flex;
    justify-content: space-between;
    gap: 1.2rem;
    margin-bottom: 1.2rem;
  }

  .notification-title {
    color: ${({ $isRead }) =>
      $isRead ? "var(--text-secondary)" : "var(--text-middle-emphasis)"};
  }

  .notification-time {
    color: var(--text-secondary);
  }

  :focus-visible {
    outline: none;
  }
`;

export const NotificationDropDownEmptyMenuItem = styled(Dropdown.Item)`
  display: flex;
  height: 4rem;
  padding: 0.8rem;
  font-size: 1.5rem;
  font-weight: 600;

  :focus-visible {
    outline: none;
  }

  > p {
    margin: auto;
  }
`;

import getAPIResponseData from "utils/getAPIResponse";

export const getPaymentsServicePlan = () => {
  return getAPIResponseData<GetPaymentsServicePlanRes>({
    url: "/payments/service-plan",
    method: "GET",
  });
};

export const getPaymentsServicePlanFree = (organizationUuid?: string) => {
  return getAPIResponseData<{ available: boolean }>({
    url: `/payments/service-plan/free/availability-check/${organizationUuid}`,
    method: "GET",
  });
};

export const getPaymentsPaymentMethodAll = (organizationUuid?: string) => {
  return getAPIResponseData<GetPaymentsMethodAllRes>({
    url: `/payments/payment-method/all/${organizationUuid}`,
    method: "GET",
  });
};

export const postPaymentsDefaultBilling = (data: PostDefaultBillingReq) => {
  return getAPIResponseData({
    url: `/payments/default-biiling`,
    method: "POST",
    data,
  });
};

export const deletePaymentMethod = (data: PostDefaultBillingReq) => {
  return getAPIResponseData({
    url: `/payments/payment-method`,
    method: "DELETE",
    data,
  });
};

export const getPaymentsHistory = (params: GetPaymentsHistoryReq) => {
  const { servicePlanName, startDateToCheck, endDateToCheck, page } = params;
  return getAPIResponseData<GetPaymentsHistoryRes>({
    url: `/payments/history/${params.organizationUuid}`,
    method: "GET",
    params: {
      servicePlanName,
      startDateToCheck,
      endDateToCheck,
      page,
    },
  });
};

export const getPaymentsProfile = (organizationUuid?: string) => {
  return getAPIResponseData<GetPaymentsProfileRes>({
    url: `/payments/profile/${organizationUuid}`,
    method: "GET",
  });
};

export const patchPaymentsServicePlanUnsubscribe = ({
  organizationUuid,
  organizationServicePlanPaymentUuid,
}: PatchPaymentsUnsubscribeReq) => {
  return getAPIResponseData({
    url: `/payments/service-plan/unsubscribe/${organizationUuid}/${organizationServicePlanPaymentUuid}`,
    method: "PATCH",
  });
};

export const postPaymentsMethodToss = ({
  organizationUuid,
  tossData,
}: PostPaymentsMethodTossReq) => {
  return getAPIResponseData({
    url: `/payments/billing-key/success/${organizationUuid}`,
    method: "POST",
    data: tossData,
  });
};

export const postPaymentsServicePlanSubscribe = (
  data: PostServicePlanSubscribeReq
) => {
  return getAPIResponseData({
    url: "/payments/service-plan/subscribe",
    method: "POST",
    data,
  });
};

export const getPaymentsTossPayments = ({
  organizationUuid,
  tossPaymentsPaymentKey,
}: GetTossPaymentsReq) => {
  return getAPIResponseData({
    url: `/payments/toss-payments/payments/${organizationUuid}/${tossPaymentsPaymentKey}`,
    method: "GET",
  });
};

export const getPaymentsUsagePeriod = () => {
  return getAPIResponseData<GetPaymentsUsagePeriodRes>({
    url: "/payments/usage-period",
    method: "GET",
  });
};

export const getPaymentsTossPaymentsBilling = (organizationUuid?: string) => {
  return getAPIResponseData<GetPaymentsTossPaymentsBillingRes>({
    url: `/payments/tosspayments/billing/${organizationUuid}`,
    method: "GET",
  });
};

export const getPaymentsSubscriptionsExistence = (
  organizationUuid?: string
) => {
  return getAPIResponseData<GetPaymentsSubscriptionsExistenceRes>({
    url: `/payments/subscriptions/existence/${organizationUuid}`,
    method: "GET",
  });
};

export const getStandardAvailability = (organizationUuid: string) => {
  return getAPIResponseData<GetStandardAvailabilityRes>({
    url: `/payments/service-plan/standard/availability-check/${organizationUuid}`,
    method: "GET",
  });
};

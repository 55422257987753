export const getDateTime = (dateTime: Date, dots = false) => {
  const year = dateTime.getFullYear();
  const month = dateTime.getMonth() + 1;
  const date = dateTime.getDate();

  const monthString = month >= 10 ? `${month}` : `0${month}`;
  const dateString = date >= 10 ? `${date}` : `0${date}`;

  const dateTimeString = `${year}-${monthString}-${dateString}`;
  const dateTimeStringWithDots = `${year}. ${monthString}. ${dateString}`;

  if (dots) return dateTimeStringWithDots;

  return dateTimeString;
};

import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Body1Regular } from "components/TextStyle";
import { IPage } from "interfaces/contract";
import { Page } from "react-pdf";

export default function SortagblePage({
  id,
  order,
  page,
  selectedPageId,
  setSelectedPageId,
  scrollToPage,
}: {
  id: string;
  order: number;
  page: IPage;
  selectedPageId?: string;
  setSelectedPageId: React.Dispatch<React.SetStateAction<string>>;
  scrollToPage?: (pageId: string) => void;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? "10" : undefined,
  };

  return (
    <div
      key={id}
      ref={setNodeRef}
      style={style}
      className={`page${page.id === selectedPageId ? " current" : ""}`}
      onClick={() => {
        setSelectedPageId(id);
        scrollToPage && scrollToPage(id);
      }}
      {...attributes}
      {...listeners}
    >
      <div className="label">
        <Body1Regular>{order}</Body1Regular>
      </div>
      <Page
        className="thumbnail"
        height={140}
        pageNumber={order}
        renderAnnotationLayer={false}
        renderTextLayer={false}
      />
      {/* <div className="functions">
        <TrashIcon
          onClick={(e) => {
            e.stopPropagation();
            console.log(e);
          }}
        />
      </div> */}
    </div>
  );
}

import SettingLayout from "components/Setting/Layout/SettingLayout";
import React from "react";
import { FreePlanItemContainer, PaymentDashboardContainer } from "./styles";
import Button from "components/Common/Button/Button";
import RatePlanItem from "components/Setting/Payment/RatePlanItem";
import { SubTitle } from "components/TextStyle";
import RatePlanItemContent from "components/Setting/Payment/RatePlanItemContent";
import { useNavigate } from "react-router-dom";
import useServicePlanFreeQuery from "hooks/queries/payment/useServicePlanFreeQuery";
import useUserState from "hooks/recoil/useUserState";
import usePrice from "hooks/setting/payment/usePrice";

const SettingPaymentDashBoardPage = () => {
  const [user] = useUserState();

  const { data: availabilityFreeData } = useServicePlanFreeQuery(
    user.organization
  );

  return (
    <SettingLayout type="payment" state="dashboard" isBg={false}>
      <div
        style={{
          margin: "auto",
        }}
      >
        <PaymentDashboardPlan availabilityFreeData={availabilityFreeData} />
      </div>
    </SettingLayout>
  );
};

export default SettingPaymentDashBoardPage;

interface PaymentDashboardPlanProps {
  availabilityFreeData?: {
    available: boolean;
  };
}

export const PaymentDashboardPlan = ({
  availabilityFreeData,
}: PaymentDashboardPlanProps) => {
  const navigate = useNavigate();

  const { renderPlanPrice, renderTotalPrice, RATE } = usePrice();

  return (
    <PaymentDashboardContainer>
      <div className="ratePlanContainer">
        {/* Free */}
        <RatePlanItem
          title="Free"
          subTitle={"다양한 기능을 30일 동안\n마음껏 이용해 보세요"}
        >
          <FreePlanItemContainer>
            <SubTitle className="content">무료로 시작하기</SubTitle>
            <Button
              colorType="primary"
              size="large"
              handleClick={() => navigate(`/setting/payment/billing?plan=Free`)}
              disabled={!(availabilityFreeData?.available ?? true)}
            >
              Free
            </Button>
          </FreePlanItemContainer>
        </RatePlanItem>

        {/* Basic */}
        <RatePlanItem
          title="Basic"
          subTitle={`월 5건 이하의 계약 체결이 필요\n개인 또는 1인 사용자용 요금제`}
        >
          <RatePlanItemContent
            plan={{
              planName: "Basic",
              planPrice: renderTotalPrice("Basic"),
            }}
          />
        </RatePlanItem>

        {/* Standard */}
        <RatePlanItem
          title="Standard"
          subTitle={`5명 내외의 인원이 계약・관리를 진행하는\n기업용 요금제`}
        >
          <RatePlanItemContent
            plan={{
              planName: "Standard",
              planPrice: renderPlanPrice("Standard"),
            }}
            discountPlan={renderTotalPrice("Standard")}
            discountPlanRate={RATE}
          />
        </RatePlanItem>

        {/* Premium */}
        <RatePlanItem
          title="Premium 간인"
          subTitle={"Standard 전 기능 외에\n간인 서비스"}
        >
          <RatePlanItemContent
            plan={{
              planName: "Premium",
              planPrice: renderPlanPrice("Premium"),
            }}
            discountPlan={renderTotalPrice("Premium")}
            discountPlanRate={RATE}
          />
        </RatePlanItem>
      </div>
    </PaymentDashboardContainer>
  );
};

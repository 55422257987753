import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useDropdownListAllQuery = (organizationId?: string) => {
  return useQuery({
    ...queries.dropwonList.list(organizationId),
    enabled: !!organizationId,
  });
};

export default useDropdownListAllQuery;

import Button from "components/Mobile/Common/Button/Button";
import TemplateDataExportModal from "components/template/Modals/TemplateDataExportModal";

import { Body3Regular, SubTitle } from "components/TextStyle";
import { format } from "date-fns";
import useModal from "hooks/useModal";
import React from "react";
import styled from "styled-components";

interface TemplateInfoProps {
  createAt: Date;
  createdUserName: string;
  email: string;
  status: "TEMP" | "COMPLETE";
  pageNum: number;
  receiverCnt: number;
  organization: string;
}

const TemplateMobileInfo = ({
  createAt,
  createdUserName,
  email,
  status,
  pageNum,
  receiverCnt,
}: // organization,
TemplateInfoProps) => {
  const STATUS_MAP = {
    TEMP: "임시",
    COMPLETE: "완료",
  };
  // const { templateId } = useParams();

  // const handleDownloadExcel = async () => {
  //   try {
  //     const res = await getTemplateDataExcel({
  //       organizationId: organization ?? "",
  //       templateId: templateId as string,
  //     });

  //     const file = new File([res.data], "template_file.xlsx", {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });

  //     // saveFile 함수를 사용하여 파일을 저장합니다.
  //     saveFile(file);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  const {
    isOpen: dataExportModalOpen,
    closeModal: closeDataExportModal,
    openModal: openDataExportModal,
  } = useModal();

  return (
    <>
      {dataExportModalOpen && (
        <TemplateDataExportModal closeModal={closeDataExportModal} />
      )}
      <DefaultInfoHeader>
        <SubTitle>기본정보</SubTitle>

        <div>
          <Button
            size="small"
            colorType="tertiary"
            disabled={status === "TEMP"}
            onClick={openDataExportModal}
          >
            템플릿 데이터 받기
          </Button>
        </div>
      </DefaultInfoHeader>
      <DefaultInfoList>
        <DefaultInfoItem>
          <Body3Regular>템플릿 생성 시간</Body3Regular>
          <Body3Regular>{format(createAt, "yyyy년 M월 d일 H:mm")}</Body3Regular>
        </DefaultInfoItem>
        <hr />
        <DefaultInfoItem>
          <Body3Regular>템플릿 생성자</Body3Regular>
          <Body3Regular>
            {createdUserName}
            <strong>({email})</strong>
          </Body3Regular>
        </DefaultInfoItem>
        <hr />
        <DefaultInfoItem>
          <Body3Regular>템플릿 상태</Body3Regular>
          <Body3Regular>
            <strong>{STATUS_MAP[status]}</strong>
          </Body3Regular>
        </DefaultInfoItem>
        <hr />
        <DefaultInfoItem>
          <Body3Regular>페이지 수</Body3Regular>
          <Body3Regular>{pageNum}p</Body3Regular>
        </DefaultInfoItem>
      </DefaultInfoList>

      <SubTitle>총 {receiverCnt}명의 수신자가 설정되어있습니다.</SubTitle>
      <Body3Regular>
        (동시전송, 대량전송 {receiverCnt > 1 ? "불가능" : "가능"})
      </Body3Regular>
    </>
  );
};

export default TemplateMobileInfo;

const DefaultInfoHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const DefaultInfoItem = styled.li`
  padding: 2rem 0;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  > :first-child {
    width: 10rem;
    margin-right: 2rem;
  }
`;

const DefaultInfoList = styled.ul`
  margin-bottom: 3rem;
  :last-child {
    hr {
      display: none;
    }
  }
  strong {
    font-weight: 400;
    color: var(--text-middle-emphasis);
  }
`;

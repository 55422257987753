import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useMailTemplateAllQuery = (organizationId?: string) => {
  return useQuery({
    ...queries.mailTemplate.list(organizationId),
    enabled: !!organizationId,
  });
};

export default useMailTemplateAllQuery;

import React from "react";
import { StampFont, TextInfoType } from "components/Profile/type";
import Stamp from "components/Profile/Stamp";
import styled from "styled-components";
import { Stage } from "konva/lib/Stage";

interface StampListProps {
  fontList: StampFont[];
  textInfoList: TextInfoType[];
  stampName: string;
  onClick: (stamp: {
    ref: React.MutableRefObject<Stage | null>;
    id: string;
  }) => void;
  selectedStamp: {
    ref?: React.MutableRefObject<Stage | null> | undefined;
    id: string;
  };
}

const StampList = ({
  fontList,
  stampName,
  textInfoList,
  onClick,
  selectedStamp,
}: StampListProps) => {
  return (
    <StampListLayout>
      {fontList.map((stampType) => {
        const textInfo = textInfoList.find(
          (info) => info.textLength === stampName.length
        );

        if (!textInfo) return;
        return textInfo.shapes.map((shape) => {
          const id = stampType.fontFamily + shape.id;
          return (
            <Stamp
              key={id}
              fontFamily={stampType.fontFamily}
              name={stampName}
              shape={shape}
              onClick={({ ref }) => {
                onClick({
                  ref,
                  id,
                });
              }}
              isSelected={selectedStamp.id === id}
            />
          );
        });
      })}
    </StampListLayout>
  );
};

export default StampList;

const StampListLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: center;
  align-items: center;
  column-gap: 1.2rem;
  row-gap: 3rem;
  margin-top: 16px;
  height: 30rem;
  overflow-y: scroll;
`;

import Button from "components/Mobile/Common/Button/Button";
import Input from "components/Mobile/Common/Input/Input";
import { STAMP_FONT, TEXT_INFO } from "components/Profile/type";
import useSignaturesMutation from "hooks/mutations/signature/useSignaturesMutation";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import StampList from "./StampList";
import useStamp from "hooks/useStamp";

interface FormData {
  stampName: string;
}

interface StampContentProps {
  closeModal: () => void;
  sign?: (file: File) => void;
}

const StampContent = ({ closeModal, sign }: StampContentProps) => {
  const { mutate } = useSignaturesMutation();
  const { selectedStamp, setSelectedStamp, createStamp, isFontLoaded } =
    useStamp({
      initialFont: STAMP_FONT,
    });

  const [stampName, setStampName] = useState("이폼서명");
  const { control, handleSubmit, watch } = useForm<FormData>({
    defaultValues: {
      stampName: "",
    },
  });

  const handleGeneratorSubmit = (data: FormData) => {
    setStampName(data.stampName);
  };

  const handleCreateStamp = async () => {
    const file = await createStamp();
    if (!file) return;

    if (sign) {
      sign(file);
      closeModal();
    } else
      mutate(file, {
        onSuccess: () => {
          closeModal();
        },
      });
  };

  return (
    <>
      <StampGeneratorForm onSubmit={handleSubmit(handleGeneratorSubmit)}>
        <Controller
          name="stampName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              {...field}
              placeholder="이름을 입력하고 만들기 버튼을 눌러주세요"
              onChange={(e) => {
                const noSpaces = e.target.value.replace(/\s/g, "");
                field.onChange(noSpaces); // 공백 제거된 값을 폼에 반영
              }}
              width="100%"
            />
          )}
        />
        <Button
          colorType="primary"
          size="large"
          style={{ width: "fit-content" }}
          isDisabled={watch("stampName").length <= 0}
        >
          만들기
        </Button>
      </StampGeneratorForm>
      {isFontLoaded && (
        <StampList
          fontList={STAMP_FONT}
          textInfoList={TEXT_INFO}
          stampName={stampName}
          onClick={({ ref, id }) => {
            setSelectedStamp({
              ref,
              id,
            });
          }}
          selectedStamp={selectedStamp}
        />
      )}
      <CreateButton
        colorType="primary"
        size="large"
        disabled={!selectedStamp.ref}
        onClick={handleCreateStamp}
      >
        도장 생성하기
      </CreateButton>
    </>
  );
};

export default StampContent;

export const StampGeneratorForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2rem;

  > button {
    white-space: nowrap;
  }
`;

const CreateButton = styled(Button)`
  display: block;
  margin: 0;
`;

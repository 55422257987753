import { postPaymentsMethodToss } from "apis/payment";
import { redirect } from "react-router-dom";
import { toast } from "react-toastify";

const PaymentTossLoader = async ({ request, params }: any) => {
  const path = sessionStorage.getItem("path");

  try {
    sessionStorage.removeItem("path");
    if (params === undefined) return redirect(path || "/payment/dashboard");

    if (params.status === "fail") {
      const message = new URL(request.url).searchParams.get("message");
      toast(message || "문제가 발생했습니다. 다시 시도해주세요.");
      return redirect(path || "/payment/dashboard");
    }

    const customerKey = new URL(request.url).searchParams.get("customerKey");
    const authKey = new URL(request.url).searchParams.get("authKey");

    if (!customerKey || !authKey) return redirect(path || "/payment/dashboard");

    await postPaymentsMethodToss({
      organizationUuid: customerKey,
      tossData: {
        customerKey,
        authKey,
      },
    });

    return redirect(path || "/payment/dashboard");
  } catch (err) {
    toast("문제가 발생했습니다. 다시 시도해주세요.");
    sessionStorage.removeItem("path");
    return redirect(path || "/payment/dashboard");
  }
};

export default PaymentTossLoader;

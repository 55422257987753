import { ToastContainer } from "react-toastify";
import styled, { createGlobalStyle } from "styled-components";

export const supportDeviceSize = 1080;

export const breakpoints = {
  lg: "1140px",
  md: "960px",
  sm: "760px",
  xs: "480px",
};

export const device = {
  lg: `(min-width: ${breakpoints.lg})`,
  md: `(min-width: ${breakpoints.md}) and (max-width: ${
    parseInt(breakpoints.lg) - 1
  }px)`,
  sm: `(min-width: ${breakpoints.sm}) and (max-width: ${
    parseInt(breakpoints.md) - 1
  }px)`,
  xs: `(max-width: ${parseInt(breakpoints.sm) - 1}px)`,
};
export const GlobalStyle = createGlobalStyle`
:root {
    --header-height: 7rem;
    --footer-height: 32rem;

    --landing-max-width: 114rem; 
    --landing-padding: 0 2.4rem;
    --landing-gap: 18rem;

  @media (${device.md}) {
    :root {
      --landing-padding: 0 2.4rem;
      --landing-gap: 18rem;
    }
  }

  @media (${device.sm}) {
    :root {
       --landing-padding: 0 1.6rem;
       --landing-gap: 14rem;
    }
  }

  @media (${device.xs}) {
    :root {
       --landing-padding: 0 1.6rem;
       --landing-gap: 10rem;
    }
  }

    --primary-100:#F2F7FF;
    --primary-200:#D7E5FF;
    --primary-300:#98BDFF;
    --primary-400:#5B96FF;
    --primary-500:#1B65E8;
    --primary-600:#0048C8;
    --primary-700:#00389A;
    --primary-800:#002566;
    --primary-900:#001539;

    --grey-10:#FDFDFD;
    --grey-100:#FAFBFD;
    --grey-200:#F3F5F9;
    --grey-300:#E9EBEE;
    --grey-400:#C6C8CD;
    --grey-500:#9AA1AB;
    --grey-600:#666F7B;
    --grey-700:#3A4552;
    --grey-800:#3A4552;
    --grey-900:#3A4552;

    --blue-100:#E5ECFA;
    --blue-200:#D7E5FF;
    --blue-400:#5B96FF;
    --blue-500:#1B65E8;
    --blue-600:#0048C8;
    --blue-700:#0043CE;
    --blue-900:#001539;

    --red-100:#FFBEC2;
    --red-400:#F04452;
    --red-500:#FA4D56;
    --red-600:#DB5257;

    --bg-white:#FFFFFF;
    --bg-grey:#F7F7F7;
    --bg-primary:#D7E5FF;
    --bg-secondary:#F2F7FF;
    --bg-dark:#FAFBFD;
    
    --bg-sender: #666F7B1A;
    --bg-receiver-1: #F8A1FF1A;
    --bg-receiver-2: #FFCB671A;
    --bg-receiver-3: #EFF1761A;
    --bg-receiver-4: #D5ED7E1A;
    --bg-receiver-5: #95F2FF1A;
    --bg-receiver-6: #6A9DF81A;
    --bg-receiver-7: #AD96FF1A;
    --bg-receiver-8: #5CFF821A;
    --bg-receiver-9: #33FFF31A;
    --bg-receiver-10: #F7752D1A;

    /*Font weight */
    --bold: 700;
    --semi-bold: 600;
    --medium: 500;
    --regular: 400;

    /* Font color */
    --text-default: #171D23;
    --text-secondary: #666F7B;
    --text-tertiary:#9AA1AB;

    --text-emphasis:#0048C8;
    --text-middle-emphasis:#1B65E8;
    --text-disabled:#C6C8CD;
    --text-placeholder:#9AA1AB;

    /* Stroke color */
    --stroke-dark: #C6C8CD;
    --stroke-light: #e9ebee;
    --stroke-primary:#1B65E8;
    --stroke-secondary:#98BDFF;
    --stroke-sender: #666F7B;
    --stroke-receiver-1: #F8A1FF;
    --stroke-receiver-2: #FFCB67;
    --stroke-receiver-3: #EFF176;
    --stroke-receiver-4: #D5ED7E;
    --stroke-receiver-5: #95F2FF;
    --stroke-receiver-6: #6A9DF8;
    --stroke-receiver-7: #AD96FF;
    --stroke-receiver-8: #5CFF82;
    --stroke-receiver-9: #33FFF3;
    --stroke-receiver-10: #F7752D1A;

    /* Icon color */
    --icon-default: #3A4552;
    --icon-middle-emphasis: #5B96FF;
    --icon-secondary: #666F7B;   
    --red:#DB5257;
    --new-red: #F04452;

    --yellow:#FB0;
    --new-green:#03B26C;

    --main-color:#0D38BD;
}
html {
  scroll-behavior: smooth;
font-size: 62.5%; // 1rem = 10px 로 변경 한 것
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!

    /* @media all and (max-width: ${supportDeviceSize}px) {
        font-size: 31.25%;
    } */

}

body {
    background: white;
    margin: 0;
    padding: 0;
    color: var(--text-default);
    height: 100%;
    font-size: 1.6rem;
}

* {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  letter-spacing: -0.03em;
}


button {
    border : none;
    outline : none;
    background-color : transparent;
    cursor : pointer;
    margin: 0;
    padding: 0;
    font-size: inherit;
}

input,
textarea {
  border: none;
  padding: none;
}

button:focus,
button:active,
textarea:focus,
textarea:active,
input:focus,
input:active {
  box-shadow: none;
  outline: none;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
li::marker {
  color: var(--grey-300);
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0;
}


a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

hr{
  background-color: var(--stroke-light);
  border: none;
  margin: 0;
  height: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6{
  margin: 0;
  padding: 0;
}
`;

export const CustomToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 0.8rem;
    background: rgba(0, 0, 0, 0.6);
    padding: 1.6rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    color: var(--bg-white);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.42px;
    white-space: pre-wrap;
    text-align: center;

    @media (max-width: 480px) {
      width: 24rem;
    }
  }
`;

import React, { MouseEventHandler, ReactNode } from "react";
import ModalLayout from "./ModalLayout";
import { Body1SemiBold, Header2 } from "components/TextStyle";
import {
  ContentModalContainer,
  ContentModalTitleContainer,
} from "./MobileModalStyledComponents";
import CloseIcon from "assets/common/icon_close.svg";
import Button from "../Button/Button";
import PrevIcon from "assets/common/36px/icon_left_large.svg";

// back - 상단 모달 타이틀 좌측 뒤로가기 화살표 아이콘 클릭 시 동작
// icon - CloseIcon(닫힘 아이콘)이 아닌 경우 해당 아이콘 / 오른쪽 상단 아이콘
// handleIcon - 모달 닫힘 이벤트가 아닌 경우 / 오른쪽 상단 아이콘 클릭 시 동작
interface ActionModalProps {
  isPrevIcon?: boolean;
  handleModalClose: () => void;
  title: ReactNode;
  children: ReactNode;
  size: "large" | "medium";
  icon?: string;
  handleIcon?: MouseEventHandler<HTMLImageElement>;
  style?: React.CSSProperties;
  isCloseIcon?: boolean;
  handleCancelButton?: MouseEventHandler<HTMLButtonElement>;
  handleConfirmButton?: MouseEventHandler<HTMLButtonElement>;
  noTitle?: boolean;
  maxHeight?: string;
  disableCloseWithOutsideClickOrEsc?: boolean;
}

const ContentModal = ({
  isPrevIcon = false,
  handleModalClose,
  title,
  children,
  size,
  icon,
  handleIcon,
  style,
  isCloseIcon = true,
  handleCancelButton,
  handleConfirmButton,
  noTitle = false,
  maxHeight,
  disableCloseWithOutsideClickOrEsc = false,
}: ActionModalProps) => {
  return (
    <ModalLayout
      handleModalClose={handleModalClose}
      disableCloseWithOutsideClickOrEsc={disableCloseWithOutsideClickOrEsc}
    >
      <ContentModalContainer $size={size} style={style} $maxHeight={maxHeight}>
        <div className="contentContainer">
          {!noTitle && (
            <ContentModalTitleContainer>
              <Header2>
                {isPrevIcon && (
                  <img
                    className="modalIcon prevIcon"
                    src={PrevIcon}
                    alt="prevIcon"
                    onClick={handleModalClose}
                  />
                )}
                {title}
              </Header2>
              {isCloseIcon && (
                <img
                  className="modalIcon"
                  src={icon || CloseIcon}
                  alt="closeIcon"
                  onClick={handleIcon || handleModalClose}
                />
              )}
            </ContentModalTitleContainer>
          )}
          {children}
        </div>
        {(handleCancelButton || handleConfirmButton) && (
          <div className="buttonContainer">
            {handleCancelButton && (
              <Button
                colorType="ghost"
                size="large"
                handleClick={handleCancelButton}
              >
                <Body1SemiBold>취소</Body1SemiBold>
              </Button>
            )}
            {handleConfirmButton && (
              <Button
                colorType="primary"
                size="large"
                handleClick={handleConfirmButton}
                type="button"
              >
                <Body1SemiBold>확인</Body1SemiBold>
              </Button>
            )}
          </div>
        )}
      </ContentModalContainer>
    </ModalLayout>
  );
};

export default ContentModal;

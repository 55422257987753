import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postContractSend } from "apis/contract";
import { queries } from "config/queryKey";

const usePostSendContractMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postContractSend,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.contract._def });
    },
  });
};

export default usePostSendContractMutation;

import getAPIResponseData from "utils/getAPIResponse";
import {
  PaginationRequest,
  GetApiStatisticsRes,
  GetApiHistoryRes,
  GetApiCreditsRes,
  PostApiKeyReq,
} from "../models/Api";

export const getStatistics = (organizationUuid?: string) => {
  return getAPIResponseData<GetApiStatisticsRes>({
    url: `/organizations/${organizationUuid}/apis/statistics`,
    method: "GET",
  });
};

export const getHistory = (
  organizationUuid?: string,
  options?: PaginationRequest
) => {
  const params: {
    [key: string]: any;
  } = {
    page: options?.page ?? 0,
    size: options?.size ?? 20,
  };
  return getAPIResponseData<GetApiHistoryRes>({
    url: `/organizations/${organizationUuid}/apis/history`,
    method: "GET",
    params,
  });
};

export const getCredits = (organizationUuid?: string) => {
  return getAPIResponseData<GetApiCreditsRes>({
    url: `/organizations/${organizationUuid}/apis/credits`,
    method: "GET",
  });
};

export const postApiKey = (data: PostApiKeyReq) => {
  return getAPIResponseData({
    url: `/organizations/apis/key`,
    method: "POST",
    data: data,
  });
};

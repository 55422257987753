import React, { useState } from "react";
import useFontLoad from "./useFontLoad";
import { Stage as StageRefType } from "konva/lib/Stage";
import resizeImage from "utils/resizeImage";
import konvaToImage from "utils/konvaToImage";
import { StampFont } from "components/Profile/type";

interface CreateStampProps {
  initialFont: StampFont[];
}

const useStamp = ({ initialFont }: CreateStampProps) => {
  const isFontLoaded = useFontLoad(initialFont);

  const [selectedStamp, setSelectedStamp] = useState<{
    id: string;
    ref?: React.MutableRefObject<StageRefType | null>;
  }>({
    id: "",
    ref: undefined,
  });

  const createStamp = async () => {
    if (!selectedStamp.ref?.current) return;

    const scaleSize = resizeImage(
      {
        width: selectedStamp.ref.current.width(),
        height: selectedStamp.ref.current.height(),
      },
      {
        width: 300,
        height: 300,
      }
    );

    const file = await konvaToImage(selectedStamp.ref.current, {
      width: 300,
      height: 300,
      ...scaleSize,
    });

    return file;
  };

  return {
    isFontLoaded,
    selectedStamp,
    setSelectedStamp,
    createStamp,
  };
};

export default useStamp;

import axios, { AxiosResponse } from "axios";
import {
  GetSignDetailsResponse,
  GetSignDirectUrlsDetailsResponse,
  GetSignDirectUrlsPasswordRequest,
  GetSignDirectUrlsPasswordResponse,
  GetSignDirectUrlsRequest,
  GetSignDirectUrlsResponse,
  GetSignPhoneVerifyValidRequest,
  GetSignPhoneVerifyValidResponse,
  PostSignDirectUrlsRequest,
  PostSignDirectUrlsVerifyRequest,
  PostSignDirectUrlsVerifyResponse,
  PostSignPasswordVerifyRequest,
  PostSignPasswordVerifyResponse,
  PostSignRequest,
  PostSignVerifyRequest,
  PostSignVerifyResponse,
  ReceiverVerifyType,
} from "models/Sign";
import getAPIResponseData from "utils/getAPIResponse";

export const postSignVerify = (
  verifyType: ReceiverVerifyType,
  data: PostSignVerifyRequest
) => {
  return getAPIResponseData<PostSignVerifyResponse, PostSignVerifyRequest>({
    url: `/sign/verify?verify-type=${verifyType}`,
    method: "POST",
    data,
  });
};

export const postSignPasswordVerify = ({
  contractReceiverUuid,
  data,
}: {
  contractReceiverUuid: string;
  data: PostSignPasswordVerifyRequest;
}) => {
  return getAPIResponseData<
    PostSignPasswordVerifyResponse,
    PostSignPasswordVerifyRequest
  >({
    url: `/sign/${contractReceiverUuid}/password-verify`,
    method: "POST",
    data,
  });
};

export const getSignDetails = () => {
  return getAPIResponseData<GetSignDetailsResponse>({
    url: `/sign/details`,
    method: "GET",
  });
};

export const postSignDeny = (data: { reason: string }) => {
  return getAPIResponseData({
    url: `/sign/deny`,
    method: "POST",
    data,
  });
};

export const getSignAssetsPresignedUrl = async ({
  file,
  fileName,
}: {
  file: File;
  fileName: string;
}) => {
  const url = await getAPIResponseData<string>({
    url: `/sign/assets/presigned-url?fileName=${fileName}`,
    method: "GET",
  });

  try {
    await axios({
      url,
      method: "PUT",
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
  } catch (err) {
    throw err;
  } finally {
    return url;
  }
};

export const postSign = (data: PostSignRequest) => {
  return getAPIResponseData({
    url: `/sign`,
    method: "POST",
    data,
  });
};

export const getSignPhoneVerifyValid = ({
  verifyType,
  requestId,
  entityId,
}: GetSignPhoneVerifyValidRequest) => {
  return getAPIResponseData<GetSignPhoneVerifyValidResponse>({
    url: `/sign/phone-verify/valid?verifyType=${verifyType}&requestId=${requestId}&entityId=${entityId}`,
    method: "GET",
  });
};

export const getSignDirectUrls = ({
  directUrlCode,
}: GetSignDirectUrlsRequest) => {
  return getAPIResponseData<GetSignDirectUrlsResponse>({
    url: `/sign/direct-urls`,
    method: "GET",
    params: {
      directUrlCode,
    },
  });
};

export const getSignDirectUrlsPassword = ({
  contractDirectUrlUuid,
}: GetSignDirectUrlsPasswordRequest) => {
  return getAPIResponseData<GetSignDirectUrlsPasswordResponse>({
    url: `/sign/direct-urls/${contractDirectUrlUuid}/password`,
    method: "GET",
  });
};

export const postSignDirectUrlsVerify = ({
  contractDirectUrlUuid,
  data,
}: {
  contractDirectUrlUuid: string;
  data: PostSignDirectUrlsVerifyRequest;
}) => {
  return getAPIResponseData<
    PostSignDirectUrlsVerifyResponse,
    PostSignDirectUrlsVerifyRequest
  >({
    url: `/sign/direct-urls/${contractDirectUrlUuid}/verify`,
    method: "POST",
    data,
  });
};

export const getSignDirectUrlsDetails = () => {
  return getAPIResponseData<GetSignDirectUrlsDetailsResponse>({
    url: `/sign/direct-urls/details`,
    method: "GET",
  });
};

export const getSignDirectUrlsAssetsPresignedUrl = async ({
  file,
  fileName,
}: {
  file: File;
  fileName: string;
}) => {
  const url = await getAPIResponseData<string>({
    url: `/sign/direct-urls/assets/presigned-url?fileName=${fileName}`,
    method: "GET",
  });

  try {
    await axios({
      url,
      method: "PUT",
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
  } catch (err) {
    throw err;
  } finally {
    return url;
  }
};

export const postDirectUrlsSign = (data: PostSignDirectUrlsRequest) => {
  return getAPIResponseData<{ isSigned: boolean }, PostSignDirectUrlsRequest>({
    url: `/sign/direct-urls/sign`,
    method: "POST",
    data,
  });
};

export const getSignContractCopies = () => {
  return getAPIResponseData<AxiosResponse<Blob>>({
    url: `/sign/copy`,
    method: "GET",
    responseType: "blob",
    headers: {
      Accept: "application/pdf",
    },
  });
};

export const getSignContractCertificate = () => {
  return getAPIResponseData<AxiosResponse<Blob>>({
    url: `/sign/certificate`,
    method: "GET",
    responseType: "blob",
    headers: {
      Accept: "application/pdf",
    },
  });
};

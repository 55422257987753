import Button from "components/Common/Button/Button";
import DatePickerModal from "components/Common/DatePicker/DatePickerModal";
import Input from "components/Common/Input/Input";
import ContentModal from "components/Common/Modal/ContentModal";
import Toggle from "components/Common/Toggle/Toggle";
import { Body1Regular, Body2Medium } from "components/TextStyle";
import { REGEX } from "config/regex";
import { addDays, addYears } from "date-fns";
import useDirectURLMutation from "hooks/mutations/directURL/useDirectURLMutation";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";
import useUserState from "hooks/recoil/useUserState";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import copyToClipboard from "utils/copyToClipboard";
import { getDateTime } from "utils/getDateTime";

const DESCRIPTION = [
  <>
    직접 만나서 작성하거나 사이트 / 카페 등에 작성 URL을 노출 하여 불특정 다수가
    쉽게 작성하도록 활용할 수 있습니다.
  </>,
  <>
    URL 생성시 설정한 이름, 건수, 유효기간은 [Direct URL 수정]에서 수정
    가능합니다.
  </>,
];

interface TemplateDirectUrlModalProps {
  templateUuid: string;
  closeModal: () => void;
}

interface FormData {
  urlName: string;
  urlCount: string;
  urlExpiration: Date;
  urlPassword: {
    isUsed: boolean;
    password: string;
  };
  phoneAuth: {
    isUsed: boolean;
  };
  approvalAuthority: {
    isUsed: boolean;
    email: string;
  };
}

const TemplateDirectUrlModal = ({
  templateUuid,
  closeModal,
}: TemplateDirectUrlModalProps) => {
  const {
    register,
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    setValue,
  } = useForm<FormData>();
  const [user] = useUserState();
  const [url, setUrl] = useState("");
  const { data: creditData } = useContractRemainCreditsQuery();
  const { mutate: postDirectUrl } = useDirectURLMutation();

  const generateURL = (e: FormData) => {
    if (!user || !user.organization) return;

    postDirectUrl(
      {
        organizationId: user?.organization as string,
        data: {
          contractTemplateUuid: templateUuid,
          urlName: e.urlName,
          urlUsageCount: +e.urlCount,
          expiredAt: getDateTime(e.urlExpiration),
          usePasswordVerify: e.urlPassword.isUsed || false,
          password: e.urlPassword.isUsed ? e.urlPassword.password : undefined,
          usePhoneVerify: e.phoneAuth.isUsed || false,
          useReview: e.approvalAuthority.isUsed || false,
          reviewEmail: e.approvalAuthority.isUsed
            ? e.approvalAuthority.email
            : undefined,
        },
      },
      {
        onSuccess: ({ directUrl }) => {
          setUrl(directUrl);
        },
      }
    );
  };

  useEffect(() => {
    setValue("urlExpiration", addDays(new Date(), 7));
  }, []);

  useEffect(() => {
    if (watch("urlPassword.isUsed") && watch("phoneAuth.isUsed"))
      setValue("phoneAuth.isUsed", false);

    if (!watch("urlPassword.isUsed")) {
      setValue("urlPassword.password", "");
    }
  }, [watch("urlPassword.isUsed")]);

  useEffect(() => {
    if (watch("urlPassword.isUsed") && watch("phoneAuth.isUsed")) {
      setValue("urlPassword.isUsed", false);
      setValue("urlPassword.password", "");
    }
  }, [watch("phoneAuth.isUsed")]);

  return (
    <ContentModal
      title="Direct URL 생성"
      handleModalClose={closeModal}
      size="medium"
      maxHeight="94rem"
    >
      <Layout>
        <ul>
          {DESCRIPTION.map((desc, index) => (
            <li key={index}>
              <Body1Regular>{desc}</Body1Regular>
            </li>
          ))}
        </ul>
        <FormLayout
          onSubmit={handleSubmit(generateURL)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <FormItem
            title="URL 이름"
            Input={<Input placeholder="템플릿 명" {...register("urlName")} />}
          />
          <FormItem
            title="URL 작성건수"
            Input={
              <Input
                type="number"
                style={{
                  paddingRight: "3rem",
                }}
                {...register("urlCount", {
                  onChange: (e) => {
                    const value = parseInt(e.target.value);
                    if (
                      !isNaN(value) &&
                      value >
                        (creditData?.remainContractCredit
                          .remainCredits as number)
                    ) {
                      e.target.value =
                        creditData?.remainContractCredit.remainCredits;
                    } else if (value < 0) {
                      e.target.value = 0;
                    }
                  },
                })}
              />
            }
            inputAddon="건"
            subDescription={`(잔여 건수: ${creditData?.remainContractCredit.remainCredits?.toLocaleString(
              "ko"
            )}건)`}
          />
          <FormItem
            title="URL 유효기간"
            Input={
              <DatePickerModal
                selected={watch("urlExpiration") || addDays(new Date(), 7)}
                onChangeHandler={(expiry) => {
                  if (expiry) {
                    setValue("urlExpiration", expiry);
                  }
                }}
                onSelectHandler={(expiry) => {
                  if (expiry) {
                    setValue("urlExpiration", expiry);
                  }
                }}
                maxDate={addYears(new Date(), 20)}
              />
            }
            subDescription={`(${getDateTime(
              watch("urlExpiration") || addDays(new Date(), 7),
              true
            )}까지)`}
          />
          <FormItem
            title={
              <Controller
                control={control}
                name="urlPassword.isUsed"
                render={({ field: { value, onChange, ref } }) => (
                  <>
                    <Toggle
                      isSelected={value}
                      handleToggle={() => onChange(!value)}
                      ref={ref}
                    />
                    URL 비밀번호
                  </>
                )}
              />
            }
            Input={
              <Input
                placeholder="4자~12자"
                {...register("urlPassword.password", {
                  required:
                    getValues("urlPassword.isUsed") &&
                    "4자~12자로 입력해주세요.",
                  pattern: {
                    value: REGEX.directUrl.password,
                    message: "4자~12자로 입력해주세요.",
                  },
                  onChange: (e) => {
                    e.target.value = e.target.value.replace(
                      /[^a-zA-Z0-9]/g,
                      ""
                    );
                  },
                })}
                isError={
                  watch("urlPassword.isUsed") && !!errors.urlPassword?.password
                }
                errorMessage={errors.urlPassword?.password?.message}
                isDisabled={!watch("urlPassword.isUsed")}
                width="38rem"
              />
            }
          />

          <FormItem
            title={
              <Controller
                control={control}
                name="phoneAuth.isUsed"
                render={({ field: { value, onChange, ref } }) => (
                  <>
                    <Toggle
                      isSelected={value}
                      handleToggle={() => onChange(!value)}
                      ref={ref}
                    />
                    휴대폰 본인 인증
                  </>
                )}
              />
            }
          />

          <FormItem
            title={
              <Controller
                control={control}
                name="approvalAuthority.isUsed"
                render={({ field: { value, onChange, ref } }) => (
                  <>
                    <Toggle
                      isSelected={value}
                      handleToggle={() => onChange(!value)}
                      ref={ref}
                    />
                    승인 권한
                  </>
                )}
              />
            }
            Input={
              <Input
                placeholder="승인 이메일"
                {...register("approvalAuthority.email", {
                  required:
                    getValues("approvalAuthority.isUsed") &&
                    "유효한 이메일 주소를 입력해 주세요",
                  pattern: {
                    value: REGEX.email,
                    message: "유효한 이메일 주소를 입력해 주세요",
                  },
                })}
                isError={!!errors.approvalAuthority?.email}
                errorMessage={errors.approvalAuthority?.email?.message}
                isDisabled={!watch("approvalAuthority.isUsed")}
                width="38rem"
              />
            }
          />
          <GenerateURLLayout>
            <Input width="26.9rem" disabled value={url} />
            <Button
              colorType="dark"
              size="medium"
              type={url ? "button" : "submit"}
              onClick={() => {
                if (url) {
                  copyToClipboard(url);
                  toast("링크가 복사되었습니다");
                }
              }}
            >
              {url ? "URL 복사" : "URL 생성"}
            </Button>
          </GenerateURLLayout>
          <div className="linear" />
        </FormLayout>
      </Layout>
    </ContentModal>
  );
};

export default TemplateDirectUrlModal;

const Layout = styled.div`
  ul {
    counter-reset: counterName;
    margin-bottom: 4rem;
  }

  li {
    display: flex;
    word-break: keep-all;
    counter-increment: counterName;
    white-space: pre-wrap;

    &::before {
      content: counter(counterName) ". ";
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: var(--text-default);
    }
  }

  .form-container {
    width: 100%;
    position: relative;
  }
`;

const FormLayout = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  max-height: 60dvh;
  height: 100%;
  overflow-y: scroll;
`;

const GenerateURLLayout = styled.div`
  display: flex;
  gap: 1.2rem;
`;

interface FormItemProps {
  title: React.ReactNode;
  Input?: React.ReactNode;
  inputAddon?: string;
  subDescription?: React.ReactNode;
}

const FormItem = ({
  title,
  Input,
  inputAddon,
  subDescription,
}: FormItemProps) => (
  <FormItemWrapper>
    <Body2Medium className="title-wrapper">{title}</Body2Medium>
    <InputContainer>
      <div className="input-wrapper">
        {Input}
        {inputAddon && <InputAddon>{inputAddon}</InputAddon>}
      </div>
      {subDescription && <SubDescription>{subDescription}</SubDescription>}
    </InputContainer>
  </FormItemWrapper>
);

const FormItemWrapper = styled.div`
  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 1.2rem;
  }
  .input-wrapper {
    position: relative;
    min-width: 16rem;
    flex: 1;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  flex: 1;
`;

const InputAddon = styled(Body1Regular)`
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
`;

const SubDescription = styled(Body2Medium)`
  color: var(--text-emphasis);
  flex: 1;
`;

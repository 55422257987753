import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  DropdownContainer,
  DropdownItem,
  DropdownItemContainer,
} from "./DropdownStyledComponents";
import { Body1Regular, Body2Medium } from "components/TextStyle";
import UpIcon from "assets/common/24px/icon_up.svg";
import DownIcon from "assets/common/24px/icon_down_default.svg";
import DownIconTable from "assets/common/20px/icon_down_default.svg";
export interface DropdownList {
  value: string;
  label: string;
  selectLabel?: string;
}

export interface DropdownProps {
  table?: boolean;
  placeholder?: string;
  selected: string;
  dropdownList: DropdownList[];
  isError?: boolean;
  setSelected: Dispatch<SetStateAction<string>> | ((value: string) => void);
  outline?: boolean;
  handleClick?: (value: string) => void;
  backgroundDimmedWhenOpened?: boolean;
  isDisabled?: boolean;
  isScrollBarView?: boolean;
}

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      table,
      placeholder,
      selected,
      setSelected,
      dropdownList,
      backgroundDimmedWhenOpened,
      isError = false,
      outline = true,
      handleClick,
      isDisabled = false,
      isScrollBarView = false,
      ...args
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (event: MouseEvent) => {
      const targetNode = event.target as Node;
      if (dropdownRef.current && !dropdownRef.current.contains(targetNode)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    return (
      <div ref={dropdownRef}>
        <DropdownContainer
          $table={table}
          $isOpen={isOpen}
          $isError={isError}
          $outline={outline}
          onClick={() => setIsOpen(!isOpen)}
          ref={ref}
          {...args}
        >
          {!isDisabled && isOpen && backgroundDimmedWhenOpened && (
            <div className="dimmed" onClick={() => setIsOpen(false)} />
          )}
          <div className="contentContainer">
            {table ? (
              <Body2Medium>
                {dropdownList?.find((e) => e.value === selected)?.label ||
                  placeholder}
              </Body2Medium>
            ) : (
              <Body1Regular className={`${!selected && "isPlaceholder"}`}>
                {dropdownList.find((e) => e.value === selected)?.selectLabel ||
                  dropdownList?.find((e) => e.value === selected)?.label ||
                  placeholder}
              </Body1Regular>
            )}
            {table ? (
              <img alt="downIcon" className="downIcon" src={DownIconTable} />
            ) : (
              <img
                alt="downIcon"
                className="downIcon"
                src={isOpen ? UpIcon : DownIcon}
              />
            )}
          </div>
          {!isDisabled && isOpen && (
            <DropdownItemContainer
              $table={table}
              $outline={outline}
              className="dropdownItemContainer"
              $isScrollBarView={isScrollBarView}
            >
              {dropdownList?.map((e, idx) => {
                return (
                  <DropdownItem
                    key={idx}
                    $isSelected={e.value === selected}
                    $table={table}
                    onClick={() => {
                      setSelected(e.value);
                      handleClick && handleClick(e.value);
                      setIsOpen(false);
                    }}
                  >
                    {table ? (
                      <Body2Medium className="itemValue">{e.label}</Body2Medium>
                    ) : (
                      <Body1Regular className="itemValue">
                        {e.label}
                      </Body1Regular>
                    )}
                  </DropdownItem>
                );
              })}
            </DropdownItemContainer>
          )}
        </DropdownContainer>
      </div>
    );
  }
);

Dropdown.displayName = "Dropdown";

export default Dropdown;

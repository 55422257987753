import { AxiosResponse } from "axios";
import {
  DeleteTemplateByIdFavoritesByIdRequest,
  DeleteTemplateByIdRequest,
  DeleteTemplateByIdRequestData,
  GetTemplateByIdByIdRequest,
  GetTemplateDataExcelRequest,
  GetTemplateDetailInfoRequest,
  GetTemplateDetailInfoResponse,
  GetTemplateDetailsByIdRequest,
  GetTemplateDetailsByIdResponse,
  GetTemplateListByIdRequest,
  GetTemplateListByIdResponse,
  GetTemplatePresignedUrlRequest,
  PatchTemplateNameRequest,
  PostSendContractTemplateRequest,
  PostTemplateByIdFavoriteByIdRequest,
  PostTemplateCopyRequest,
  PostTemplateCopyResponse,
  PostTemplateRequest,
  PutTemplateRequest,
} from "models/Template";
import getAPIResponseData from "utils/getAPIResponse";

export const deleteTemplateByIdFavoriteById = ({
  organizationId,
  organizationGroupId,
  templateId,
}: DeleteTemplateByIdFavoritesByIdRequest) => {
  const params = organizationGroupId
    ? {
        organizationGroupUuid: organizationGroupId,
      }
    : {};
  return getAPIResponseData({
    url: `/templates/${organizationId}/favorite/${templateId}`,
    method: "DELETE",
    params,
  });
};

export const deleteTemplateById = ({
  organizationId,
  organizationGroupId,
  templateIdList,
}: DeleteTemplateByIdRequest) => {
  const params = organizationGroupId
    ? {
        organizationGroupUuid: organizationGroupId,
      }
    : {};
  return getAPIResponseData<DeleteTemplateByIdRequestData>({
    url: `/templates/${organizationId}`,
    method: "DELETE",
    data: {
      templateUuidList: templateIdList.map((templateId) => ({
        templateUuid: templateId,
      })),
    },
    params,
  });
};

export const getTemplateByIdById = ({
  organizationId,
  organizationGroupId,
  templateId,
}: GetTemplateByIdByIdRequest) => {
  return getAPIResponseData<AxiosResponse<Blob>>({
    url: `/templates/${organizationId}/${templateId}`,
    responseType: "blob",
    method: "GET",
    params: {
      ...(organizationGroupId && {
        organizationGroupUuid: organizationGroupId,
      }),
    },
  });
};

export const getTemplatePresignedUrl = ({
  organizationId,
}: GetTemplatePresignedUrlRequest) => {
  return getAPIResponseData({
    url: `/templates/presigned-url`,
    method: "GET",
    params: {
      uuid: organizationId,
    },
  });
};

export const getTemplateListById = ({
  organizationId,
  organizationGroupId,
  options,
}: GetTemplateListByIdRequest) => {
  // 초기 매개변수 객체 설정
  let params: {
    [key: string]: any;
  } = {
    total: options?.total,
    createdUserName: options?.createdUserName,
    templateName: options?.templateName,
    keyword: options?.keyword,
    searchType: options?.searchType,
    onlyFavoriteTemplate: options?.onlyFavoriteTemplate ?? false,
    onlyMyTemplate: options?.onlyMyTemplate ?? false,
    page: options?.page ?? 0,
    size: options?.size ?? 20,
  };

  // organizationGroupId가 존재하는 경우에만 매개변수에 추가
  if (organizationGroupId) {
    params = { ...params, organizationGroupUuid: organizationGroupId };
  }

  return getAPIResponseData<GetTemplateListByIdResponse>({
    url: `/templates/list/${organizationId}`,
    method: "GET",
    params,
  });
};

export const getTemplateDetailInfo = ({
  organizationId,
  templateId,
}: GetTemplateDetailInfoRequest) => {
  return getAPIResponseData<GetTemplateDetailInfoResponse>({
    url: `/templates/${organizationId}/${templateId}/details-info`,
    method: "GET",
  });
};

export const getTemplateDataExcel = ({
  organizationId,
  templateId,
  request,
}: GetTemplateDataExcelRequest) => {
  return getAPIResponseData<AxiosResponse<Blob>>({
    url: `/templates/${organizationId}/${templateId}/data-excel`,
    responseType: "arraybuffer",
    headers: {
      accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    method: "GET",
    params: request,
  });
};

export const patchTemplateName = ({
  organizationId,
  organizationGroupId,
  templateId,
  name,
}: PatchTemplateNameRequest) => {
  const params = organizationGroupId
    ? {
        templateUuid: templateId,
        organizationUuid: organizationId,
        organizationGroupUuid: organizationGroupId,
      }
    : {
        templateUuid: templateId,
        organizationUuid: organizationId,
      };
  return getAPIResponseData({
    url: `/templates/${organizationId}/${templateId}/name`,
    method: "PATCH",
    data: { templateName: name },
    params,
  });
};

export const postTemplateByIdFavoriteById = ({
  organizationId,
  organizationGroupId,
  templateId,
}: PostTemplateByIdFavoriteByIdRequest) => {
  const params = organizationGroupId
    ? {
        organizationGroupUuid: organizationGroupId,
      }
    : {};
  return getAPIResponseData({
    url: `/templates/${organizationId}/favorite/${templateId}`,
    method: "POST",
    params,
  });
};

export const postTemplate = ({
  organizationUuid,
  data,
}: PostTemplateRequest) => {
  return getAPIResponseData({
    url: `/templates/${organizationUuid}`,
    method: "POST",
    data,
  });
};

export const getTemplateDetailsById = ({
  organizationUuid,
  templateUuid,
}: GetTemplateDetailsByIdRequest) => {
  return getAPIResponseData<GetTemplateDetailsByIdResponse>({
    url: `/templates/${organizationUuid}/${templateUuid}/details`,
    method: "GET",
  });
};

export const putTemplate = ({
  organizationUuid,
  contractTemplateUuid,
  data,
}: PutTemplateRequest) => {
  return getAPIResponseData({
    url: `/templates/${organizationUuid}/${contractTemplateUuid}`,
    method: "PUT",
    data,
  });
};

export const postContractTemplate = ({
  organizationUuid,
  organizationGroupUuid = "",
  data,
}: PostSendContractTemplateRequest) => {
  return getAPIResponseData({
    url: `/templates/${organizationUuid}/send-contract-template${
      organizationGroupUuid
        ? `?organizationGroupUuid=${organizationGroupUuid}}`
        : ""
    }`,
    method: "POST",
    data,
  });
};

export const postContractTemplateSingleMultiple = ({
  organizationUuid,
  organizationGroupUuid = "",
  data,
}: PostSendContractTemplateRequest) => {
  return getAPIResponseData({
    url: `/templates/${organizationUuid}/send-single-multiple-contract-template${
      organizationGroupUuid
        ? `?organizationGroupUuid=${organizationGroupUuid}}`
        : ""
    }`,
    method: "POST",
    data,
  });
};

export const postTemplateCopy = ({
  organizationUuid,
  templateUuid,
}: PostTemplateCopyRequest) => {
  return getAPIResponseData<PostTemplateCopyResponse>({
    url: `/templates/${organizationUuid}/${templateUuid}`,
    method: "POST",
  });
};

import Button from "components/Common/Button/Button";
import Dropdown from "components/Common/Dropdown/Dropdown";
import Input from "components/Common/Input/Input";
import ModalLayout from "components/Common/Modal/ModalLayout";
import { Body1SemiBold, Title1 } from "components/TextStyle";
import useDropdownListAllQuery from "hooks/queries/dropdownList/useDropdownListAllQuery";
import useDropdownListDetailQuery from "hooks/queries/dropdownList/useDropdownListDetailQuery";
import useUserState from "hooks/recoil/useUserState";
import { DropDownItem } from "models/Contract";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";

interface IDropdownEditModalProps {
  defaultContents?: DropDownItem[];
  closeModal: () => void;
  editDropdown: (contents: string) => void;
}

export default function DropdownEditModal({
  defaultContents,
  closeModal,
  editDropdown,
}: IDropdownEditModalProps) {
  const [contents, setContents] = useState(
    defaultContents?.map((item) => item.value).join(",") || ""
  );
  const [current, setCurrent] = useState<string | null>(null);
  const [user] = useUserState();

  const { data: dropDownList } = useDropdownListAllQuery(
    user.organization || ""
  );

  const { data: dropdownListDetail, refetch } = useDropdownListDetailQuery(
    current || ""
  );

  const selectElement = (dropdownListUuid: string) => {
    setCurrent(dropdownListUuid);
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      if (!e.target?.result) return;

      const workbook = XLSX.read(e.target.result, { type: "binary" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const data: string[][] = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      });

      const listItem = data.flatMap((e) => e).join(",");

      setContents(listItem);
    };
    reader.readAsArrayBuffer(e.target.files[0]);

    e.target.value = "";
  };

  useEffect(() => {
    if (current) refetch();
  }, [current]);

  useEffect(() => {
    if (dropdownListDetail?.dropdownListItemsName) {
      setContents(dropdownListDetail?.dropdownListItemsName.join(","));
    }
  }, [dropdownListDetail]);

  return (
    <ModalLayout handleModalClose={closeModal}>
      <DropdownEditModalStyled>
        <div className="main">
          <Title1>내용 입력</Title1>
          <div className="contents">
            <div>
              <div className="desc">
                드롭다운 리스트에 표시될 내용을 순서대로 입력해 주세요.
                <br />
                콤마(,)로 구분합니다.
              </div>
              <div className="inputs">
                <Input
                  type="text"
                  placeholder="내용을 입력하세요"
                  value={contents}
                  setValue={setContents}
                />
                <div className="upload">
                  <Button colorType="tertiary" size="small">
                    <input
                      type="file"
                      accept=".xls,.xlsx"
                      onChange={onChangeHandler}
                    />
                    파일 등록
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="text">
                <div>엑셀 파일의 첫번째 열의 데이터를 등록합니다.</div>
                <div>{`[설정] > [드롭다운 리스트] 에 등록된 내용을 등록합니다.`}</div>
              </div>
              <div className="dropdown">
                <Dropdown
                  placeholder={"선택해주세요."}
                  selected={current || ""}
                  dropdownList={(dropDownList || []).map((list) => ({
                    value: list.dropdownListUuid,
                    label: list.dropdownListTitle,
                  }))}
                  setSelected={selectElement}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="buttons-container">
          <Button colorType="ghost" size="medium" onClick={closeModal}>
            <Body1SemiBold>취소</Body1SemiBold>
          </Button>
          <Button
            colorType="primary"
            size="medium"
            onClick={() => {
              editDropdown(contents);
              closeModal();
            }}
          >
            <Body1SemiBold>확인</Body1SemiBold>
          </Button>
        </div>
      </DropdownEditModalStyled>
    </ModalLayout>
  );
}

const DropdownEditModalStyled = styled.div`
  padding: 3.6rem 4rem 4rem 4rem;
  background-color: white;
  box-sizing: border-box;
  width: 46rem;
  height: max-content;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;

  .main {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    .contents {
      display: flex;
      flex-direction: column;
      row-gap: 3.2rem;

      & > div {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        .desc {
          font-size: 1.6rem;
          line-height: 2.4rem;
          letter-spacing: -0.03em;
          color: var(--text-secondary);
        }

        .inputs {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
        }

        .text {
          display: flex;
          flex-direction: column;
          row-gap: 0.4rem;

          & > div {
            display: flex;
            align-items: center;
            column-gap: 0.8rem;
            font-size: 1.4rem;
            line-height: 2rem;
            letter-spacing: -0.03em;
            color: var(--text-secondary);

            &::before {
              content: "";
              width: 0.4rem;
              height: 0.4rem;
              background-color: var(--grey-200);
              border-radius: 100%;
            }
          }
        }

        .upload {
          button {
            position: relative;
            cursor: pointer;

            input {
              position: absolute;
              inset: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
          }
        }

        .dropdown {
          & > div:first-of-type {
            border-radius: 0.5rem;
          }

          .dropdownContainer {
            padding: 0.8rem;
            width: 38rem;
            top: calc(100% + 0.775rem);
            box-sizing: border-box;
            border-radius: 0.5rem;
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            row-gap: 0.8rem;
            max-height: 28.4rem;
            overflow: scroll;

            & > div {
              padding: 0.8rem;
            }

            & > div:first-of-type > .itemValue {
              color: var(--text-placeholder);
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 1rem;

    button {
      width: 10rem;
      height: 4.8rem;
    }
  }
`;

import ActionModal from "components/Mobile/Common/Modal/ActionModal";
import { Body1Medium } from "components/TextStyle";
import { ReactComponent as NoticeIcon } from "assets/common/icon_notice.svg";
import React from "react";
import styled from "styled-components";

interface TemplateDeleteMobileModalProps {
  closeModal: () => void;
  handleDelete: () => void;
  selectedCnt: number;
}

const TemplateDeleteMobileModal = ({
  closeModal,
  handleDelete,
  selectedCnt,
}: TemplateDeleteMobileModalProps) => {
  return (
    <ActionModal
      title={
        <ErrorModalTitle>
          <NoticeIcon />
          삭제하기
        </ErrorModalTitle>
      }
      handleModalClose={closeModal}
      handleCancelButton={closeModal}
      handleConfirmButton={handleDelete}
    >
      <ErrorModalContent>
        <Body1Medium>선택한 {selectedCnt}개의 템플릿을 삭제합니다.</Body1Medium>
        <Body1Medium
          style={{
            color: "#DB5257",
          }}
        >
          삭제 후 복구가 불가하오니 주의바랍니다.
        </Body1Medium>
      </ErrorModalContent>
    </ActionModal>
  );
};

export default TemplateDeleteMobileModal;

export const ErrorModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  > svg {
    width: 10rem;
    height: auto;
  }
`;
export const ErrorModalContent = styled.div`
  border-radius: 1.2rem;
  padding: 2rem;

  text-align: center;
`;

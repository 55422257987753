import React, { ChangeEvent, SetStateAction, useState } from "react";
import {
  PreviewActionModalStyled,
  ScaleControllerStyled,
  SendActionModalStyled,
  ToolbarStyled,
} from "./styles";
import Button from "components/Common/Button/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Body1Medium,
  Body1Regular,
  Body2Medium,
  Body2Regular,
  Body3Regular,
} from "components/TextStyle";
import Dropdown from "components/Common/Dropdown/Dropdown";
import { ReactComponent as AttachIcon } from "assets/common/30px/icon_clip.svg";
import { ReactComponent as PrevIcon } from "assets/common/30px/icon_back.svg";
import { ReactComponent as NextIcon } from "assets/common/30px/icon_forward.svg";
import { ReactComponent as QuestionIcon } from "assets/common/30px/icon_question.svg";
import { SCALES } from "config/constant";
import Tooltip from "components/Common/Tooltip";
import { isValidAttachment, isValidFileSize } from "utils/utility";
import { IAttachedFile, IPage, IParticipant } from "interfaces/contract";
import ActionModal from "components/Common/Modal/ActionModal";
import { toast } from "react-toastify";
import { getContractsAttachmentsPresignedUrl } from "apis/contract";
import { v4 as uuidv4 } from "uuid";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";
import { IConfigForm } from "pages/contract/send";
import useContractGanin from "hooks/queries/contract/useContractsGanin";
import useUserState from "hooks/recoil/useUserState";
import useStandardAvailabilityCheck from "hooks/queries/payment/useStandardAvailabilityCheckQuery";
import useModal from "hooks/useModal";
import useAnnualAuthQuery from "hooks/queries/auth/useAnnualAuthQuery";
import AnnualAuthModal from "components/Profile/Modal/AnnualAuthModal";

const PREVIEW_ALERTS = [
  `발신자는 다음 미리보기 화면의 발신자 입력란에 정보를 입력\n한 후 전송할 수 있습니다.`,
  `발신자가 입력한 내용은 수신자가 수정할 수 없습니다. 동일 그룹의 체크 필드는 수정할 수 없습니다.`,
  `대량전송은 [전체 템플릿 목록]의 우측 '대량전송' 버튼을 이용해 주세요.`,
];

const SEND_ALERTS = (isGanin = false) => {
  return [
    `[Chrome] 을 사용하여 전송해 주세요.`,
    `템플릿을 전송할 때 ${
      isGanin ? "간인" : "전송"
    } 건수가 차감되며,\n수신자 작성 여부와는 상관 없습니다.`,
    `수신자 정보가 정확한지 반드시 확인하고 전송해주세요.\n잘못된 입력으로 전송되지 않은 건은 환불되지 않습니다.`,
    `휴대폰 본인인증은 발신자가 기재한 수신자명과 휴대폰 명의자가 일치해야 합니다.`,
  ];
};

interface IToolbar {
  pages: IPage[];
  form?: IConfigForm;
  setForm?: React.Dispatch<React.SetStateAction<IConfigForm>>;
  template?: boolean;
  exit?: boolean;
  func?: boolean;
  save?: boolean;
  currentStep?: number;
  setCurrentStep?: React.Dispatch<SetStateAction<number>>;
  isLastStep: boolean;
  scale: string;
  setScale: React.Dispatch<SetStateAction<string>>;
  attachedFiles?: IAttachedFile[];
  setAttachedFiles?: React.Dispatch<SetStateAction<IAttachedFile[]>>;
  redo?: () => void;
  undo?: () => void;
  send?: () => void;
  isSenderSignaturesEntered?: () => boolean;
  receivers?: IParticipant[];
  validate?: () => void;
  saveTemp?: (templateStatus: "COMPLETE" | "TEMP") => void;
  setContractModeModalOpened?: React.Dispatch<SetStateAction<boolean>>;
  setStampModalOpened?: React.Dispatch<SetStateAction<boolean>>;
  isSending?: boolean;
}

export default function Toolbar({
  pages,
  form,
  setForm,
  template,
  exit,
  func,
  save,
  currentStep,
  setCurrentStep,
  isLastStep,
  scale,
  setScale,
  attachedFiles,
  setAttachedFiles,
  undo,
  redo,
  send,
  isSenderSignaturesEntered,
  receivers,
  validate,
  saveTemp,
  setContractModeModalOpened,
  isSending,
}: IToolbar) {
  const [user] = useUserState();
  const { contractId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [saveActionModalOpened, setSaveActionModalOpened] = useState(false);
  const [previewActionModalOpened, setPreviewActionModalOpened] =
    useState(false);
  const [sendActionModalOpened, setSendActionModalOpened] = useState(false);
  const {
    isOpen,
    openModal: openAnnualAuthModal,
    closeModal: closeAnnualAuthModal,
  } = useModal();
  const { data: isAuth, refetch: refetchAnnualAuth } = useAnnualAuthQuery();
  const { data: isGaninAvailable } = useContractGanin({
    organizationUuid: user.organization,
    organizationGroupUuid: user.organizationGroup,
  });

  const { data: remainContractsCreditDto } = useContractRemainCreditsQuery();

  const { data: isAboveStandard } = useStandardAvailabilityCheck(
    user?.organization
  );

  const goPrevStep = () => {
    if (currentStep === 4) {
      setCurrentStep && setCurrentStep(3);
      navigate(`/contract/send/${contractId}/editor`, {
        state: {
          again: true,
          pdf: state.pdf,
          contractType: state.contractType,
          contractSendType: state.contractSendType,
          participants: state.receivers,
        },
      });
    } else setCurrentStep && setCurrentStep((prev) => prev - 1 || 1);
  };

  const goNextStep = () => {
    refetchAnnualAuth().then((res) => {
      if (res.status === "success") {
        if (template && validate) {
          validate();
        } else if (isLastStep) {
          if (!isAuth?.annualAuthDto.valid) {
            openAnnualAuthModal();
            return;
          }
          setSendActionModalOpened(true);
        } else if (currentStep === 3) {
          const INPUTS = [];

          for (const page of pages) {
            if (page.fields.length === 0) continue;
            INPUTS.push(...page.fields);
          }

          if (INPUTS.length === 0) {
            toast("하나 이상의 필드를 배치해주세요.");
          } else if (INPUTS.some((field) => field.name === "" || !field.name)) {
            toast("필드 이름을 모두 입력해주세요.");
          } else if (
            new Set(INPUTS.map((field) => field.name)).size !==
            INPUTS.map((field) => field.name).length
          ) {
            toast("각 필드의 이름은 중복될 수 없습니다.");
          } else if (isSenderSignaturesEntered && isSenderSignaturesEntered()) {
            // 페이지 1개인 경우, 토스트 노출
            if (pages?.length <= 1) {
              toast(
                "계약 문서가 1페 페이지인 경우 간인 건수를 이용할 수 없습니다."
              );
            }

            navigate(`/contract/send/${contractId}/config`, {
              state: {
                contractType: state.contractType,
                contractSendType: state?.contractSendType,
                receivers: state.participants,
              },
            });
          } else toast("발신자의 서명 입력란에 서명/도장을 넣어주세요.");
        } else if (currentStep === 4) {
          if (form) {
            if (form.name === "") {
              toast("계약명을 입력해주세요.");
            } else setPreviewActionModalOpened(true);
          } else if (
            (remainContractsCreditDto?.remainContractCredit
              .remainCredits as number) < (receivers || []).length
          )
            toast("전송할 수 있는 잔여 건수가 충분하지 않습니다.");
          else setPreviewActionModalOpened(true);
        } else setCurrentStep && setCurrentStep((prev) => prev + 1);
      }
    });
  };

  const cancel = () => {
    if (isLastStep) {
      if (template) navigate(-1);
      else goPrevStep();
    } else {
      if (template) navigate(-1);
      else navigate("/dashboard");
    }
  };

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      if (e.target.files.length > 1)
        return alert("한 개의 파일만 업로드 가능합니다!");
      else {
        const selectedFile = e.target.files[0];

        if (!selectedFile) return;

        if (!isValidAttachment(selectedFile.type))
          return alert(
            "pdf, jpg, jpeg, png 형식의 파일만 업로드할 수 있습니다."
          );

        if (!isValidFileSize(selectedFile.size))
          return alert("파일 최대 용량은 10MB입니다.");

        if (attachedFiles && attachedFiles.length >= 1) {
          if (!isAboveStandard?.isAvailable) {
            toast("추가 첨부파일은 최대 1개까지 가능합니다");
            return;
          } else {
            if (attachedFiles.length >= 10) {
              toast("추가 첨부파일은 최대 10개까지 가능합니다");
              return;
            }
          }
        }

        const fileUrl = await getContractsAttachmentsPresignedUrl({
          file: selectedFile,
          fileName: selectedFile.name,
        });

        const fileType = selectedFile.type.split("/")[1] as
          | "jpeg"
          | "pdf"
          | "png";

        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = async (e) => {
          setAttachedFiles &&
            setAttachedFiles(
              (prev) =>
                prev
                  .concat({
                    id: uuidv4(),
                    sequence: 0, // 초기화
                    fileName: selectedFile.name,
                    fileUrl: fileUrl.split("?")[0],
                    preview:
                      fileType === "pdf" ? selectedFile : e.target?.result,
                    fileType,
                  })
                  .map((file, idx) => ({ ...file, sequence: idx })) // sequence 정리
            );
        };

        e.target.value = "";
      }
    }
  };

  const closeActionModal = (type: "save" | "preview" | "send") => {
    if (type === "save") setSaveActionModalOpened(false);
    else if (type === "preview") setPreviewActionModalOpened(false);
    else setSendActionModalOpened(false);
  };

  const closeModal = (type: "save" | "preview" | "send") => {
    if (type === "save") {
      saveTemp && saveTemp("TEMP");
    } else if (type === "preview") {
      setPreviewActionModalOpened(false);

      if (isGaninAvailable?.isPremiumPlan) {
        setContractModeModalOpened && setContractModeModalOpened(true);
      } else {
        setForm && setForm((prev) => ({ ...prev, contractClass: "NORMAL" }));
        setCurrentStep && setCurrentStep((prev) => prev + 1);
      }
    } else {
      send && send();
      setSendActionModalOpened(false);
    }
  };

  const validateFields = () => {
    const ALL_FIELDS = [];

    for (const page of pages) {
      for (const field of page.fields) {
        if (field.required && field.type === "sender") ALL_FIELDS.push(field);
      }
    }

    // 라디오 버튼 검증
    const RADIO_GROUPS = ALL_FIELDS.filter(
      (field) => field.fieldType === "CHECKBOX" && field.buttonType === "radio"
    );

    const isRadioValid = !RADIO_GROUPS.some(
      (group) =>
        (group.selectCount?.options?.filter((option) => option.checked)
          ?.length as number) === 0
    );

    // 체크박스 검증
    const CHECKBOX_GROUPS = ALL_FIELDS.filter(
      (field) =>
        field.fieldType === "CHECKBOX" && field.buttonType === "checkbox"
    );

    const isCheckboxValid = !CHECKBOX_GROUPS.some(
      (group) =>
        (group.selectCount?.mode === "MATCH" &&
          (group.selectCount?.options?.filter((option) => option.checked)
            ?.length as number) !== (group.selectCount?.number as number)) ||
        (group.selectCount?.mode === "BELOW" &&
          (group.selectCount?.options?.filter((option) => option.checked)
            ?.length as number) > (group.selectCount?.number as number)) ||
        (group.selectCount?.mode === "ABOVE" &&
          (group.selectCount?.options?.filter((option) => option.checked)
            ?.length as number) < (group.selectCount?.number as number))
    );

    // 기타 필드 검증
    const isOtherFieldsValid = !ALL_FIELDS.filter(
      (field) => field.fieldType !== "CHECKBOX"
    ).some((field) => !field.value || field.value === "");

    return isRadioValid && isCheckboxValid && isOtherFieldsValid;
  };

  return (
    <>
      <ToolbarStyled currentStep={currentStep}>
        <div className="left">
          <Body1Medium onClick={exit ? cancel : goPrevStep}>
            {exit ? "이전" : "나가기"}
          </Body1Medium>
        </div>
        {func && (
          <div className="center">
            <div className="tools">
              <Dropdown
                table
                selected={scale}
                setSelected={setScale}
                dropdownList={SCALES.map((scale) => ({
                  label: `${scale}%`,
                  value: `${scale}%`,
                }))}
                backgroundDimmedWhenOpened
              />
              <div className="functions">
                <label htmlFor="attach" className="function file">
                  <Tooltip
                    align="center"
                    side="bottom"
                    trigger={
                      <>
                        <AttachIcon />
                        <input
                          id="attach"
                          type="file"
                          accept=".png,.jpg,.jpeg,.pdf"
                          onChange={uploadFile}
                        />
                      </>
                    }
                  >
                    <Body3Regular>
                      추가 파일 첨부: jpg, jpeg, pdf, png
                    </Body3Regular>
                  </Tooltip>
                </label>
                <div className="function">
                  <Tooltip
                    align="center"
                    side="bottom"
                    trigger={<PrevIcon onClick={undo} />}
                  >
                    <Body3Regular>되돌리기</Body3Regular>
                  </Tooltip>
                </div>
                <div className="function">
                  <Tooltip
                    align="center"
                    side="bottom"
                    trigger={<NextIcon onClick={redo} />}
                  >
                    <Body3Regular>앞으로 돌리기</Body3Regular>
                  </Tooltip>
                </div>
                <div className="function">
                  <Tooltip
                    align="center"
                    side="bottom"
                    trigger={
                      <QuestionIcon onClick={() => navigate("/notice/guide")} />
                    }
                  >
                    <Body3Regular>클릭하여, 설명 페이지로 이동</Body3Regular>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="right">
          {save && (
            <Button
              colorType="tertiary"
              size="medium"
              handleClick={() => setSaveActionModalOpened(true)}
            >
              <Body2Medium>임시저장</Body2Medium>
            </Button>
          )}
          <Button
            colorType="primary"
            size="medium"
            handleClick={goNextStep}
            disabled={isSending || (isLastStep && !validateFields())}
          >
            <Body2Medium>
              {template
                ? isLastStep
                  ? "전송"
                  : "생성"
                : !isLastStep
                ? "다음"
                : "전송"}
            </Body2Medium>
          </Button>
        </div>
      </ToolbarStyled>
      {saveActionModalOpened && (
        <ActionModal
          title="임시 저장"
          handleModalClose={() => closeActionModal("save")}
          handleCancelButton={() => closeActionModal("save")}
          handleConfirmButton={() => closeModal("save")}
        >
          본 문서를 임시저장하시겠습니까?
        </ActionModal>
      )}
      {previewActionModalOpened && (
        <ActionModal
          title="작성 후 전송 안내"
          handleModalClose={() => closeActionModal("preview")}
          handleConfirmButton={() => closeModal("preview")}
        >
          <PreviewActionModalStyled>
            {PREVIEW_ALERTS.map((alert, idx) => (
              <div key={idx}>
                <Body1Medium>{idx + 1}. </Body1Medium>
                <Body1Medium key={idx} className="list">
                  {alert}
                </Body1Medium>
              </div>
            ))}
          </PreviewActionModalStyled>
        </ActionModal>
      )}
      {sendActionModalOpened && (
        <ActionModal
          title={`${
            state?.receivers?.length || state?.data?.receivers.length
          }건의 ${
            form?.contractClass === "GANIN" ? "간인 " : ""
          }문서를 전송합니다`}
          handleModalClose={() => closeActionModal("send")}
          handleCancelButton={() => closeActionModal("send")}
          handleConfirmButton={() => closeModal("send")}
        >
          <SendActionModalStyled>
            {SEND_ALERTS(form?.contractClass === "GANIN").map((alert, idx) => (
              <Body1Regular key={idx} className="list">
                {alert}
              </Body1Regular>
            ))}
          </SendActionModalStyled>
        </ActionModal>
      )}
      {isOpen && <AnnualAuthModal closeModal={closeAnnualAuthModal} />}
      {currentStep === 5 && (
        <ScaleControllerStyled>
          <Dropdown
            table
            selected={scale}
            setSelected={setScale}
            dropdownList={SCALES.map((scale) => ({
              label: `${scale}%`,
              value: `${scale}%`,
            }))}
            backgroundDimmedWhenOpened
          />
          {form?.contractClass === "GANIN" && (
            <Body2Regular className="paraphDetail">
              마지막 페이지를 제외한 모든 문서 뒷장에 도장 윗부분이 날인된
              상태입니다.
            </Body2Regular>
          )}
        </ScaleControllerStyled>
      )}
    </>
  );
}

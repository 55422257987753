import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import AppHeader from "components/Mobile/AppHeader";
// import { useLocation } from "react-router-dom";
import { RootLayout } from "./RootLayout";
import Footer from "components/Mobile/Footer";
const DefaultLayout = ({ children }: PropsWithChildren) => {
  // const { pathname } = useLocation();

  /*const navigate = useNavigate()

  // 페이지 첫 로딩시 로직
  useEffect(() => {
    // 벨리데이션
    if (!user.name) {
      alert('로그인을 해주세요')
      navigate('/login')
    }
  }, [])*/

  return (
    <RootLayout>
      <AppHeader />
      <MainContent>
        <ContentLayout>{children}</ContentLayout>
      </MainContent>
      <Footer />
    </RootLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  min-height: 100%;
  align-items: stretch;

  flex: 1;
`;

const ContentLayout = styled.div`
  flex-grow: 1;
`;

export default DefaultLayout;

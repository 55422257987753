import Button from "components/Common/Button/Button";
import Input from "components/Common/Input/Input";
import { STAMP_FONT, TEXT_INFO } from "components/Profile/type";
import { Body1Regular } from "components/TextStyle";
import useSignaturesMutation from "hooks/mutations/signature/useSignaturesMutation";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import StampList from "./StampList";
import useStamp from "hooks/useStamp";
import { breakpoints } from "components/styles";

interface FormData {
  stampName: string;
}

interface StampContentProps {
  closeModal: () => void;
  signMode?: boolean;
  sign?: (file: File) => void;
}

const StampContent = ({
  closeModal,
  signMode = false,
  sign,
}: StampContentProps) => {
  const { mutate } = useSignaturesMutation();
  const { selectedStamp, setSelectedStamp, createStamp, isFontLoaded } =
    useStamp({
      initialFont: STAMP_FONT,
    });

  const [stampName, setStampName] = useState("이폼서명");
  const { control, handleSubmit, watch } = useForm<FormData>({
    defaultValues: {
      stampName: "",
    },
  });

  const handleGeneratorSubmit = (data: FormData) => {
    setStampName(data.stampName);
  };

  const handleCreateStamp = async () => {
    const file = await createStamp();
    if (!file) return;

    if (signMode && sign) {
      sign(file);
      closeModal();
    } else
      mutate(file, {
        onSuccess: () => {
          closeModal();
        },
      });
  };

  console.log(isFontLoaded);

  return (
    <>
      <Body1Regular>
        다양한 모양의 도장을 만들고 마음에 드는 도장을 입력해 보세요.
      </Body1Regular>
      <StampGeneratorForm onSubmit={handleSubmit(handleGeneratorSubmit)}>
        <Controller
          name="stampName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              {...field}
              placeholder="이름을 입력하고 만들기 버튼을 눌러주세요"
              onChange={(e) => {
                const noSpaces = e.target.value.replace(/\s/g, "");
                field.onChange(noSpaces); // 공백 제거된 값을 폼에 반영
              }}
              maxLength={10}
            />
          )}
        />
        <Button
          colorType="primary"
          size="large"
          isDisabled={watch("stampName").length <= 0}
        >
          만들기
        </Button>
      </StampGeneratorForm>
      {isFontLoaded && (
        <StampList
          fontList={STAMP_FONT}
          textInfoList={TEXT_INFO}
          stampName={stampName}
          onClick={({ ref, id }) => {
            setSelectedStamp({
              ref,
              id,
            });
          }}
          selectedStamp={selectedStamp}
        />
      )}
      {signMode ? (
        <ModalFooter>
          <Button colorType="tertiary" size="large" onClick={closeModal}>
            취소
          </Button>
          <Button colorType="primary" size="large" onClick={handleCreateStamp}>
            확인
          </Button>
        </ModalFooter>
      ) : (
        <CreateButton
          colorType="primary"
          size="large"
          disabled={!selectedStamp.ref}
          onClick={handleCreateStamp}
        >
          도장 생성하기
        </CreateButton>
      )}
    </>
  );
};

export default StampContent;

export const StampGeneratorForm = styled.form`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  gap: 2rem;

  input {
    width: 37rem;
  }

  @media (max-width: ${breakpoints.md}) {
    gap: 1rem;
    margin-top: 6rem;

    input {
      width: 30rem;
    }
    button {
      width: 10rem;
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    gap: 1rem;
    margin-top: 6rem;

    input {
      width: 25rem;
    }
    button {
      width: 7rem;
    }
  }
`;

const CreateButton = styled(Button)`
  display: block;
  margin: 3.6rem auto 0;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 5.6rem;
  > button {
    width: 24rem;
  }
`;

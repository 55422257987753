import ContractConfigMobileForm from "components/template/Config/(mobile)/ContractConfigMobileForm";
import MailConfigMobileForm from "components/template/Config/(mobile)/MailConfigMobileForm";
import {
  ConfigMobileContent,
  ContentMobileLayout,
} from "components/template/Config/(mobile)/MobileLayout";
import { ContractConfigFormData } from "components/template/Config/ContractConfigForm";
import {
  ConfigNavigation,
  ConfigTitle,
  NextButton,
} from "components/template/Config/Layout";
import { MailConfigFormData } from "components/template/Config/MailConfigForm";
import { SingleReceiverConfigFormData } from "components/template/Config/SingleReceiverConfigForm";
import { addDays } from "date-fns";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as ArrowLeftIcon } from "assets/common/24px/icon_left_default.svg";
import MobileContractModeModal from "components/Contract/(mobile)/MobileContractModeModal";
import MobileParaphStampModal from "components/Contract/(mobile)/MobileParaphStampModal";
import ActionModal from "components/Mobile/Common/Modal/ActionModal";
import useUserState from "hooks/recoil/useUserState";
import useTemplateDetailsById from "hooks/queries/template/useTemplateDetailsById";
import { SubTitle } from "components/TextStyle";

export interface ConfigFormData
  extends SingleReceiverConfigFormData,
    MailConfigFormData,
    ContractConfigFormData {}

export default function TemplateSendConfigMobileLayout({
  children,
}: PropsWithChildren) {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const methods = useForm<ConfigFormData>({
    defaultValues: {
      contractClass: "NORMAL",
      ganinImageUrl: "",
      mail: {
        type: "DEFAULT",
        title: "",
        content: "",
      },
      contractName: "",
      contractType: "SINGLE",
      password: {
        password: "",
        isUsed: false,
      },
      isPhoneNumberAuth: {
        isUsed: false,
      },
      expired: {
        expired: true,
        expiredAt: addDays(new Date(), 7),
      },
      receivers: [],
    },
  });
  const { state } = useLocation();
  const {
    password: { isUsed },
    isPhoneNumberAuth: { isUsed: phoneNumberAuthIsUsed },
  } = methods.watch();
  const [contractModeModalOpened, setContractModeModalOpened] = useState(false);
  const [stampModalOpened, setStampModalOpened] = useState(false);
  const [checkedType, setCheckedType] = useState<
    "normal" | "paraphrase" | null
  >(null);

  const [user] = useUserState();
  const { data: templateDetails } = useTemplateDetailsById({
    organizationUuid: user?.organization,
    templateUuid: templateId,
  });

  const openContractModeModal = () => {
    const { contractName, receivers } = methods.getValues();

    if (!contractName || contractName?.trim() === "") {
      toast("계약명을 입력해주세요.");
      return;
    } else if (
      receivers.some(
        (receiver) =>
          !receiver.name ||
          receiver.name?.trim() === "" ||
          ((!receiver.email || receiver.email?.trim() === "") &&
            (!receiver.phoneNumber || receiver.phoneNumber?.trim() === ""))
      ) ||
      receivers.length === 0
    ) {
      toast("수신자 정보를 입력해주세요.");
      return;
    } else if (
      receivers.some(
        (receiver) =>
          (receiver.phoneNumber?.trim() === "" || !receiver.phoneNumber) &&
          receiver.verification.phoneNumber.isUsed
      )
    ) {
      toast("보안 인증을 위한 수신자의 휴대폰 번호를 입력해주세요.");
      return;
    }
    if (!templateId) return;

    setContractModeModalOpened(true);
  };

  const handleChecked = (type: "normal" | "paraphrase") => {
    setCheckedType(type);
  };

  const handleSubmit = () => {
    if (checkedType === "normal") {
      methods.setValue("contractClass", "NORMAL");
      setContractModeModalOpened(false);
      goToPreview && goToPreview();
    } else if (checkedType === "paraphrase") {
      if (
        state?.contractType === "MULTIPLE" ||
        (methods?.getValues("receivers").length as number) > 1 ||
        (templateDetails?.contractTemplateDetail.pageNum as number) <= 1
      )
        return;
      setContractModeModalOpened(false);
      setStampModalOpened(true);
    }
  };

  const goToPreview = async (ganinImageUrl?: string) => {
    if (ganinImageUrl) {
      methods.setValue("ganinImageUrl", ganinImageUrl);
      methods.setValue("contractClass", "GANIN");
    }

    navigate(`/template/send/${templateId}/preview`, {
      state: {
        data: methods.getValues(),
      },
    });
  };

  useEffect(() => {
    if (isUsed && phoneNumberAuthIsUsed)
      methods.setValue("isPhoneNumberAuth.isUsed", false);

    if (!isUsed) {
      methods.setValue("password.password", "");
    }
  }, [isUsed]);

  useEffect(() => {
    if (isUsed && phoneNumberAuthIsUsed) {
      methods.setValue("password.isUsed", false);
      methods.setValue("password.password", "");
    }
  }, [phoneNumberAuthIsUsed]);

  return (
    <>
      <ConfigNavigation>
        <ArrowLeftIcon onClick={() => navigate("/template/dashboard")} />
        <ConfigTitle>수신자 설정</ConfigTitle>
        <NextButton onClick={openContractModeModal}>다음</NextButton>
      </ConfigNavigation>
      <ContentMobileLayout>
        <ConfigMobileContent>
          <FormProvider {...methods}>
            <ContractConfigMobileForm
              receiverCount={methods.getValues("receivers").length}
            />
            {children}
            <MailConfigMobileForm />
          </FormProvider>
        </ConfigMobileContent>
      </ContentMobileLayout>
      {contractModeModalOpened && (
        <ActionModal
          title={<SubTitle>계약 형식 선택</SubTitle>}
          handleConfirmButton={handleSubmit}
          handleCancelButton={() => {
            setContractModeModalOpened(false);
            setCheckedType(null);
          }}
          handleModalClose={() => {
            setContractModeModalOpened(false);
            setCheckedType(null);
          }}
          wideBtn
        >
          <MobileContractModeModal
            methods={methods}
            checkedType={checkedType}
            handleChecked={handleChecked}
          />
        </ActionModal>
      )}
      {stampModalOpened && (
        <MobileParaphStampModal
          setContractModeModalOpened={setContractModeModalOpened}
          setStampModalOpened={setStampModalOpened}
          goToPreview={goToPreview}
        />
      )}
    </>
  );
}

import React from "react";
import { OptionProps } from "interfaces/contract";
import { Body3Regular } from "components/TextStyle";
import Dropdown from "components/Common/Dropdown/Dropdown";
import { FONT_SIZES } from "config/constant";
import Tooltip from "components/Common/Tooltip";
import { ReactComponent as InfoIcon } from "assets/common/20px/icon_information_small.svg";
import { Label } from "../CreateContractRight";

export default function FontSizeOption({
  fields,
  side,
  setPages,
  selectedPageId,
  selectedFieldId,
}: OptionProps) {
  return side ? (
    <>
      <Label>
        글자 크기
        <Tooltip side="top" trigger={<InfoIcon />}>
          <Body3Regular>
            필드 배경에 작성한 문구를 표시합니다.
            <br />
            글자 크기가 28 이상이면 필드 설명은 표시되지 않습니다.
          </Body3Regular>
        </Tooltip>
      </Label>
      <Dropdown
        table
        dropdownList={FONT_SIZES.map((item) => ({
          value: item,
          label: `${item}pt`,
        }))}
        selected={
          fields?.find(
            (field) => `${field.fieldType}:${field.id}` === selectedFieldId
          )?.size || "14"
        }
        setSelected={(size: string) => {
          setPages((pages) =>
            pages.map((page) => {
              if (page.id === selectedPageId) {
                return {
                  ...page,
                  fields: page?.fields?.map((field) => {
                    if (`${field.fieldType}:${field.id}` === selectedFieldId)
                      return { ...field, size };
                    else return { ...field };
                  }),
                };
              } else return { ...page };
            })
          );
        }}
      />
    </>
  ) : (
    <>
      <Body3Regular>글자 크기</Body3Regular>
      <div className="dropdown">
        <Dropdown
          table
          dropdownList={FONT_SIZES.map((item) => ({
            value: item,
            label: `${item}pt`,
          }))}
          selected={
            fields?.find(
              (field) => `${field.fieldType}:${field.id}` === selectedFieldId
            )?.size || "14"
          }
          setSelected={(size: string) => {
            setPages((pages) =>
              pages.map((page) => {
                if (page.id === selectedPageId) {
                  return {
                    ...page,
                    fields: page?.fields?.map((field) => {
                      if (`${field.fieldType}:${field.id}` === selectedFieldId)
                        return { ...field, size };
                      else return { ...field };
                    }),
                  };
                } else return { ...page };
              })
            );
          }}
        />
      </div>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DraggableStyled } from "./styles";
import { Body3Regular } from "components/TextStyle";
import { ReactComponent as DeleteIcon } from "assets/common/24px/icon_close_default.svg";
import { ReactComponent as PencilIcon } from "assets/common/20px/icon_pencil.svg";
import { IField, IPage } from "interfaces/contract";
import { Field } from "pages/contract/send";
import RequiredOption from "components/Contract/Options/Required";
import CreateSignModal from "pages/profile/sign/CreateSignModal";
import {
  getSignAssetsPresignedUrl,
  getSignDirectUrlsAssetsPresignedUrl,
} from "apis/sign";
import { useLocation } from "react-router-dom";
import Resizable from "components/Resizable";
import CreateSignBottomSheet from "pages/profile/sign/(mobile)/CreateSignBottomSheet";

interface SignatureProps extends React.HTMLAttributes<HTMLDivElement> {
  template?: boolean;
  edit?: boolean;
  complete?: boolean;
  id: string;
  type?: "sender" | "receiver";
  fields: IField[];
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  selectedPageId: string;
  selectedFieldId?: string;
  setSelectedFieldId?: React.Dispatch<React.SetStateAction<string>>;
  onDeleteHandler: (id: string) => void;
  selectField?: (id: string, type: Field) => void;
  scale: number;
  setSignatureSelectionModal?: React.Dispatch<React.SetStateAction<boolean>>;
  templateSend?: boolean;
  isMobile?: boolean;
  currentSize: { w: number; h: number };
  handleMouseDown: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>,
    direction: string
  ) => void;
  handleMouseMove: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>
  ) => void;
  handleMouseUp: () => void;
  newField?: boolean;
}

export default function Signature({
  template,
  edit = false,
  complete = false,
  id,
  type,
  fields,
  pages,
  setPages,
  selectedPageId,
  selectedFieldId,
  setSelectedFieldId,
  onDeleteHandler,
  selectField,
  scale,
  setSignatureSelectionModal,
  draggable,
  templateSend,
  isMobile,
  currentSize,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  newField,
  ...args
}: SignatureProps) {
  const { state } = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const editOptionRef = useRef<HTMLDivElement>(null);

  const closeModal = () => setIsOpen(false);

  const sign = async (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      let fileUrl = "";

      if (state?.directUrl && state?.directUrlCode) {
        fileUrl = await getSignDirectUrlsAssetsPresignedUrl({
          file,
          fileName: file.name,
        });
      } else {
        fileUrl = await getSignAssetsPresignedUrl({
          file,
          fileName: file.name,
        });
      }

      if (fileUrl && e.target && e.target.result) {
        setPages((prev) =>
          prev.map((page) => {
            if (isMobile) {
              if (page.order.toString() === selectedPageId) {
                return {
                  ...page,
                  fields: page.fields.map((field) => {
                    if (`${field.fieldType}:${field.id}` === id) {
                      return {
                        ...field,
                        value: fileUrl,
                        signPreview: e.target?.result,
                      };
                    } else return { ...field };
                  }),
                };
              } else return { ...page };
            } else {
              if (page.id === selectedPageId) {
                return {
                  ...page,
                  fields: page.fields.map((field) => {
                    if (`${field.fieldType}:${field.id}` === id) {
                      return {
                        ...field,
                        value: fileUrl,
                        signPreview: e.target?.result,
                      };
                    } else return { ...field };
                  }),
                };
              } else return { ...page };
            }
          })
        );
        setIsOpen(false);
      }
    };
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!isOpen && !editOptionRef.current?.contains(e.target as Node))
        setIsOpen(false);
    };

    if (!isOpen) return;

    window.addEventListener("click", handleClick);

    return () => window.removeEventListener("click", handleClick);
  }, [isOpen]);

  return (
    <>
      <SignatureStyled
        id={id}
        type={type}
        color={
          type === "sender"
            ? "#666F7B"
            : (fields.find((field) => `${field.fieldType}:${field.id}` === id)
                ?.writer?.color as string)
        }
        required={
          !!fields.find((field) => `${field.fieldType}:${field.id}` === id)
            ?.required
        }
        scale={scale}
        draggable={draggable}
        complete={complete}
        newField={newField}
        isMobile={isMobile}
        {...args}
      >
        <div className="wrapper">
          <div className="field-container">
            <div
              className="container signature"
              onClick={(e) => {
                e.stopPropagation();
                if (type === "receiver" && !templateSend && !complete && edit)
                  setIsOpen(true);
                selectField && selectField(id, id.split(":")[0] as Field);
              }}
              style={{
                width: `${(currentSize.w / 10) * scale}rem`,
                height: `${(currentSize.h / 10) * scale}rem`,
              }}
            >
              {edit ? (
                <div className="edit">
                  {fields.find(
                    (field) => `${field.fieldType}:${field.id}` === id
                  )?.value ||
                  fields.find(
                    (field) => `${field.fieldType}:${field.id}` === id
                  )?.signPreview ? (
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url(${
                          fields.find(
                            (field) => `${field.fieldType}:${field.id}` === id
                          )?.signPreview ||
                          fields.find(
                            (field) => `${field.fieldType}:${field.id}` === id
                          )?.value
                        })`,
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  ) : complete ? (
                    ""
                  ) : (
                    fields.find(
                      (field) => `${field.fieldType}:${field.id}` === id
                    )?.description || ""
                  )}
                </div>
              ) : fields.find(
                  (field) => `${field.fieldType}:${field.id}` === id
                )?.value ? (
                <div
                  className="selectedSignature"
                  style={{
                    backgroundImage: `url(${
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.value
                    })`,
                  }}
                />
              ) : (
                <>
                  <PencilIcon />
                  {isEdit ? (
                    <textarea
                      value={
                        fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.name
                      }
                      style={{
                        fontSize: `${
                          +(fields.find(
                            (field) => `${field.fieldType}:${field.id}` === id
                          )?.size as string) / 10
                        }rem`,
                        lineHeight: 1.4285714286,
                        color: "var(--text-secondary)",
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setIsEdit(false);
                        }
                      }}
                      onChange={(e) => {
                        setPages((prev) =>
                          prev.map((page) => {
                            if (
                              page.fields
                                .map(
                                  (field) => `${field.fieldType}:${field.id}`
                                )
                                .includes(id)
                            ) {
                              return {
                                ...page,
                                fields: page.fields.map((field) => {
                                  if (`${field.fieldType}:${field.id}` === id) {
                                    return { ...field, name: e.target.value };
                                  } else return { ...field };
                                }),
                              };
                            } else return { ...page };
                          })
                        );
                      }}
                    />
                  ) : (
                    <Body3Regular
                      style={{
                        fontSize: `${
                          +(fields.find(
                            (field) => `${field.fieldType}:${field.id}` === id
                          )?.size as string) / 10
                        }rem`,
                        lineHeight: 1.4285714286,
                      }}
                      onDoubleClick={() => setIsEdit((prev) => !prev)}
                    >
                      {
                        fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.name
                      }
                    </Body3Regular>
                  )}
                </>
              )}
            </div>
            {draggable && draggable && id === selectedFieldId && (
              <Resizable
                pages={pages}
                setPages={setPages}
                selectedFieldId={selectedFieldId}
                selectedPageId={selectedPageId}
                handleMouseUp={handleMouseUp}
                handleMouseDown={handleMouseDown}
                handleMouseMove={handleMouseMove}
                color={
                  type === "sender"
                    ? "#666F7B"
                    : !!fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.required
                    ? "red"
                    : "var(--blue-500)"
                }
                scale={scale}
              />
            )}
          </div>
          {draggable && id === selectedFieldId && (
            <DeleteIcon onClick={() => onDeleteHandler(id)} />
          )}
        </div>
        {/* 선택했을 때 => 기본값 */}
        {/* 계약서 작성 - 필드 배치할 때 */}
        {/* 템플릿 작성 - 필드 배치할 때 + 수신자인 경우만 */}
        {/* 템플릿 전송 - 미리보기에서 + 발신자인 경우만 */}
        {selectedFieldId === id &&
          (draggable ||
            (draggable && template && type === "receiver") ||
            (templateSend && type === "sender")) && (
            <div
              className={`options${
                type === "receiver" ? " receiver" : " sender"
              }`}
              style={{
                width: isMobile || type === "receiver" ? "20rem" : "32.4rem",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {type === "sender" && (
                <div className="option">
                  <button
                    type="button"
                    className="select"
                    onClick={
                      setSignatureSelectionModal
                        ? () => setSignatureSelectionModal(true)
                        : undefined
                    }
                  >
                    발신자 서명 선택하기
                  </button>
                  <button
                    className="close-button"
                    onClick={() => {
                      setIsOpen(false);
                      setSelectedFieldId && setSelectedFieldId("");
                    }}
                  >
                    닫기
                  </button>
                </div>
              )}
              {!templateSend && type === "receiver" && (
                <div className="option">
                  <RequiredOption
                    fields={fields}
                    setPages={setPages}
                    selectedPageId={selectedPageId}
                    selectedFieldId={selectedFieldId}
                  />
                </div>
              )}
            </div>
          )}
      </SignatureStyled>
      {isMobile ? (
        <CreateSignBottomSheet
          isOpen={isOpen}
          closeBottomSheet={closeModal}
          sign={sign}
        />
      ) : (
        <CreateSignModal
          isOpen={isOpen}
          closeModal={closeModal}
          signMode
          sign={sign}
        />
      )}
    </>
  );
}

const SignatureStyled = styled(DraggableStyled)`
  .wrapper {
    .container {
      padding: ${({ scale, isMobile }) =>
        isMobile ? "0" : scale ? `${scale * 1}rem` : "1rem"};
      box-sizing: border-box;
      display: flex;
      column-gap: 0.4rem;
      border-radius: ${({ scale }) => (scale ? `${scale * 0.4}rem` : "0.4rem")};
      color: var(--text-secondary);

      .selectedSignature {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .edit {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${({ scale, fontSize }) =>
          scale
            ? `${scale * (fontSize ? +fontSize / 10 : 1.6)}rem`
            : `${fontSize ? +fontSize / 10 : 1.6}rem`};

        & > div.img {
          display: block;
          margin: 0 auto;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .sender {
    border-radius: 1.487rem;

    button {
      width: 100%;
      padding: 0.8rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.19rem;
      font-weight: 600;
      line-height: 1.78rem;
      letter-spacing: -0.03em;
      color: var(--text-default);
      border: 0.1rem solid var(--text-default);
      border-radius: 0.372rem;
    }

    .close-button {
      width: fit-content;
      white-space: nowrap;
      padding: 0.8rem 1.2rem;
    }
  }

  .receiver {
    width: 20rem;
    display: flex;
    align-items: center;
  }

  .receiver.edit {
    width: 34.2rem;
  }
`;

import TextArea from "components/Common/Input/TextArea";
import Tooltip from "components/Common/Tooltip/Tooltip";
import {
  Body1SemiBold,
  Body2Medium,
  Body3Regular,
  SubTitle,
} from "components/TextStyle";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ReactComponent as InformationIcon } from "assets/common/24px/icon_information_default.svg";
import styled from "styled-components";
import { MAIL_TEMPLATE_OPTIONS } from "config/constant";
import useMailTemplateAllQuery from "hooks/queries/mailTemplate/useMailTemplateAllQuery";
import useUserState from "hooks/recoil/useUserState";
import useMailTemplateDetailQuery from "hooks/queries/mailTemplate/useMailTemplateDetailQuery";
import useBottomSheet from "hooks/Mobile/useBottomSheet";
import BottomSheet from "components/Mobile/Common/BottomSheet/BottomSheet";
import { ReactComponent as CloseIcon } from "assets/common/24px/icon_close_default.svg";
import { ReactComponent as DownIcon } from "assets/common/24px/icon_down_default.svg";

export interface MailConfigMobileFormData {
  mail: {
    type: "DEFAULT" | string;
    title: string;
    content: string;
  };
}

const MailConfigMobileForm = () => {
  const [user] = useUserState();
  const { register, watch, setValue } =
    useFormContext<MailConfigMobileFormData>();
  const { isOpen, openBottomSheet, closeBottomSheet } = useBottomSheet();
  const [selectedTemplate, setSelectedTemplate] = React.useState<string | null>(
    null
  );
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const { data: mailTemplateList } = useMailTemplateAllQuery(
    user?.organization
  );

  const { data: mailTemplateDetail } = useMailTemplateDetailQuery(
    watch("mail.type").includes("-") ? watch("mail.type") : ""
  );

  useEffect(() => {
    setValue("mail.title", mailTemplateDetail?.title || "");
    setValue("mail.content", mailTemplateDetail?.content || "");
  }, [selectedTemplate]);

  const handleTemplateSelect = (template: { value: string; label: string }) => {
    setValue("mail.type", template.value);
    setSelectedTemplate(template.label);
    closeBottomSheet();
  };

  const onClickTooltip = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  return (
    <MailConfigLayout>
      <div className="mail-config-title">
        <Body1SemiBold
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.4rem",
          }}
        >
          메일 설정
          <div
            onClick={onClickTooltip}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Tooltip
              isOpen={isTooltipOpen}
              description={
                <>
                  작성 요청 이메일의 제목과 문구를 입력해 주세요. <br />
                  입력하지 않을 경우 기본 내용으로 보내집니다.
                </>
              }
              side="bottom"
            >
              <InformationIcon />
            </Tooltip>
          </div>
        </Body1SemiBold>

        <TemplateSelectContainer onClick={openBottomSheet}>
          <Body2Medium>{selectedTemplate || "템플릿 선택"}</Body2Medium>
          <DownIcon />
        </TemplateSelectContainer>
      </div>
      <ConfigContentDescription>
        카카오톡(문자) 내용은 기본 문구로 전송됩니다.
      </ConfigContentDescription>
      <BottomSheet
        handleBottomSheetClose={closeBottomSheet}
        isOpen={isOpen}
        detent="content-height"
      >
        <BottomSheetContainer>
          <BottomSheetHeader>
            <SubTitle>템플릿 선택</SubTitle>

            <CloseIcon onClick={closeBottomSheet} />
          </BottomSheetHeader>
          <BottomSheetList>
            {MAIL_TEMPLATE_OPTIONS.concat(
              ...(mailTemplateList || []).map((template) => ({
                value: template.uuid,
                label: template.name,
              }))
            ).map((template) => (
              <Body2Medium
                key={template.value}
                onClick={() => handleTemplateSelect(template)}
              >
                {template.label}
              </Body2Medium>
            ))}
          </BottomSheetList>
        </BottomSheetContainer>
      </BottomSheet>
      <div className="mail-text-area-layout">
        <TextArea
          placeholder="메일 제목을 작성해주세요"
          maxLength={100}
          rows={1}
          value={watch("mail.title") || ""}
          {...register("mail.title")}
        />
        <TextArea
          maxLength={1000}
          rows={8}
          placeholder="메일 내용을 작성해주세요"
          value={watch("mail.content") || ""}
          {...register("mail.content")}
        />
      </div>
    </MailConfigLayout>
  );
};

export default MailConfigMobileForm;

const MailConfigLayout = styled.div`
  .mail-config-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.8rem;
  }

  .mail-text-area-layout {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  margin-bottom: 3rem;
`;

const ConfigContentDescription = styled(Body3Regular)`
  margin-bottom: 2rem;
  color: var(--text-secondary);
`;

const TemplateSelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

const BottomSheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 2.7rem 1.6rem;
`;

const BottomSheetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BottomSheetList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-top: 2.7rem;
`;

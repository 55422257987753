import React from "react";

import { Sheet } from "react-modal-sheet";

interface BottomSheetProps {
  children: React.ReactNode;
  isOpen: boolean;
  detent: "content-height" | "full-height";
  handleBottomSheetClose: () => void;
}

function BottomSheet({
  children,
  isOpen,
  detent,
  handleBottomSheetClose,
}: BottomSheetProps) {
  return (
    <>
      <Sheet isOpen={isOpen} onClose={handleBottomSheetClose} detent={detent}>
        <Sheet.Container>
          <Sheet.Content>{children}</Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}

export default BottomSheet;

/*
사용법

import BottomSheet from "components/Mobile/Common/BottomSheet/BottomSheet";
import useBottomSheet from "hooks/Mobile/useBottomSheet";

const Component = () => {
  const { openBottomSheet, closeBottomSheet, isOpen: isBottomSheetOpen } =
    useBottomSheet();

  return (
    <>
      {isBottomSheetOpen && (
        <BottomSheet
          isOpen={isBottomSheetOpen}
          handleBottomSheetClose={closeBottomSheet}
        >
          <div>BottomSheet</div>
        </BottomSheet>
      )}

      <Button onClick={openBottomSheet}>Open BottomSheet</Button>
    </>
  );
};

*/

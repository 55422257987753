import React from "react";
import DefaultModal from "components/Mobile/Common/Modal/DefaultModal";
import StampContent from "./(textStamp)/StampContent";

interface StampModalProps {
  closeModal: () => void;
  isOpen: boolean;
  sign?: (file: File) => void;
}

const StampModal = ({ closeModal, isOpen, sign }: StampModalProps) => {
  if (!isOpen) return null;

  return (
    <DefaultModal
      handleModalClose={closeModal}
      title="도장"
      subTitle="다양한 모양의 도장을 만들고 마음에 드는 도장을 입력해 보세요."
    >
      <StampContent closeModal={closeModal} sign={sign} />
    </DefaultModal>
  );
};

export default StampModal;

const copyToClipboard = (
  text: string,
  option?: {
    onSuccess?: () => void;
  }
) => {
  // 최신 브라우저 지원
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("텍스트가 클립보드에 복사되었습니다.");
        option?.onSuccess && option.onSuccess();
        return true;
      })
      .catch(() => {
        console.error("클립보드 복사 실패");
        return false;
      });
  } else {
    // 이전 브라우저 지원
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      document.body.removeChild(textArea);
      if (successful) {
        option?.onSuccess && option.onSuccess();
        return true;
      } else {
        console.error("클립보드 복사 실패");
        return false;
      }
    } catch (err) {
      console.error("클립보드 복사 중 오류 발생:", err);
      document.body.removeChild(textArea);
      return false;
    }
  }
  return false;
};

export default copyToClipboard;

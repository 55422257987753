import React, { useEffect, useRef, useState } from "react";
import {
  DropdownStyled,
  Fields,
  GaninLoadingStyled,
  Main,
  PreviewStyled,
  SendStyled,
  Top,
} from "./styles";
import {
  Body1Regular,
  Body1SemiBold,
  Body2Medium,
  Body2Regular,
  Body3Regular,
  Title1,
} from "components/TextStyle";
import Toolbar from "components/Toolbar";
import { ReactComponent as InfoIcon } from "assets/common/20px/icon_information_small.svg";
import { ReactComponent as DownIcon } from "assets/common/20px/icon_down_default.svg";
import { ReactComponent as SignatureIcon } from "assets/common/20px/icon_pencil.svg";
import { ReactComponent as TextIcon } from "assets/common/20px/icon_square_2.svg";
import { ReactComponent as LinesIcon } from "assets/common/20px/icon_square_hangul.svg";
import { ReactComponent as NumberIcon } from "assets/common/20px/icon_square.svg";
import { ReactComponent as CalendarIcon } from "assets/common/20px/icon_calendar.svg";
import { ReactComponent as LocationIcon } from "assets/common/20px/icon_location.svg";
import { ReactComponent as ImageIcon } from "assets/common/20px/icon_image.svg";
import { ReactComponent as DropdownIcon } from "assets/common/20px/icon_list.svg";
import { ReactComponent as CheckBoxIcon } from "assets/common/20px/icon_checked.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CreateContractRight from "components/Contract/CreateContractRight";
import { IAttachedFile, IPage, IParticipant } from "interfaces/contract";
import { Document, Page } from "react-pdf";
import Step from "components/Common/Step/Step";
import { v4 as uuidv4 } from "uuid";
import Item from "components/Draggable/Item";
import {
  extractNumbersFromStrings,
  getDefaultSizeOfFields,
} from "utils/utility";
import ModalLayout from "components/Common/Modal/ModalLayout";
import DropdownEditModal from "components/Contract/DropdownEditModal";
import { MAIL_TEMPLATE_OPTIONS, PALETTE } from "config/constant";
import useUserState from "hooks/recoil/useUserState";
import {
  AttachmentFile,
  ContractSendType,
  ContractType,
  Input,
  TextAlign,
} from "models/Contract";
import usePostSendContractMutation from "hooks/mutations/contract/usePostSendContractMutation";
import { getDateTime } from "utils/getDateTime";
import { toast } from "react-toastify";
import SenderSignatureModal from "components/Contract/SenderSignatureModal";
import Tooltip from "components/Common/Tooltip";
import { addDays } from "date-fns";
import ContentModal from "components/Common/Modal/ContentModal";
import gifParaphAnimation from "assets/Contract/paraph_animation.gif";
import ParaphStampModal from "components/Contract/ParaphStampModal";
import axios from "axios";
import ContractModeModal from "components/Contract/ContractModeModal";
import usePostSendSingleMultipleContractMutation from "hooks/mutations/contract/usePostSendSingleMultipleContractMutation";
import { getJWTToken } from "utils/saveJwtToken";
import { getAllFields, getFieldNameInKor } from "utils/getAllFields";
import CustomTooltip from "components/Common/Tooltip/Custom";
import balloonSign from "assets/Contract/balloons/balloon-sign.png";
import balloonText from "assets/Contract/balloons/balloon-text.png";
import balloonLongText from "assets/Contract/balloons/balloon-long-text.png";
import balloonNumber from "assets/Contract/balloons/balloon-number.png";
import balloonDate from "assets/Contract/balloons/balloon-date.png";
import balloonAddress from "assets/Contract/balloons/balloon-address.png";
import balloonImage from "assets/Contract/balloons/balloon-image.png";
import balloonDropdown from "assets/Contract/balloons/balloon-dropdown.png";
import balloonCheckBox from "assets/Contract/balloons/balloon-check-box.png";
import { PageContainer } from "./detail/editor";
import { LoadingStyled } from "./upload/styles";
import Loading from "components/Common/Loading";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";

const Upload = React.lazy(() => import("./upload"));
const Editor = React.lazy(() => import("./detail/editor"));
const Config = React.lazy(() => import("./detail/config"));

export interface History {
  pages: IPage[];
  selectedFieldId: string;
  selectedFieldType: Field;
  isCurrent: boolean;
}

export type Field =
  | "SIGN"
  | "TEXT"
  | "LONGTEXT"
  | "NUMBER"
  | "DATE"
  | "ADDRESS"
  | "IMAGE"
  | "DROPDOWN"
  | "CHECKBOX"
  | "RADIO_BTN"
  | null;

export interface IConfigForm {
  contractClass: "NORMAL" | "DIRECT_URL" | "GANIN";
  ganinImageUrl?: string;
  name: string;
  expiry: Date;
  mail: {
    template: { value: string; label: string };
    title: string;
    contents: string;
  };
}

const STEPS = [
  { step: 1, label: "파일 업로드" },
  { step: 2, label: "수신자 설정" },
  { step: 3, label: "필드 배치 및 설정" },
  { step: 4, label: "발송 준비" },
  { step: 5, label: "미리보기" },
];

export const FIELDS: {
  id: number;
  label: string;
  value: Field;
  icon: React.ReactNode;
  balloon: string;
}[] = [
  {
    id: 0,
    label: "서명",
    value: "SIGN",
    icon: <SignatureIcon />,
    balloon: balloonSign,
  },
  {
    id: 1,
    label: "텍스트",
    value: "TEXT",
    icon: <TextIcon />,
    balloon: balloonText,
  },
  {
    id: 2,
    label: "장문텍스트",
    value: "LONGTEXT",
    icon: <LinesIcon />,
    balloon: balloonLongText,
  },
  {
    id: 3,
    label: "숫자",
    value: "NUMBER",
    icon: <NumberIcon />,
    balloon: balloonNumber,
  },
  {
    id: 4,
    label: "날짜",
    value: "DATE",
    icon: <CalendarIcon />,
    balloon: balloonDate,
  },
  {
    id: 5,
    label: "주소",
    value: "ADDRESS",
    icon: <LocationIcon />,
    balloon: balloonAddress,
  },
  {
    id: 6,
    label: "이미지",
    value: "IMAGE",
    icon: <ImageIcon />,
    balloon: balloonImage,
  },
  {
    id: 7,
    label: "드롭다운 리스트",
    value: "DROPDOWN",
    icon: <DropdownIcon />,
    balloon: balloonDropdown,
  },
  {
    id: 8,
    label: "체크 박스",
    value: "CHECKBOX",
    icon: <CheckBoxIcon />,
    balloon: balloonCheckBox,
  },
];

export const ParticipantsDropdown = ({
  dropdownOpened,
  setDropdownOpened,
  selectedOption,
  options,
  selectOption,
}: {
  dropdownOpened: boolean;
  setDropdownOpened: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOption: IParticipant;
  options: IParticipant[];
  selectOption: (option: IParticipant) => void;
}) => {
  return (
    <DropdownStyled>
      <div
        className="selected"
        onClick={() => setDropdownOpened((prev) => !prev)}
      >
        <div>
          <span
            className="indicator"
            style={{ backgroundColor: selectedOption?.color }}
          />
          <Body2Medium>
            {selectedOption?.name || selectedOption?.label}
          </Body2Medium>
        </div>
        <DownIcon />
      </div>
      {dropdownOpened && (
        <div className="options" onClick={(e) => e.stopPropagation()}>
          {options.map((option) => (
            <div
              className="option"
              key={option.id}
              onClick={() => selectOption(option)}
            >
              <span
                className="indicator"
                style={{ backgroundColor: option.color }}
              />
              <Body2Medium>{option.name || option.label}</Body2Medium>
            </div>
          ))}
        </div>
      )}
    </DropdownStyled>
  );
};

export default function Send() {
  const navigate = useNavigate();
  const { contractId } = useParams();
  const { mutate: sendContract } = usePostSendContractMutation();
  const { mutate: sendSingleMultipleContract } =
    usePostSendSingleMultipleContractMutation();
  const { pathname, state } = useLocation();
  const [currentStep, setCurrentStep] = useState(1);
  const [isLastStep, setIsLastStep] = useState(false);
  const [pdf, setPdf] = useState<File | string>(state?.pdf);
  const pagesContainerRef = useRef<null[] | HTMLDivElement[]>([]);
  const [scale, setScale] = useState("100%");
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [participants, setParticipants] = useState<IParticipant[]>([
    {
      id: uuidv4(),
      label: "발신자",
      type: "sender",
      color: "#666F7B",
    },
    {
      id: uuidv4(),
      label: "수신자",
      type: "receiver",
      name: "",
      email: "",
      phoneNumber: "",
      dropdown: false,
      verification: {
        type: null,
        password: "",
      },
    },
  ]);
  const [selectedParticipant, setSelectedParticipant] = useState<IParticipant>(
    participants[1]
  );
  const [selectedFieldType, setSelectedFieldType] = useState<Field>(null);
  const [selectedFieldId, setSelectedFieldId] = useState("");
  const [selectedPageId, setSelectedPageId] = useState<string>("");
  const [pages, setPages] = useState<IPage[]>([]);
  const [attachedFiles, setAttachedFiles] = useState<IAttachedFile[]>([]);
  const [newFieldId, setNewFieldId] = useState(uuidv4());
  const [signatureSelectionModal, setSignatureSelectionModal] = useState(false);
  const [dropdownEditModalOpened, setDropdownEditModalOpened] = useState(false);
  const [lastFieldSize, setLastFieldSize] = useState<{
    [key: string]: number[];
  }>({
    SIGN: getDefaultSizeOfFields("SIGN"),
    TEXT: getDefaultSizeOfFields("TEXT"),
    LONGTEXT: getDefaultSizeOfFields("LONGTEXT"),
    NUMBER: getDefaultSizeOfFields("NUMBER"),
    DATE: getDefaultSizeOfFields("DATE"),
    ADDRESS: getDefaultSizeOfFields("ADDRESS"),
    IMAGE: getDefaultSizeOfFields("IMAGE"),
    DROPDOWN: getDefaultSizeOfFields("DROPDOWN"),
    CHECKBOX: getDefaultSizeOfFields("CHECKBOX"),
  });

  const [isSending, setIsSending] = useState(false);

  // 조직 잔여 건수 조회 API
  const { refetch } = useContractRemainCreditsQuery();

  // 드래그 관련
  const [start, setStart] = useState({ x: 0, y: 0 });
  const [draggingFieldId, setDraggingFieldId] = useState<string>("");
  const [draggingStartedPosition, setDraggingStartedPosition] = useState({
    x: 0,
    y: 0,
  });

  // 간인
  const [isInProgress, setIsInProgres] = useState(false);
  const [contractModeModalOpened, setContractModeModalOpened] = useState(false);
  const [stampModalOpened, setStampModalOpened] = useState(false);

  // config: 설정 (step === 5)
  const [form, setForm] = useState<IConfigForm>({
    contractClass: "NORMAL",
    name: "",
    expiry: addDays(new Date(), 7),
    mail: {
      template: MAIL_TEMPLATE_OPTIONS[0],
      title: "",
      contents: "",
    },
  });

  const [{ organization }] = useUserState();

  const scrollToPage = (pageId: string) => {
    document.getElementById(pageId)?.scrollIntoView({ behavior: "smooth" });
  };

  const selectParticipant = (participant: IParticipant) => {
    setSelectedParticipant(participant);
    setDropdownOpened(false);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const { target, clientX, clientY, currentTarget } = e;

    if (target) (target as HTMLDivElement).classList.add("dragging");

    setDraggingFieldId(currentTarget.id);
    setSelectedFieldId(currentTarget.id);

    const [, id] = currentTarget.id.split(":");
    const currentPage = pages.find((page) => page.id === selectedPageId);

    if (
      (currentPage?.fields.filter((field) => field.id === id)
        .length as number) <= 0
    ) {
      setDraggingStartedPosition({
        x: clientX - currentTarget.getBoundingClientRect().left,
        y: clientY - currentTarget.getBoundingClientRect().top,
      });
    } else {
      setStart(() => ({ x: clientX, y: clientY }));
    }
  };

  const handleDragEnd = (
    e: React.DragEvent<HTMLDivElement>,
    optionId?: string
  ) => {
    const { target, clientX, clientY, currentTarget } = e;

    if (target) (target as HTMLDivElement).classList.remove("dragging");

    const [fieldType, id] = currentTarget.id.split(":");
    const currentPage = pages.find((page) => page.id === selectedPageId);

    if (!currentPage) return;

    const currentPageId = currentPage.id;

    if (
      (currentPage?.fields.filter((field) => field.id === id)
        .length as number) > 0
    ) {
      if (fieldType === "CHECKBOX") {
        setPages((prev) => {
          const NEW_PAGES = prev.map((page) => {
            if (page.id === currentPageId) {
              return {
                ...page,
                fields: page.fields?.map((field) => {
                  if (field.id === id) {
                    return {
                      ...field,
                      selectCount: {
                        ...field.selectCount,
                        options: field.selectCount?.options?.map((option) => {
                          if (option.id === optionId) {
                            return {
                              ...option,
                              position: {
                                ...option.position,
                                x:
                                  (option.position.x *
                                    (+scale.replace("%", "") / 100) +
                                    (clientX - start.x)) /
                                  (+scale.replace("%", "") / 100),
                                y:
                                  (option.position.y *
                                    (+scale.replace("%", "") / 100) +
                                    (clientY - start.y)) /
                                  (+scale.replace("%", "") / 100),
                              },
                            };
                          } else return { ...option };
                        }),
                      },
                    };
                  } else return { ...field };
                }),
              };
            } else return { ...page };
          });

          saveToHistory({
            pages: NEW_PAGES,
            selectedFieldId: `${fieldType as Field}:${
              (target as HTMLDivElement).id
            }`,
            selectedFieldType: fieldType as Field,
            isCurrent: true,
          });

          return NEW_PAGES;
        });
      } else {
        setPages((prev) => {
          const NEW_PAGES = prev.map((page) => {
            if (page.id === currentPageId) {
              return {
                ...page,
                fields: page.fields?.map((field) => {
                  if (field.id === id) {
                    return {
                      ...field,
                      position: {
                        ...field.position,
                        x:
                          (field.position.x * (+scale.replace("%", "") / 100) +
                            (clientX - start.x)) /
                          (+scale.replace("%", "") / 100),
                        y:
                          (field.position.y * (+scale.replace("%", "") / 100) +
                            (clientY - start.y)) /
                          (+scale.replace("%", "") / 100),
                      },
                    };
                  } else return { ...field };
                }),
              };
            } else return { ...page };
          });

          saveToHistory({
            pages: NEW_PAGES,
            selectedFieldId: `${fieldType as Field}:${
              (target as HTMLDivElement).id
            }`,
            selectedFieldType: fieldType as Field,
            isCurrent: true,
          });
          return NEW_PAGES;
        });
      }
    }
  };

  /**
   * 필드를 캔버스에서 첫 배치할 때 드롭 함수
   */
  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    const { left, top } = e.currentTarget.getBoundingClientRect();
    const { target, clientX, clientY } = e;
    if (!target) return;

    const [fieldType, id] = draggingFieldId.split(":");
    const currentPageId = e.currentTarget.classList[0].split(":")[1];
    const currentPage = pages.find((page) => page.id === currentPageId);
    const [width, height] = lastFieldSize[fieldType as string];

    if (currentPage?.fields.filter((field) => field.id === id).length === 0) {
      setPages((prev: IPage[]) => {
        const NEW_PAGES = prev.map((pageData: IPage) => {
          const FIELDS = getAllFields(prev);
          const order = extractNumbersFromStrings(
            FIELDS.filter(
              (field) =>
                field.writer === selectedParticipant &&
                field.fieldType === fieldType
            ).map((item) => item.name || ""),
            `${
              fieldType === "SIGN"
                ? selectedParticipant.type === "sender"
                  ? "발신자 "
                  : `${selectedParticipant.label} `
                : ""
            }${getFieldNameInKor(fieldType as Field)}`
          );

          if (pageData.id === currentPageId) {
            return {
              ...pageData,
              fields: pageData.fields.concat({
                fieldType: fieldType as Field,
                id,
                name: `${
                  fieldType === "SIGN"
                    ? selectedParticipant.type === "sender"
                      ? "발신자 "
                      : `${selectedParticipant.label} `
                    : ""
                }${getFieldNameInKor(fieldType as Field)}${order}`,
                required:
                  selectedParticipant.type === "sender" && fieldType === "SIGN"
                    ? true
                    : false,
                writer: selectedParticipant,
                type: selectedParticipant.type,
                width,
                height,
                position: {
                  x:
                    (clientX - left - draggingStartedPosition.x) /
                    (+scale.replace("%", "") / 100),
                  y:
                    (clientY - top - draggingStartedPosition.y) /
                    (+scale.replace("%", "") / 100),
                },
                buttonType: fieldType === "CHECKBOX" ? "checkbox" : undefined,
                buttonsCount: fieldType === "CHECKBOX" ? 1 : undefined,
                selectCount:
                  fieldType === "CHECKBOX"
                    ? {
                        options: [
                          {
                            id: uuidv4(),
                            label: "",
                            width,
                            height: "max-content",
                            checked: false,
                            position: {
                              x:
                                (clientX - left - draggingStartedPosition.x) /
                                (+scale.replace("%", "") / 100),
                              y:
                                (clientY - top - draggingStartedPosition.y) /
                                (+scale.replace("%", "") / 100),
                            },
                          },
                        ],
                      }
                    : undefined,
              }),
            };
          } else return { ...pageData };
        });
        saveToHistory({
          pages: NEW_PAGES,
          selectedFieldId: `${fieldType as Field}:${id}`,
          selectedFieldType: fieldType as Field,
          isCurrent: true,
        });
        return NEW_PAGES;
      });

      setNewFieldId(uuidv4());
    }

    setSelectedPageId(currentPageId);
    setSelectedFieldId(`${fieldType}:${id}`);
    setSelectedFieldType(fieldType as Field);

    (target as HTMLDivElement).classList.remove("dragging");
  };

  const selectField = (id: string, type: Field) => {
    setSelectedFieldId(id);
    setSelectedFieldType(type);

    const selectedPageId = pages.find(
      (page) =>
        page.fields.findIndex(
          (field) => `${field.fieldType}:${field.id}` === id
        ) >= 0
    )?.id;

    if (selectedPageId) setSelectedPageId(selectedPageId);
  };

  const closeSignatureSelectionModal = () => {
    setSignatureSelectionModal(false);
  };

  const openDropdownEditModal = () => {
    setDropdownEditModalOpened(true);
  };

  const closeDropdownEditModal = () => {
    setDropdownEditModalOpened(false);
  };

  const editDropdown = (contents: string) => {
    const dropDownItems = contents.split(",").map((item, idx) => ({
      id: uuidv4(),
      value: item.trim(),
      sequence: idx,
    }));

    setPages((prev) =>
      prev.map((page) => {
        if (page.id === selectedPageId) {
          return {
            ...page,
            fields: page.fields.map((field) => {
              if (`${field.fieldType}:${field.id}` === selectedFieldId) {
                return { ...field, dropDownItems };
              } else return { ...field };
            }),
          };
        } else return { ...page };
      })
    );
  };

  const align = ({
    type,
    align,
    all,
  }: {
    type: Field;
    align?: TextAlign;
    all?: boolean;
  }) => {
    setPages((prev) =>
      prev.map((page) => {
        if (all) {
          return {
            ...page,
            fields: page.fields.map((field) => {
              if (field.fieldType === type) {
                return { ...field, align };
              } else return { ...field };
            }),
          };
        } else {
          if (page.id === selectedPageId) {
            return {
              ...page,
              fields: page.fields.map((field) => {
                if (`${field.fieldType}:${field.id}` === selectedFieldId) {
                  return { ...field, align };
                } else return { ...field };
              }),
            };
          } else return { ...page };
        }
      })
    );
  };

  const isSenderSignaturesEntered = () => {
    const SENDERS_SIGNATURE_FIELDS = [];

    for (const page of pages) {
      for (const field of page.fields) {
        if (
          field.required &&
          field.type === "sender" &&
          field.fieldType === "SIGN"
        )
          SENDERS_SIGNATURE_FIELDS.push(field);
      }
    }

    if (SENDERS_SIGNATURE_FIELDS.some((field) => !field.value)) return false;

    return true;
  };

  const validate = () => {
    const ALL_FIELDS = [];

    for (const page of pages) {
      for (const field of page.fields) {
        if (field.required && field.type === "sender") ALL_FIELDS.push(field);
      }
    }

    // 라디오 버튼
    // 체크되어 있는지
    const RADIO_GROUPS = ALL_FIELDS.filter(
      (field) => field.fieldType === "CHECKBOX" && field.buttonType === "radio"
    );

    if (
      RADIO_GROUPS.some(
        (group) =>
          (group.selectCount?.options?.filter((option) => option.checked)
            ?.length as number) === 0
      )
    ) {
      toast("필수 항목을 입력해주세요.");
      return;
    }

    const CHECKBOX_GROUPS = ALL_FIELDS.filter(
      (field) =>
        field.fieldType === "CHECKBOX" && field.buttonType === "checkbox"
    );

    if (
      CHECKBOX_GROUPS.some(
        (group) =>
          (group.selectCount?.mode === "MATCH" &&
            (group.selectCount?.options?.filter((option) => option.checked)
              ?.length as number) !== (group.selectCount?.number as number)) ||
          (group.selectCount?.mode === "BELOW" &&
            (group.selectCount?.options?.filter((option) => option.checked)
              ?.length as number) > (group.selectCount?.number as number)) ||
          (group.selectCount?.mode === "ABOVE" &&
            (group.selectCount?.options?.filter((option) => option.checked)
              ?.length as number) < (group.selectCount?.number as number))
      )
    ) {
      toast("필수 항목을 입력해주세요.");
      return;
    }

    if (
      ALL_FIELDS.filter((field) => field.fieldType !== "CHECKBOX").some(
        (field) => !field.value || field.value === ""
      )
    ) {
      toast("필수 항목을 입력해주세요.");
      return;
    }

    return true;
  };

  const send = async () => {
    if (!validate() || isSending) return;

    try {
      setIsSending(true);

      const count =
        (state?.contractType as ContractType) === "ONES"
          ? participants.filter(
              (participant) => participant.type === "receiver"
            )?.length
          : 1;

      const { data: remainCredits } = await refetch();

      if (
        (remainCredits?.remainContractCredit.remainCredits as number) < count
      ) {
        toast("전송할 수 있는 잔여 건수가 충분하지 않습니다.");
        return;
      }

      const {
        contractClass,
        ganinImageUrl,
        name,
        expiry,
        mail: { title, contents },
      } = form;

      if (!organization || !expiry) return;

      const inputs: Input[] = [];
      let i = 1;

      if (contractClass !== "GANIN") {
        for (const { fields } of pages) {
          if (fields.length === 0) {
            i++;
            continue;
          }

          for (const {
            name,
            fieldType,
            description,
            width,
            height,
            value,
            size,
            align,
            commas,
            autoDate,
            buttonType,
            selectCount,
            required,
            position,
            dropDownItems,
            writer,
          } of fields) {
            const defaultInput =
              writer?.type === "receiver"
                ? {
                    inputName: name || "",
                    inputType: fieldType,
                    width,
                    height,
                    page: i,
                    writer,
                  }
                : {
                    inputName: name || "",
                    inputType: fieldType,
                    width,
                    height,
                    value,
                    page: i,
                    writer,
                  };

            if (fieldType === "SIGN") {
              if (writer?.type === "sender") {
                const { origin, pathname } = new URL(value);

                inputs.push({
                  ...defaultInput,
                  value: origin + pathname,
                  x: position.x || 0,
                  y: position.y || 0,
                  description: description || "",
                  required: required || false,
                });
              } else {
                inputs.push({
                  ...defaultInput,
                  x: position.x || 0,
                  y: position.y || 0,
                  description: description || "",
                  required: required || false,
                });
              }
            } else if (
              fieldType === "TEXT" ||
              fieldType === "LONGTEXT" ||
              fieldType === "ADDRESS"
            ) {
              inputs.push({
                ...defaultInput,
                required: required || false,
                x: position.x || 0,
                y: position.y || 0,
                textSize: +(size || 0) || 16,
                textAlign: align || "LEFT",
                description: description || "",
              });
            } else if (fieldType === "NUMBER") {
              inputs.push({
                ...defaultInput,
                required: required || false,
                x: position.x || 0,
                y: position.y || 0,
                textSize: +(size || 0) || 16,
                textAlign: align || "LEFT",
                description: description || "",
                isCommaText: commas || false,
              });
            } else if (fieldType === "DATE") {
              inputs.push({
                ...defaultInput,
                required: required || false,
                x: position.x || 0,
                y: position.y || 0,
                textSize: +(size || 0) || 16,
                textAlign: align || "LEFT",
                description: description || "",
                isTodayDate: autoDate || false,
              });
            } else if (fieldType === "IMAGE") {
              inputs.push({
                ...defaultInput,
                required: required || false,
                x: position.x || 0,
                y: position.y || 0,
                description: description || "",
              });
            } else if (fieldType === "DROPDOWN") {
              inputs.push({
                ...defaultInput,
                required: required || false,
                x: position.x || 0,
                y: position.y || 0,
                textSize: +(size || 0) || 16,
                textAlign: align || "LEFT",
                dropDownItems: dropDownItems?.map(({ value, sequence }) => ({
                  value,
                  sequence,
                })),
              });
            } else if (fieldType === "CHECKBOX") {
              if (buttonType === "checkbox") {
                inputs.push({
                  ...defaultInput,
                  required: required || false,
                  x: position.x || 0,
                  y: position.y || 0,
                  textSize: +(size || 0) || 16,
                  textAlign: align || "LEFT",
                  numOfConcurrentSelect: selectCount?.number || 0,
                  typeOfConcurrentSelect: selectCount?.mode,
                  checkBoxItems:
                    selectCount?.options?.map((option) => ({
                      name: option.label || "",
                      x: option.position.x || 0,
                      y: option.position.y || 0,
                    })) || [],
                });
              } else {
                inputs.push({
                  ...defaultInput,
                  inputType: "RADIO_BTN",
                  required: required || false,
                  x: position.x || 0,
                  y: position.y || 0,
                  textSize: +(size || 0) || 16,
                  textAlign: align || "LEFT",
                  numOfConcurrentSelect: selectCount?.number || 0,
                  typeOfConcurrentSelect: selectCount?.mode,
                  radioButtonItems:
                    selectCount?.options?.map((option) => ({
                      name: option.label || "",
                      x: option.position.x || 0,
                      y: option.position.y || 0,
                    })) || [],
                });
              }
            }
          }

          i++;
        }
      } else {
        for (const { fields } of pages) {
          if (fields.length === 0) {
            i++;
            continue;
          }

          for (const {
            name,
            fieldType,
            description,
            width,
            height,
            value,
            size,
            align,
            commas,
            autoDate,
            buttonType,
            selectCount,
            required,
            position,
            dropDownItems,
            writer,
          } of fields) {
            const defaultInput =
              writer?.type === "receiver"
                ? {
                    inputName: name || "",
                    inputType: fieldType,
                    width,
                    height,
                    page: (i + 1) / 2,
                    writer,
                  }
                : {
                    inputName: name || "",
                    inputType: fieldType,
                    width,
                    height,
                    value,
                    page: (i + 1) / 2,
                    writer,
                  };

            if (fieldType === "SIGN") {
              if (writer?.type === "sender") {
                const { origin, pathname } = new URL(value);

                inputs.push({
                  ...defaultInput,
                  value: origin + pathname,
                  x: position.x || 0,
                  y: position.y || 0,
                  description: description || "",
                  required: required || false,
                });
              } else {
                inputs.push({
                  ...defaultInput,
                  x: position.x || 0,
                  y: position.y || 0,
                  description: description || "",
                  required: required || false,
                });
              }
            } else if (
              fieldType === "TEXT" ||
              fieldType === "LONGTEXT" ||
              fieldType === "ADDRESS"
            ) {
              inputs.push({
                ...defaultInput,
                required: required || false,
                x: position.x || 0,
                y: position.y || 0,
                textSize: +(size || 0) || 16,
                textAlign: align || "LEFT",
                description: description || "",
              });
            } else if (fieldType === "NUMBER") {
              inputs.push({
                ...defaultInput,
                required: required || false,
                x: position.x || 0,
                y: position.y || 0,
                textSize: +(size || 0) || 16,
                textAlign: align || "LEFT",
                description: description || "",
                isCommaText: commas || false,
              });
            } else if (fieldType === "DATE") {
              inputs.push({
                ...defaultInput,
                required: required || false,
                x: position.x || 0,
                y: position.y || 0,
                textSize: +(size || 0) || 16,
                textAlign: align || "LEFT",
                description: description || "",
                isTodayDate: autoDate || false,
              });
            } else if (fieldType === "IMAGE") {
              inputs.push({
                ...defaultInput,
                required: required || false,
                x: position.x || 0,
                y: position.y || 0,
                description: description || "",
              });
            } else if (fieldType === "DROPDOWN") {
              inputs.push({
                ...defaultInput,
                required: required || false,
                x: position.x || 0,
                y: position.y || 0,
                textSize: +(size || 0) || 16,
                textAlign: align || "LEFT",
                dropDownItems: dropDownItems?.map(({ value, sequence }) => ({
                  value,
                  sequence,
                })),
              });
            } else if (fieldType === "CHECKBOX") {
              if (buttonType === "checkbox") {
                inputs.push({
                  ...defaultInput,
                  required: required || false,
                  x: position.x || 0,
                  y: position.y || 0,
                  textSize: +(size || 0) || 16,
                  textAlign: align || "LEFT",
                  numOfConcurrentSelect: selectCount?.number || 0,
                  typeOfConcurrentSelect: selectCount?.mode,
                  checkBoxItems:
                    selectCount?.options?.map((option) => ({
                      name: option.label || "",
                      x: option.position.x || 0,
                      y: option.position.y || 0,
                    })) || [],
                });
              } else {
                inputs.push({
                  ...defaultInput,
                  inputType: "RADIO_BTN",
                  required: required || false,
                  x: position.x || 0,
                  y: position.y || 0,
                  textSize: +(size || 0) || 16,
                  textAlign: align || "LEFT",
                  numOfConcurrentSelect: selectCount?.number || 0,
                  typeOfConcurrentSelect: selectCount?.mode,
                  radioButtonItems:
                    selectCount?.options?.map((option) => ({
                      name: option.label || "",
                      x: option.position.x || 0,
                      y: option.position.y || 0,
                    })) || [],
                });
              }
            }
          }

          i++;
        }
      }

      const receivers = participants
        .filter(({ type }) => type === "receiver")
        .map(({ name, email, phoneNumber, verification }, idx) => {
          const defaultData = {
            receiverName: name || "",
            receiverEmail: email || "",
            contractSequence: idx + 1,
            phoneNumber: phoneNumber || "",
          };

          if (verification && verification.type) {
            if (verification.type === "PASSWORD") {
              return {
                ...defaultData,
                securityVerifyType: verification.type,
                password: verification?.password,
              };
            } else {
              return {
                ...defaultData,
                securityVerifyType: verification.type,
              };
            }
          } else {
            return {
              receiverName: name || "",
              receiverEmail: email || "",
              contractSequence: idx + 1,
              phoneNumber: phoneNumber || "",
              securityVerifyType: null,
            };
          }
        })
        .map((receiver) =>
          state?.contractType === "ONES"
            ? { ...receiver }
            : {
                ...receiver,
                receiverInputs: (state?.contractType === "ONE"
                  ? inputs.filter((input) => input.writer?.type === "receiver")
                  : inputs.filter(
                      ({ writer }) => writer?.email === receiver.receiverEmail
                    )
                )?.map((input) => {
                  delete input.writer;
                  return input;
                }),
              }
        );

      const senderInputs = inputs
        .filter(({ writer }) => writer?.type === "sender")
        .map((input) => {
          delete input.writer;
          return input;
        });

      const contractFile = sessionStorage.getItem("contractFileUrl");

      const attachmentFiles: AttachmentFile[] = attachedFiles.map(
        ({ fileName, fileUrl }, sequence) => ({
          sequence: sequence + 1,
          attachmentFileName: fileName,
          attachmentFileUrl: fileUrl,
        })
      );

      const data = {
        contract: {
          contractClass,
          ganinImageUrl,
          contractName: name,
          usePasswordVerify: false,
          usePhoneVerify: false,
          expiredAt: getDateTime(expiry),
          contractType: state?.contractSendType as ContractSendType,
          mailTitle: title,
          mailContent: contents,
        },
        contractFileUrl:
          JSON.parse(contractFile || "{}")[contractId as string].split(
            "?"
          )[0] || "",
        senderInputs,
        receivers,
        attachmentFiles,
      };

      if (state?.contractType === "ONES") {
        sendSingleMultipleContract(
          {
            organizationUuid: organization,
            data: {
              ...data,
              contract: { ...data.contract, contractType: "SINGLE" },
              receiverInputs: inputs.filter(
                (input) => input.writer?.type === "receiver"
              ),
            },
          },
          {
            onSuccess: () => {
              sessionStorage.removeItem("contractFileUrl");
              navigate("/dashboard");
            },
            onError: (error) => {
              toast(`계약서 전송에 실패했습니다.`);
              console.log("계약서 전송 실패", error);
            },
          }
        );
      } else {
        sendContract(
          {
            organizationUuid: organization,
            data,
          },
          {
            onSuccess: () => {
              sessionStorage.removeItem("contractFileUrl");
              navigate("/dashboard");
            },
            onError: (error) => {
              toast("계약서 전송에 실패했습니다.");
              console.log("계약서 전송 실패", error);
            },
          }
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSending(false);
    }
  };

  const saveToHistory = (current: History) => {
    const HISTORIES: History[] = sessionStorage.getItem("contractData")
      ? JSON.parse(sessionStorage.getItem("contractData") as string)
      : [];

    // isCurrent === true인 게 HISTORIES.length - 1이면
    // 새로 등록하는 것

    if (HISTORIES.length > 0) {
      let NEW_HISTORIES;

      if (HISTORIES[HISTORIES.length - 1].isCurrent) {
        NEW_HISTORIES = HISTORIES.map((history) => ({
          ...history,
          isCurrent: false,
        }));
      } else {
        let index = 0;
        while (!HISTORIES[index].isCurrent) {
          index++;
        }

        NEW_HISTORIES = HISTORIES.slice(0, index + 1).map((history) => ({
          ...history,
          isCurrent: false,
        }));
      }

      sessionStorage.setItem(
        "contractData",
        JSON.stringify(NEW_HISTORIES.concat(current))
      );
    } else sessionStorage.setItem("contractData", JSON.stringify([current]));
  };

  const undo = () => {
    const HISTORIES: History[] = sessionStorage.getItem("contractData")
      ? JSON.parse(sessionStorage.getItem("contractData") as string)
      : [];

    if (HISTORIES.length === 0 || HISTORIES[0].isCurrent) return;
    else {
      let index = 0;

      while (!HISTORIES[index].isCurrent) {
        index++;
      }

      const PREVIOUS = HISTORIES[index - 1];

      setPages(PREVIOUS.pages);
      setSelectedFieldId(PREVIOUS.selectedFieldId);
      setSelectedFieldType(PREVIOUS.selectedFieldType);

      const NEW_HISTORIES = HISTORIES.map((history, idx) =>
        idx === index - 1
          ? { ...history, isCurrent: true }
          : { ...history, isCurrent: false }
      );

      sessionStorage.setItem("contractData", JSON.stringify(NEW_HISTORIES));
    }
  };

  const redo = () => {
    const HISTORIES: History[] = sessionStorage.getItem("contractData")
      ? JSON.parse(sessionStorage.getItem("contractData") as string)
      : [];

    if (HISTORIES[HISTORIES.length - 1].isCurrent) return;
    else {
      let index = 0;

      while (!HISTORIES[index].isCurrent) {
        index++;
      }

      const NEXT = HISTORIES[index + 1];

      setPages(NEXT.pages);
      setSelectedFieldId(NEXT.selectedFieldId);
      setSelectedFieldType(NEXT.selectedFieldType);

      const NEW_HISTORIES = HISTORIES.map((history, idx) =>
        idx === index + 1
          ? { ...history, isCurrent: true }
          : { ...history, isCurrent: false }
      );

      sessionStorage.setItem("contractData", JSON.stringify(NEW_HISTORIES));
    }
  };

  const selectSignature = (value: string) => {
    setPages((prev) =>
      prev.map((page) => {
        if (page.id === selectedPageId) {
          return {
            ...page,
            fields: page.fields.map((field) => {
              if (`${field.fieldType}:${field.id}` === selectedFieldId) {
                return { ...field, value };
              } else return { ...field };
            }),
          };
        } else return { ...page };
      })
    );
  };

  const deleteAttachedFile = (id: string) => {
    setAttachedFiles((prev) =>
      prev
        .filter((attachment) => attachment.id !== id)
        .map((attachment, idx) => ({ ...attachment, sequence: idx + 1 }))
    );

    toast("첨부파일이 삭제되었습니다.");
  };

  const goToPreview = async (ganinImageUrl: string) => {
    if (!contractId) return;

    const contractFileUrl = JSON.parse(
      sessionStorage.getItem("contractFileUrl") || "{}"
    )[contractId];

    if (!contractFileUrl) return;

    try {
      setIsInProgres(true);
      setStampModalOpened(false);
      setContractModeModalOpened(false);
      const response = await axios.post(
        `${process.env.REACT_APP_API}/contracts/ganin-contract`,
        {
          ganinImageUrl,
          contractFileUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${getJWTToken().accessJwt}`,
          },
          responseType: "blob",
        }
      );

      if (response?.data) {
        setForm((prev) => ({ ...prev, contractClass: "GANIN", ganinImageUrl }));

        const fileData = new File([response.data], "convertedFile", {
          type: "application/pdf",
        });

        setPdf(fileData);
        setCurrentStep((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsInProgres(false);
    }
  };

  const saveLastSize = (field: Field, size: { w: number; h: number }) => {
    setLastFieldSize((prev) => ({
      ...prev,
      [field as string]: [size.w, size.h],
    }));
  };

  useEffect(() => {
    sessionStorage.removeItem("contractData");
  }, []);

  useEffect(() => {
    if (currentStep === 2 && (!state || !state?.pdf)) {
      navigate("/contract/send/upload");
    }
  }, [currentStep, state, navigate]);

  useEffect(() => {
    if (pathname.startsWith("/contract/send")) {
      if (pathname.split("/")[pathname.split("/").length - 1] === "editor") {
        setCurrentStep(3);

        if (state && state.participants) {
          setParticipants((prev) => [prev[0]].concat(...state.participants));
          if (state.contractType === "ONES" || state.contractType === "ONE") {
            setSelectedParticipant({
              id: "수신자",
              label: "수신자",
              type: "receiver",
              name: "수신자",
              color: PALETTE.receivers[0],
            });
          } else {
            setSelectedParticipant(state.participants[0]);
          }
        }
      } else if (
        pathname.split("/")[pathname.split("/").length - 1] === "config"
      )
        setCurrentStep(4);
    }
  }, [pathname, state]);

  useEffect(() => {
    setIsLastStep(currentStep === 5);
  }, [currentStep]);

  return (
    <>
      <SendStyled>
        <Top>
          {STEPS.map(({ step, label }) => (
            <Step
              key={step}
              selected={currentStep === step}
              step={step}
              isLastStep={isLastStep}
              label={label}
            />
          ))}
        </Top>
        <Toolbar
          pages={pages}
          form={form}
          setForm={setForm}
          exit={currentStep === 1 || currentStep === 3}
          func={currentStep < 4}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isLastStep={isLastStep}
          scale={scale}
          setScale={setScale}
          attachedFiles={attachedFiles}
          setAttachedFiles={setAttachedFiles}
          send={send}
          undo={undo}
          redo={redo}
          isSenderSignaturesEntered={isSenderSignaturesEntered}
          receivers={participants.filter(
            (participant) => participant.type === "receiver"
          )}
          setContractModeModalOpened={setContractModeModalOpened}
          isSending={isSending}
        />
        <Main>
          {currentStep <= 3 && (
            <Fields>
              <div className="participants">
                <div className="label">
                  <Body1SemiBold>서명 참여자</Body1SemiBold>
                  <Tooltip side="bottom" trigger={<InfoIcon />}>
                    <Body2Regular>
                      서명 참여자별로 입력란을 구분하여 배치할 수 있습니다.
                    </Body2Regular>
                  </Tooltip>
                </div>
                <ParticipantsDropdown
                  dropdownOpened={dropdownOpened}
                  setDropdownOpened={setDropdownOpened}
                  options={
                    state?.contractType === "ONE" ||
                    state?.contractType === "ONES"
                      ? [
                          {
                            id: uuidv4(),
                            label: "발신자",
                            type: "sender",
                            color: "#666F7B",
                          },
                          {
                            id: "수신자",
                            label: "수신자",
                            type: "receiver",
                            name: "수신자",
                            color: PALETTE.receivers[0],
                          },
                        ]
                      : participants
                  }
                  selectOption={selectParticipant}
                  selectedOption={selectedParticipant}
                />
              </div>
              <ul className="fields">
                {FIELDS.map(
                  (field) =>
                    field.value &&
                    lastFieldSize[field.value] && (
                      <CustomTooltip
                        key={field.id}
                        side="right"
                        trigger={
                          <li
                            className={`field${
                              selectedFieldType === field.value
                                ? " selected"
                                : ""
                            }`}
                          >
                            {field.icon}
                            <Item
                              newField
                              id={newFieldId}
                              type={
                                selectedParticipant?.label === "발신자"
                                  ? "sender"
                                  : "receiver"
                              }
                              field={field}
                              pages={pages}
                              setPages={setPages}
                              selectedFieldId={selectedFieldId}
                              setSelectedFieldId={setSelectedFieldId}
                              setSelectedFieldType={setSelectedFieldType}
                              selectedPageId={selectedPageId}
                              handleDragStart={handleDragStart}
                              selectField={selectField}
                              scale={+scale.replace("%", "") / 100}
                              setSignatureSelectionModal={
                                setSignatureSelectionModal
                              }
                              editDropdown={editDropdown}
                              align={align}
                              openModal={openDropdownEditModal}
                              saveToHistory={saveToHistory}
                              lastFieldSize={lastFieldSize}
                            />
                            <Body3Regular>{field.label}</Body3Regular>
                          </li>
                        }
                      >
                        <img src={field.balloon} alt="" />
                      </CustomTooltip>
                    )
                )}
              </ul>
            </Fields>
          )}
          {currentStep <= 2 && (
            <Upload
              setCurrentStep={setCurrentStep}
              participants={participants}
              setParticipants={setParticipants}
              pdf={pdf}
              setPdf={setPdf}
            />
          )}
          {currentStep === 3 && (
            <Document
              className="pdf"
              file={pdf}
              noData={<></>}
              onLoadSuccess={(doc) => {
                setPages((prev) => {
                  if (state.again) return prev;

                  const firstPageId = uuidv4();
                  setSelectedPageId(firstPageId);

                  const initialPages = Array(doc.numPages)
                    .fill(0)
                    .map((_, idx) => ({
                      id: idx === 0 ? firstPageId : uuidv4(),
                      order: idx + 1,
                      fields: [],
                    }));

                  saveToHistory({
                    pages: initialPages,
                    isCurrent: true,
                    selectedFieldId: "",
                    selectedFieldType: null,
                  });

                  return initialPages;
                });
              }}
            >
              <Editor
                pages={pages}
                setPages={setPages}
                selectedPageId={selectedPageId}
                setSelectedPageId={setSelectedPageId}
                setSelectedFieldType={setSelectedFieldType}
                selectedFieldId={selectedFieldId}
                setSelectedFieldId={setSelectedFieldId}
                scale={scale}
                handleDragStart={handleDragStart}
                handleDragEnd={handleDragEnd}
                onDrop={onDropHandler}
                pagesContainerRef={pagesContainerRef}
                selectField={selectField}
                setSignatureSelectionModal={setSignatureSelectionModal}
                editDropdown={editDropdown}
                align={align}
                openModal={openDropdownEditModal}
                saveToHistory={saveToHistory}
                saveLastSize={saveLastSize}
              />
              {currentStep > 2 && (
                <CreateContractRight
                  selectedFieldId={selectedFieldId}
                  selectedFieldType={selectedFieldType}
                  fields={getAllFields(pages)}
                  pages={pages}
                  setPages={setPages}
                  selectedPageId={selectedPageId}
                  setSelectedPageId={setSelectedPageId}
                  attachedFiles={attachedFiles}
                  deleteAttachedFile={deleteAttachedFile}
                  participants={
                    state?.contractType === "ONES" ||
                    state?.contractType === "ONE"
                      ? [
                          {
                            id: uuidv4(),
                            label: "발신자",
                            type: "sender",
                            color: "#666F7B",
                          },
                          {
                            id: "수신자",
                            label: "수신자",
                            type: "receiver",
                            name: "수신자",
                            color: PALETTE.receivers[0],
                          },
                        ]
                      : participants
                  }
                  setSignatureSelectionModal={setSignatureSelectionModal}
                  align={align}
                  scrollToPage={scrollToPage}
                />
              )}
            </Document>
          )}
          {currentStep === 4 && <Config form={form} setForm={setForm} />}
          {currentStep === 5 && (
            <PreviewStyled>
              <Document
                className="pdf"
                file={pdf}
                noData={<></>}
                onLoadSuccess={(doc) => {
                  if (form.contractClass === "NORMAL") return;

                  setPages((prev) => {
                    return Array(doc.numPages)
                      .fill(0)
                      .map((_, idx) => ({
                        id: idx % 2 === 1 ? uuidv4() : prev[idx / 2].id,
                        order: idx + 1,
                        fields: idx % 2 === 1 ? [] : prev[idx / 2].fields,
                      }));
                  });
                }}
              >
                <div className="left">
                  <div className="pages">
                    {pages.map((page) => (
                      <div
                        className={`page${
                          page.id === selectedPageId ? " current" : ""
                        }`}
                        key={page.id}
                        onClick={() => {
                          setSelectedPageId(page.id);
                          scrollToPage(page.id);
                        }}
                      >
                        <div className="label">
                          <Body1Regular>{page.order}</Body1Regular>
                        </div>
                        <Page
                          className="thumbnail"
                          height={140}
                          pageNumber={page.order}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="main">
                  <div className="pdf-container">
                    {pages.map((page, order) => (
                      <PageContainer
                        key={page.id}
                        id={page.id}
                        order={order}
                        setSelectedPageId={setSelectedPageId}
                      >
                        <Page
                          className="page"
                          pageNumber={page.order}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          scale={+scale.replace("%", "") / 50}
                        />
                        <div
                          className={`canvas:${page.id} canvas`}
                          onClick={() =>
                            setSelectedPageId && setSelectedPageId(page.id)
                          }
                          ref={(el) => {
                            if (!pagesContainerRef.current) return;

                            pagesContainerRef.current[order] = el;
                          }}
                        >
                          {page?.fields.map(
                            ({
                              id,
                              type,
                              fieldType,
                              selectCount,
                              position: { x, y },
                            }) =>
                              fieldType === "CHECKBOX" ? (
                                selectCount?.options?.map((option) => (
                                  <Item
                                    edit={type === "sender"}
                                    preview
                                    field={
                                      FIELDS.find(
                                        (field) => field.value === fieldType
                                      ) || FIELDS[0]
                                    }
                                    id={id}
                                    optionId={option.id}
                                    key={id}
                                    pages={pages}
                                    setPages={setPages}
                                    type={type}
                                    setSelectedFieldType={setSelectedFieldType}
                                    selectedFieldId={selectedFieldId}
                                    setSelectedFieldId={setSelectedFieldId}
                                    selectedPageId={selectedPageId}
                                    handleDragStart={handleDragStart}
                                    handleDragEnd={onDropHandler}
                                    selectField={selectField}
                                    setSignatureSelectionModal={
                                      setSignatureSelectionModal
                                    }
                                    scale={+scale.replace("%", "") / 100}
                                    editDropdown={editDropdown}
                                    align={align}
                                    openModal={openDropdownEditModal}
                                    saveToHistory={saveToHistory}
                                    style={{
                                      width: `max-content`,
                                      height: `max-content`,
                                      position: "absolute",
                                      left:
                                        option.position.x *
                                        (+scale.replace("%", "") / 100),
                                      top:
                                        option.position.y *
                                        (+scale.replace("%", "") / 100),
                                      zIndex:
                                        selectedFieldId === `${fieldType}:${id}`
                                          ? 5
                                          : 4,
                                      opacity: 1,
                                    }}
                                  />
                                ))
                              ) : (
                                <Item
                                  edit={type === "sender"}
                                  preview
                                  field={
                                    FIELDS.find(
                                      (field) => field.value === fieldType
                                    ) || FIELDS[0]
                                  }
                                  id={id}
                                  key={id}
                                  pages={pages}
                                  setPages={setPages}
                                  type={type}
                                  setSelectedFieldType={setSelectedFieldType}
                                  selectedFieldId={selectedFieldId}
                                  setSelectedFieldId={setSelectedFieldId}
                                  selectedPageId={selectedPageId}
                                  handleDragStart={handleDragStart}
                                  handleDragEnd={onDropHandler}
                                  selectField={selectField}
                                  setSignatureSelectionModal={
                                    setSignatureSelectionModal
                                  }
                                  scale={+scale.replace("%", "") / 100}
                                  editDropdown={editDropdown}
                                  align={align}
                                  openModal={openDropdownEditModal}
                                  saveToHistory={saveToHistory}
                                  style={{
                                    width: `max-content`,
                                    height: `max-content`,
                                    position: "absolute",
                                    left: x * (+scale.replace("%", "") / 100),
                                    top: y * (+scale.replace("%", "") / 100),
                                    zIndex:
                                      selectedFieldId === `${fieldType}:${id}`
                                        ? 5
                                        : 4,
                                    opacity: 1,
                                  }}
                                />
                              )
                          )}
                        </div>
                      </PageContainer>
                    ))}
                  </div>
                </div>
              </Document>
            </PreviewStyled>
          )}
        </Main>
      </SendStyled>
      {signatureSelectionModal && (
        <ModalLayout handleModalClose={closeSignatureSelectionModal}>
          <SenderSignatureModal
            selectSignature={selectSignature}
            closeSignatureSelectionModal={closeSignatureSelectionModal}
          />
        </ModalLayout>
      )}
      {dropdownEditModalOpened && (
        <DropdownEditModal
          defaultContents={
            pages
              .find((page) => page.id === selectedPageId)
              ?.fields.find(
                (field) => `${field.fieldType}:${field.id}` === selectedFieldId
              )?.dropDownItems
          }
          closeModal={closeDropdownEditModal}
          editDropdown={editDropdown}
        />
      )}
      {contractModeModalOpened && (
        <ContentModal
          size="medium"
          title="계약 형식 선택"
          handleModalClose={() => setContractModeModalOpened(false)}
        >
          <ContractModeModal
            setForm={setForm}
            setContractModeModalOpened={setContractModeModalOpened}
            setCurrentStep={setCurrentStep}
            pages={pages}
            setStampModalOpened={setStampModalOpened}
          />
        </ContentModal>
      )}
      {stampModalOpened && (
        <ParaphStampModal
          setContractModeModalOpened={setContractModeModalOpened}
          setStampModalOpened={setStampModalOpened}
          goToPreview={goToPreview}
        />
      )}
      {/* 간인 계약 진행 중 애니메이션 */}
      {isInProgress && (
        <GaninLoadingStyled>
          <img src={gifParaphAnimation} alt="" />
          <Title1>
            간인 계약서로 만드는 중입니다.
            <br />
            잠시만 기다려주세요.
          </Title1>
        </GaninLoadingStyled>
      )}
      {/* 계약서 전송 중 애니메이션 */}
      {isSending && (
        <ModalLayout>
          <LoadingStyled>
            <Loading />
            <Title1>
              계약서를 전송하고 있습니다.
              <br />
              잠시만 기다려주세요.
            </Title1>
          </LoadingStyled>
        </ModalLayout>
      )}
    </>
  );
}

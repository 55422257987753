import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getAnnualAuth, getPhoneAuthValid } from "apis/auth";
import { User } from "models/User";

export const authQueryKey = createQueryKeys("auth", {
  annualAuth: {
    queryKey: ["annualAuth"],
    queryFn: () => {
      return getAnnualAuth();
    },
  },
  phoneAuthValid: (requestUuid: string, userUuid: User["uuid"]) => ({
    queryKey: ["phoneAuthValid", requestUuid, userUuid],
    queryFn: () => {
      return getPhoneAuthValid({ requestUuid, userUuid });
    },
  }),
});

import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getInquiriesAllTypes, getInquiriesQnas } from "apis/inquiries";

export const inquiryQueryKey = createQueryKeys("inquiry", {
  qnas: (searchValue: string | null) => ({
    queryKey: [searchValue || "all"],
    queryFn: () => getInquiriesQnas(searchValue),
  }),
  types: {
    queryKey: null,
    queryFn: getInquiriesAllTypes,
  },
});

import React, { useState } from "react";
import styled from "styled-components";
import { Body3Regular } from "components/TextStyle";
import { ReactComponent as DeleteIcon } from "assets/common/24px/icon_close_default.svg";
import { ReactComponent as CalendarIcon } from "assets/common/20px/icon_calendar.svg";
import Toggle from "components/Common/Toggle/Toggle";
import { IField, IPage } from "interfaces/contract";
import { DraggableStyled } from "./styles";
import { Field } from "pages/contract/send";
import RequiredOption from "components/Contract/Options/Required";
import FontSizeOption from "components/Contract/Options/FontSize";
import AlignOption from "components/Contract/Options/Align";
import Resizable from "components/Resizable";
import DatePickerModal from "components/Common/DatePicker/DatePickerModal";
import { TextAlign } from "models/Contract";

interface DateProps extends React.HTMLAttributes<HTMLDivElement> {
  edit?: boolean;
  complete?: boolean;
  id: string;
  type: "sender" | "receiver";
  fields: IField[];
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  selectedPageId: string;
  selectedFieldId?: string;
  setSelectedFieldId?: React.Dispatch<React.SetStateAction<string>>;
  onDeleteHandler: (id: string) => void;
  selectField?: (id: string, type: Field) => void;
  scale: number;
  align?: ({
    type,
    align,
    all,
  }: {
    type: Field;
    align?: TextAlign | undefined;
    all?: boolean | undefined;
  }) => void;
  pageContainerRef?: any;
  currentSize: { w: number; h: number };
  handleMouseDown: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>,
    direction: string
  ) => void;
  handleMouseMove: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>
  ) => void;
  handleMouseUp: () => void;
  newField?: boolean;
  isMobile?: boolean;
}

export default function DateField({
  edit = false,
  complete = false,
  id,
  type,
  pages,
  fields,
  setPages,
  selectedPageId,
  selectedFieldId,
  setSelectedFieldId,
  onDeleteHandler,
  selectField,
  scale,
  draggable,
  align,
  pageContainerRef,
  currentSize,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  newField,
  isMobile,
  ...args
}: DateProps) {
  const [isEdit, setIsEdit] = useState(false);

  const handleAutoDate = () => {
    setPages((prev) =>
      prev.map((page) => {
        if (page.id === selectedPageId) {
          return {
            ...page,
            fields: page.fields.map((field) => {
              if (`${field.fieldType}:${field.id}` === selectedFieldId) {
                return { ...field, autoDate: !field.autoDate };
              } else return { ...field };
            }),
          };
        } else return { ...page };
      })
    );
  };

  return (
    <DateStyled
      id={id}
      type={type}
      color={
        type === "sender"
          ? "#666F7B"
          : (fields.find((field) => `${field.fieldType}:${field.id}` === id)
              ?.writer?.color as string)
      }
      required={
        !!fields.find((field) => `${field.fieldType}:${field.id}` === id)
          ?.required
      }
      draggable={draggable}
      scale={scale}
      align={
        fields.find((field) => `${field.fieldType}:${field.id}` === id)?.align
      }
      complete={complete}
      newField={newField}
      isMobile={isMobile}
      {...args}
    >
      <div className="wrapper">
        <div className="field-container">
          <div
            className="container date"
            onClick={(e) => {
              e.stopPropagation();
              selectField && selectField(id, id.split(":")[0] as Field);
            }}
            style={{
              width: `${(currentSize.w / 10) * scale}rem`,
              height: isMobile
                ? "1.4rem"
                : `${(currentSize.h / 10) * scale}rem`,
            }}
          >
            {edit ? (
              <div className="datepicker-container">
                <DatePickerModal
                  selected={
                    pages
                      .find((page) => page.id === selectedPageId)
                      ?.fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.value || null
                  }
                  onChangeHandler={(date) =>
                    setPages((prev) =>
                      prev.map((page) => {
                        if (isMobile) {
                          if (page.order.toString() === selectedPageId) {
                            return {
                              ...page,
                              fields: page.fields.map((field) => {
                                if (`${field.fieldType}:${field.id}` === id) {
                                  return { ...field, value: date };
                                } else return { ...field };
                              }),
                            };
                          } else return { ...page };
                        } else {
                          if (page.id === selectedPageId) {
                            return {
                              ...page,
                              fields: page.fields.map((field) => {
                                if (`${field.fieldType}:${field.id}` === id) {
                                  return { ...field, value: date };
                                } else return { ...field };
                              }),
                            };
                          } else return { ...page };
                        }
                      })
                    )
                  }
                  onSelectHandler={(date) =>
                    setPages((prev) =>
                      prev.map((page) => {
                        if (isMobile) {
                          if (page.order.toString() === selectedPageId) {
                            return {
                              ...page,
                              fields: page.fields.map((field) => {
                                if (`${field.fieldType}:${field.id}` === id) {
                                  return { ...field, value: date };
                                } else return { ...field };
                              }),
                            };
                          } else return { ...page };
                        } else {
                          if (page.id === selectedPageId) {
                            return {
                              ...page,
                              fields: page.fields.map((field) => {
                                if (`${field.fieldType}:${field.id}` === id) {
                                  return { ...field, value: date };
                                } else return { ...field };
                              }),
                            };
                          } else return { ...page };
                        }
                      })
                    )
                  }
                  disabled={complete}
                />
              </div>
            ) : (
              <>
                <CalendarIcon />
                {isEdit ? (
                  <textarea
                    value={
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.name
                    }
                    style={{
                      fontSize: `${
                        +(fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.size as string) / 10
                      }rem`,
                      lineHeight: 1.4285714286,
                      color: "var(--text-secondary)",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsEdit(false);
                      }
                    }}
                    onChange={(e) => {
                      setPages((prev) =>
                        prev.map((page) => {
                          if (
                            page.fields
                              .map((field) => `${field.fieldType}:${field.id}`)
                              .includes(id)
                          ) {
                            return {
                              ...page,
                              fields: page.fields.map((field) => {
                                if (`${field.fieldType}:${field.id}` === id) {
                                  return { ...field, name: e.target.value };
                                } else return { ...field };
                              }),
                            };
                          } else return { ...page };
                        })
                      );
                    }}
                  />
                ) : (
                  <Body3Regular
                    style={{
                      fontSize: `${
                        +(fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.size as string) / 10
                      }rem`,
                      lineHeight: 1.4285714286,
                    }}
                    onDoubleClick={() => setIsEdit((prev) => !prev)}
                  >
                    {
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.name
                    }
                  </Body3Regular>
                )}
              </>
            )}
          </div>
          {draggable && draggable && id === selectedFieldId && (
            <Resizable
              pages={pages}
              setPages={setPages}
              selectedFieldId={selectedFieldId}
              selectedPageId={selectedPageId}
              handleMouseUp={handleMouseUp}
              handleMouseDown={handleMouseDown}
              handleMouseMove={handleMouseMove}
              color={
                type === "sender"
                  ? "#666F7B"
                  : !!fields.find(
                      (field) => `${field.fieldType}:${field.id}` === id
                    )?.required
                  ? "red"
                  : "var(--blue-500)"
              }
              scale={scale}
            />
          )}
        </div>

        {draggable && id === selectedFieldId && (
          <DeleteIcon onClick={() => onDeleteHandler(id)} />
        )}
      </div>
      {draggable && selectedFieldId === id && (
        <div className="options" onClick={(e) => e.stopPropagation()}>
          <div className="option">
            <FontSizeOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="option">
            <AlignOption
              alignFunc={align}
              type="DATE"
              selected={
                fields.find((field) => `${field.fieldType}:${field.id}` === id)
                  ?.align || "LEFT"
              }
            />
          </div>
          <div className="option">
            <Body3Regular>서명날짜 설정</Body3Regular>
            <Toggle
              isSelected={
                !!fields.find(
                  (field) => `${field.fieldType}:${field.id}` === id
                )?.autoDate
              }
              handleToggle={handleAutoDate}
            />
          </div>
          <div className="option">
            <RequiredOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
        </div>
      )}
    </DateStyled>
  );
}

const DateStyled = styled(DraggableStyled)<{ type: "sender" | "receiver" }>`
  .wrapper {
    .container {
      padding: ${({ scale, isMobile }) =>
        isMobile ? "0" : `${(scale as number) * 1}rem`};
      box-sizing: border-box;
      display: flex;
      column-gap: 0.4rem;
      border-radius: ${({ scale }) => (scale ? `${scale * 0.4}rem` : "0.4rem")};
      color: var(--text-secondary);

      .datepicker-container {
        overflow: auto;
        .datepicker {
          font-size: ${({ isMobile }) => (isMobile ? "0.8rem" : "")};
          height: 100%;
          border: none;
        }
      }
    }
  }

  .options {
    .option {
      .dropdown {
        width: max-content;

        .contentContainer {
          justify-content: flex-start;
          padding: 0;
        }
      }

      .alignOptions {
        display: flex;
        align-items: center;
        column-gap: 0.4rem;
      }
    }
  }
`;

import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useDropdownListDetailQuery = (dropdownListUuid?: string) => {
  return useQuery({
    ...queries.dropwonList.detail(dropdownListUuid),
    enabled: !!dropdownListUuid,
  });
};

export default useDropdownListDetailQuery;

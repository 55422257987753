import axios, {
  AxiosError,
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { JWT_KEY } from "../../config/constant";
import { clearJWTToken, getJWTToken, saveJWTToken } from "utils/saveJwtToken";

// InternalAxiosRequestConfig 인터페이스 확장
declare module "axios" {
  interface InternalAxiosRequestConfig {
    _retry?: boolean;
  }
}

export type BaseResponse<T = any> = {
  isSuccess: boolean;
  message: string;
  code: number;
  result: T;
  extras?: any;
};

interface CustomInstance extends AxiosInstance {
  interceptors: {
    request: AxiosInterceptorManager<InternalAxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse<BaseResponse>>;
  };
  getUri(config?: AxiosRequestConfig): string;
  request<T>(config: AxiosRequestConfig): Promise<T>;
  get<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  head<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  options<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
}

// 토큰 리프레시 상태 관리
let isRefreshing = false;
let refreshTokenPromise: Promise<{
  accessJwt: string;
  refreshJwt: string;
}> | null = null;

export const request: CustomInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 20000,
  headers: {
    accept: "application/json",
  },
});

// 요청 인터셉터
request.interceptors.request.use(
  (config) => {
    const jwt =
      window.localStorage.getItem(JWT_KEY) ||
      window.sessionStorage.getItem(JWT_KEY);

    // 로그인/인증 관련 요청 처리
    if (config.url?.split("/")[1] === "sign") {
      config.baseURL = process.env.REACT_APP_SIGN_API;

      const accessJwt = sessionStorage.getItem("accessJwt");
      if (accessJwt) config.headers.Authorization = `Bearer ${accessJwt}`;
      return config;
    }

    // 일반 요청에 JWT 토큰 추가
    config.headers.Authorization = `Bearer ${jwt}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터
request.interceptors.response.use(
  (response) => {
    const contentType = response.headers["content-type"];
    if (contentType !== "application/json") {
      return response;
    }

    console.log("network log", response);

    // 성공적인 응답인 경우 결과 반환
    if (response.data.isSuccess) {
      return response.data.result;
    }

    return response;
  },
  async (error: AxiosError<BaseResponse>) => {
    const originalRequest = error.config;

    if (error.response?.status === 404) {
      return Promise.reject(error);
    }

    if (error.response?.data.code === 401 && !isRefreshing) {
      isRefreshing = true;

      try {
        // 첫 번째 요청이 토큰 리프레시를 수행
        if (!refreshTokenPromise) {
          const { refreshJwt: refreshToken } = getJWTToken();

          if (refreshToken) {
            refreshTokenPromise = request
              .patch<{
                userInfo: {
                  uuid: string;
                  accessJwt: string;
                  refreshJwt: string;
                };
              }>(`/auths/access-tokens`, {
                refreshJwt: refreshToken,
              })
              .then((response) => {
                console.log(response);
                const { accessJwt, refreshJwt } = response.userInfo;

                // 새로운 토큰 저장
                saveJWTToken({
                  accessJwt,
                  refreshJwt,
                });

                return { accessJwt, refreshJwt };
              });
          } else {
            throw new Error("No refresh token");
          }
        }

        // 다른 대기 중인 요청들은 토큰 리프레시 완료를 기다림
        const { accessJwt } = await refreshTokenPromise;

        if (originalRequest) {
          originalRequest.headers["Authorization"] = `Bearer ${accessJwt}`;
          return request(originalRequest);
        }
      } catch (refreshError) {
        clearJWTToken();

        return Promise.reject(refreshError);
      } finally {
        // 리프레시 상태 초기화
        isRefreshing = false;
        refreshTokenPromise = null;
      }
    }

    // 이미 리프레시 중인 경우 해당 프로미스 대기
    if (isRefreshing && refreshTokenPromise) {
      try {
        const { accessJwt } = await refreshTokenPromise;

        if (originalRequest) {
          originalRequest.headers["Authorization"] = `Bearer ${accessJwt}`;

          return request(originalRequest);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }

    // 네트워크 에러 처리
    return Promise.reject(
      error.code === "ERR_NETWORK"
        ? "허용되지 않은 네트워크 접근입니다."
        : error
    );
  }
);

export default request.request;

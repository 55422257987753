import { IField, IPage } from "interfaces/contract";
import { Field } from "pages/contract/send";

export const getAllFields = (pages: IPage[]) => {
  const ALL_FIELDS: IField[] = [];

  pages.forEach((page) => {
    if (page.fields.length <= 0) return;
    else {
      ALL_FIELDS.push(...page.fields);
    }
  });

  return ALL_FIELDS;
};

export const getFieldNameInKor = (field: Field) => {
  if (!field) return "";

  switch (field) {
    case "SIGN":
      return "서명";
    case "TEXT":
      return "텍스트";
    case "LONGTEXT":
      return "장문텍스트";
    case "NUMBER":
      return "숫자";
    case "DATE":
      return "날짜";
    case "ADDRESS":
      return "주소";
    case "IMAGE":
      return "이미지";
    case "DROPDOWN":
      return "드롭다운 리스트";
    case "CHECKBOX":
      return "체크박스";
    default:
      return "";
  }
};

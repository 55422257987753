import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";
import { GetContractsGaninRequest } from "models/Contract";

const useContractsGanin = (options: GetContractsGaninRequest) => {
  return useQuery({
    ...queries.contract.ganin(options),
    enabled: !!options.organizationUuid,
  });
};

export default useContractsGanin;

import React from "react";
import { Label } from "../CreateContractRight";
import Input from "components/Common/Input/Input";
import { OptionProps } from "interfaces/contract";
import Tooltip from "components/Common/Tooltip";
import { ReactComponent as InfoIcon } from "assets/common/20px/icon_information_small.svg";
import { Body3Regular } from "components/TextStyle";

export default function NameOption({
  label,
  fields,
  setPages,
  selectedPageId,
  selectedFieldId,
}: OptionProps) {
  return (
    <>
      <Label>
        {label || "입력란 이름"}
        {label && (
          <Tooltip side="top" trigger={<InfoIcon />}>
            <Body3Regular>
              체크 또는 라디오 박스를 삽입할 수 있는 입력란입니다.
              <br />
              수신자는 설정된 항목을 선택할 수 있습니다.
            </Body3Regular>
          </Tooltip>
        )}
      </Label>
      <Input
        value={
          fields?.find(
            (field) => `${field.fieldType}:${field.id}` === selectedFieldId
          )?.name
        }
        setValue={(name: string) => {
          setPages((pages) =>
            pages.map((page) => {
              if (page.id === selectedPageId) {
                return {
                  ...page,
                  fields: page?.fields?.map((field) => {
                    if (`${field.fieldType}:${field.id}` === selectedFieldId)
                      return { ...field, name };
                    else return { ...field };
                  }),
                };
              } else return { ...page };
            })
          );
        }}
        placeholder="입력란"
        maxLength={100}
      />
    </>
  );
}

import { JWT_KEY, JWT_REFRESH_KEY } from "config/constant";
import useUserState from "./useUserState";
import { useQueryClient } from "@tanstack/react-query";

const useResetUser = () => {
  const [_, setOrganizationIndex, setOrganizationGroupIndex] = useUserState();
  const queryClient = useQueryClient();

  return () => {
    window.localStorage.removeItem(JWT_REFRESH_KEY);
    window.localStorage.removeItem(JWT_KEY);
    window.sessionStorage.removeItem(JWT_KEY);
    window.sessionStorage.removeItem(JWT_REFRESH_KEY);
    queryClient.clear();
    setOrganizationIndex(0);
    setOrganizationGroupIndex(0);
  };
};

export default useResetUser;

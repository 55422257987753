import React from "react";
import BottomSheet from "components/Mobile/Common/BottomSheet/BottomSheet";
import { SubTitle, Body1Medium } from "components/TextStyle";
import { ReactComponent as CloseIcon } from "assets/common/32px/icon_close_large.svg";
import styled from "styled-components";
import useModal from "hooks/useModal";
import DrawSignModal from "./sign-modal/DrawSignModal";
import StampModal from "./sign-modal/StampModal";
import UploadStampModal from "./sign-modal/UploadStampModal";

interface CreateSignBottomSheetProps {
  isOpen: boolean;
  closeBottomSheet: () => void;
  sign?: (file: File) => void;
}

const CreateSignBottomSheet = ({
  isOpen,
  closeBottomSheet,
  sign,
}: CreateSignBottomSheetProps) => {
  const {
    openModal: openDraw,
    closeModal: closeDraw,
    isOpen: isDrawOpen,
  } = useModal();
  const {
    openModal: openStamp,
    closeModal: closeStamp,
    isOpen: isStampOpen,
  } = useModal();
  const {
    openModal: openUpload,
    closeModal: closeUpload,
    isOpen: isUploadOpen,
  } = useModal();

  const handleDraw = () => {
    closeBottomSheet();
    openDraw();
  };

  const handleStamp = () => {
    closeBottomSheet();
    openStamp();
  };

  const handleUpload = () => {
    closeBottomSheet();
    openUpload();
  };

  return (
    <>
      {isOpen && (
        <BottomSheet
          isOpen={isOpen}
          handleBottomSheetClose={closeBottomSheet}
          detent="content-height"
        >
          <BottomSheetContainer>
            <BottomSheetHeader>
              <SubTitle>서명 추가</SubTitle>
              <CloseIcon onClick={() => closeBottomSheet()} />
            </BottomSheetHeader>
            <BottomSheetList>
              <Body1Medium onClick={handleDraw}>그리기</Body1Medium>
              <Body1Medium onClick={handleStamp}>도장</Body1Medium>
              <Body1Medium onClick={handleUpload}>업로드</Body1Medium>
            </BottomSheetList>
          </BottomSheetContainer>
        </BottomSheet>
      )}

      <DrawSignModal isOpen={isDrawOpen} closeModal={closeDraw} sign={sign} />
      <StampModal isOpen={isStampOpen} closeModal={closeStamp} sign={sign} />
      <UploadStampModal
        isOpen={isUploadOpen}
        closeModal={closeUpload}
        sign={sign}
      />
    </>
  );
};

export default CreateSignBottomSheet;

const BottomSheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 2.7rem 1.6rem;
`;

const BottomSheetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BottomSheetList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-top: 2.7rem;
`;

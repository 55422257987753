import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";
import { GetTemplateListByIdRequest } from "models/Template";

const useTemplateList = ({
  organizationId = "",
  organizationGroupId,
  options,
}: Partial<GetTemplateListByIdRequest>) => {
  return useQuery({
    ...queries.template.list({ organizationId, organizationGroupId, options }),
    enabled: !!organizationId,
    placeholderData: (data) => data,
  });
};

export default useTemplateList;

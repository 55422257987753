import React, {
  CSSProperties,
  ChangeEvent,
  TextareaHTMLAttributes,
  forwardRef,
  useState,
} from "react";
import styled from "styled-components";
import { DefaultInputType } from "./Input";
import { Body1Regular, Body3Regular } from "components/TextStyle";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  maxLength: number;
  _style?: CSSProperties;
  rows?: number;
  largeCurrentLength?: boolean;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  errorMessage?: string;
}

const TextArea = forwardRef<
  HTMLTextAreaElement,
  TextAreaProps & DefaultInputType
>(
  (
    {
      maxLength,
      isError = false,
      isDisabled = false,
      value,
      setValue,
      _style,
      rows = 2,
      largeCurrentLength,
      onChange,
      errorMessage,
      ...props
    },
    ref
  ) => {
    const [inputCount, setInputCount] = useState(0);

    const onInputHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setInputCount(e.target.value.length);
      setValue && setValue(e.target.value);
    };

    return (
      <TextAreaWrapper>
        <TextAreaContainer
          $isError={isError || inputCount > maxLength}
          $isDisabled={isDisabled}
          $isSingleLine={rows === 1}
          $isErrorMsg={errorMessage ? true : false}
        >
          <textarea
            onChange={(e) => {
              onChange && onChange(e);
              onInputHandler(e);
            }}
            maxLength={maxLength}
            disabled={isDisabled}
            value={value}
            style={_style}
            ref={ref}
            rows={rows}
            {...props}
          />
          <div className="letterCount">
            {largeCurrentLength ? (
              <Body1Regular>
                <span>{inputCount}</span> / {maxLength}자
              </Body1Regular>
            ) : (
              <>
                <span>{inputCount}</span>/{maxLength}
              </>
            )}
          </div>
        </TextAreaContainer>
        {isError && errorMessage && (
          <Body3Regular className="errorMsg">{errorMessage}</Body3Regular>
        )}
      </TextAreaWrapper>
    );
  }
);

TextArea.displayName = "TextArea";

export default TextArea;

export const TextAreaContainer = styled.div<{
  $isError: boolean;
  $isDisabled: boolean;
  $isSingleLine: boolean;
  $isErrorMsg: boolean;
}>`
  display: flex;
  padding: ${(props) => (props.$isSingleLine ? "1.2rem" : "1.6rem")};
  flex-direction: ${({ $isSingleLine }) => ($isSingleLine ? "row" : "column")};
  gap: 0.4rem;
  border-radius: 1rem;
  border: ${(props) =>
    props.$isError
      ? "1px solid var(--red-500) !important"
      : "1px solid var(--grey-300)"};
  background: ${(props) =>
    props.$isDisabled ? "var(--grey-100)" : "var(--bg-white)"};
  min-height: ${({ $isSingleLine }) => ($isSingleLine ? "none" : "10.6rem")};
  width: 100%;
  box-sizing: border-box;
  align-items: flex-end;
  cursor: ${(props) => (props.$isDisabled ? "default" : "pointer")};

  &:hover {
    border: ${({ $isDisabled }) => !$isDisabled && "1px solid var(--grey-400)"};
  }

  &:focus-within {
    border: 1px solid var(--primary-500);

    .letterCount > span {
      color: #171d23;
    }
  }

  textarea {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    color: ${(props) => (props.$isDisabled ? "#D0D2DA" : "#171d23)")};
    font-size: 1.6rem;
    font-weight: var(--regular);
    line-height: 2.4rem;
    letter-spacing: -0.48px;

    &::-webkit-scrollbar {
      display: none;
    }

    &::placeholder {
      color: ${({ $isError, $isErrorMsg }) =>
        $isError && !$isErrorMsg ? "var(--red-500)" : "var(--grey-500)"};
    }

    &:disabled {
      background-color: var(--grey-100);
    }
  }

  .letterCount {
    font-size: 1.2rem;
    font-weight: var(--regular);
    line-height: 2rem;
    color: var(--grey-600);
  }
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 100%;

  .errorMsg {
    color: var(--red-500);
  }
`;

import Button from "components/Common/Button/Button";
import {
  DropDownContent,
  DropDownItem,
  DropdownItemType,
  DropDownRoot,
  DropDownTrigger,
} from "components/Common/Dropdown/DropdownMenu";
import React, { MouseEvent, MouseEventHandler, useState } from "react";

import { ReactComponent as UpIcon } from "assets/common/24px/icon_up.svg";
import { ReactComponent as DownIcon } from "assets/common/24px/icon_down_default.svg";
import { useNavigate } from "react-router-dom";

interface DashboardRowSendButtonProps {
  receiverCount: number;
  list: DropdownItemType[];
  id: string;
  isDisabled?: boolean;
}

const DashboardRowSendButton = ({
  receiverCount,
  list,
  id,
  isDisabled,
}: DashboardRowSendButtonProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenChange = (open: boolean) => {
    isDisabled || setIsOpen(open);
  };

  const handleItemClick = (
    event: MouseEvent<HTMLDivElement>,
    onClick?: MouseEventHandler<HTMLDivElement>
  ) => {
    event.stopPropagation(); // 이벤트 전파 중지
    if (onClick) {
      onClick(event); // 원래 항목에 설정된 onClick 함수 실행
    }
  };

  return (
    <Button
      colorType="tertiary"
      size="small"
      style={{
        width: "100%",
      }}
      onClick={(e) => {
        e.stopPropagation(); // 이벤트 전파 중지
        navigate(
          `/template/send/${id}/config/${
            receiverCount <= 1 ? "single" : "many"
          }`
        );
      }}
      isDisabled={isDisabled}
    >
      전송하기
      {list.length > 0 && (
        <DropDownRoot open={isOpen} onOpenChange={handleOpenChange}>
          <DropDownTrigger>
            {isOpen ? <UpIcon /> : <DownIcon opacity={isDisabled ? 0.3 : 1} />}
          </DropDownTrigger>
          <DropDownContent>
            {list.map(({ label, onClick, ...args }) => {
              return (
                <DropDownItem
                  key={label}
                  onClick={(e) => handleItemClick(e, onClick)}
                  {...args}
                >
                  {label}
                </DropDownItem>
              );
            })}
          </DropDownContent>
        </DropDownRoot>
      )}
    </Button>
  );
};

export default DashboardRowSendButton;

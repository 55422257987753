import {
  PostAuthPasswordSessionCodesRequest,
  PostAuthPasswordsSessionCodesRequest,
  PostAuthSignInRequest,
  PostAuthSignInResponse,
  PostAuthSignUpRequest,
  PostAuthSignUpResponse,
  PostAuthSignUpSessionCodesRequest,
  PostAuthSignUpSessionCodesResponse,
  GetAnnualAuthResponse,
  PostPhoneAuthRequest,
  GetPhoneAuthValidRequest,
  GetPhoneAuthValidResponse,
} from "models/Auth";
import getAPIResponseData from "utils/getAPIResponse";

/**
 * @description 회원가입을 위한 인증 메일을 전송한다.
 */

export const postAuthSignUpEmails = ({ email }: { email: string }) => {
  return getAPIResponseData({
    url: "/auths/sign-up/emails",
    method: "POST",
    data: { email },
  });
};

/**
 * @description 초대메일을 통한, 회원가입을 위한 인증 메일을 전송한다.
 */

export const postAuthSignUpEmailsByInvitation = ({
  email,
  organizationMemberUuid,
  inviteeEmail,
}: {
  email: string;
  organizationMemberUuid: string;
  inviteeEmail: string;
}) => {
  return getAPIResponseData({
    url: `/auths/sign-up/emails/${organizationMemberUuid}?inviteeEmail=${inviteeEmail}`,
    method: "POST",
    data: { email },
  });
};

/**
 *
 * @description 회원가입을 위한 메일 인증 세션 코드를 검증합니다.
 */

export const postAuthSignUpSessionCodes = ({
  sessionCode,
}: PostAuthSignUpSessionCodesRequest) => {
  return getAPIResponseData<
    PostAuthSignUpSessionCodesResponse,
    PostAuthSignUpSessionCodesRequest
  >({
    url: "/auths/sign-up/session-codes",
    method: "POST",
    data: { sessionCode },
  });
};

/**
 * @description 비밀번호 변경 요청에 대한 세션 코드를 검사한다.
 */

export const postAuthPasswordSessionCodes = ({
  sessionCode,
}: PostAuthPasswordSessionCodesRequest) => {
  return getAPIResponseData({
    url: "/auths/passwords/session-codes",
    method: "POST",
    data: { sessionCode },
  });
};

/**
 * @description 회원가입을 진행합니다.
 */

export const postAuthSignUp = ({
  sessionCode,
  name,
  password,
  serviceTermAgree,
  personalInfoTermAgree,
  marketingReceiveAgree,
}: PostAuthSignUpRequest) => {
  return getAPIResponseData<PostAuthSignUpResponse, PostAuthSignUpRequest>({
    url: "/auths/sign-up",
    method: "POST",
    data: {
      sessionCode,
      name,
      password,
      serviceTermAgree,
      personalInfoTermAgree,
      marketingReceiveAgree,
    },
  });
};

/**
 * @description 회원가입을 진행합니다.
 */

export const postAuthSignUpByInvitation = ({
  data,
  organizationMemberUuid,
  inviteeEmail,
}: {
  data: PostAuthSignUpRequest;
  organizationMemberUuid: string;
  inviteeEmail: string;
}) => {
  return getAPIResponseData<PostAuthSignUpResponse, PostAuthSignUpRequest>({
    url: `/auths/sign-up/${organizationMemberUuid}?inviteeEmail=${inviteeEmail}`,
    method: "POST",
    data,
  });
};

/**
 * @description 회원가입을 위한 인증 메일을 전송한다.
 */

export const postAuthPasswordsEmails = ({ email }: { email: string }) => {
  return getAPIResponseData({
    url: "/auths/passwords/emails",
    method: "POST",
    data: { email },
  });
};

/**
 * @description 비밀번호를 변경합니다.
 */

export const patchAuthPasswords = ({
  sessionCode,
  password,
}: PostAuthPasswordsSessionCodesRequest) => {
  return getAPIResponseData({
    url: "/auths/passwords",
    method: "PATCH",
    data: { sessionCode, password },
  });
};

/**
 * @description 로그인을 진행합니다.
 */

export const postAuthSignIn = ({ email, password }: PostAuthSignInRequest) => {
  return getAPIResponseData<PostAuthSignInResponse, PostAuthSignInRequest>({
    url: "/auths/login",
    method: "POST",
    data: { email, password },
  });
};

/**
 * @description 초대메일을 통한, 로그인을 진행합니다.
 */

export const postAuthSignInByInvitation = ({
  data,
  organizationMemberUuid,
}: {
  data: PostAuthSignInRequest;
  organizationMemberUuid: string;
}) => {
  return getAPIResponseData<PostAuthSignInResponse, PostAuthSignInRequest>({
    url: `/auths/login/${organizationMemberUuid}`,
    method: "POST",
    data,
  });
};

/**
 * @description 연간 본인인증 여부 조회
 */

export const getAnnualAuth = () => {
  return getAPIResponseData<GetAnnualAuthResponse>({
    url: `/auths/phone-verify/annual/validity`,
    method: "GET",
  });
};

/**
 * @description 휴대폰 본인인증 페이지 요청
 */

export const postPhoneAuth = ({
  callbackUrl,
  parameterOption1,
  parameterOption2,
}: PostPhoneAuthRequest) => {
  const data = new FormData();
  data.append("callbackUrl", callbackUrl);
  data.append("parameterOption1", parameterOption1);
  data.append("parameterOption2", parameterOption2);

  return getAPIResponseData<string, FormData>({
    url: "/auths/phone-verify",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "text/html",
    },
    data,
    responseType: "text",
  });
};

/**
 * @description 휴대폰 본인인증결과 유효성 검증
 */
export const getPhoneAuthValid = ({
  requestUuid,
  userUuid,
}: GetPhoneAuthValidRequest) => {
  return getAPIResponseData<GetPhoneAuthValidResponse>({
    url: `/auths/phone-verify/valid?requestUuid=${requestUuid}&userUuid=${userUuid}`,
    method: "GET",
  });
};

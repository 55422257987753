import { getUser } from "apis/user";
import { redirect } from "react-router-dom";

const NonAuthOnlyLoader = async () => {
  try {
    await getUser();
    return redirect("/dashboard");
  } catch (err) {
    return null;
  }
};

export default NonAuthOnlyLoader;

import React, { PropsWithChildren } from "react";
import { RootLayout } from "./RootLayout";
import styled from "styled-components";
import {
  Header,
  LabelContainer,
  LogoContainer,
} from "components/AppHeader/styles";
import { Link, useLocation } from "react-router-dom";
import LogoIcon from "assets/common/icon_logo.svg";
import { Body1Medium } from "components/TextStyle";

export function Receiverlayout({ children }: PropsWithChildren) {
  const { pathname } = useLocation();
  const title = () => {
    switch (pathname.split("/")[1]) {
      case "annual-auth":
        return "연동 인증";
      default:
        return "수신자 본인 확인";
    }
  };

  return (
    <RootLayout>
      <Header>
        <LogoContainer>
          <Link to={"/"}>
            <img src={LogoIcon} alt="logoIcon" />
          </Link>
          <div className="line" />
        </LogoContainer>
        <LabelContainer>
          <Body1Medium>{title()}</Body1Medium>
        </LabelContainer>
      </Header>
      <MainContent>{children}</MainContent>
    </RootLayout>
  );
}

const MainContent = styled.main`
  display: flex;
  height: calc(100dvh - 6.8rem);
`;

import Button from "components/Common/Button/Button";
import DatePicker from "components/Common/DatePicker/DatePicker";
import ActionModal from "components/Common/Modal/ActionModal";
import { Body3Regular } from "components/TextStyle";
import React, { useState } from "react";
import styled from "styled-components";
import { Duration, format, sub } from "date-fns";
import { getTemplateDataExcel } from "apis/template";
import saveFile from "utils/saveFile";
import useUserState from "hooks/recoil/useUserState";
import { useParams } from "react-router-dom";

const DESCRIPTION = [
  <>
    지정한 기간 동안 전송된 계약의 발신자, 수신자, 진행 상태를 엑셀 형식
    <br />
    으로 일괄 추출할 수 있습니다.
  </>,
  "서명, 도장 데이터는 추출할 수 없습니다.",
  "열 데이터 순서는 임의 추출이며 순서를 지정할 수 없습니다.",
];

interface TemplateDataExportModalProps {
  closeModal: () => void;
  templateIdParam?: string;
}

const TemplateDataExportModal = ({
  closeModal,
  templateIdParam,
}: TemplateDataExportModalProps) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [user] = useUserState();
  const { templateId } = useParams();

  console.log(
    "🚀 ~ file: TemplateDataExportModal.tsx:36 ~ templateId:",
    templateIdParam
  );

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const changeSubDate = (option: Duration) => {
    return () => {
      setStartDate(sub(new Date(), { ...option }));
      setEndDate(new Date());
    };
  };

  const handleDownloadExcel = async () => {
    try {
      const res = await getTemplateDataExcel({
        organizationId: user.organization ?? "",
        templateId: templateIdParam || templateId || "",
        request: {
          startDate: format(startDate ?? new Date(), "yyyy-MM-dd"),
          endDate: format(endDate ?? new Date(), "yyyy-MM-dd"),
        },
      });

      const file = new File([res.data], "template_file.xlsx", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // saveFile 함수를 사용하여 파일을 저장합니다.
      saveFile(file);
      closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ActionModal title="데이터 추출" handleModalClose={closeModal} widthFull>
      <Layout>
        <ul>
          {DESCRIPTION.map((desc, index) => (
            <li key={index}>
              <Body3Regular>{desc}</Body3Regular>
            </li>
          ))}
        </ul>
        <DatePickerLayout>
          <DatePicker
            selectedDate={startDate ?? undefined}
            onChange={handleStartDateChange}
            maxDate={endDate ?? undefined}
          />
          <hr />
          <DatePicker
            selectedDate={endDate ?? undefined}
            onChange={handleEndDateChange}
            minDate={startDate ?? undefined}
          />
        </DatePickerLayout>
        <DateButtonLayout>
          <Button
            colorType="tertiary"
            size="medium"
            onClick={changeSubDate({ days: 0 })}
          >
            오늘
          </Button>
          <Button
            colorType="tertiary"
            size="medium"
            onClick={changeSubDate({ weeks: 1 })}
          >
            1주일
          </Button>
          <Button
            colorType="tertiary"
            size="medium"
            onClick={changeSubDate({ months: 1 })}
          >
            1개월
          </Button>
          <Button
            colorType="tertiary"
            size="medium"
            onClick={changeSubDate({ months: 3 })}
          >
            3개월
          </Button>
        </DateButtonLayout>
      </Layout>
      <Footer>
        <Button colorType="ghost" size="large" onClick={closeModal}>
          취소
        </Button>
        <Button colorType="primary" size="large" onClick={handleDownloadExcel}>
          추출하기
        </Button>
      </Footer>
    </ActionModal>
  );
};

export default TemplateDataExportModal;

const Layout = styled.div`
  li {
    list-style: outside;
    margin-left: 1rem;
  }
`;

const DatePickerLayout = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 1.7rem;
  align-items: center;
  width: 38rem;
  hr {
    height: 1px;
    border: none;
    background-color: var(--stroke-dark);
    width: 1.2rem;
  }
`;

const DateButtonLayout = styled.div`
  margin-top: 1.2rem;
  display: flex;
  gap: 1.7rem;
  align-items: center;

  button {
    flex: 1;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: -2rem;
`;

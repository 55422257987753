import React from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import { Address } from "react-daum-postcode/lib/loadPostcode";
import ContentModal from "../Modal/ContentModal";
import styled from "styled-components";
import { Body2Regular } from "components/TextStyle";
import Input from "../Input/Input";

export default function Postcode({
  address,
  setAddress,
  handleModalClose,
  onCompleteHandler,
  detailMode,
  handleCancelButton,
  handleConfirmButton,
}: {
  address: { main: string; detail: string };
  setAddress: React.Dispatch<
    React.SetStateAction<{ main: string; detail: string }>
  >;
  handleModalClose: () => void;
  onCompleteHandler: (address: Address) => void;
  detailMode: boolean;
  handleCancelButton: () => void;
  handleConfirmButton: () => void;
}) {
  return (
    <ContentModal
      handleModalClose={handleModalClose}
      title="주소 입력하기"
      isCloseIcon
      size="medium"
      handleCancelButton={detailMode ? handleCancelButton : undefined}
      handleConfirmButton={detailMode ? handleConfirmButton : undefined}
    >
      <DaumPostcodeEmbed onComplete={onCompleteHandler} />
      {detailMode && (
        <DetailAddressFormStyled>
          <div className="form-title">
            <Body2Regular>
              상세 주소를 추가로 입력해 주신 후 <strong>{`'적용'`} </strong>
              버튼을 눌러주세요.
            </Body2Regular>
          </div>
          <div className="inputs">
            <div>
              <label>
                <Body2Regular>기본 주소</Body2Regular>
              </label>
              <Body2Regular>{address.main}</Body2Regular>
            </div>
            <div>
              <label htmlFor="detailAddress">
                <Body2Regular>상세 주소</Body2Regular>
              </label>
              <Input
                id="detailAddress"
                type="text"
                value={address.detail}
                onChange={(e) =>
                  setAddress((prev) => ({ ...prev, detail: e.target.value }))
                }
                width="100%"
                placeholder="상세 주소를 입력해주세요"
              />
            </div>
          </div>
        </DetailAddressFormStyled>
      )}
    </ContentModal>
  );
}

const DetailAddressFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: var(--bg-dark);
  row-gap: 2rem;
  border-radius: 2rem;

  .inputs {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;

    & > div {
      display: flex;
      align-items: center;
      column-gap: 1.2rem;

      label {
        color: var(--text-secondary);
        min-width: max-content;
      }
    }
  }
`;

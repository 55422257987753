import Konva from "konva";
import { Stage as StageRefType } from "konva/lib/Stage";

const konvaToImage = async (
  current: StageRefType,
  object: {
    width: number;
    height: number;
    scaleX: number;
    scaleY: number;
    offsetX: number;
    offsetY: number;
    contrast?: number;
  }
) => {
  const scaledStage = current.clone({
    width: object.width,
    height: object.height,
    scaleX: object.scaleX,
    scaleY: object.scaleY,
    x: object.offsetX,
    y: object.offsetY,
  });

  // contrast 필터 적용
  const layer = scaledStage.findOne("Layer") as Konva.Layer;
  if (layer && object.contrast !== undefined) {
    const image = layer.findOne("Image") as Konva.Image;
    if (image) {
      image.cache();
      image.filters([Konva.Filters.Contrast]);
      image.contrast(object.contrast);
    }
  }

  const blob = (await scaledStage.toBlob({
    mimeType: "image/png",
    width: object.width,
    height: object.height,
  })) as Blob;

  return new File([blob], "image.png", { type: "image/png" });
};

export default konvaToImage;

import React, { useState } from "react";
import {
  InputContainer,
  SupportContainer,
  SupportContentContainer,
} from "./styles";
import { Body1Regular, Header2 } from "components/TextStyle";
import Dropdown from "components/Common/Dropdown/Dropdown";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import Input from "components/Common/Input/Input";
import TextArea from "components/Common/Input/TextArea";
import Button from "components/Common/Button/Button";
import CheckBox from "components/Common/CheckBox/CheckBox";
import SettingLayout from "components/Setting/Layout/SettingLayout";
import { REGEX } from "config/regex";
import useInquiriesMutation from "hooks/mutations/inquiries/useInquiriesMutation";
import { PostAppInquiriesRequest } from "models/Inquiries";
import {
  formatEmailInput,
  formatNameInput,
  formatPhoneNumber,
} from "utils/utility";
import { toast } from "react-toastify";
import useInquiriesAllTypesQuery from "hooks/queries/inquiry/useInquiriesAllTypesQuery";

type InquiryFormData = Omit<PostAppInquiriesRequest, "personalInfoTermAgree">;

const SettingSupportPage = () => {
  return (
    <SettingLayout type="support" state="support">
      <SupportBox />
    </SettingLayout>
  );
};

export default SettingSupportPage;

export const SupportBox = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm<InquiryFormData>({
    mode: "onTouched",
    defaultValues: {
      contactPhoneNumber: "",
      serviceInquiryType: "",
      email: "",
      name: "",
      companyName: "",
      inquiryContent: "",
    },
  });
  const [isChecked, setIsChecked] = useState(false);

  const { mutate } = useInquiriesMutation();

  const handleInquirySubmit: SubmitHandler<InquiryFormData> = (data) => {
    const { contactPhoneNumber, companyName, ...restData } = data;

    const inquiriesData = {
      ...restData,
      ...(contactPhoneNumber && { contactPhoneNumber }),
      ...(companyName && { companyName }),
      personalInfoTermAgree: isChecked,
    };
    mutate(inquiriesData, {
      onSuccess: () => {
        toast("문의에 대한 답변은 메일로 전송됩니다.");
        reset();
      },
    });
  };

  const { field: inquiryTypeField, fieldState: inquiryTypeFieldState } =
    useController({
      name: "serviceInquiryType",
      control,
      rules: {
        required: "true",
      },
    });

  const { field: phoneNumberField } = useController({
    name: "contactPhoneNumber",
    control,
  });

  const { field: nameField } = useController({
    name: "name",
    control,
  });

  const { field: emailField } = useController({
    name: "email",
    control,
  });

  const { data: typeListData } = useInquiriesAllTypesQuery();

  const dropdownList =
    typeListData?.flatMap((e) => {
      return {
        value: e.typeName,
        label: e.typeName,
      };
    }) || [];

  return (
    <SupportContainer onSubmit={handleSubmit(handleInquirySubmit)}>
      <div className="inputContainer">
        <SupportContentContainer>
          <InputContainer>
            <Header2>문의 종류</Header2>
            <Dropdown
              placeholder="문의 종류를 선택해 주세요"
              selected={inquiryTypeField.value}
              setSelected={inquiryTypeField.onChange}
              dropdownList={dropdownList}
              isError={inquiryTypeFieldState.error ? true : false}
            />
          </InputContainer>
          <InputContainer>
            <Header2>연락처</Header2>
            <Input
              placeholder="연락처를 입력해 주세요"
              {...register("contactPhoneNumber", {
                required: true,
              })}
              maxLength={13}
              value={phoneNumberField.value}
              onChange={(e) => {
                phoneNumberField.onChange(formatPhoneNumber(e.target.value));
              }}
              isError={errors.contactPhoneNumber ? true : false}
            />
          </InputContainer>
          <InputContainer>
            <Header2>회사명</Header2>
            <Input
              placeholder="회사명을 입력해 주세요"
              {...register("companyName", {
                required: true,
              })}
              maxLength={20}
              isError={errors.companyName ? true : false}
            />
          </InputContainer>
          <InputContainer>
            <Header2>문의 내용</Header2>
            <TextArea
              placeholder="문의 내용을 입력해주세요"
              maxLength={1000}
              {...register("inquiryContent", {
                required: true,
              })}
              isError={errors.inquiryContent ? true : false}
            />
          </InputContainer>
          <div className="checkboxContainer">
            <CheckBox
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <Body1Regular className="checkboxText">
              <a
                href="https://softsquared.notion.site/b145b6a389704010a4a8c7c59f625d86"
                target="_blank"
                rel="noreferrer"
              >
                개인정보 수집 및 이용
              </a>
              에 동의합니다.
            </Body1Regular>
          </div>
        </SupportContentContainer>

        <SupportContentContainer>
          <InputContainer>
            <Header2>이름</Header2>
            <Input
              placeholder="이름을 입력해 주세요"
              {...register("name", {
                required: "이름을 입력해주세요",
              })}
              value={nameField.value}
              onChange={(e) =>
                nameField.onChange(formatNameInput(e.target.value))
              }
              isError={errors.name ? true : false}
              errorMessage={errors.name?.message}
              maxLength={30}
            />
          </InputContainer>
          <InputContainer>
            <Header2>이메일</Header2>
            <Input
              placeholder="이메일을 입력해 주세요"
              {...register("email", {
                required: "이메일 주소를 입력하세요",
                pattern: {
                  value: REGEX.email,
                  message: "올바른 이메일 주소를 입력해주세요.",
                },
              })}
              value={emailField.value}
              onChange={(e) =>
                emailField.onChange(formatEmailInput(e.target.value))
              }
              isError={errors.email ? true : false}
              errorMessage={errors.email?.message}
              maxLength={50}
            />
          </InputContainer>
        </SupportContentContainer>
      </div>

      <Button
        colorType="primary"
        size="large"
        type="submit"
        isDisabled={!isValid || !isChecked}
      >
        문의하기
      </Button>
    </SupportContainer>
  );
};

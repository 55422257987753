import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useInquiriesAllTypesQuery = () => {
  return useQuery({
    ...queries.inquiry.types,
  });
};

export default useInquiriesAllTypesQuery;

import { useBoundaryInView } from "components/Common/BoundaryInView/BoundaryInViewProvider";
import { Body1SemiBold } from "components/TextStyle";
import useDebounce from "hooks/useDebounce";
import React from "react";
import { Page } from "react-pdf";

interface ListPdfPreviewItemProps {
  index: number;
}

const ListPdfPreviewItem = ({ index }: ListPdfPreviewItemProps) => {
  const { activeSection, setActiveSection } = useBoundaryInView();
  const debounce = useDebounce(100);

  const scrollMainPdf = (id: number) => {
    const element = document.getElementById(id.toString());
    element?.scrollIntoView({ behavior: "auto", block: "nearest" });
  };

  return (
    <div key={`page_${index}`}>
      <Body1SemiBold>{index}</Body1SemiBold>
      <Page
        pageNumber={index}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        height={120}
        className={`pdf-document ${
          activeSection.replace("#", "") === index.toString() && "isActive"
        }`}
        onClick={() => {
          scrollMainPdf(index);
          debounce(() => setActiveSection(`#${index}`));
        }}
      />
    </div>
  );
};

export default ListPdfPreviewItem;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  getTemplateByIdById,
  getTemplateDetailInfo,
  getTemplateDetailsById,
  getTemplateListById,
} from "apis/template";
import {
  GetTemplateByIdByIdRequest,
  GetTemplateDetailInfoRequest,
  GetTemplateDetailsByIdRequest,
  GetTemplateListByIdRequest,
} from "models/Template";

export const templateQueryKey = createQueryKeys("template", {
  list: ({
    organizationId,
    organizationGroupId,
    options,
  }: GetTemplateListByIdRequest) => ({
    queryKey: [organizationId, organizationGroupId, options],
    queryFn: () =>
      getTemplateListById({ organizationId, organizationGroupId, options }),
  }),
  download: ({
    organizationId,
    organizationGroupId,
    templateId,
  }: GetTemplateByIdByIdRequest) => ({
    queryKey: [organizationId, organizationGroupId, templateId],
    queryFn: () =>
      getTemplateByIdById({ organizationId, organizationGroupId, templateId }),
  }),
  detail: ({
    organizationUuid,
    templateUuid,
  }: GetTemplateDetailsByIdRequest) => ({
    queryKey: [organizationUuid, templateUuid],
    queryFn: () => getTemplateDetailsById({ organizationUuid, templateUuid }),
  }),
  detailInfo: ({
    organizationId,
    templateId,
  }: GetTemplateDetailInfoRequest) => ({
    queryKey: [organizationId, templateId],
    queryFn: () =>
      getTemplateDetailInfo({
        organizationId,
        templateId,
      }),
  }),
});

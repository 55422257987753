import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";
import { Organization } from "models/Organization";

const useOrganizationGroupListQuery = (id?: Organization["uuid"]) => {
  return useQuery({
    ...queries.organizationGroup.detail(id)._ctx.groupList,
    enabled: !!id,
    select: (data) => ({
      organizationGroupList: [
        { organizationGroupUuid: "", groupName: "그룹 없음", role: "" },
        ...(data?.organizationGroupList ?? []),
      ],
    }),
  });
};

export default useOrganizationGroupListQuery;

import React, { MouseEventHandler, ReactNode } from "react";
import ModalLayout from "./ModalLayout";
import { Body1SemiBold, Title1 } from "components/TextStyle";
import Button from "../Button/Button";
import { ActionModalContainer } from "./ModalStyledComponents";
import CloseIcon from "assets/common/24px/icon_close_default.svg";
import LogoIcon from "assets/common/icon_logo.svg";

interface ActionModalProps {
  zIndex?: number;
  logo?: boolean;
  handleModalClose?: () => void;
  title: React.ReactNode;
  children: ReactNode;
  handleCancelButton?: MouseEventHandler<HTMLButtonElement> | null;
  confirmText?: string;
  handleConfirmButton?: MouseEventHandler<HTMLButtonElement> | null;
  isCloseIconButton?: boolean;
  widthFull?: boolean;
  isForm?: boolean;
  disabledConfirmButton?: boolean;
  disableCloseWithOutsideClickOrEsc?: boolean;
  wideBtn?: boolean;
  cancelText?: string;
}

const ActionModal = ({
  zIndex,
  logo = false,
  handleModalClose,
  title,
  children,
  handleConfirmButton,
  confirmText,
  handleCancelButton,
  isCloseIconButton = false,
  widthFull = false,
  isForm = false,
  disabledConfirmButton = false,
  disableCloseWithOutsideClickOrEsc = false,
  wideBtn = false,
  cancelText,
}: ActionModalProps) => {
  return (
    <ModalLayout
      handleModalClose={handleModalClose}
      disableCloseWithOutsideClickOrEsc={disableCloseWithOutsideClickOrEsc}
      style={{
        zIndex,
      }}
    >
      <ActionModalContainer
        $widthFull={widthFull}
        $wideBtn={wideBtn}
        as={isForm ? "form" : "div"}
      >
        <div className="modalContentContainer">
          <div className="headerContainer">
            {logo && <img className="logo" src={LogoIcon} alt="logoIcon" />}
            {isCloseIconButton && (
              <img
                src={CloseIcon}
                alt="closeIcon"
                className="closeIcon"
                onClick={handleModalClose}
              />
            )}

            <Title1>{title}</Title1>
          </div>

          {children}
        </div>

        {(handleCancelButton || handleConfirmButton) && (
          <div className="buttonContainer">
            {handleCancelButton && (
              <Button
                colorType="ghost"
                size="large"
                handleClick={handleCancelButton}
              >
                <Body1SemiBold>{cancelText || "취소"}</Body1SemiBold>
              </Button>
            )}
            {handleConfirmButton && (
              <Button
                colorType="primary"
                size="large"
                handleClick={handleConfirmButton}
                type={isForm ? "submit" : "button"}
                disabled={disabledConfirmButton}
              >
                <Body1SemiBold>{confirmText || "확인"}</Body1SemiBold>
              </Button>
            )}
          </div>
        )}
      </ActionModalContainer>
    </ModalLayout>
  );
};

export default ActionModal;

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Title1, SubTitle, Body3SemiBold } from "components/TextStyle";
import { LNBContainer } from "./style";
import NoImgIcon from "assets/common/icon_no_img.svg";
import { ReactComponent as RightIcon } from "assets/common/24px/icon_right_default.svg";
import useUserState from "hooks/recoil/useUserState";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";
import useOrganizationListQuery from "hooks/queries/organization/useOrganizationListQuery";
const DashboardLNB = () => {
  const [user] = useUserState();
  const organization = user.organization;

  const { data: remainCreditsData } = useContractRemainCreditsQuery();
  const { data: organizationData } = useOrganizationListQuery();
  const currentOrganization = organizationData.organizationList.find(
    (i) => i.organizationUuid === organization
  );

  return (
    <LNBContainer>
      <OrganizationContainer>
        <OrganizationLogo
          src={currentOrganization?.logoImageUrl ?? NoImgIcon}
        />
        <Title1>{currentOrganization?.companyName}</Title1>
      </OrganizationContainer>
      <MainContainer>
        <ContractContainer>
          <SubTitle>전송 가능 건수</SubTitle>
          <Content to="/setting/payment/dashboard">
            <Body3SemiBold style={{ color: "var(--text-secondary)" }}>
              충전하러 가기
            </Body3SemiBold>
            <RightIcon />
          </Content>
          <RemainInfo>
            {remainCreditsData?.remainContractCredit.remainCredits.toLocaleString()}{" "}
            건
          </RemainInfo>
        </ContractContainer>
      </MainContainer>
    </LNBContainer>
  );
};

export default DashboardLNB;

const OrganizationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 2.4rem;
`;

const OrganizationLogo = styled.img`
  width: 5.2rem;
  height: 5.2rem;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const ContractContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  height: 19.1rem;
  padding: 1.6rem 2rem;

  background-color: var(--bg-dark);
  border: 1px solid var(--stroke-light);
  border-radius: 1.2rem;
`;

const Content = styled(Link)`
  display: flex;
  align-items: center;

  svg {
    width: 2rem;
    height: 2rem;

    path {
      stroke: var(--text-secondary);
    }
  }
`;

const RemainInfo = styled(Title1)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  width: 100%;
  height: 100%;
`;

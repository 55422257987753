import styled from "styled-components";

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 3.6rem 4rem;
  box-sizing: border-box;
  border-radius: 2rem;
  background: var(--bg-white);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  height: fit-content;

  .modalContentContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }
`;

export const ActionModalContainer = styled(ModalContainer)<{
  $widthFull: boolean;
  $wideBtn?: boolean;
}>`
  pointer-events: auto;
  width: ${({ $widthFull }) => ($widthFull ? "content-fit" : "46rem")};

  .headerContainer {
    display: flex;
    flex-direction: column;

    .logo {
      width: 8.4rem;
      height: auto;
      margin-bottom: 1.6rem;
    }

    .closeIcon {
      width: 2.6rem;
      align-self: flex-end;
      cursor: pointer;
    }
  }

  .buttonContainer {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    button {
      padding: 1.2rem 0;
      width: ${({ $wideBtn }) => ($wideBtn ? "17.1rem" : "10rem")};
    }
  }
`;

export const ContentModalContainer = styled(ModalContainer)<{
  $size: "large" | "medium";
  $maxHeight?: string;
}>`
  width: ${(props) => (props.$size === "large" ? "108rem" : "74rem")};
  max-height: ${({ $maxHeight }) => $maxHeight || "65rem"};
  overflow-y: scroll;
  border-radius: 0.5rem;
  row-gap: 3.2rem;

  .contentContainer {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
  }

  -ms-overflow-style: none; /*IE, Edge*/
  scrollbar-width: none; /*Firefox*/

  ::-webkit-scrollbar {
    display: none; /*Chrome, Safari, Opera*/
    width: 0px;
  }
`;

export const ContentModalTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  &:has(.prevIcon) {
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .modalIcon {
    cursor: pointer;
    width: 3.2rem;
    height: 3.2rem;
  }
`;

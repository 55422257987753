import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postContractSendSingleMultiple } from "apis/contract";
import { queries } from "config/queryKey";

const usePostSendSingleMultipleContractMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postContractSendSingleMultiple,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.contract._def });
    },
  });
};

export default usePostSendSingleMultipleContractMutation;

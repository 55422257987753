import React, { useState } from "react";
import styled from "styled-components";
import { DraggableStyled } from "./styles";
import { Body3Regular } from "components/TextStyle";
import { ReactComponent as DeleteIcon } from "assets/common/24px/icon_close_default.svg";
import { ReactComponent as ImageIcon } from "assets/common/20px/icon_image.svg";
import { Field } from "pages/contract/send";
import { IField, IPage } from "interfaces/contract";
import RequiredOption from "components/Contract/Options/Required";
import Resizable from "components/Resizable";
import { isValidImage } from "utils/utility";
import { getContractsAttachmentsPresignedUrl } from "apis/contract";

interface ImageProps extends React.HTMLAttributes<HTMLDivElement> {
  edit?: boolean;
  complete?: boolean;
  id: string;
  type: "sender" | "receiver";
  fields: IField[];
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  selectedPageId: string;
  selectedFieldId?: string;
  onDeleteHandler: (id: string) => void;
  selectField?: (id: string, type: Field) => void;
  scale: number;
  pageContainerRef?: any;
  currentSize: { w: number; h: number };
  handleMouseDown: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>,
    direction: string
  ) => void;
  handleMouseMove: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>
  ) => void;
  handleMouseUp: () => void;
  newField?: boolean;
  isMobile?: boolean;
}

export default function Image({
  edit = false,
  complete = false,
  id,
  type,
  fields,
  pages,
  setPages,
  selectedPageId,
  selectedFieldId,
  onDeleteHandler,
  selectField,
  scale,
  draggable,
  pageContainerRef,
  currentSize,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  newField,
  isMobile,
  ...args
}: ImageProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [imgPreview, setImgPreview] = useState<string | ArrayBuffer | null>(
    null
  );

  const onUploadHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const uploaded = e.target.files[0];

      if (!isValidImage(uploaded.type)) alert("이미지만 업로드 가능합니다.");

      const value = await getContractsAttachmentsPresignedUrl({
        file: uploaded,
        fileName: uploaded.name,
      });

      if (!value) return;

      const reader = new FileReader();
      reader.readAsDataURL(uploaded);
      reader.onload = async (e) => {
        setImgPreview(e.target?.result || "");
        setPages((prev) =>
          prev.map((page) => {
            if (isMobile) {
              if (page.order.toString() === selectedPageId) {
                return {
                  ...page,
                  fields: page.fields.map((field) => {
                    if (`${field.fieldType}:${field.id}` === id) {
                      return { ...field, value: value?.split("?")[0] };
                    } else return { ...field };
                  }),
                };
              } else return { ...page };
            } else {
              if (page.id === selectedPageId) {
                return {
                  ...page,
                  fields: page.fields.map((field) => {
                    if (`${field.fieldType}:${field.id}` === id) {
                      return { ...field, value: value?.split("?")[0] };
                    } else return { ...field };
                  }),
                };
              } else return { ...page };
            }
          })
        );
      };
    }

    e.target.value = "";
  };

  return (
    <ImageStyled
      id={id}
      type={type}
      color={
        type === "sender"
          ? "#666F7B"
          : (fields.find((field) => `${field.fieldType}:${field.id}` === id)
              ?.writer?.color as string)
      }
      required={
        !!fields.find((field) => `${field.fieldType}:${field.id}` === id)
          ?.required
      }
      field="IMAGE"
      draggable={draggable}
      scale={scale}
      complete={complete}
      newField={newField}
      isMobile={isMobile}
      {...args}
    >
      <div className="wrapper">
        <div className="field-container">
          <label
            htmlFor={`image-${id}`}
            className="container image"
            onClick={(e) => {
              e.stopPropagation();
              selectField && selectField(id, id.split(":")[0] as Field);
            }}
            style={{
              width: `${(currentSize.w / 10) * scale}rem`,
              height: `${(currentSize.h / 10) * scale}rem`,
            }}
          >
            {edit ? (
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={onUploadHandler}
                disabled={complete}
                style={{ fontSize: isMobile ? "0.8rem" : "" }}
              />
            ) : (
              <>
                <ImageIcon />
                {isEdit ? (
                  <textarea
                    value={
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.name
                    }
                    style={{
                      lineHeight: 1.4285714286,
                      color: "var(--text-secondary)",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsEdit(false);
                      }
                    }}
                    onChange={(e) => {
                      setPages((prev) =>
                        prev.map((page) => {
                          if (
                            page.fields
                              .map((field) => `${field.fieldType}:${field.id}`)
                              .includes(id)
                          ) {
                            return {
                              ...page,
                              fields: page.fields.map((field) => {
                                if (`${field.fieldType}:${field.id}` === id) {
                                  return { ...field, name: e.target.value };
                                } else return { ...field };
                              }),
                            };
                          } else return { ...page };
                        })
                      );
                    }}
                  />
                ) : (
                  <Body3Regular
                    onDoubleClick={() => setIsEdit((prev) => !prev)}
                  >
                    {
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.name
                    }
                  </Body3Regular>
                )}
              </>
            )}
            {imgPreview && (
              <div
                className="uploaded"
                style={{
                  backgroundImage: `url(${imgPreview})`,
                }}
              />
            )}
            {!imgPreview && !complete && (
              <Body3Regular className={`message${edit ? " edit" : ""}`}>
                여기를 클릭하여
                <br />
                JPG, PNG 이미지를 삽입해주세요.
              </Body3Regular>
            )}
          </label>
          {draggable && draggable && id === selectedFieldId && (
            <Resizable
              pages={pages}
              setPages={setPages}
              selectedFieldId={selectedFieldId}
              selectedPageId={selectedPageId}
              handleMouseUp={handleMouseUp}
              handleMouseDown={handleMouseDown}
              handleMouseMove={handleMouseMove}
              color={
                type === "sender"
                  ? "#666F7B"
                  : !!fields.find(
                      (field) => `${field.fieldType}:${field.id}` === id
                    )?.required
                  ? "red"
                  : "var(--blue-500)"
              }
              scale={scale}
            />
          )}
        </div>
        {draggable && id === selectedFieldId && (
          <DeleteIcon onClick={() => onDeleteHandler(id)} />
        )}
      </div>
      {draggable && selectedFieldId === id && (
        <div className="options" onClick={(e) => e.stopPropagation()}>
          <div className="option">
            <RequiredOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
        </div>
      )}
    </ImageStyled>
  );
}

const ImageStyled = styled(DraggableStyled)<{
  type: "sender" | "receiver";
}>`
  .wrapper {
    width: max-content;

    .container {
      cursor: pointer;
      position: relative;
      padding: ${({ scale, isMobile }) =>
        isMobile ? "0" : scale ? `${scale * 1}rem` : "1rem"};
      box-sizing: border-box;
      display: flex;
      column-gap: 0.4rem;
      border-radius: ${({ scale }) => (scale ? `${scale * 0.4}rem` : "0.4rem")};
      color: var(--text-secondary);
      overflow: visible;

      input[type="file"] {
        position: absolute;
        inset: 0;
        opacity: 0;
        z-index: 100;
      }

      .uploaded {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .message {
        font-size: ${({ scale }) => (scale ? `${1.4 * scale}rem` : "1.4rem")};
        width: max-content;
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        &.edit {
          font-size: ${({ scale, isMobile }) =>
            isMobile ? "0.6rem" : scale ? `${1.6 * scale}rem` : "1.6rem"};
        }
      }
    }
  }

  .options {
    .option {
      .dropdown {
        width: max-content;

        .contentContainer {
          justify-content: flex-start;
          padding: 0;
        }
      }

      .alignOptions {
        display: flex;
        align-items: center;
        column-gap: 0.4rem;
      }
    }
  }
`;

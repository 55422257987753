import React from "react";
import { Label } from "../CreateContractRight";
import Button from "components/Common/Button/Button";
import { Field } from "pages/contract/send";
import { Body3Regular } from "components/TextStyle";
import { ReactComponent as LeftIcon } from "assets/common/20px/icon_align_left.svg";
import { ReactComponent as CenterIcon } from "assets/common/20px/icon_align_center.svg";
import { ReactComponent as RightIcon } from "assets/common/20px/icon_align_right.svg";
import { ReactComponent as LeftIconSelected } from "assets/common/20px/icon_align_left_selected.svg";
import { ReactComponent as CenterIconSelected } from "assets/common/20px/icon_align_center_selected.svg";
import { ReactComponent as RightIconSelected } from "assets/common/20px/icon_align_right_selected.svg";
import { TextAlign } from "models/Contract";

interface AlignOptionProps {
  side?: boolean;
  alignFunc?: ({
    type,
    align,
    all,
  }: {
    type: Field;
    align?: TextAlign;
    all?: boolean;
  }) => void;
  type: Field;
  selected?: TextAlign;
}

export default function AlignOption({
  side,
  alignFunc,
  type,
  selected,
}: AlignOptionProps) {
  return (
    <>
      {side ? (
        <>
          <Label justifyContent="space-between">
            글자 정렬
            <Button
              size="small"
              colorType="tertiary"
              onClick={() =>
                alignFunc && alignFunc({ type, align: selected, all: true })
              }
            >
              모두 적용
            </Button>
          </Label>
        </>
      ) : (
        <>
          <Body3Regular>글자 정렬</Body3Regular>
        </>
      )}
      <div className="alignOptions">
        {selected === "LEFT" ? (
          <LeftIconSelected
            onClick={() => alignFunc && alignFunc({ type, align: "LEFT" })}
          />
        ) : (
          <LeftIcon
            onClick={() => alignFunc && alignFunc({ type, align: "LEFT" })}
          />
        )}
        {selected === "CENTER" ? (
          <CenterIconSelected
            onClick={() => alignFunc && alignFunc({ type, align: "CENTER" })}
          />
        ) : (
          <CenterIcon
            onClick={() => alignFunc && alignFunc({ type, align: "CENTER" })}
          />
        )}
        {selected === "RIGHT" ? (
          <RightIconSelected
            onClick={() => alignFunc && alignFunc({ type, align: "RIGHT" })}
          />
        ) : (
          <RightIcon
            onClick={() => alignFunc && alignFunc({ type, align: "RIGHT" })}
          />
        )}
      </div>
    </>
  );
}

import { MenuItems } from ".";
import HomeIcon from "assets/common/24px/icon_home.svg";
import ActiveHomeIcon from "assets/common/24px/icon_home_active.svg";
import TemplateIcon from "assets/common/24px/icon_template.svg";
import ActiveTemplateIcon from "assets/common/24px/icon_template_active.svg";
import ContractIcon from "assets/common/24px/icon_contract.svg";
import ActiveContractIcon from "assets/common/24px/icon_contract_active.svg";
import SettingsIcon from "assets/common/24px/icon_settings.svg";
import ActiveSettingsIcon from "assets/common/24px/icon_settings_active.svg";
import AlertIcon from "assets/common/24px/icon_bell.svg";

import ProfileInformationIcon from "assets/MobileMenuIcon/icon_profile_information.svg";
import ProfileSignIcon from "assets/MobileMenuIcon/icon_profile_sign.svg";
import ProfileOrganizationIcon from "assets/MobileMenuIcon/icon_profile_organization.svg";
import TemplateDashboardIcon from "assets/MobileMenuIcon/icon_template_dashboard.svg";
import TemplateMeIcon from "assets/MobileMenuIcon/icon_template_me.svg";
import TemplateBookmarkIcon from "assets/MobileMenuIcon/icon_template_bookmark.svg";
import TemplateStoreIcon from "assets/MobileMenuIcon/icon_template_store.svg";
import ContractNormalIcon from "assets/MobileMenuIcon/icon_contract_normal.svg";
import ContractDirectUrlIcon from "assets/MobileMenuIcon/icon_contract_direct-url.svg";
import SettingPaymentIcon from "assets/MobileMenuIcon/icon_setting_payment.svg";
import SettingOrganizationIcon from "assets/MobileMenuIcon/icon_setting_organization.svg";
import SettingMemberIcon from "assets/MobileMenuIcon/icon_setting_member.svg";
import SettingContactIcon from "assets/MobileMenuIcon/icon_setting_contact.svg";
import SettingSupportIcon from "assets/MobileMenuIcon/icon_setting_support.svg";

import ProfileInformationActiveIcon from "assets/MobileMenuIcon/active/icon_profile_information.svg";
import ProfileSignActiveIcon from "assets/MobileMenuIcon/active/icon_profile_sign.svg";
import ProfileOrganizationActiveIcon from "assets/MobileMenuIcon/active/icon_profile_organization.svg";
import TemplateDashboardActiveIcon from "assets/MobileMenuIcon/active/icon_template_dashboard.svg";
import TemplateMeActiveIcon from "assets/MobileMenuIcon/active/icon_template_me.svg";
import TemplateBookmarkActiveIcon from "assets/MobileMenuIcon/active/icon_template_bookmark.svg";
import TemplateStoreActiveIcon from "assets/MobileMenuIcon/active/icon_template_store.svg";
import ContractNormalActiveIcon from "assets/MobileMenuIcon/active/icon_contract_normal.svg";
import ContractDirectUrlActiveIcon from "assets/MobileMenuIcon/active/icon_contract_direct-url.svg";
import SettingPaymentActiveIcon from "assets/MobileMenuIcon/active/icon_setting_payment.svg";
import SettingOrganizationActiveIcon from "assets/MobileMenuIcon/active/icon_setting_organization.svg";
import SettingMemberActiveIcon from "assets/MobileMenuIcon/active/icon_setting_member.svg";
import SettingContactActiveIcon from "assets/MobileMenuIcon/active/icon_setting_contact.svg";
import SettingSupportActiveIcon from "assets/MobileMenuIcon/active/icon_setting_support.svg";

// TODO routes에 맞게 수정 필요
// subMenu > include는 subMenu의 path(to)는 아니지만 해당 subMenu가 활성화되야 하는 경우 추가
export const MENU_ITEMS: MenuItems[] = [
  {
    label: "홈",
    icon: HomeIcon,
    activeIcon: ActiveHomeIcon,
    to: "/dashboard",
    include: ["/notice", "/alert"],
    subMenu: [
      {
        label: "내 정보",
        icon: ProfileInformationIcon,
        activeIcon: ProfileInformationActiveIcon,
        to: "/profile/information",
        include: ["/profile/information"],
      },
      {
        label: "내 서명",
        icon: ProfileSignIcon,
        activeIcon: ProfileSignActiveIcon,
        to: "/profile/sign",
        include: ["/profile/sign"],
      },
      {
        label: "초대받은 조직",
        icon: ProfileOrganizationIcon,
        activeIcon: ProfileOrganizationActiveIcon,
        to: "/profile/invited-organization",
        include: ["/profile/invited-organization"],
      },
    ],
  },
  {
    label: "템플릿 관리",
    icon: TemplateIcon,
    activeIcon: ActiveTemplateIcon,
    to: "/template/dashboard",
    subMenu: [
      {
        label: "전체 템플릿 목록",
        icon: TemplateDashboardIcon,
        activeIcon: TemplateDashboardActiveIcon,
        to: "/template/dashboard",
        include: ["/template/dashboard"],
      },
      {
        label: "내가 만든 템플릿",
        icon: TemplateMeIcon,
        activeIcon: TemplateMeActiveIcon,
        to: "/template/dashboard/me",
        include: ["/template/dashboard/me"],
      },
      {
        label: "즐겨찾기",
        icon: TemplateBookmarkIcon,
        activeIcon: TemplateBookmarkActiveIcon,
        to: "/template/dashboard/bookmark",
        include: ["/template/dashboard/bookmark"],
      },
      {
        label: "템플릿 스토어",
        icon: TemplateStoreIcon,
        activeIcon: TemplateStoreActiveIcon,
        to: "/template/store",
        include: ["/template/store"],
      },
    ],
  },
  {
    label: "계약 관리",
    icon: ContractIcon,
    activeIcon: ActiveContractIcon,
    to: "/contract/dashboard/normal",
    subMenu: [
      {
        label: "일반 계약",
        icon: ContractNormalIcon,
        activeIcon: ContractNormalActiveIcon,
        to: "/contract/dashboard/normal",
        include: ["/contract/dashboard/normal"],
      },
      {
        label: "Direct URL",
        icon: ContractDirectUrlIcon,
        activeIcon: ContractDirectUrlActiveIcon,
        to: "/contract/dashboard/direct-url",
        include: ["/contract/dashboard/direct-url"],
      },
    ],
  },
  {
    label: "설정",
    icon: SettingsIcon,
    activeIcon: ActiveSettingsIcon,
    to: "/setting/payment/dashboard",
    subMenu: [
      {
        label: "결제 관리",
        icon: SettingPaymentIcon,
        activeIcon: SettingPaymentActiveIcon,
        to: "/setting/payment/dashboard",
        include: [
          "/setting/payment/billing",
          "/setting/payment/dashboard",
          "/setting/payment/history",
          "/setting/payment/billing/complete",
        ],
        permission: {
          organization: ["MASTER"],
        },
      },
      {
        label: "일반 설정",
        icon: SettingOrganizationIcon,
        activeIcon: SettingOrganizationActiveIcon,
        to: "/setting/organization",
        include: ["/setting/organization", "/setting/service"],
        permission: {
          organization: ["MASTER", "ADMIN"],
        },
      },
      {
        label: "멤버/그룹 관리",
        icon: SettingMemberIcon,
        activeIcon: SettingMemberActiveIcon,
        to: "/setting/member",
        include: ["/setting/member", "/setting/group"],
      },
      {
        label: "간편 설정",
        icon: SettingContactIcon,
        activeIcon: SettingContactActiveIcon,
        to: "/setting/contact",
        include: ["/setting/contact", "/setting/list-box-db", "/setting/mail"],
        permission: {
          organization: ["MASTER", "ADMIN"],
        },
      },
      {
        label: "문의하기",
        icon: SettingSupportIcon,
        activeIcon: SettingSupportActiveIcon,
        to: "/setting/support",
        include: ["/setting/support", "/setting/faq"],
      },
    ],
  },
  {
    label: "API",
    to: "/api/dashboard",
    icon: TemplateIcon,
    activeIcon: ActiveTemplateIcon,
    include: ["/api/getting-started"],
  },
  {
    label: "알림",
    icon: AlertIcon,
    activeIcon: AlertIcon,
    to: "/alert/dashboard",
    include: ["/alert/dashboard"],
    hidden: true,
  },
  {
    label: "공지사항",
    icon: AlertIcon,
    activeIcon: AlertIcon,
    to: "/notice/dashboard",
    include: ["/notice/guide"],
    hidden: true,
  },
];

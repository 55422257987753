import styled from "styled-components";

export const ToolbarStyled = styled.div<{ currentStep?: number }>`
  width: 100%;
  height: 6.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--bg-white);
  box-shadow: ${({ currentStep }) =>
    currentStep !== 5 && "0px 2px 10px 0px #0000000d"};
  border-bottom: 0.1rem solid var(--stroke-light);
  padding: 0 4rem;
  position: relative;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2.4rem;

    &.left {
      height: 6.8rem;
      position: absolute;
      left: 4rem;
      color: var(--text-secondary);

      & > div {
        cursor: pointer;
      }
    }

    &.center {
      .tools {
        display: flex;

        .functions {
          display: flex;
          align-items: center;
          column-gap: 1.6rem;

          .function {
            cursor: pointer;

            &.file {
              cursor: pointer;
              position: relative;

              input {
                cursor: pointer;
                width: 100%;
                position: absolute;
                inset: 0;
                opacity: 0;

                &::-webkit-file-upload-button {
                  visibility: hidden;
                }
              }
            }
          }
        }
      }
    }

    &.right {
      position: absolute;
      right: 4rem;

      & > button {
        min-width: 8.4rem;
      }
    }
  }
`;

const ActionModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
`;

export const PreviewActionModalStyled = styled(ActionModalStyled)`
  & > div {
    display: flex;
    column-gap: 0.4rem;

    &:last-of-type {
      color: var(--red-600);
    }
  }
`;

export const SendActionModalStyled = styled(ActionModalStyled)`
  & > div {
    display: flex;
    column-gap: 0.6rem;

    &::before {
      content: "";
      width: 0.4rem;
      height: 0.4rem;
      margin-top: 1rem;
      border-radius: 100%;
      background-color: var(--grey-200);
    }
  }
`;

export const ScaleControllerStyled = styled.div`
  position: relative;
  width: 100%;
  height: 6.8rem;
  background-color: var(--bg-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);

  & > div {
    width: max-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contentContainer {
    width: max-content;
    background-color: transparent;
  }

  .dropdownContainer {
    top: 6.8rem;
    border-radius: 0.5rem;
  }

  .paraphDetail {
    position: absolute;
    right: 4rem;
  }
`;

export const ContractModeModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  .contractModes {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;

    .contractMode {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      row-gap: 1.2rem;
      border: 0.1rem solid var(--stroke-dark);
      border-radius: 1.2rem;
      cursor: pointer;

      &.disabled {
        border-color: var(--stroke-light);

        * {
          color: var(--text-disabled) !important;
        }

        .animation {
          opacity: 0.5;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;

        & > div:first-of-type {
          display: flex;
          align-items: center;
          column-gap: 1.2rem;

          & > div:last-of-type {
            color: var(--text-middle-emphasis);
          }
        }

        & > div:last-of-type {
          color: var(--text-secondary);
        }
      }

      .animation {
        margin-top: 0.8rem;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--grey-300);
        border-radius: 1.2rem;

        img {
          width: 14rem;
          height: 14rem;
        }
      }

      .button {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

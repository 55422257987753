import React from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { TooltipTrigger } from "./styles";
import styled from "styled-components";

export default function CustomTooltip({
  align,
  side,
  trigger,
  children,
}: {
  align?: "center" | "end" | "start";
  side?: "bottom" | "top" | "right" | "left";
  trigger?: React.ReactNode;
  children?: React.ReactNode;
}) {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={0}>
        <TooltipTrigger className="trigger">{trigger}</TooltipTrigger>
        <RadixTooltip.Portal>
          <CustomTooltipContent align={align} side={side}>
            {children}
          </CustomTooltipContent>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}

const CustomTooltipContent = styled(RadixTooltip.Content)`
  img {
    width: 25.2rem;
  }
`;

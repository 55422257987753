import React, { useState } from "react";
import styled from "styled-components";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import Button from "components/Common/Button/Button";

export type DropdownItemType = React.ComponentProps<typeof Dropdown.Item> & {
  label: string;
};

interface DropdownMenuProps {
  list: DropdownItemType[];
  children: React.ReactNode;
}

const DetailDropdownMenu = ({ list, children }: DropdownMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };
  return (
    <DropDownRoot onOpenChange={handleOpenChange} open={isOpen}>
      <DropDownTrigger>
        <Button
          colorType="ghost"
          size="small"
          style={{ backgroundColor: "transparent" }}
        >
          {children}
        </Button>
      </DropDownTrigger>
      <DropDownContent align="center">
        {list.map(({ label, ...args }) => {
          return (
            <DropDownItem key={label} {...args}>
              {label}
            </DropDownItem>
          );
        })}
      </DropDownContent>
    </DropDownRoot>
  );
};

export default DetailDropdownMenu;

export const DropDownRoot = styled(Dropdown.Root)`
  position: relative;
  padding: 1.1rem 1.4rem;
  outline: none;
`;

export const DropDownTrigger = styled(Dropdown.Trigger)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  white-space: nowrap;
`;

export const DropDownContent = styled(Dropdown.Content)`
  background: var(--bg-white);
  border-radius: 0.5rem;
  height: fit-content;
  padding: 0.8rem;
  z-index: 100;
  position: absolute;
  top: 0;
  right: -10px;

  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
`;

export const DropDownItem = styled(Dropdown.Item)`
  padding: 0.8rem;
  outline: none;
  min-width: 10rem;
  margin-top: 0.8rem;
  cursor: pointer;

  text-align: left;

  &:hover {
    color: var(--text-emphasis);
    background-color: var(--bg-dark);
  }
`;

import AuthHeader from "components/AuthHeader";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { RootLayout } from "./RootLayout";
import Footer from "components/Mobile/Footer";
import BoundaryInViewProvider from "components/Common/BoundaryInView/BoundaryInViewProvider";

const LandingLayout = ({ children }: PropsWithChildren) => {
  return (
    <BoundaryInViewProvider>
      <RootLayout>
        <AuthHeader />
        <MainContent>
          <ContentLayout>{children}</ContentLayout>
        </MainContent>
        <Footer />
      </RootLayout>
    </BoundaryInViewProvider>
  );
};

export default LandingLayout;

const MainContent = styled.div`
  display: flex;
  height: 100%;
  align-items: stretch;
  flex: 1;
  background-color: var(--bg-white);
  overflow: hidden;
`;

const ContentLayout = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

import {
  PatchUserProfileImageRequest,
  PatchUserExtraDetailsRequest,
  PatchUserNameRequest,
  PatchUserPasswordRequest,
  PostUserProfileImagePresignedURLRequest,
  GetUserExtraDetailsResponse,
  GetUserResponse,
  GetUserDetailsResponse,
  PostUserLogoutRequest,
  patchDeleteUserRequest,
} from "models/User";
import getAPIResponseData from "utils/getAPIResponse";

export const patchDeleteUser = ({ withdrawReason }: patchDeleteUserRequest) => {
  return getAPIResponseData({
    url: "/users/withdraw",
    method: "PATCH",
    data: { withdrawReason },
  });
};

export const getUserExtraDetails = () => {
  return getAPIResponseData<GetUserExtraDetailsResponse>({
    url: "/users/extra-details",
    method: "GET",
  });
};

export const getUser = () => {
  return getAPIResponseData<GetUserResponse>({
    url: "/users",
    method: "GET",
  });
};

export const getUserDetails = () => {
  return getAPIResponseData<GetUserDetailsResponse>({
    url: "/users/details",
    method: "GET",
  });
};

export const patchUserProfileImage = ({
  profileImgUrl,
}: PatchUserProfileImageRequest) => {
  return getAPIResponseData({
    url: "/users/profile-images",
    method: "PATCH",
    data: { profileImgUrl },
  });
};

export const patchUserPasswords = ({
  password,
  newPassword,
}: PatchUserPasswordRequest) => {
  return getAPIResponseData({
    url: "/users/passwords",
    method: "PATCH",
    data: { password, newPassword },
  });
};

export const patchUserName = ({ name }: PatchUserNameRequest) => {
  return getAPIResponseData({
    url: "/users/names",
    method: "PATCH",
    data: { name },
  });
};

export const patchUserExtraDetails = ({
  ...req
}: PatchUserExtraDetailsRequest) => {
  return getAPIResponseData({
    url: "/users/extra-details",
    method: "PATCH",
    data: { ...req },
  });
};

export const postUserProfileImagePresignedURL = ({
  fileName,
}: PostUserProfileImagePresignedURLRequest) => {
  return getAPIResponseData<string, PostUserProfileImagePresignedURLRequest>({
    url: "/users/profile-images/presigned-url",
    method: "POST",
    data: { fileName },
  });
};

export const postUserLogout = ({ refreshJwt }: PostUserLogoutRequest) => {
  return getAPIResponseData({
    url: "/users/logout",
    method: "POST",
    data: { refreshJwt },
  });
};

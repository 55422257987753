import Button from "components/Common/Button/Button";
import Input from "components/Common/Input/Input";
import { REGEX } from "config/regex";
import useSignVerifyMutation from "hooks/mutations/sign/useSignVerifyMutation";
import { EmailReq } from "models/Sign";
import { EmailStyled } from "pages/receiver/email";
import React from "react";
import { useController, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function MobileCertificates() {
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<EmailReq>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const { mutate } = useSignVerifyMutation();

  const { field: email } = useController({
    name: "email",
    control,
    rules: {
      required: "true",
    },
  });

  const handleSubmitForm = (data: { email: string }) => {
    const ci = query.get("ci");
    const ri = query.get("ri");

    if (!ci || !ri) return;

    const req = { ...data, ci, ri, verifyType: "EMAIL" as const };

    mutate(
      { verifyType: "EMAIL", data: req },
      {
        onSuccess: (res) => {
          const { accessJwt, contractName } = res.contractInfo;

          if (accessJwt) {
            sessionStorage.setItem("accessJwt", accessJwt);

            navigate("/download/certificates", {
              state: {
                name: contractName || "",
              },
            });
          }
        },
      }
    );
  };

  return (
    <>
      <EmailStyled onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="title-container">
          <div className="title">수신자 본인 확인</div>
          <div className="guide">
            전자문서의 보안을 위해,
            <br />
            해당 문서를 수신한 <span>이메일 주소</span>를 입력해주세요.
          </div>
        </div>
        <div className="input-and-button">
          <Input
            placeholder="이메일을 입력해 주세요."
            width="30rem"
            {...register("email", {
              required: "이메일을 입력해주세요.",
              pattern: {
                value: REGEX.email,
                message: "이메일 형식이 올바르지 않습니다.",
              },
            })}
            value={email.value}
            isError={errors.email ? true : false}
            errorMessage={errors.email?.message}
          />
          <Button
            type="submit"
            colorType="primary"
            size="large"
            disabled={!isValid}
          >
            확인
          </Button>
        </div>
      </EmailStyled>
    </>
  );
}

import styled from "styled-components";

export const StepStyled = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.4rem;
  background-color: ${({ selected }) =>
    selected ? "var(--blue-900)" : "var(--grey-100)"};
  padding: ${({ selected }) => (selected ? "0.8rem 1.2rem" : "0.8rem 1.4rem")};
  color: ${({ selected }) => (selected ? "white" : "var(--text-secondary)")};
  border-radius: ${({ selected }) => (selected ? "20rem" : "10rem")};
`;

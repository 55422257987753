import React from "react";
import { Body3Regular, CaptionRegular } from "components/TextStyle";
import { ContractModeModalStyled } from "components/Toolbar/styles";
import { IPage } from "interfaces/contract";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";
import { UseFormReturn } from "react-hook-form";
import { ConfigFormData } from "pages/template/send/detail/config";
import { useLocation, useParams } from "react-router-dom";
import useUserState from "hooks/recoil/useUserState";
import useTemplateDetailsById from "hooks/queries/template/useTemplateDetailsById";

interface IContractModeModal {
  methods?: UseFormReturn<ConfigFormData, any, undefined>;
  pages?: IPage[];
  checkedType: "normal" | "paraphrase" | null;
  handleChecked: (type: "normal" | "paraphrase") => void;
}

export default function MobileContractModeModal({
  methods,
  pages,
  checkedType,
  handleChecked,
}: IContractModeModal) {
  const { data: remainContractsCreditDto } = useContractRemainCreditsQuery();
  const { state } = useLocation();
  const { templateId } = useParams();
  const [user] = useUserState();
  const { data: templateDetails } = useTemplateDetailsById({
    organizationUuid: user?.organization,
    templateUuid: templateId,
  });
  return (
    <ContractModeModalStyled>
      <div
        style={{
          fontSize: "1.3rem",
          textAlign: "center",
          color: "var(--text-default)",
          marginBottom: "1.6rem",
        }}
      >
        계약서 형식을 선택해주세요.
      </div>
      <div className="contractModes">
        <div
          className="contractMode"
          onClick={() => {
            handleChecked("normal");
          }}
          style={{
            border:
              checkedType === "normal" ? "1px solid var(--stroke-primary)" : "",
          }}
        >
          <div className="text" style={{ gap: "0.4rem" }}>
            <div>
              <p
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                }}
              >
                일반 형식으로 보내기
              </p>
              <Body3Regular
                style={{
                  color: `${
                    remainContractsCreditDto?.remainContractCredit.remainCredits
                      ? "var(--text-middle-emphasis)"
                      : "var(--new-red)"
                  }`,
                }}
              >
                잔여 건수 :{" "}
                {remainContractsCreditDto?.remainContractCredit.remainCredits.toLocaleString(
                  "ko"
                )}
                건
              </Body3Regular>
            </div>
            <CaptionRegular style={{ color: "var(--text-secondary)" }}>
              입력란 배치를 완료한 계약서를 그대로 전송합니다.
            </CaptionRegular>
          </div>
        </div>
        <div
          className={`contractMode${
            (pages?.length as number) <= 1 ||
            state?.contractType === "MULTIPLE" ||
            (methods?.getValues("receivers").length as number) > 1 ||
            (templateDetails?.contractTemplateDetail.pageNum as number) <= 1
              ? " disabled"
              : ""
          }`}
          onClick={() => {
            handleChecked("paraphrase");
          }}
          style={{
            border:
              checkedType === "paraphrase"
                ? "1px solid var(--stroke-primary)"
                : "",
          }}
        >
          <div className="text">
            <div>
              <p
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                }}
              >
                간인 형식으로 보내기
              </p>
              <Body3Regular
                style={{
                  color: `${
                    remainContractsCreditDto?.remainContractCredit.remainCredits
                      ? "var(--text-middle-emphasis)"
                      : "var(--new-red)"
                  }`,
                }}
              >
                잔여 건수 :{" "}
                {remainContractsCreditDto?.remainContractCredit.remainCredits.toLocaleString(
                  "ko"
                )}
                건
              </Body3Regular>
            </div>
            <CaptionRegular style={{ color: "var(--text-secondary)" }}>
              계약서 모든 페이지에 간인을 찍어 전송합니다. 단, 문서가 한
              페이지일 경우는 제외됩니다.
            </CaptionRegular>
          </div>
        </div>
      </div>
    </ContractModeModalStyled>
  );
}

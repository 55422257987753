import React from "react";
import ContentModal from "components/Mobile/Common/Modal/ContentModal";
import UploadStampContent from "./(uploadImageStamp)/UploadStampContent";
import { SubTitle } from "components/TextStyle";

interface UploadStampModalProps {
  closeModal: () => void;
  isOpen: boolean;
  signMode?: boolean;
  sign?: (file: File) => void;
}

const UploadStampModal = ({
  closeModal,
  isOpen,
  sign,
}: UploadStampModalProps) => {
  if (!isOpen) return null;

  return (
    <ContentModal
      handleModalClose={closeModal}
      size="large"
      noTitle
      title="업로드"
      style={{
        maxHeight: "none",
      }}
    >
      <SubTitle style={{ margin: "auto" }}>업로드</SubTitle>
      <UploadStampContent closeModal={closeModal} sign={sign} />
    </ContentModal>
  );
};

export default UploadStampModal;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchTemplateName } from "apis/template";
import { queries } from "config/queryKey";

const useTemplateNameUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchTemplateName,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.template.list._def });
    },
  });
};

export default useTemplateNameUpdateMutation;

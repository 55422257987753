import React from "react";
import ContentModal from "components/Mobile/Common/Modal/ContentModal";
import DrawSignContent from "./DrawSignContent";
import { SubTitle } from "components/TextStyle";

interface DrawSignModalProps {
  closeModal: () => void;
  isOpen: boolean;

  sign?: (file: File) => void;
}

const DrawSignModal = ({ closeModal, isOpen, sign }: DrawSignModalProps) => {
  if (!isOpen) return null;

  return (
    <ContentModal
      handleModalClose={closeModal}
      size="large"
      noTitle
      title="그리기"
      isCloseIcon={false}
    >
      <SubTitle style={{ margin: "auto" }}>그리기</SubTitle>
      <DrawSignContent closeModal={closeModal} signMode sign={sign} />
    </ContentModal>
  );
};

export default DrawSignModal;

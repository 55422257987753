import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useInquiriesQnasQuery = (searchValue: string | null) => {
  return useQuery({
    ...queries.inquiry.qnas(searchValue),
    enabled: searchValue === null || searchValue.length > 1,
  });
};

export default useInquiriesQnasQuery;

import Button from "components/Common/Button/Button";
import TextArea from "components/Common/Input/TextArea";
import ActionModal from "components/Common/Modal/ActionModal";
import { Body1SemiBold } from "components/TextStyle";
import useModal from "hooks/useModal";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function Invalid({
  name,
  date,
  reason,
  prev,
}: {
  name: string;
  date: string;
  reason: string;
  prev?: boolean;
}) {
  const navigate = useNavigate();
  const { openModal, closeModal, isOpen } = useModal();

  return (
    <>
      <InvalidStyled>
        <div>
          <div className="title">거절/취소된 문서입니다.</div>
          <div className="desc">
            <div className="name">
              {name}에 의해 작성이 거절/취소된 문서입니다
            </div>
            <div className="date">{date}</div>
          </div>
        </div>
        <div>
          <Button
            colorType="secondary"
            size="large"
            style={{ width: "24rem", height: "5.6rem" }}
            onClick={openModal}
          >
            <Body1SemiBold>사유 보기</Body1SemiBold>
          </Button>
          <Button
            colorType="primary"
            size="large"
            onClick={() => {
              if (prev)
                navigate("/contract/dashboard/normal", { replace: true });
              else navigate("/", { replace: true });
            }}
            style={{ width: "24rem", height: "5.6rem" }}
          >
            <Body1SemiBold>나가기</Body1SemiBold>
          </Button>
        </div>
      </InvalidStyled>
      {isOpen && (
        <ActionModal
          title="거절/취소 사유 확인"
          handleModalClose={closeModal}
          handleConfirmButton={closeModal}
        >
          <TextArea isDisabled value={reason} noLength />
        </ActionModal>
      )}
    </>
  );
}

const InvalidStyled = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 6rem;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 4rem;

    .title {
      font-size: 4.6rem;
      font-weight: 700;
      line-height: 3.6rem;
      letter-spacing: -0.03em;
      color: white;
    }

    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 0.8rem;
      letter-spacing: -0.03em;

      .name {
        font-size: 2.4rem;
        line-height: 3.6rem;
        color: white;
      }

      .date {
        font-size: 1.8rem;
        line-height: 2.6rem;
        color: var(--text-disabled);
      }
    }

    .expiredAt {
      font-size: 1.8rem;
      line-height: 2.6rem;
      letter-spacing: -0.03em;
      color: var(--text-disabled);
      text-align: center;
    }
  }

  & > div:last-of-type {
    display: flex;
    column-gap: 3rem;
  }
`;

import { useMutation } from "@tanstack/react-query";
import { postPhoneAuth } from "apis/auth";

const usePhoneAuthMutation = () => {
  return useMutation({
    mutationFn: postPhoneAuth,
    onSuccess: (res: any) => {
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const left = (screenWidth - 500) / 2;
      const top = (screenHeight - 600) / 2;

      const popup = window.open(
        "",
        "phoneAuthPopup",
        `width=500,height=600,left=${left},top=${top},menubar=no,toolbar=no,location=no,status=no,scrollbars=yes,resizable=no`
      );

      if (popup) {
        const htmlContent = res?.data || res;

        popup.document.open();
        popup.document.write(htmlContent);
        popup.document.close();

        popup.document.title = "본인인증";
      }
    },
  });
};

export default usePhoneAuthMutation;

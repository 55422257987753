import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as HandlerIcon } from "assets/Contract/icon_handle.svg";
import { Body1Medium, Body1SemiBold } from "components/TextStyle";
import Input from "components/Common/Input/Input";
import Button from "components/Common/Button/Button";
import { FieldArrayWithId, UseFormRegister } from "react-hook-form";
import { ManyReceiverConfigFormData } from "../../Config/ManyReceiverConfigForm";
import { PALETTE } from "config/constant";
import styled from "styled-components";
import useContractRemainCreditsQuery from "hooks/queries/contract/useContractRemainCreditsQuery";

export function MobileSortableItem({
  id,
  idx,
  field,
  register,
  setVerificationModal,
  mode,
  dragOverlay,
}: {
  id: string;
  idx?: number;
  field?: FieldArrayWithId<ManyReceiverConfigFormData, "receivers", "id">;
  register?: UseFormRegister<ManyReceiverConfigFormData>;
  setVerificationModal?: React.Dispatch<
    React.SetStateAction<{
      opened: boolean;
      index: number;
      prevData: {
        password: {
          isUsed: boolean;
          password: string;
        };
        phoneNumber: {
          isUsed: boolean;
        };
      };
    }>
  >;
  mode?: "MANY" | "MANY_SEQ" | null;
  dragOverlay?: boolean;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const { data: remainContractsCreditDto } = useContractRemainCreditsQuery();
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: dragOverlay ? "grabbing" : "grab",
    opacity: isDragging ? 0.5 : 1,
  };
  if (idx === undefined) return;

  return (
    <>
      <div
        className="single-receiver-form-title"
        key={id}
        ref={setNodeRef}
        {...attributes}
        style={style}
      >
        <div className="left">
          <div
            style={{
              width: "2rem",
              aspectRatio: "1/1",
              borderRadius: "100%",
              backgroundColor: PALETTE.receivers[idx],
            }}
          />
          <Body1SemiBold>수신자</Body1SemiBold>
          <LeftSendTitle>
            잔여건수:{" "}
            {(
              (remainContractsCreditDto?.remainContractCredit
                .remainCredits as number) || 0
            ).toLocaleString("ko")}
            건
          </LeftSendTitle>
          {mode === "MANY_SEQ" && (
            <HandlerIcon
              style={{
                marginLeft: "auto",
              }}
              {...listeners}
            />
          )}
        </div>

        <div key={id} className="single-receiver-form-from">
          <FormList>
            <Input
              width="100%"
              placeholder="이름"
              {...register?.(`receivers.${idx}.name` as const)}
            />
            <Input
              width="100%"
              placeholder="이메일"
              {...register?.(`receivers.${idx}.email` as const)}
            />
            <Input
              width="100%"
              placeholder="휴대폰 번호"
              {...register?.(`receivers.${idx}.phoneNumber` as const)}
            />
            <div>
              <Body1Medium>개별인증: </Body1Medium>
              <Button
                colorType="tertiary"
                size="small"
                onClick={() =>
                  setVerificationModal?.((prev) => ({
                    ...prev,
                    opened: true,
                    index: idx,
                  }))
                }
              >
                {(field?.verification.password.isUsed &&
                  field?.verification.password.password) ||
                field?.verification.phoneNumber.isUsed
                  ? "변경"
                  : "설정"}
                하기
              </Button>
            </div>
          </FormList>
        </div>
      </div>
    </>
  );
}
const LeftSendTitle = styled(Body1Medium)`
  color: var(--text-emphasis);
`;
const FormList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  & > * {
    width: 100%;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`;

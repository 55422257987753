import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchNotification } from "apis/notification";
import { queries } from "config/queryKey";

const useNotificationReadUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchNotification,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: queries.notification.today.queryKey,
      });
      console.log(res);
    },
  });
};

export default useNotificationReadUpdate;

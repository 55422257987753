import styled, { css } from "styled-components";

export const DropdownContainer = styled.div<{
  $table?: boolean;
  $isOpen: boolean;
  $isError?: boolean;
  $outline?: boolean;
}>`
  display: flex;
  padding: ${({ $table }) => ($table ? "0" : "1.1rem 1.4rem")};
  width: 100%;
  box-sizing: border-box;
  border-radius: ${(props) => (props.$isOpen ? "0.5rem 0.5rem 0 0" : "0.5rem")};
  border: ${(props) =>
    props.$table || !props.$outline
      ? "none"
      : props.$isError
      ? "1px solid #FA4D56"
      : props.$isOpen
      ? "1px solid var(--grey-400)"
      : "1px solid var(--grey-300)"};
  height: ${({ $table }) => ($table ? "max-content" : "4.6rem")};
  cursor: pointer;
  position: relative;
  background-color: var(--bg-white);

  &:hover {
    border: ${({ $table, $outline }) =>
      $table || !$outline ? "none" : "1px solid var(--grey-400);"};
  }

  .dimmed {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
    cursor: default;
  }

  .contentContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #171d23;
    column-gap: ${({ $table }) => ($table ? "0.4rem" : "")};
    padding: ${({ $table }) => ($table ? "1.6rem 2.4rem" : "0")};
    z-index: 9;
    background-color: var(--bg-white);
    border-radius: 0.5rem;

    .isPlaceholder {
      color: var(--grey-500);
    }

    & > div {
      color: ${({ $table }) => ($table ? "var(--text-secondary)" : "")};
    }
  }

  .downIcon {
    height: 2rem;
  }
`;

export const DropdownItemContainer = styled.div<{
  $table?: boolean;
  $outline?: boolean;
  $isScrollBarView?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 5px 5px;
  border: ${({ $table, $outline }) =>
    $table || !$outline ? "none" : "0.1rem solid var(--grey-400)"};
  border-top: 0;
  background: var(--bg-white);
  width: calc(100% + 0.2rem);
  height: fit-content;
  position: absolute;
  left: -0.1rem;
  top: 4.5rem;
  z-index: 10;
  padding: ${({ $table }) => ($table ? "0.8rem" : "")};
  row-gap: ${({ $table }) => ($table ? "0.8rem" : "")};
  box-sizing: border-box;
  max-height: 30rem;
  overflow-y: scroll;

  ${({ $isScrollBarView }) =>
    !$isScrollBarView &&
    css`
      -ms-overflow-style: none; /*IE, Edge*/
      scrollbar-width: none; /*Firefox*/
      ::-webkit-scrollbar {
        display: none; /*Chrome, Safari, Opera*/
        width: 0px;
      }
    `}
`;

export const DropdownItem = styled.div<{
  $table?: boolean;
  $isSelected: boolean;
}>`
  display: flex;
  padding: ${({ $table }) => ($table ? "0.8rem" : "1.2rem 1.4rem")};
  width: 100%;
  height: ${({ $table }) => ($table ? "max-content" : "4.8rem")};
  box-sizing: border-box;
  align-items: center;
  background-color: ${({ $table, $isSelected }) =>
    $table && $isSelected && "var(--bg-dark)"};

  &:hover {
    background: var(--Background-Color-Background-Light, #fdfdfd);
    border-radius: ${({ $table }) => ($table ? "0.4rem" : "")};
  }

  .itemValue {
    color: ${(props) =>
      props.$table
        ? "var(--text-secondary)"
        : props.$isSelected
        ? "var(--primary-600)"
        : "var(--grey-600)"};
  }
`;

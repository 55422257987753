import React, { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import { DefaultModalContainer } from "./MobileModalStyledComponents";
import ModalLayout from "./ModalLayout";
import { SubTitle, Body3Regular } from "components/TextStyle";
import CloseIcon from "assets/common/icon_close.svg";
import Button from "components/Mobile/Common/Button/Button";
import { Body1SemiBold } from "components/TextStyle";
interface DefaultModalProps {
  handleModalClose: () => void;
  handleConfirmButton?: MouseEventHandler<HTMLButtonElement> | null;
  confirmText?: string;
  title: string;
  subTitle?: string;
  children: ReactNode;

  disableCloseWithOutsideClickOrEsc?: boolean;
}

const DefaultModal = ({
  title,
  subTitle = "",
  children,
  handleModalClose,
  handleConfirmButton,
  confirmText,
  disableCloseWithOutsideClickOrEsc = false,
}: DefaultModalProps) => {
  return (
    <ModalLayout
      isWhole={true}
      handleModalClose={handleModalClose}
      disableCloseWithOutsideClickOrEsc={disableCloseWithOutsideClickOrEsc}
    >
      <DefaultModalContainer>
        <img
          src={CloseIcon}
          alt="closeIcon"
          className="closeIcon"
          onClick={handleModalClose}
        />
        <div className="headerContainer">
          <Title>{title}</Title>
          {subTitle && <Caption>{subTitle}</Caption>}
        </div>

        <div className="contentContainer">{children}</div>

        <div className="buttonContainer">
          {handleConfirmButton && (
            <Button
              colorType="primary"
              size="large"
              handleClick={handleConfirmButton}
              type="button"
            >
              <Body1SemiBold>{confirmText || "확인"}</Body1SemiBold>
            </Button>
          )}
        </div>
      </DefaultModalContainer>
    </ModalLayout>
  );
};

export default DefaultModal;

const Title = styled(SubTitle)`
  margin-bottom: 1.2rem;
`;

const Caption = styled(Body3Regular)`
  color: var(--text-secondary);
`;

import { Board } from "components/Common/Board/Board";
import React from "react";
import styled from "styled-components";
import BoundaryInViewProvider from "components/Common/BoundaryInView/BoundaryInViewProvider";
import { ReactComponent as KebabIcon } from "assets/common/20px/icon_kebab_row.svg";
import useTemplateDetailInfoQuery from "hooks/queries/template/useTemplateDetailInfoQuery";
import useUserState from "hooks/recoil/useUserState";
import { useNavigate, useParams } from "react-router-dom";
import TemplateMobileInfo from "./TemplateMobileInfo";
import { Body1SemiBold } from "components/TextStyle";
import DetailDropdownMenu from "./DetailDropdownMenu";
import useModal from "hooks/useModal";
import TemplateDeleteMobileModal from "components/Mobile/template/Modals/TemplateDeleteMobileModal";
import useDeleteTemplateMutation from "hooks/mutations/template/useDeleteTemplateMutation";
import { getTemplateByIdById } from "apis/template";
import saveFile from "utils/saveFile";

const TemplateDetailMobilePage = () => {
  const navigate = useNavigate();
  const [user] = useUserState();
  const { templateId } = useParams();
  const { mutate: deleteMutate } = useDeleteTemplateMutation();
  const {
    isOpen: deleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal();

  const { data } = useTemplateDetailInfoQuery({
    organizationId: user.organization,
    templateId: templateId as string,
  });

  if (!data) return;

  const handleDelete = () => {
    deleteMutate({
      organizationId: user.organization ?? "",
      organizationGroupId: user.organizationGroup,
      templateIdList: [templateId as string],
    });
    closeDeleteModal();
    navigate(-1);
  };

  const handleDownload = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const result = await getTemplateByIdById({
      organizationId: user.organization,
      organizationGroupId: user.organizationGroup,
      templateId: templateId as string,
    });

    saveFile(result.data, {
      fileName: "test",
      // result.headers["content-disposition"].split(
      //   "filename="
      // )[1],
    });
  };

  return (
    <>
      {deleteModalOpen && (
        <TemplateDeleteMobileModal
          closeModal={closeDeleteModal}
          handleDelete={handleDelete}
          selectedCnt={1}
        />
      )}

      <BoundaryInViewProvider>
        <TemplateDetailHeader>
          <div>
            <Body1SemiBold>
              {data.contractTemplateDetails.templateName}
            </Body1SemiBold>
          </div>

          <DetailDropdownMenu
            list={
              data.contractTemplateDetails.templateStatus === "TEMP"
                ? [
                    {
                      label: "문서보기",
                      onClick: () => {
                        navigate(`/template/send/${templateId}/view`);
                      },
                    },
                    {
                      label: "삭제",
                      onClick: (e) => {
                        e.stopPropagation();
                        openDeleteModal();
                      },
                    },
                  ]
                : [
                    {
                      label: "문서보기",
                      onClick: () => {
                        navigate(`/template/send/${templateId}/view`);
                      },
                    },
                    {
                      label: "원본 다운로드",
                      onClick: handleDownload,
                    },
                    {
                      label: "삭제",
                      onClick: (e) => {
                        e.stopPropagation();
                        openDeleteModal();
                      },
                    },
                  ]
            }
          >
            <KebabIcon />
          </DetailDropdownMenu>
        </TemplateDetailHeader>
        <TemplateDetailLayout>
          <TemplateMobileInfo
            createAt={data.contractTemplateDetails.createdAt}
            createdUserName={data.contractTemplateDetails.userName}
            email={data.contractTemplateDetails.userEmail}
            status={data.contractTemplateDetails.templateStatus}
            pageNum={data.contractTemplateDetails.pageNum}
            receiverCnt={data.contractTemplateDetails.receiverCnt}
            organization={user.organization}
          />
        </TemplateDetailLayout>
      </BoundaryInViewProvider>
    </>
  );
};

export default TemplateDetailMobilePage;

const TemplateDetailLayout = styled(Board)`
  padding: 1.6rem;
  gap: 1rem;
  max-height: 102.4rem;
  background-color: var(--bg-dark);
`;

const TemplateDetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 2.4rem 1.6rem 1.2rem;
  > div {
    display: flex;
    align-items: center;
  }
`;

import {
  DeleteDirectURLRequest,
  GetDirectUrlByIdRequest,
  GetDirectUrlByIdResponse,
  GetDirectUrlManagementRequest,
  GetDirectUrlManagementResponse,
  GetDirectUrlParticipantsInfoRequest,
  GetDirectUrlParticipantsInfoResponse,
  GetDirectUrlsRequest,
  GetDirectUrlsResponse,
  PatchDirectUrlData,
  PatchDirectUrlExpirationRequest,
  PatchDirectUrlRequest,
} from "models/Contract";
import getAPIResponseData from "utils/getAPIResponse";

// direct url 삭제 API
export const deleteDirectURLById = ({
  organizationId,
  contractDirectUrlId,
  organizationGroupId,
}: DeleteDirectURLRequest) => {
  return getAPIResponseData({
    url: `/contracts/direct-urls/${organizationId}/${contractDirectUrlId}`,
    method: "DELETE",
    params: {
      organizationGroupUuid: organizationGroupId,
    },
  });
};

// Direct URL 수정 정보 조회 API
export const getDirectUrlById = ({
  organizationId,
  contractDirectUrlId,
  organizationGroupId,
}: GetDirectUrlByIdRequest) => {
  return getAPIResponseData<GetDirectUrlByIdResponse>({
    url: `/contracts/direct-urls/${organizationId}/${contractDirectUrlId}`,
    method: "GET",
    params: {
      organizationGroupUuid: organizationGroupId,
    },
  });
};

// Direct URL 계약 목록 조회 API
export const getDirectUrls = ({
  organizationId,
  organizationGroupId,
  keyword,
  page,
  size,
  searchType,
  contractStatus,
}: GetDirectUrlsRequest) => {
  return getAPIResponseData<GetDirectUrlsResponse>({
    url: `/contracts/direct-urls/${organizationId}`,
    method: "GET",
    params: {
      organizationGroupUuid: organizationGroupId,
      keyword: keyword ? keyword : null,
      page,
      size,
      searchType,
      contractStatus,
    },
  });
};

// Direct URL 작성자 보기 API
export const getDirectUrlParticipantsInfo = ({
  organizationId,
  organizationGroupId,
  contractDirectUrlId,
  contractStatus = "ALL",
  receivedUserName,
  page,
  size,
}: GetDirectUrlParticipantsInfoRequest) => {
  const params: {
    organizationGroupUuid?: string;
    receivedUserName?: string;
    page: number;
    size: number;
    contractStatus: GetDirectUrlParticipantsInfoRequest["contractStatus"];
  } = {
    page,
    size,
    contractStatus,
  };

  if (organizationGroupId) {
    params.organizationGroupUuid = organizationGroupId;
  }

  if (receivedUserName) {
    params.receivedUserName = receivedUserName;
  }

  return getAPIResponseData<GetDirectUrlParticipantsInfoResponse>({
    url: `/contracts/direct-urls/${organizationId}/${contractDirectUrlId}/participants-info`,
    method: "GET",
    params,
  });
};

// Direct Url 관리 정보 조회 API
export const getDirectUrlManagement = ({
  organizationId,
  organizationGroupId,
  contractDirectUrlId,
}: GetDirectUrlManagementRequest) => {
  return getAPIResponseData<GetDirectUrlManagementResponse>({
    url: `/contracts/direct-urls/${organizationId}/${contractDirectUrlId}/managements`,
    method: "GET",
    params: {
      organizationGroupUuid: organizationGroupId,
    },
  });
};
// Direct URL 수정 API
export const patchDirectUrl = ({
  organizationId,
  contractDirectUrlId,
  organizationGroupId,

  urlName,
  urlUsageCount,
  expiredAt,
}: PatchDirectUrlRequest & PatchDirectUrlData) => {
  return getAPIResponseData<GetDirectUrlByIdResponse, PatchDirectUrlData>({
    url: `/contracts/direct-urls/${organizationId}/${contractDirectUrlId}`,
    method: "PATCH",
    params: {
      organizationGroupUuid: organizationGroupId,
    },
    data: {
      urlName,
      urlUsageCount,
      expiredAt,
    },
  });
};

export const patchDirectUrlExpiration = ({
  organizationId,
  contractDirectUrlId,
  organizationGroupId,
}: PatchDirectUrlExpirationRequest) => {
  return getAPIResponseData({
    url: `/contracts/direct-urls/${organizationId}/${contractDirectUrlId}/expirations`,
    method: "PATCH",
    params: {
      organizationGroupUuid: organizationGroupId,
    },
  });
};

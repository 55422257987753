import React from "react";
import { StepStyled } from "./styles";
import { Body3Regular, Body3SemiBold } from "components/TextStyle";
import { ReactComponent as CheckArrow } from "assets/common/16px/icon_check_arrow.svg";

export default function Step({
  selected,
  step,
  isLastStep,
  label,
}: {
  selected: boolean;
  step: number;
  isLastStep: boolean;
  label: string;
}) {
  return (
    <>
      <StepStyled selected={selected}>
        {!selected ? (
          <Body3SemiBold>{step}</Body3SemiBold>
        ) : (
          <>
            <CheckArrow />
            <Body3Regular>{label}</Body3Regular>
          </>
        )}
      </StepStyled>
      {selected && !isLastStep && <div className="bar" />}
    </>
  );
}

interface PlanType {
  [key: string]: string;
}

export const PLAN: PlanType = {
  Free: "다양한 기능을 30일 동안 마음껏 이용해 보세요",
  Basic: "월 5건 이하의 계약 체결이 필요한 개인 또는 1인 사업자용 요금제",
  Standard: "5명 내외의 인원이 계약・관리를 진행하는 기업용 요금제",
  Premium: "Standard 전 기능 외에 간인 서비스",
};

type BottomTextItemType = {
  text?: string;
  gray?: string;
  bold?: string;
  tooltip?: string;
};

type BottomTextType = {
  [key: string]: BottomTextItemType[];
};

export const PLAN_BOTTOM_TEXT: BottomTextType = {
  Free: [
    {
      text: "문서 100건",
      gray: "(무료체험 기간 한정)",
    },
    {
      text: `카카오톡 알림톡/SMS 알림 무제한`,
    },
    {
      text: "일괄작성・대량전송",
    },
    {
      text: "링크 서명",
    },
    {
      text: "그룹 및 권한 설정",
    },
  ],
  Basic: [
    {
      text: "개인용",
    },
    {
      text: "서명 요청 월5건",
    },
    {
      text: "템플릿",
      bold: "1개",
    },
    {
      bold: "대량전송",
      tooltip: `동일한 양식의 문서를 여러 서명자에게 전송할 수 있습니다.\n수신자 정보를 엑셀 파일로 업로드 하여 각 수신자별로 필드값을 맞춤 입력하여 전송할 수 있습니다.`,
    },
    {
      bold: "링크 서명",
      tooltip:
        "불특정 다수가 서명해야 하는 경우 URL을 공유하여 손쉽게 서명할 수 있습니다.",
    },
    {
      text: "문서당",
      bold: "최대 10명 서명",
      tooltip: "하나의 계약에 최대 10명까지 참여할 수 있습니다.",
    },
    {
      text: "문서당 첨부파일",
      bold: "1개",
    },
    {
      text: "실시간 고객 지원",
    },
  ],
  Standard: [
    {
      text: "서명 요청 연",
      bold: "360건",
      tooltip: `서명 요청 건수는 계약서 발송 횟수를 의미합니다.\n\n최대 360건까지 요청할 수 있으며, 일반 전송 / 링크서명 / 대량 전송 중\n원하는 방식을 선택하여 이용할 수 있습니다. `,
    },
    {
      text: "템플릿",
      bold: "무제한",
      tooltip: `서명이나 텍스트 등의 작성란을 미리 설정한 문서 양식(템플릿)을 만들어 두면,\n필요할 때 바로 계약 문서를 전송할 수 있습니다.`,
    },
    {
      bold: "대량전송",
      tooltip: `동일한 양식의 문서를 여러 서명자에게 전송할 수 있습니다.\n수신자 정보를 엑셀 파일로 업로드 하여 각 수신자별로 필드값을 맞춤 입력하여 전송할 수 있습니다.`,
    },
    {
      bold: "링크 서명",
      tooltip:
        "불특정 다수가 서명해야 하는 경우 URL을 공유하여 손쉽게 서명할 수 있습니다.",
    },
    {
      text: "문서당",
      bold: "최대 10명 서명",
      tooltip: "하나의 계약에 최대 10명까지 참여할 수 있습니다.",
    },
    {
      text: "문서당 첨부파일",
      bold: "10개",
      tooltip:
        "하나의 계약에 최대 10개의 첨부파일(별첨 문서)을 추가할 수 있습니다.",
    },
    {
      bold: "개인 연락처",
      tooltip:
        "연락처와 이메일을 저장해 두면, 계약 문서를 보낼 때 수신자 정보를 쉽게 입력할 수 있습니다.",
    },
    {
      bold: "계약 리마인드",
      tooltip: "수신자가 계약을 놓친 경우 계약 문서를 재전송할 수 있습니다.",
    },
    {
      bold: "문서 관련 정보 추출",
      tooltip:
        "문서를 작성한 발신자, 수신자의 정보 및 상태값을 엑셀 파일로 추출할 수 있습니다.",
    },
    {
      bold: "감사 로그",
      tooltip: `시스템 활동을 실시간으로 기록하여 기업 내외부에서 발생한 모든 활동을 명확하게 확인할 수 있습니다.\n이를 통해 보안 위협을 빠르게 식별하고 적시에 대응할 수 있습니다.`,
    },
    {
      bold: "Footer 설정",
      tooltip:
        "계약 전송 시 메일 내에 하단 영역에 Footer를 설정하여 기업에 대한 정보를 제공할 수 있습니다.",
    },
    {
      text: "실시간 고객 지원",
    },
  ],
  Premium: [
    {
      text: "서명 요청 연",
      bold: "360건",
      tooltip: `서명 요청 건수는 계약서 발송 횟수를 의미합니다.\n\n최대 360건까지 요청할 수 있으며, 일반 전송 / 링크서명 / 대량 전송 중\n원하는 방식을 선택하여 이용할 수 있습니다. `,
    },
    {
      text: "템플릿",
      bold: "무제한",
      tooltip: `서명이나 텍스트 등의 작성란을 미리 설정한 문서 양식(템플릿)을 만들어 두면,\n필요할 때 바로 계약 문서를 전송할 수 있습니다.`,
    },
    {
      bold: "대량전송",
      tooltip: `동일한 양식의 문서를 여러 서명자에게 전송할 수 있습니다.\n수신자 정보를 엑셀 파일로 업로드 하여 각 수신자별로 필드값을 맞춤 입력하여 전송할 수 있습니다.`,
    },
    {
      bold: "링크 서명",
      tooltip:
        "불특정 다수가 서명해야 하는 경우 URL을 공유하여 손쉽게 서명할 수 있습니다.",
    },
    {
      text: "문서당",
      bold: "최대 10명 서명",
      tooltip: "하나의 계약에 최대 10명까지 참여할 수 있습니다.",
    },
    {
      text: "문서당 첨부파일",
      bold: "10개",
      tooltip:
        "하나의 계약에 최대 10개의 첨부파일(별첨 문서)을 추가할 수 있습니다.",
    },
    {
      bold: "개인 연락처",
      tooltip:
        "연락처와 이메일을 저장해 두면, 계약 문서를 보낼 때 수신자 정보를 쉽게 입력할 수 있습니다.",
    },
    {
      bold: "계약 리마인드",
      tooltip: "수신자가 계약을 놓친 경우 계약 문서를 재전송할 수 있습니다.",
    },
    {
      bold: "문서 입력값 추출",
      tooltip:
        "문서를 작성한 발신자, 수신자의 정보 및 상태값을 엑셀 파일로 추출할 수 있습니다. ",
    },
    {
      bold: "감사 로그",
      tooltip: `시스템 활동을 실시간으로 기록하여 기업 내외부에서 발생한 모든 활동을 명확하게 확인할 수 있습니다.\n이를 통해 보안 위협을 빠르게 식별하고 적시에 대응할 수 있습니다.`,
    },
    {
      bold: "Footer 설정",
      tooltip:
        "계약 전송 시 메일 내에 하단 영역에 Footer를 설정하여 기업에 대한 정보를 제공할 수 있습니다.",
    },
    {
      bold: "간인 서비스",
      tooltip: `1:1 계약 시 간인 서명을 추가하여 계약서를 수신자에게 전송할 수 있으며,\n수신자는 간인 서명이 적용된 인증서 사본을 받을 수 있습니다.\n간인 서비스를 통해 계약의 신뢰성을 높이고 안전하고 효율적인 계약 체결이 가능합니다.`,
    },
    {
      text: "실시간 고객 지원",
    },
  ],
};

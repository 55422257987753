import React, { useState, useMemo, useEffect } from "react";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import ProfileIcon from "assets/Header/icon_profile.svg";
import DownIcon from "assets/common/36px/icon_down_large.svg";
import styled from "styled-components";
import {
  Body3Regular,
  Body3SemiBold,
  CaptionRegular,
} from "components/TextStyle";
import useUserDetailQuery from "hooks/queries/user/useUserDetailQuery";
import { device } from "components/styles";
import AccordionList from "components/Mobile/Common/Accodion/Accodion";

import useOrganizationListQuery from "hooks/queries/organization/useOrganizationListQuery";
import useOrganizationGroupListQuery from "hooks/queries/organizationGroup/useOrganizationGroupListQuery";
import useUserState from "hooks/recoil/useUserState";
import useSignOutMutation from "hooks/mutations/auth/useSignOutMutation";

const UserDropDownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { mutate: signOutMutate } = useSignOutMutation();
  const { data } = useUserDetailQuery();
  const [user, setOrganizationIndex, setOrganizationGroupIndex] =
    useUserState();

  const { data: organizationListData } = useOrganizationListQuery();
  const organizationListDTO = useMemo(() => {
    return (
      organizationListData.organizationList.map((organization) => ({
        label: organization.companyName,
        value: organization.organizationUuid,
      })) || []
    );
  }, [organizationListData]);

  const { data: organizationGroupListData } = useOrganizationGroupListQuery(
    user?.organization
  );

  const organizationGroupListDTO = useMemo(() => {
    const baseList = organizationGroupListData?.organizationGroupList.map(
      (group) => ({
        label: group.groupName,
        value: group.organizationGroupUuid,
      })
    );

    return [{ label: "그룹 없음", value: "" }, ...(baseList ?? [])];
  }, [organizationGroupListData]);

  useEffect(() => {
    setIsOpen(false);
  }, [user.organization]);

  return (
    <DropDownRoot open={isOpen} onOpenChange={setIsOpen}>
      <DropDownTrigger asChild>
        <ProfileButton>
          <ProfileName>{data?.name.slice(0, 1)}</ProfileName>
          <img className="down-icon" src={DownIcon} alt="down" />
        </ProfileButton>
      </DropDownTrigger>
      {isOpen && <Backdrop onClick={() => setIsOpen(false)} />}
      <DropDownContent align="end" alignOffset={-60} sideOffset={30}>
        <div className="user-drop-down-profile">
          <img src={data?.profileImageUrl ?? ProfileIcon} alt="profile" />
          <div>
            <Body3SemiBold>{data?.name}</Body3SemiBold>
            <CaptionRegular style={{ color: "var(--text-secondary)" }}>
              {data?.email}
            </CaptionRegular>
          </div>
        </div>
        <div className="association">
          <button className="logout-container" onClick={() => signOutMutate()}>
            <Body3Regular className="logout-text">로그아웃</Body3Regular>
          </button>
          <AccordionList
            list={organizationListDTO}
            onSelect={(value: string) => {
              const organizationIndex =
                organizationListData?.organizationList.findIndex(
                  (i) => i.organizationUuid === value
                );
              setOrganizationIndex(organizationIndex);
            }}
          />
          <AccordionList
            list={organizationGroupListDTO}
            onSelect={(value: string) => {
              const organizationGroupIndex =
                organizationGroupListData?.organizationGroupList.findIndex(
                  (i) => i.organizationGroupUuid === value
                );
              setOrganizationGroupIndex(organizationGroupIndex ?? 0);
            }}
          />
        </div>
      </DropDownContent>
    </DropDownRoot>
  );
};

export default UserDropDownMenu;

const ProfileButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 1.6rem;
  z-index: 1;

  background-color: var(--grey-200);

  width: 6.4rem;
  height: 3.2rem;

  .down-icon {
    width: 3.2rem;
    height: 3.2rem;
  }
`;

const ProfileName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background: var(--primary-200);

  width: 3.2rem;
  height: 3.2rem;
`;

const DropDownRoot = styled(Dropdown.Root)`
  position: relative;
  padding: 1.1rem 1.4rem;
  outline: none;
`;

const DropDownTrigger = styled(Dropdown.Trigger)`
  position: relative;
  padding: 1.1rem 1.4rem;
  display: flex;
  white-space: nowrap;
`;

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 102;
`;

const DropDownContent = styled(Dropdown.Content)`
  background: var(--bg-white);
  border-radius: 0.5rem;
  height: 100%;
  padding: 1rem;
  z-index: 103;

  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  @media ${device.md}, ${device.sm} {
    width: 28rem;
  }

  @media ${device.xs} {
    width: 20rem;
  }

  .user-drop-down-profile {
    display: flex;
    gap: 1rem;

    > img {
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  .logout-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    padding: 1.2rem 2rem;

    color: var(--grey-500);
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    width: 100%;
    margin: 0;
  }
`;

export const NOTIFICATION_TYPE_MAP = {
  INVITE_SUCCESS: {
    text: "회원가입",
    link: "/setting/member",
  },
  INVITE_EXPIRED: {
    text: "초대 만료",
    link: "/setting/member",
    //TODO: 초대된 맴버에 대한 url정보가 있어야함
  },
  ORG_MEMBER_WITHDRAW: {
    text: "조직 탈퇴",
    link: "/setting/member",
    //TODO: 조직 탈퇴에 대한 url정보가 있어야함
  },
  SEND_CONTRACT: {
    text: "서명 요청 (조직에서 발송)",
    link: "/contract/dashboard/normal",
  },
  CONTRACT_RECEIVE: {
    text: "서명 요청 (조직 관리자에게 메일 도착)",
    link: "/contract/dashboard/normal",
  },
  CONTRACT_REFUSE: {
    text: "작성 거절",
    link: "/contract/dashboard/normal",
  },
  CONTRACT_COMPLETE: {
    text: "서명 완료",
    link: "/contract/dashboard/normal",
    //TODO: 추후 계약 type에 따라서 url정보가 달라져야함
  },
  DIRECT_URL_CONFIRM_REQ: {
    text: "승인 대기",
    link: "/contract/dashboard/direct-url",
  },
  DIRECT_URL_CONFIRM_REFUSE: {
    text: "승인 취소",
    link: "/contract/dashboard/direct-url",
  },
  DIRECT_URL_CONFIRM_ALLOW: {
    text: "승인 완료",
    link: "/contract/dashboard/direct-url",
  },
  CONTRACT_CANCEL: {
    text: "서명 요청 취소",
    link: "/contract/dashboard/normal",
  },
};

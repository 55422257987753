import styled from "styled-components";

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  height: 100%;

  container-type: inline-size;
`;

export const LayoutTitleContainer = styled.div`
  display: flex;
  gap: 1.8rem;
  align-items: center;

  .backIcon {
    width: 3.6rem;
    margin-right: -1.5rem;
    cursor: pointer;
  }

  .titleItemContainer {
    display: flex;
    gap: 1.8rem;
    align-items: center;

    .line {
      width: 0.1rem;
      background-color: var(--grey-300);
      height: 1.2rem;
    }

    .title {
      color: var(--grey-500);
      cursor: pointer;
    }

    .active {
      color: #171d23;
    }
  }
`;

export const LayoutContentContainer = styled.div`
  border-radius: 2rem;
  background: var(--bg-white);
  width: 100%;
  box-sizing: border-box;
  min-height: 100%;
  padding: 8rem;
`;

export const SupportLayoutContentContainer = styled(LayoutContentContainer)`
  padding: 10rem 0 8rem;
`;

import styled from "styled-components";

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.6rem 7rem 1.6rem;
  min-height: 100%;
  overflow: auto;

  background-color: var(--bg-dark);
`;

import React, { useState } from "react";
import styled from "styled-components";
import { DraggableStyled } from "./styles";
import { Body3Regular } from "components/TextStyle";
import { ReactComponent as DeleteIcon } from "assets/common/24px/icon_close_default.svg";
import { ReactComponent as LinesIcon } from "assets/common/20px/icon_square_hangul.svg";
import { IField, IPage } from "interfaces/contract";
import { Field } from "pages/contract/send";
import RequiredOption from "components/Contract/Options/Required";
import FontSizeOption from "components/Contract/Options/FontSize";
import AlignOption from "components/Contract/Options/Align";
import Resizable from "components/Resizable";
import { TextAlign } from "models/Contract";

interface LongTextProps extends React.HTMLAttributes<HTMLDivElement> {
  edit?: boolean;
  complete?: boolean;
  id: string;
  type: "sender" | "receiver";
  fields: IField[];
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  selectedPageId: string;
  setSelectedFieldId?: React.Dispatch<React.SetStateAction<string>>;
  selectedFieldId?: string;
  onDeleteHandler: (id: string) => void;
  selectField?: (id: string, type: Field) => void;
  scale: number;
  align?: ({
    type,
    align,
    all,
  }: {
    type: Field;
    align?: TextAlign | undefined;
    all?: boolean | undefined;
  }) => void;
  pageContainerRef?: any;
  currentSize: { w: number; h: number };
  handleMouseDown: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>,
    direction: string
  ) => void;
  handleMouseMove: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>
  ) => void;
  handleMouseUp: () => void;
  onChangeHandler?: (id: string, value: string) => void;
  newField?: boolean;
  isMobile?: boolean;
}

export default function LongText({
  edit = false,
  complete = false,
  id,
  type,
  fields,
  pages,
  setPages,
  selectedPageId,
  setSelectedFieldId,
  selectedFieldId,
  onDeleteHandler,
  selectField,
  scale,
  draggable,
  align,
  pageContainerRef,
  currentSize,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  onChangeHandler,
  newField,
  isMobile,
  ...args
}: LongTextProps) {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <LongTextStyled
      id={id}
      type={type}
      color={
        type === "sender"
          ? "#666F7B"
          : (fields.find((field) => `${field.fieldType}:${field.id}` === id)
              ?.writer?.color as string)
      }
      required={
        !!fields.find((field) => `${field.fieldType}:${field.id}` === id)
          ?.required
      }
      draggable={draggable}
      scale={scale}
      align={
        fields.find((field) => `${field.fieldType}:${field.id}` === id)?.align
      }
      fontSize={
        fields.find((field) => `${field.fieldType}:${field.id}` === id)?.size
      }
      complete={complete}
      newField={newField}
      isMobile={isMobile}
      {...args}
    >
      <div className="wrapper">
        <div className="field-container">
          <div
            className="container longText"
            onClick={(e) => {
              e.stopPropagation();
              selectField && selectField(id, id.split(":")[0] as Field);
            }}
            style={{
              width: `${(currentSize.w / 10) * scale}rem`,
              height: isMobile
                ? "1.4rem"
                : `${(currentSize.h / 10) * scale}rem`,
            }}
          >
            {edit ? (
              <textarea
                style={{
                  fontSize: isMobile ? "0.6rem" : "",
                }}
                placeholder={
                  complete
                    ? ""
                    : fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.description || ""
                }
                value={
                  fields.find(
                    (field) => `${field.fieldType}:${field.id}` === id
                  )?.value
                }
                onChange={(e) =>
                  onChangeHandler && onChangeHandler(id, e.target.value)
                }
                disabled={complete}
              />
            ) : (
              <>
                <LinesIcon />
                {isEdit ? (
                  <textarea
                    value={
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.name
                    }
                    style={{
                      fontSize: `${
                        +(fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.size as string) / 10
                      }rem`,
                      lineHeight: 1.4285714286,
                      color: "var(--text-secondary)",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsEdit(false);
                      }
                    }}
                    onChange={(e) => {
                      setPages((prev) =>
                        prev.map((page) => {
                          if (
                            page.fields
                              .map((field) => `${field.fieldType}:${field.id}`)
                              .includes(id)
                          ) {
                            return {
                              ...page,
                              fields: page.fields.map((field) => {
                                if (`${field.fieldType}:${field.id}` === id) {
                                  return { ...field, name: e.target.value };
                                } else return { ...field };
                              }),
                            };
                          } else return { ...page };
                        })
                      );
                    }}
                  />
                ) : (
                  <Body3Regular
                    style={{
                      fontSize: `${
                        +(fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.size as string) / 10
                      }rem`,
                      lineHeight: 1.4285714286,
                    }}
                    onDoubleClick={() => setIsEdit((prev) => !prev)}
                  >
                    {
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.name
                    }
                  </Body3Regular>
                )}
              </>
            )}
          </div>
          {draggable && draggable && id === selectedFieldId && (
            <Resizable
              pages={pages}
              setPages={setPages}
              selectedFieldId={selectedFieldId}
              selectedPageId={selectedPageId}
              handleMouseUp={handleMouseUp}
              handleMouseDown={handleMouseDown}
              handleMouseMove={handleMouseMove}
              color={
                type === "sender"
                  ? "#666F7B"
                  : !!fields.find(
                      (field) => `${field.fieldType}:${field.id}` === id
                    )?.required
                  ? "red"
                  : "var(--blue-500)"
              }
              scale={scale}
            />
          )}
        </div>
        {draggable && id === selectedFieldId && (
          <DeleteIcon onClick={() => onDeleteHandler(id)} />
        )}
      </div>
      {draggable && selectedFieldId === id && (
        <div className="options" onClick={(e) => e.stopPropagation()}>
          <div className="option">
            <FontSizeOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="option">
            <AlignOption
              alignFunc={align}
              type="LONGTEXT"
              selected={
                fields.find((field) => `${field.fieldType}:${field.id}` === id)
                  ?.align || "LEFT"
              }
            />
          </div>
          <div className="option">
            <RequiredOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
        </div>
      )}
    </LongTextStyled>
  );
}

const LongTextStyled = styled(DraggableStyled)`
  .wrapper {
    .container {
      padding: ${({ scale, isMobile }) =>
        isMobile ? "0" : `${(scale as number) * 1}rem`};
      box-sizing: border-box;
      display: flex;
      column-gap: 0.4rem;
      border-radius: ${({ scale }) => (scale ? `${scale * 0.4}rem` : "0.4rem")};
      color: var(--text-secondary);
    }
  }

  .options {
    .option {
      .dropdown {
        width: max-content;

        .contentContainer {
          justify-content: flex-start;
          padding: 0;
        }
      }

      .alignOptions {
        display: flex;
        align-items: center;
        column-gap: 0.4rem;
      }
    }
  }
`;

import React, { useState } from "react";
import { Body1Regular, Body1SemiBold, Title1 } from "components/TextStyle";
import { SenderSignatureModalStyled } from "pages/contract/send/styles";
import { ReactComponent as CloseIcon } from "assets/common/32px/icon_close_large.svg";
import { Link } from "react-router-dom";
import Button from "components/Common/Button/Button";
import useSignatureQuery from "hooks/queries/signature/useSignatureQuery";
import { ReactComponent as RefreshIcon } from "assets/common/30px/icon_refresh.svg";

export default function SenderSignatureModal({
  selectSignature,
  closeSignatureSelectionModal,
}: {
  selectSignature: (value: string) => void;
  closeSignatureSelectionModal: () => void;
}) {
  const { data: list, isLoading, refetch } = useSignatureQuery();
  const [selected, setSelected] = useState("");

  return (
    <SenderSignatureModalStyled noSignatures={list?.signatureList.length === 0}>
      {!isLoading && (
        <>
          <div className="main">
            <Title1 className="title">
              <span>발신자 서명 선택하기</span>
              {list?.signatureList.length === 0 ? (
                <RefreshIcon onClick={() => refetch()} />
              ) : (
                <CloseIcon onClick={closeSignatureSelectionModal} />
              )}
            </Title1>
            {list?.signatureList.length === 0 && (
              <>
                <Body1Regular className="desc">
                  등록된 내 서명이 없습니다. 서명을 만들어주세요.
                </Body1Regular>
                <Link to="/profile/sign?openModal=true" target="_blank">
                  <Button colorType="tertiary" size="large">
                    <Body1SemiBold>내 서명 만들기</Body1SemiBold>
                  </Button>
                </Link>
              </>
            )}
          </div>
          {(list?.signatureList?.length as number) > 0 && (
            <>
              <div className="refresh">
                <RefreshIcon onClick={() => refetch()} />
              </div>
              <div className="signatures">
                <div className="signatures-container">
                  {list?.signatureList.map(
                    ({ signatureUuid, signatureUrl }) => (
                      <div
                        key={signatureUuid}
                        className={`signature${
                          selected === signatureUrl ? " selected" : ""
                        }`}
                        onClick={() => setSelected(signatureUrl)}
                      >
                        <img src={signatureUrl} alt="" />
                      </div>
                    )
                  )}
                </div>
                <div className="make-signatures">
                  <Link to="/profile/sign?openModal=true" target="_blank">
                    <Button colorType="tertiary" size="medium">
                      내 서명 만들기
                    </Button>
                  </Link>
                </div>
              </div>
            </>
          )}
          <div className="footer">
            <div className="buttons-container">
              <Button
                colorType="ghost"
                size={list?.signatureList.length === 0 ? "medium" : "large"}
                onClick={closeSignatureSelectionModal}
              >
                <Body1SemiBold>취소</Body1SemiBold>
              </Button>
              <Button
                colorType="primary"
                size={list?.signatureList.length === 0 ? "medium" : "large"}
                disabled={
                  (list?.signatureList.length as number) === 0 ||
                  selected === ""
                }
                onClick={() => {
                  selectSignature(selected);
                  closeSignatureSelectionModal();
                }}
              >
                <Body1SemiBold>선택하기</Body1SemiBold>
              </Button>
            </div>
          </div>
        </>
      )}
    </SenderSignatureModalStyled>
  );
}

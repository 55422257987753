import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "assets/common/20px/icon_pencil.svg";
import CloseIcon from "assets/common/20px/icon_close_small.svg";
import { Body2Regular } from "components/TextStyle";

const EditInput = ({
  value,
  setIsEditState,
  callback,
}: {
  value: string;
  setIsEditState?: Dispatch<SetStateAction<boolean>>;
  callback: (inputValue: string) => void;
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const activeEnter = (e: { key: string }) => {
    if (e.key === "Enter") {
      if (inputValue.length > 0) {
        callback(inputValue);
        setIsEdit(false);
        setIsEditState && setIsEditState(false);
      }
    }
  };

  return (
    <GroupNameContainer>
      {isEdit ? (
        <div
          className="editContainer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={activeEnter}
            value={inputValue}
            maxLength={20}
          />
          <img
            src={CloseIcon}
            alt="closeIcon"
            className="closeIcon"
            onClick={() => {
              setInputValue(value);
              setIsEdit(false);
              setIsEditState && setIsEditState(false);
            }}
          />
        </div>
      ) : (
        <div className="defaultContainer">
          <Body2Regular>{inputValue}</Body2Regular>
          <EditIcon
            className="editIcon"
            onClick={(e) => {
              e.stopPropagation();
              setIsEdit(true);
              setIsEditState && setIsEditState(true);
            }}
          />
        </div>
      )}
    </GroupNameContainer>
  );
};

export default EditInput;

const GroupNameContainer = styled.div`
  display: flex;

  .defaultContainer {
    display: flex;
    width: fit-content;
    gap: 0.4rem;
    align-items: center;

    .editIcon {
      cursor: pointer;
      width: 2rem;
      height: 2rem;

      &:hover path:first-of-type {
        stroke: #1b65e8;
      }
      &:hover path:last-of-type {
        fill: #1b65e8;
      }
    }
  }

  .editContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-400);

    input {
      color: var(--text-default);
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: -0.45px;
      background-color: transparent;
    }

    .closeIcon {
      cursor: pointer;
    }
  }
`;

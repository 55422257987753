import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useContractDetail = (contractUuid: string) => {
  return useQuery({
    ...queries.contract.detail(contractUuid),
  });
};

export default useContractDetail;

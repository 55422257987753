import { Board } from "components/Common/Board/Board";
import Button from "components/Common/Button/Button";
import React, { useRef } from "react";
import styled from "styled-components";
import TemplateInfo from "./TemplateInfo";
import BoundaryInViewProvider from "components/Common/BoundaryInView/BoundaryInViewProvider";
import { ReactComponent as ArrowLeft } from "assets/common/36px/icon_left_large.svg";
import { Header1 } from "components/TextStyle";
import useTemplateDetailInfoQuery from "hooks/queries/template/useTemplateDetailInfoQuery";
import useUserState from "hooks/recoil/useUserState";
import { useNavigate, useParams } from "react-router-dom";
import TemplateDataExportModal from "components/template/Modals/TemplateDataExportModal";
import useModal from "hooks/useModal";
import { PdfPreviewLayout } from "components/Common/PdfPreview/PdfPreviewLayout";
import PdfPreviewer from "components/Common/PdfPreview/PdfPreviewer";
import MainPdfPreviewItem from "components/Common/PdfPreview/MainPdfPreviewItem";
import ListPdfPreviewItem from "components/Common/PdfPreview/ListPdfPreviewItem";

const TemplateDetailPage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [user] = useUserState();
  const { templateId } = useParams();
  const navigate = useNavigate();

  const {
    isOpen: dataExportModalOpen,
    closeModal: closeDataExportModal,
    openModal: openDataExportModal,
  } = useModal();

  const { data } = useTemplateDetailInfoQuery({
    organizationId: user.organization,
    templateId: templateId as string,
  });

  if (!data) return;

  const handleBackSpaceClick = () => {
    navigate(-1);
  };

  return (
    <>
      {dataExportModalOpen && (
        <TemplateDataExportModal closeModal={closeDataExportModal} />
      )}
      <BoundaryInViewProvider>
        <div>
          <TemplateDetailHeader>
            <div>
              <button onClick={handleBackSpaceClick}>
                <ArrowLeft />
              </button>
              <Header1>
                {data.contractTemplateDetails.templateName}(
                {data.contractTemplateDetails.templateUuid})
              </Header1>
            </div>
            <Button
              size="small"
              colorType="ghost"
              disabled={data.contractTemplateDetails.templateStatus === "TEMP"}
              onClick={openDataExportModal}
            >
              템플릿 데이터 받기
            </Button>
          </TemplateDetailHeader>
          <TemplateDetailLayout>
            <div
              style={{
                flexShrink: 0,
              }}
            >
              <TemplateInfo
                createAt={data.contractTemplateDetails.createdAt}
                createdUserName={data.contractTemplateDetails.userName}
                email={data.contractTemplateDetails.userEmail}
                status={data.contractTemplateDetails.templateStatus}
                pageCount={data.contractTemplateDetails.pageNum}
                receiverCnt={data.contractTemplateDetails.receiverCnt}
              />
            </div>

            <PdfPreviewLayout ref={ref}>
              <PdfPreviewer file={data.contractTemplateDetails.templateFile}>
                {(index) => (
                  <MainPdfPreviewItem
                    rootRef={ref}
                    key={`pdf_main_page_${index}`}
                    index={index}
                  />
                )}
              </PdfPreviewer>
            </PdfPreviewLayout>
            <PdfPreviewLayout
              style={{
                flexShrink: 0,
              }}
            >
              <PdfPreviewer file={data.contractTemplateDetails.templateFile}>
                {(index) => (
                  <ListPdfPreviewItem key={`pdf_page_${index}`} index={index} />
                )}
              </PdfPreviewer>
            </PdfPreviewLayout>
          </TemplateDetailLayout>
        </div>
      </BoundaryInViewProvider>
    </>
  );
};

export default TemplateDetailPage;

const TemplateDetailLayout = styled(Board)`
  padding: 4rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  max-height: 102.4rem;
`;

const TemplateDetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;

  > div {
    display: flex;
    align-items: center;
  }
`;

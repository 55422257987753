import React, { useState } from "react";
import styled from "styled-components";
import { DraggableStyled } from "./styles";
import { Body3Regular } from "components/TextStyle";
import { ReactComponent as DeleteIcon } from "assets/common/24px/icon_close_default.svg";
import { ReactComponent as TextIcon } from "assets/common/20px/icon_square_2.svg";
import { IField, IPage } from "interfaces/contract";
import { Field } from "pages/contract/send";
import RequiredOption from "components/Contract/Options/Required";
import FontSizeOption from "components/Contract/Options/FontSize";
import AlignOption from "components/Contract/Options/Align";
import { TextAlign } from "models/Contract";
import Resizable from "components/Resizable";
import { device } from "components/styles";

interface TextProps extends React.HTMLAttributes<HTMLDivElement> {
  edit?: boolean;
  complete?: boolean;
  id: string;
  type: "sender" | "receiver";
  fields: IField[];
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  selectedPageId: string;
  setSelectedFieldId?: React.Dispatch<React.SetStateAction<string>>;
  selectedFieldId?: string;
  onDeleteHandler: (id: string) => void;
  selectField?: (id: string, type: Field) => void;
  scale: number;
  align?: ({
    type,
    align,
    all,
  }: {
    type: Field;
    align?: TextAlign | undefined;
    all?: boolean | undefined;
  }) => void;
  onChangeHandler?: (id: string, value: string) => void;
  currentSize: { w: number; h: number };
  handleMouseDown: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>,
    direction: string
  ) => void;
  handleMouseMove: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>
  ) => void;
  handleMouseUp: () => void;
  newField?: boolean;
  isMobile?: boolean;
}

export default function Text({
  isMobile = false,
  edit = false,
  complete = false,
  id,
  type,
  fields,
  pages,
  setPages,
  selectedPageId,
  setSelectedFieldId,
  selectedFieldId,
  onDeleteHandler,
  selectField,
  scale,
  draggable,
  align,
  onChangeHandler,
  currentSize,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  newField,
  ...args
}: TextProps) {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <TextStyled
      className="draggable-text"
      id={id}
      type={type}
      scale={scale}
      color={
        type === "sender"
          ? "#666F7B"
          : (fields.find((field) => `${field.fieldType}:${field.id}` === id)
              ?.writer?.color as string)
      }
      required={
        !!fields.find((field) => `${field.fieldType}:${field.id}` === id)
          ?.required
      }
      draggable={draggable}
      align={
        fields.find((field) => `${field.fieldType}:${field.id}` === id)?.align
      }
      fontSize={
        fields.find((field) => `${field.fieldType}:${field.id}` === id)?.size
      }
      complete={complete}
      newField={newField}
      isMobile={isMobile}
      {...args}
    >
      <div className="wrapper">
        <div className="field-container">
          <div
            className="container text"
            onClick={(e) => {
              e.stopPropagation();
              selectField && selectField(id, id.split(":")[0] as Field);
            }}
            style={{
              width: `${(currentSize.w / 10) * scale}rem`,
              // height: `${(currentSize.h / 10) * scale}rem`,
              height: isMobile
                ? "1.4rem"
                : `${(currentSize.h / 10) * scale}rem`,
            }}
          >
            {edit ? (
              <>
                <input
                  type="text"
                  style={{
                    fontSize: isMobile ? "0.8rem" : "",
                  }}
                  placeholder={
                    complete
                      ? ""
                      : fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.description || ""
                  }
                  value={
                    fields.find(
                      (field) => `${field.fieldType}:${field.id}` === id
                    )?.value
                  }
                  onChange={(e) =>
                    onChangeHandler && onChangeHandler(id, e.target.value)
                  }
                  disabled={complete}
                />
              </>
            ) : (
              <>
                <TextIcon />
                {isEdit ? (
                  <textarea
                    value={
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.name
                    }
                    style={{
                      fontSize: `${
                        +(fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.size as string) / 10
                      }rem`,
                      lineHeight: 1.4285714286,
                      color: "var(--text-secondary)",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsEdit(false);
                      }
                    }}
                    onChange={(e) => {
                      setPages((prev) =>
                        prev.map((page) => {
                          if (
                            page.fields
                              .map((field) => `${field.fieldType}:${field.id}`)
                              .includes(id)
                          ) {
                            return {
                              ...page,
                              fields: page.fields.map((field) => {
                                if (`${field.fieldType}:${field.id}` === id) {
                                  return { ...field, name: e.target.value };
                                } else return { ...field };
                              }),
                            };
                          } else return { ...page };
                        })
                      );
                    }}
                  />
                ) : (
                  <Body3Regular
                    style={{
                      fontSize: `${
                        +(fields.find(
                          (field) => `${field.fieldType}:${field.id}` === id
                        )?.size as string) / 10
                      }rem`,
                      lineHeight: 1.4285714286,
                    }}
                    onDoubleClick={() => setIsEdit((prev) => !prev)}
                  >
                    {
                      fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.name
                    }
                  </Body3Regular>
                )}
              </>
            )}
            {draggable && draggable && id === selectedFieldId && (
              <Resizable
                pages={pages}
                setPages={setPages}
                selectedFieldId={selectedFieldId}
                selectedPageId={selectedPageId}
                handleMouseUp={handleMouseUp}
                handleMouseDown={handleMouseDown}
                handleMouseMove={handleMouseMove}
                color={
                  type === "sender"
                    ? "#666F7B"
                    : !!fields.find(
                        (field) => `${field.fieldType}:${field.id}` === id
                      )?.required
                    ? "red"
                    : "var(--blue-500)"
                }
                scale={scale}
              />
            )}
          </div>
        </div>
        {draggable && id === selectedFieldId && (
          <DeleteIcon onClick={() => onDeleteHandler(id)} />
        )}
      </div>
      {draggable && selectedFieldId === id && (
        <div className="options" onClick={(e) => e.stopPropagation()}>
          <div className="option">
            <FontSizeOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
          <div className="option">
            <AlignOption
              alignFunc={align}
              type="TEXT"
              selected={
                fields.find((field) => `${field.fieldType}:${field.id}` === id)
                  ?.align || "LEFT"
              }
            />
          </div>
          <div className="option">
            <RequiredOption
              fields={fields}
              setPages={setPages}
              selectedPageId={selectedPageId}
              selectedFieldId={selectedFieldId}
            />
          </div>
        </div>
      )}
    </TextStyled>
  );
}

const TextStyled = styled(DraggableStyled)<{
  type: "sender" | "receiver";
}>`
  .wrapper {
    .container {
      padding: ${({ scale, isMobile }) =>
        isMobile ? "0.2rem" : scale ? `${scale * 1}rem` : "1rem"};
      box-sizing: border-box;
      display: flex;
      column-gap: 0.4rem;
      border-radius: ${({ scale }) => (scale ? `${scale * 0.4}rem` : "0.4rem")};
      color: var(--text-secondary);
      @media (max-width: ${device.md}) {
        padding: 0;
      }
    }
  }

  .options {
    .option {
      .dropdown {
        width: max-content;

        .contentContainer {
          justify-content: flex-start;
          padding: 0;
        }
      }

      .alignOptions {
        display: flex;
        align-items: center;
        column-gap: 0.4rem;
      }
    }
  }
`;

import useBodyScrollLock from "hooks/useBodyScrollLock";
import React, { MouseEvent, ReactNode, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

interface ModalLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  handleModalClose?: () => void;
  isBg?: boolean;
  isWhole?: boolean;
  disableCloseWithOutsideClickOrEsc?: boolean;
}

const ModalLayout = ({
  children,
  handleModalClose,
  isBg = true,
  disableCloseWithOutsideClickOrEsc,
  isWhole = false,
  ...args
}: ModalLayoutProps) => {
  const portalRoot = document.getElementById("modal") as HTMLElement;

  const { lockScroll, openScroll } = useBodyScrollLock();

  const modalOutsideRef = useRef<HTMLDivElement>(null);

  // 모달 바깥 부분 클릭 시 모달 닫히는 기능
  const modalOutsideClick = (e: MouseEvent) => {
    if (disableCloseWithOutsideClickOrEsc) return;

    if (modalOutsideRef.current === e.target) {
      handleModalClose && handleModalClose();
    }
  };

  // esc 키 누르면 모달 닫히는 기능
  useEffect(() => {
    if (disableCloseWithOutsideClickOrEsc) return;

    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleModalClose && handleModalClose();
      }
    };

    document.addEventListener("keydown", handleEscape);
    lockScroll();

    return () => {
      openScroll();
      document.removeEventListener("keydown", handleEscape);
    };
  }, [handleModalClose]);

  return ReactDOM.createPortal(
    <ModalLayoutContainer
      ref={modalOutsideRef}
      onClick={modalOutsideClick}
      $isBg={isBg}
      $isWhole={isWhole}
      {...args}
    >
      {children}
    </ModalLayoutContainer>,
    portalRoot
  );
};

export default ModalLayout;

const ModalLayoutContainer = styled.div<{ $isBg: boolean; $isWhole: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 202;
  background: ${({ $isBg }) => ($isBg ? " rgba(0, 0, 0, 0.4)" : "")};
  padding: ${({ $isWhole }) => ($isWhole ? "0px" : "0 1rem")};
  overflow: scroll;
`;

import NoticeNavigation from "components/Mobile/Notice/NoticeNavigation";
import React from "react";
import styled from "styled-components";
import { ReactComponent as RightArrowIcon } from "assets/common/24px/icon_right_default.svg";
import { Body3Regular, Body2SemiBold } from "components/TextStyle";
import { useNavigate } from "react-router-dom";

const GUIDE_LIST = [
  {
    subTitle: "처음 오셨나요?",
    title: "01. 퀵 가이드 다운로드",
    link: "",
  },
  {
    subTitle: "기존 고객님이신가요?",
    title: "02. 변경 부분 가이드 다운로드",
    link: "",
  },
  {
    subTitle: "및춤형 서비스를 찾으시나요?",
    title: "03. 브랜딩 서비스 가이드 다운로드",
    link: "",
  },
];

const NoticeGuidePage = () => {
  const navigate = useNavigate();
  return (
    <NoticeGuideLayout>
      <NoticeNavigation />
      <NoticeGuideBoard>
        <video width="100%" controls>
          <source src="path/to/your/video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <GuideListLayout>
          {GUIDE_LIST.map((guide) => {
            return (
              <div key={guide.link}>
                <GuideListItem>
                  <Body3Regular style={{ color: "var(--text-secondary)" }}>
                    {guide.subTitle}
                  </Body3Regular>
                  <div className="guide-list-title">
                    <Body2SemiBold>{guide.title}</Body2SemiBold>
                    <RightArrowIcon />
                  </div>
                </GuideListItem>
              </div>
            );
          })}
          <div
            className="add-more-guide"
            onClick={() => {
              navigate("/setting/support");
            }}
          >
            <Body3Regular>더 많은 도움이 필요하신가요</Body3Regular>
          </div>
        </GuideListLayout>
      </NoticeGuideBoard>
    </NoticeGuideLayout>
  );
};

export default NoticeGuidePage;

const NoticeGuideLayout = styled.div`
  padding: 3rem;
`;

const NoticeGuideBoard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  gap: 3rem;
`;

const GuideListLayout = styled.ul`
  display: flex;
  flex-direction: column;

  max-width: 60rem;
  width: 100%;

  .guide-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .add-more-guide {
    display: flex;
    align-items: center;
    color: var(--text-secondary);
  }
`;

const GuideListItem = styled.li`
  margin-bottom: 3.2rem;
`;

import React from "react";
import { ResizableDot } from "./styles";
import { IPage } from "interfaces/contract";

interface ResizableProps {
  pages: IPage[];
  setPages: React.Dispatch<React.SetStateAction<IPage[]>>;
  selectedPageId: string;
  selectedFieldId: string;
  handleMouseDown: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>,
    direction: string
  ) => void;
  handleMouseMove: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>
  ) => void;
  handleMouseUp: (
    e: MouseEvent | React.MouseEvent<Element, MouseEvent>
  ) => void;
  color: string;
  scale: number;
}

export default function Resizable({
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  color,
  scale,
}: ResizableProps) {
  return (
    <>
      <ResizableDot
        scale={scale}
        color={color}
        className="top left"
        onMouseDown={(e) => handleMouseDown(e, "top left")}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      />
      <ResizableDot
        scale={scale}
        color={color}
        className="top right"
        onMouseDown={(e) => handleMouseDown(e, "top right")}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      />
      <ResizableDot
        scale={scale}
        color={color}
        className="bottom right"
        onMouseDown={(e) => handleMouseDown(e, "bottom right")}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      />
      <ResizableDot
        scale={scale}
        color={color}
        className="bottom left"
        onMouseDown={(e) => handleMouseDown(e, "bottom left")}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      />
    </>
  );
}

import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useServicePlanQuery = () => {
  return useQuery({
    ...queries.payment.plan,
    select: (res) => res.servicePlanDtos,
    staleTime: 1000 * 60 * 5,
  });
};

export default useServicePlanQuery;

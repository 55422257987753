import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useAnnualAuthQuery = () => {
  return useQuery({
    ...queries.auth.annualAuth,
  });
};

export default useAnnualAuthQuery;

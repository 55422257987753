import styled from "styled-components";

export const UploadStyled = styled.div`
  flex-grow: 1;
`;

export const FileUploadModalStyled = styled.div<{
  templateMode: boolean;
  overMaximumSize: boolean;
}>`
  width: max-content;
  max-width: 108rem;
  height: max-content;
  padding: 3.6rem 4rem;
  background-color: var(--bg-white);
  border-radius: 0.5rem;
  box-sizing: border-box;

  .modalContainer {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;

    .top {
      display: flex;
      justify-content: space-between;

      .title {
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
      }

      svg {
        cursor: pointer;
      }

      .desc {
        color: var(--text-secondary);
      }
    }

    .main {
      width: 100rem;
      display: flex;
      flex-direction: column;
      row-gap: ${({ templateMode }) => (templateMode ? "8.4rem" : "3rem")};
      padding-top: ${({ templateMode }) => (templateMode ? "0" : "1.6rem")};

      .upload {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;

        label {
          box-sizing: border-box;
          width: 100%;
          height: 22rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 0.4rem;
          color: ${({ overMaximumSize }) =>
            overMaximumSize ? "var(--red-600)" : "var(--text-secondary)"};
          background-color: ${({ overMaximumSize }) =>
            overMaximumSize ? "#FFF9F9" : "#d7e5ff59"};
          border: ${({ overMaximumSize }) =>
            overMaximumSize
              ? "0.15rem dashed var(--red-600)"
              : "0.15rem dashed #3182f659"};
          border-radius: 1.2rem;
          position: relative;

          img {
            width: 10rem;
            height: 10rem;
          }

          input {
            opacity: 0;
            position: absolute;
            inset: 0;
          }
        }

        ul {
          color: ${({ overMaximumSize }) =>
            overMaximumSize ? "var(--red-600)" : "var(--text-secondary)"};

          li {
            display: flex;
            align-items: center;

            &::before {
              content: "";
              width: 0.4rem;
              height: 0.4rem;
              margin-right: 0.6rem;
              background-color: ${({ overMaximumSize }) =>
                overMaximumSize ? "var(--red-600)" : "var(--grey-200)"};
              border-radius: 100%;
            }
          }
        }
      }

      table {
        width: 100%;
        border-collapse: collapse;

        * {
          box-sizing: border-box;
        }

        td {
          display: flex;
          align-items: center;
          padding: 1.6rem 2.4rem;

          &.check {
            min-width: 6.8rem;
            max-width: 6.8rem;

            input {
              display: none;
            }

            img {
              width: 2rem;
              height: 2rem;
            }
          }

          &.status {
            min-width: 7.4rem;
            max-width: 7.4rem;
          }

          &.name {
            min-width: 32rem;
            max-width: 32rem;
          }

          &.author {
            min-width: 12rem;
            max-width: 12rem;
          }

          &.createdAt,
          &.updatedAt {
            min-width: 18rem;
            max-width: 18rem;
          }

          & > div {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }
        }

        tr {
          display: flex;
          border-bottom: 0.1rem solid var(--stroke-light);
        }

        thead {
          width: 100%;
          background-color: var(--bg-dark);
          color: var(--text-secondary);

          td.check {
            min-width: 6.8rem;
            max-width: 6.8rem;
          }
        }

        tbody {
          display: block;
          width: 100%;
          max-height: 22rem;
          overflow-y: scroll;

          td {
            color: var(--text-default);

            &.status {
              color: var(--text-emphasis);
            }
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        column-gap: 3rem;
        padding-top: 3.6rem;

        label,
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24rem;
          height: 5.6rem;
          text-align: center;
        }

        label {
          cursor: pointer;
          border: 0.1rem solid var(--stroke-dark);
          border-radius: 0.5rem;
          color: var(--text-secondary);

          &:hover {
            border-color: var(--stroke-light);
            color: #9aa1ab;
          }

          &:active {
            border-color: #9aa1ab;
            color: var(--text-secondary);
          }
        }
      }
    }
  }
`;

export const ContractTypesModalStyled = styled.div`
  width: max-content;
  max-width: 124rem;
  height: 44.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  row-gap: 1.2rem;
  box-sizing: border-box;

  & > svg {
    cursor: pointer;
  }

  .options {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;

    .option {
      cursor: pointer;
      width: 40rem;
      height: 40rem;
      background-color: var(--bg-white);
      border-radius: 1.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 8rem;

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 1.2rem;

        .desc {
          color: var(--text-secondary);
        }
      }
    }
  }
`;

export const RecevierModalStyled = styled.div`
  width: 100%;
  max-width: 108rem;
  height: max-content;
  padding: 3.6rem 4rem;
  background-color: var(--bg-white);
  border-radius: 0.5rem;
  margin: auto;
  box-sizing: border-box;

  .modalContainer {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;

    .top {
      display: flex;
      justify-content: space-between;

      .title {
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
      }

      svg {
        cursor: pointer;
      }

      .desc {
        color: var(--text-secondary);
      }
    }

    .main {
      display: flex;
      flex-direction: column;
      row-gap: 3rem;

      .container {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            display: flex;
            align-items: center;
            column-gap: 1.2rem;

            & > div:last-of-type {
              color: var(--text-emphasis);
            }
          }

          .method {
            display: flex;
            align-items: center;
            column-gap: 1.2rem;

            .radioContainer {
              display: flex;
              align-items: center;

              input {
                padding: 0;
                margin: 0;
              }

              label {
                margin: 0;
              }
            }
          }
        }

        .receivers {
          display: flex;
          flex-direction: column;
          row-gap: 2rem;

          .receiver {
            display: flex;
            align-items: center;

            .left {
              display: flex;
              justify-content: space-between;
              align-items: center;
              column-gap: 1.2rem;

              .order {
                min-width: 2.4rem;
              }

              .color {
                width: 2rem;
                height: 2rem;
                border-radius: 100%;
                background-color: var(--bg-primary);
                margin-right: 0.8rem;
              }
            }

            .center {
              display: flex;
              column-gap: 3.2rem;

              .info {
                display: flex;
                align-items: center;
                column-gap: 0.8rem;

                .name {
                  position: relative;
                  width: 14rem;

                  .dropdown {
                    width: 100%;
                    box-sizing: border-box;
                    position: absolute;
                    top: calc(100% + 0.4rem);
                    padding: 0.8rem;
                    background-color: white;
                    display: none;
                    flex-direction: column;
                    row-gap: 0.8rem;
                    z-index: 15;

                    &.opened {
                      display: flex;
                    }

                    li {
                      cursor: pointer;
                      box-sizing: border-box;
                      padding: 0.8rem;
                      color: var(--text-secondary);
                      border-radius: 0.4rem;

                      &.selected,
                      &:hover {
                        background-color: var(--bg-dark);
                        color: var(--text-primary);
                      }
                    }
                  }
                }

                .email {
                  width: 28rem;
                  .errorMsg {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    margin-top: 0.4rem;
                  }
                }

                .phoneNumber {
                  width: 24rem;
                  .errorMsg {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    margin-top: 0.4rem;
                  }
                }
              }

              .verification {
                display: flex;
                align-items: center;
                column-gap: 1.2rem;

                svg {
                  cursor: pointer;
                }
              }
            }

            .handler {
              margin-left: auto;
              cursor: pointer;
            }
          }

          .add {
            cursor: pointer;
            box-sizing: border-box;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 0.4rem;
            padding: 2rem;
            color: var(--text-secondary);
            background-color: #d7e5ff59;
            border: 0.15rem dashed #3182f659;
            border-radius: 0.8rem;

            &.unallowed {
              cursor: not-allowed;
              background-color: #fff9f9;
              border-color: #e71f2a;
              color: #e71f2a;
            }
          }
        }
      }

      .buttons {
        padding-top: 3.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 3rem;

        button {
          width: 24rem;
          height: 5.6rem;
        }
      }
    }
  }
`;

export const LoadingStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  row-gap: 1.2rem;

  img {
    width: 6rem;
    height: 6rem;
  }
`;

import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Body3SemiBold } from "components/TextStyle";

const NOTICE_PATH = {
  DASHBOARD: "/notice/dashboard",
  GUIDE: "/notice/guide",
};

const NoticeNavigation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const isActive = (path: string) => pathname.includes(path);

  return (
    <LayoutTitleContainer>
      <div className="titleItemContainer">
        <Body3SemiBold
          className={`title ${isActive(NOTICE_PATH.DASHBOARD) && "active"}`}
          onClick={() => handleNavigate(NOTICE_PATH.DASHBOARD)}
        >
          공지사항
        </Body3SemiBold>
        <div
          className={`line ${isActive(NOTICE_PATH.DASHBOARD) && "active"}`}
        />
      </div>
      <div className="titleItemContainer">
        <Body3SemiBold
          className={`title ${isActive(NOTICE_PATH.GUIDE) && "active"}`}
          onClick={() => handleNavigate(NOTICE_PATH.GUIDE)}
        >
          이용방법
        </Body3SemiBold>
        <div className={`line ${isActive(NOTICE_PATH.GUIDE) && "active"}`} />
      </div>
    </LayoutTitleContainer>
  );
};

export default NoticeNavigation;

const LayoutTitleContainer = styled.div`
  display: flex;
  gap: 1.8rem;
  align-items: center;
  .titleItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    font-weight: var(--bold);

    .line {
      background-color: #161616;
      width: 100%;
      height: 0.3rem;
      border-radius: 0.2rem 0.2rem 0 0;
      opacity: 0;
    }
    .title {
      color: var(--grey-500);
      cursor: pointer;
    }
    .active {
      color: #161616;
    }
    .active + .line {
      opacity: 1;
    }
  }
`;

import DefaultLayout from "layout/DefaultLayout";
import MobileLayout from "layout/Mobile/DefaultLayout";
import ProfileSignPage from "pages/profile/sign";
import ProfileSignMobilePage from "pages/profile/sign/(mobile)/mobile";
import ProfileInvitedOrganizationPage from "pages/profile/invited-organization";
import ProfileInvitedOrganizationMobilePage from "pages/profile/invited-organization/(mobile)/mobile";
import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import TemplateDashboardPage from "pages/template/dashboard";
import AuthSignUpFormLoader from "pages/auth/sign-up/form/loader";
import AuthChangePasswordLoader from "pages/auth/change-password/loader";
import TemplateSendLayout from "layout/TemplateSendLayout";
import TemplateSendConfigSinglePage from "./pages/template/send/detail/config/single";
import TemplateSendConfigMultiplePage from "pages/template/send/detail/config/mutiple";
import NoticeDashboardPage from "pages/notice/dashboard";
import NoticeDashboardMobilePage from "pages/notice/dashboard/(mobile)/mobile";
import NoticeGuidePage from "pages/notice/guide";
import NoticeGuideMobilePage from "pages/notice/guide/(mobile)/mobile";
import NoticeDashboardDetailPage from "pages/notice/dashboard/detail";
import NoticeDashboardDetailMobilePage from "pages/notice/dashboard/(mobile)/detail/mobile";
import AlertDashboardPage from "pages/alert/dashboard";
import AlertDashboardMobilePage from "pages/alert/dashboard/(mobile)/mobile";
import AuthOnlyLoader from "loaders/AuthOnlyLoader";
import LandingLayout from "layout/LandingLayout";
import MobileLandingLayout from "layout/Mobile/LandingLayout";
import { RootLayout } from "layout/RootLayout";
import PlanPage from "pages/(landing)/plan";
import LandingFaq from "pages/(landing)/faq";
import LandingMobileFaqPage from "pages/(landing)/faq/(mobile)/mobile";
import LandingSupportPage from "pages/(landing)/support";
import LandingSupportMobilePage from "pages/(landing)/support/(mobile)/mobile";
import { Receiverlayout } from "layout/ReceiverLayout";
import TemplateDetailPage from "pages/template/detail";
import TemplateSendConfigManyPage from "pages/template/send/detail/config/many";
import TemplateSendConfigLayout from "pages/template/send/detail/config";
import NonAuthOnlyLoader from "loaders/NonAuthOnlyLoader";
import PaymentTossLoader from "pages/setting/payment/toss/loader";
import { createResponsiveRouter } from "config/createResponsiveRouter";
import TemplateDetailMobilePage from "pages/template/detail/(mobile)/mobile";

import TemplateSendConfigMobileLayout from "pages/template/send/detail/config/(mobile)/mobile";
import TemplateSendConfigSingleMobilePage from "pages/template/send/detail/config/single/(mobile)/mobile";

import MobileTemplateSendConfigManyPage from "pages/template/send/detail/config/many/(mobile)/mobile";
import MobileViewDocuments from "components/Contract/(mobile)/MobileViewDocuments";
import MobileCertificates from "pages/certificates/(mobile)/mobile";
import VerificationLogin from "pages/receiver/verification/login";
import ViewDocuments from "components/Contract/ViewDocuments";

const RootLandingPage = React.lazy(() => import("./pages"));
const RootLandingMobilePage = React.lazy(
  () => import("./pages/template/(mobile)/mobile")
);
const Page404 = React.lazy(() => import("./pages/page404/Page404"));
const ErrorPage = React.lazy(() => import("./pages/error"));
const DashBoardPage = React.lazy(() => import("./pages/dashboard"));
const DashBoardMobilePage = React.lazy(
  () => import("./pages/dashboard/(mobile)/mobile")
);
const APIDashboardPage = React.lazy(() => import("./pages/api/dashboard"));
const APIDashboardMobilePage = React.lazy(
  () => import("./pages/api/dashboard/(mobile)/mobile")
);
const APIGettingStartedPage = React.lazy(
  () => import("./pages/api/getting-started")
);
const APIGettingStartedMobilePage = React.lazy(
  () => import("./pages/api/getting-started/(mobile)/mobile")
);
const AuthFindPasswordPage = React.lazy(
  () => import("./pages/auth/sign-in/find-password")
);
const AuthFindPasswordMobilePage = React.lazy(
  () => import("./pages/auth/sign-in/find-password/(mobile)/mobile")
);
const AuthSignInPage = React.lazy(() => import("./pages/auth/sign-in"));
const AuthSignInMobilePage = React.lazy(
  () => import("./pages/auth/sign-in/(mobile)/mobile")
);
const AuthSignUpFormPage = React.lazy(
  () => import("./pages/auth/sign-up/form")
);
const AuthSignUpFormMobilePage = React.lazy(
  () => import("./pages/auth/sign-up/(mobile)/form/mobile")
);
const AnnualAuthVerificationPage = React.lazy(
  () => import("./pages/annual-auth")
);
const AnnualAuthVerificationMobilePage = React.lazy(
  () => import("./pages/annual-auth/(mobile)/mobile")
);
const ProfileInformationPage = React.lazy(
  () => import("./pages/profile/information")
);
const ProfileInformationMobilePage = React.lazy(
  () => import("./pages/profile/information/(mobile)/mobile")
);
const AuthSignUpPage = React.lazy(() => import("./pages/auth/sign-up"));
const AuthSignUpMobilePage = React.lazy(
  () => import("./pages/auth/sign-up/(mobile)/mobile")
);
const AuthChangePasswordPage = React.lazy(
  () => import("./pages/auth/change-password")
);
const AuthChangePasswordMobilePage = React.lazy(
  () => import("./pages/auth/change-password/(mobile)/mobile")
);
const ContractDetailPage = React.lazy(() => import("./pages/contract/detail"));
const ContractDashBoardDirectURLPage = React.lazy(
  () => import("./pages/contract/dashboard/direct-url")
);
const ContractDashBoardDirectURLMobilePage = React.lazy(
  () => import("./pages/contract/dashboard/direct-url/(mobile)/mobile")
);
const MobileContractDetailPage = React.lazy(
  () => import("./pages/contract/detail/(mobile)/mobile")
);

const ContractDashBoardNormalURLPage = React.lazy(
  () => import("./pages/contract/dashboard/normal")
);
const ContractDashBoardNormalURLMobilePage = React.lazy(
  () => import("./pages/contract/dashboard/normal/(mobile)/mobile")
);
const ContractSendPage = React.lazy(() => import("./pages/contract/send"));
const SettingServicePage = React.lazy(() => import("./pages/setting/service"));
const SettingServiceMobilePage = React.lazy(
  () => import("./pages/setting/service/(mobile)/mobile")
);
const SettingContactPage = React.lazy(() => import("./pages/setting/contact"));
const SettingContactMobilePage = React.lazy(
  () => import("./pages/setting/contact/(mobile)/mobile")
);
const SettingFAQPage = React.lazy(() => import("./pages/setting/faq"));
const SettingFAQMobilePage = React.lazy(
  () => import("./pages/setting/faq/(mobile)/mobile")
);
const SettingGroupPage = React.lazy(() => import("./pages/setting/group"));
const SettingGroupMobilePage = React.lazy(
  () => import("./pages/setting/group/(mobile)/mobile")
);
const SettingListBoxDBPage = React.lazy(
  () => import("./pages/setting/list-box-db")
);
const SettingListBoxDBMobilePage = React.lazy(
  () => import("./pages/setting/list-box-db/(mobile)/mobile")
);
const SettingMailPage = React.lazy(() => import("./pages/setting/mail"));
const SettingMailMobilePage = React.lazy(
  () => import("./pages/setting/mail/(mobile)/mobile")
);
const SettingMemberPage = React.lazy(() => import("./pages/setting/member"));
const SettingMemberMobilePage = React.lazy(
  () => import("./pages/setting/member/(mobile)/mobile")
);
const SettingOrganizationPage = React.lazy(
  () => import("./pages/setting/organization")
);
const SettingOrganizationMobilePage = React.lazy(
  () => import("./pages/setting/organization/(mobile)/mobile")
);
const SettingPaymentBillingPage = React.lazy(
  () => import("./pages/setting/payment/billing")
);
const SettingPaymentBillingMobilePage = React.lazy(
  () => import("./pages/setting/payment/billing/(mobile)/mobile")
);
const SettingPaymentBillingTossPage = React.lazy(
  () => import("./pages/setting/payment/toss")
);
const SettingPaymentDashBoardPage = React.lazy(
  () => import("./pages/setting/payment/dashboard")
);
const SettingPaymentDashBoardMobilePage = React.lazy(
  () => import("./pages/setting/payment/dashboard/(mobile)/mobile")
);
const SettingPaymentHistoryPage = React.lazy(
  () => import("./pages/setting/payment/history")
);
const SettingPaymentHistoryMobilePage = React.lazy(
  () => import("./pages/setting/payment/history/(mobile)/mobile")
);
const SettingSupportPage = React.lazy(() => import("./pages/setting/support"));
const SettingSupportMobilePage = React.lazy(
  () => import("./pages/setting/support/(mobile)/mobile")
);
const TemplateDashBoardBookMarkPage = React.lazy(
  () => import("./pages/template/dashboard/bookmark")
);
const TemplateDashBoardBookMarkMobilePage = React.lazy(
  () => import("./pages/template/dashboard/bookmark/(mobile)/mobile")
);
const TemplateDashBoardMePage = React.lazy(
  () => import("./pages/template/dashboard/me")
);
const TemplateDashBoardMobilePage = React.lazy(
  () => import("./pages/template/dashboard/(mobile)/mobile")
);

const TemplateDashBoardSendPreviewPage = React.lazy(
  () => import("./pages/template/send/detail/preview")
);
const MobileTemplateDashBoardSendPreviewPage = React.lazy(
  () => import("./pages/template/send/detail/preview/(mobile)/mobile")
);
const TemplateStorePage = React.lazy(() => import("./pages/template/store"));
const TemplateStoreMobilePage = React.lazy(
  () => import("./pages/template/store/(mobile)/mobile")
);
const SettingPaymentBillingCompletePage = React.lazy(
  () => import("./pages/setting/payment/billing/complete")
);
const SettingPaymentBillingCompleteMobilePage = React.lazy(
  () => import("./pages/setting/payment/billing/(mobile)/complete")
);
const InvitationAcceptancePage = React.lazy(() => import("./pages/invitation"));
const Create = React.lazy(() => import("./pages/template/create"));
const Sign = React.lazy(() => import("./pages/sign"));
const MobileSign = React.lazy(() => import("./pages/sign/(mobile)/mobile"));
const Email = React.lazy(() => import("./pages/receiver/email"));
const MobileEmail = React.lazy(
  () => import("./pages/receiver/email/(mobile)/mobile")
);
const Phone = React.lazy(() => import("./pages/receiver/phone"));
const MobilePhone = React.lazy(
  () => import("./pages/receiver/phone/(mobile)/mobile")
);
const PasswordVerification = React.lazy(
  () => import("./pages/receiver/verification/password")
);
const MobilePasswordVerification = React.lazy(
  () => import("./pages/receiver/verification/password/(mobile)/mobile")
);
const PhoneVerification = React.lazy(
  () => import("./pages/receiver/verification/phone")
);
const MobileVerificationLogin = React.lazy(
  () => import("./pages/receiver/verification/login/(mobile)/mobile")
);
const Confirm = React.lazy(() => import("./pages/receiver/confirm"));
const MobileConfirm = React.lazy(
  () => import("./pages/receiver/confirm/(mobile)/mobile")
);
const Callback = React.lazy(() => import("./pages/receiver/callback"));
const DirectURL = React.lazy(() => import("./pages/direct-url"));
const Copies = React.lazy(() => import("./pages/copies"));
const Certificates = React.lazy(() => import("./pages/certificates"));
const DownloadCopies = React.lazy(() => import("./pages/download/Copies"));
const DownloadCertificates = React.lazy(
  () => import("./pages/download/Certificates")
);
const Preview = React.lazy(() => import("./pages/preview"));

const loading = <div>화면을 불러오는 중 입니다.</div>;

const routes = createResponsiveRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={loading}>
        <Outlet />
      </Suspense>
    ),
    errorElement: <Page404 />,
    children: [
      {
        index: true,
        element: <Navigate to="/landing" replace={true} />,
      },
      {
        path: "/landing",
        loader: NonAuthOnlyLoader,
        element: (
          <LandingLayout>
            <RootLandingPage />
          </LandingLayout>
        ),
        mobile: (
          <MobileLandingLayout>
            <RootLandingMobilePage />
          </MobileLandingLayout>
        ),
      },
      {
        path: "/plan",
        loader: NonAuthOnlyLoader,
        element: (
          <LandingLayout>
            <PlanPage />
          </LandingLayout>
        ),
      },
      {
        path: "/faq",
        loader: NonAuthOnlyLoader,
        element: (
          <LandingLayout>
            <RootLayout>
              <LandingFaq />
            </RootLayout>
          </LandingLayout>
        ),
        mobile: (
          <MobileLandingLayout>
            <LandingMobileFaqPage />
          </MobileLandingLayout>
        ),
      },
      {
        path: "/support",
        loader: NonAuthOnlyLoader,
        element: (
          <LandingLayout>
            <RootLayout>
              <LandingSupportPage />
            </RootLayout>
          </LandingLayout>
        ),
        mobile: (
          <MobileLandingLayout>
            <LandingSupportMobilePage />
          </MobileLandingLayout>
        ),
      },
      {
        path: "auth",
        loader: NonAuthOnlyLoader,
        element: (
          <LandingLayout>
            <RootLayout>
              <Outlet />
            </RootLayout>
          </LandingLayout>
        ),
        mobile: (
          <MobileLandingLayout>
            <Outlet />
          </MobileLandingLayout>
        ),
        children: [
          {
            path: "sign-in",
            element: <AuthSignInPage />,
            mobile: <AuthSignInMobilePage />,
          },
          {
            path: "sign-up",
            children: [
              {
                path: "form/:tokenId",
                element: <AuthSignUpFormPage />,
                mobile: <AuthSignUpFormMobilePage />,
                loader: AuthSignUpFormLoader,
              },
              {
                index: true,
                element: <AuthSignUpPage />,
                mobile: <AuthSignUpMobilePage />,
              },
            ],
          },
          {
            path: "find-password",
            element: <AuthFindPasswordPage />,
            mobile: <AuthFindPasswordMobilePage />,
          },
          {
            path: "change-password",
            children: [
              {
                path: ":tokenId",
                element: <AuthChangePasswordPage />,
                mobile: <AuthChangePasswordMobilePage />,
                loader: AuthChangePasswordLoader,
              },
            ],
          },
        ],
      },
      {
        path: "error",
        element: (
          <Receiverlayout>
            <ErrorPage />
          </Receiverlayout>
        ),
      },
      {
        path: "annual-auth",
        element: (
          <Receiverlayout>
            <Outlet />
          </Receiverlayout>
        ),
        children: [
          {
            index: true,
            element: <AnnualAuthVerificationPage />,
            mobile: <AnnualAuthVerificationMobilePage />,
          },
        ],
      },
      {
        path: "dashboard",
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        mobile: (
          <MobileLayout>
            <Outlet />
          </MobileLayout>
        ),
        loader: AuthOnlyLoader,
        children: [
          {
            index: true,
            element: <DashBoardPage />,
            mobile: <DashBoardMobilePage />,
          },
        ],
      },
      {
        path: "profile",
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        mobile: (
          <MobileLayout>
            <Outlet />
          </MobileLayout>
        ),
        loader: AuthOnlyLoader,
        children: [
          {
            path: "information",
            element: <ProfileInformationPage />,
            mobile: <ProfileInformationMobilePage />,
          },
          {
            path: "sign",
            element: <ProfileSignPage />,
            mobile: <ProfileSignMobilePage />,
          },
          {
            path: "invited-organization",
            element: <ProfileInvitedOrganizationPage />,
            mobile: <ProfileInvitedOrganizationMobilePage />,
          },
        ],
      },
      {
        path: "template",
        loader: AuthOnlyLoader,
        children: [
          {
            path: "dashboard",
            element: (
              <DefaultLayout>
                <Outlet />
              </DefaultLayout>
            ),
            mobile: (
              <MobileLayout>
                <Outlet />
              </MobileLayout>
            ),
            children: [
              {
                index: true,
                element: <TemplateDashboardPage />,
                mobile: <TemplateDashBoardMobilePage />,
              },

              {
                path: "bookmark",
                element: <TemplateDashBoardBookMarkPage />,
                mobile: <TemplateDashBoardBookMarkMobilePage />,
              },
              {
                path: "me",
                element: <TemplateDashBoardMePage />,
                mobile: <TemplateDashBoardMobilePage />,
              },
            ],
          },
          {
            path: ":templateId",
            element: (
              <DefaultLayout>
                <Outlet />
              </DefaultLayout>
            ),
            mobile: (
              <MobileLayout>
                <Outlet />
              </MobileLayout>
            ),
            children: [
              {
                index: true,
                element: <TemplateDetailPage />,
                mobile: <TemplateDetailMobilePage />,
              },
            ],
          },
          {
            path: "create",
            children: [
              {
                path: "upload",
                element: <Create />,
              },
              {
                path: ":templateId",
                children: [
                  {
                    path: "editor",
                    element: <Create />,
                  },
                ],
              },
            ],
          },
          {
            path: "send",
            children: [
              {
                path: ":templateId",
                element: (
                  <>
                    <Outlet />
                  </>
                ),
                mobile: (
                  <>
                    <Outlet />
                  </>
                ),
                children: [
                  {
                    path: "config",
                    element: (
                      <TemplateSendLayout>
                        <TemplateSendConfigLayout>
                          <Outlet />
                        </TemplateSendConfigLayout>
                      </TemplateSendLayout>
                    ),
                    mobile: (
                      <TemplateSendConfigMobileLayout>
                        <Outlet />
                      </TemplateSendConfigMobileLayout>
                    ),
                    children: [
                      {
                        path: "single",
                        element: <TemplateSendConfigSinglePage />,
                        mobile: <TemplateSendConfigSingleMobilePage />,
                      },
                      {
                        path: "many",
                        element: <TemplateSendConfigManyPage />,
                        mobile: <MobileTemplateSendConfigManyPage />,
                      },
                      {
                        path: "multiple",
                        element: <TemplateSendConfigMultiplePage />,
                      },
                    ],
                  },
                  {
                    path: "preview",
                    element: <TemplateDashBoardSendPreviewPage />,
                    mobile: <MobileTemplateDashBoardSendPreviewPage />,
                  },
                ],
              },
              {
                path: "view",
                element: (
                  <>
                    <Outlet />
                  </>
                ),
                mobile: (
                  <>
                    <Outlet />
                  </>
                ),
                children: [
                  {
                    index: true,
                    element: <ViewDocuments />,
                    mobile: <MobileViewDocuments />,
                  },
                ],
              },
            ],
          },

          {
            path: "store",
            element: (
              <DefaultLayout>
                <Outlet />
              </DefaultLayout>
            ),
            mobile: (
              <MobileLayout>
                <Outlet />
              </MobileLayout>
            ),
            children: [
              {
                index: true,
                element: <TemplateStorePage />,
                mobile: <TemplateStoreMobilePage />,
              },
            ],
          },
        ],
      },
      {
        path: "contract",
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        mobile: (
          <MobileLayout>
            <Outlet />
          </MobileLayout>
        ),
        loader: AuthOnlyLoader,
        children: [
          {
            path: "dashboard",
            children: [
              {
                path: "normal",
                element: <ContractDashBoardNormalURLPage />,
                mobile: <ContractDashBoardNormalURLMobilePage />,
              },
              {
                path: "direct-url",
                element: <ContractDashBoardDirectURLPage />,
                mobile: <ContractDashBoardDirectURLMobilePage />,
              },
            ],
          },
          {
            path: "send",
            children: [
              {
                path: "upload",
                element: <ContractSendPage />,
              },
              {
                path: ":contractId",
                children: [
                  {
                    path: "config",
                    element: <ContractSendPage />,
                  },
                  {
                    path: "editor",
                    element: <ContractSendPage />,
                  },
                ],
              },
            ],
          },
          {
            path: ":contractId",
            element: <ContractDetailPage />,
            mobile: <MobileContractDetailPage />,
          },
        ],
      },
      {
        path: "setting",
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        mobile: (
          <MobileLayout>
            <Outlet />
          </MobileLayout>
        ),
        loader: AuthOnlyLoader,
        children: [
          {
            path: "organization",
            element: <SettingOrganizationPage />,
            mobile: <SettingOrganizationMobilePage />,
          },
          {
            path: "service",
            element: <SettingServicePage />,
            mobile: <SettingServiceMobilePage />,
          },
          {
            path: "payment",
            children: [
              {
                path: "dashboard",
                element: <SettingPaymentDashBoardPage />,
                mobile: <SettingPaymentDashBoardMobilePage />,
              },
              {
                path: "billing",
                element: <SettingPaymentBillingPage />,
                mobile: <SettingPaymentBillingMobilePage />,
              },
              {
                path: "billing/complete",
                element: <SettingPaymentBillingCompletePage />,
                mobile: <SettingPaymentBillingCompleteMobilePage />,
              },
              {
                path: "history",
                element: <SettingPaymentHistoryPage />,
                mobile: <SettingPaymentHistoryMobilePage />,
              },
              {
                path: "toss/:status",
                loader: PaymentTossLoader,
                element: <SettingPaymentBillingTossPage />,
              },
            ],
          },
          {
            path: "member",
            element: <SettingMemberPage />,
            mobile: <SettingMemberMobilePage />,
          },
          {
            path: "group",
            element: <SettingGroupPage />,
            mobile: <SettingGroupMobilePage />,
          },
          {
            path: "mail",
            element: <SettingMailPage />,
            mobile: <SettingMailMobilePage />,
          },
          {
            path: "contact",
            element: <SettingContactPage />,
            mobile: <SettingContactMobilePage />,
          },
          {
            path: "list-box-db",
            element: <SettingListBoxDBPage />,
            mobile: <SettingListBoxDBMobilePage />,
          },
          {
            path: "support",
            element: <SettingSupportPage />,
            mobile: <SettingSupportMobilePage />,
          },
          {
            path: "faq",
            element: <SettingFAQPage />,
            mobile: <SettingFAQMobilePage />,
          },
        ],
      },
      {
        path: "api",
        loader: AuthOnlyLoader,
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        mobile: (
          <MobileLayout>
            <Outlet />
          </MobileLayout>
        ),
        children: [
          {
            path: "getting-started",
            element: <APIGettingStartedPage />,
            mobile: <APIGettingStartedMobilePage />,
          },
          {
            path: "dashboard",
            element: <APIDashboardPage />,
            mobile: <APIDashboardMobilePage />,
          },
        ],
      },
      {
        path: "notice",
        loader: AuthOnlyLoader,
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        mobile: (
          <MobileLayout>
            <Outlet />
          </MobileLayout>
        ),
        children: [
          {
            path: "dashboard",
            children: [
              {
                index: true,
                element: <NoticeDashboardPage />,
                mobile: <NoticeDashboardMobilePage />,
              },
              {
                path: ":noticeId",
                element: <NoticeDashboardDetailPage />,
                mobile: <NoticeDashboardDetailMobilePage />,
              },
            ],
          },
          {
            path: "guide",
            element: <NoticeGuidePage />,
            mobile: <NoticeGuideMobilePage />,
          },
        ],
      },
      {
        path: "alert",
        loader: AuthOnlyLoader,
        element: (
          <DefaultLayout>
            <Outlet />
          </DefaultLayout>
        ),
        mobile: (
          <MobileLayout>
            <Outlet />
          </MobileLayout>
        ),
        children: [
          {
            path: "dashboard",
            element: <AlertDashboardPage />,
            mobile: <AlertDashboardMobilePage />,
          },
        ],
      },
      {
        path: "sign",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <Sign />,
            mobile: <MobileSign />,
          },
        ],
      },
      {
        path: "receiver",
        element: (
          <Receiverlayout>
            <Outlet />
          </Receiverlayout>
        ),
        mobile: (
          <MobileLandingLayout>
            <Outlet />
          </MobileLandingLayout>
        ),
        children: [
          { path: "callback", element: <Callback /> },
          {
            path: "",
            element: <Phone />,
            mobile: <MobilePhone />,
          },
          {
            path: "email",
            element: <Email />,
            mobile: <MobileEmail />,
          },
          {
            path: "phone-number",
            element: <Phone />,
            mobile: <MobilePhone />,
          },
          {
            path: "verification",
            children: [
              {
                path: "password",
                element: <PasswordVerification />,
                mobile: <MobilePasswordVerification />,
              },
              {
                path: "phone",
                element: <PhoneVerification />,
              },
              {
                path: "login",
                element: <VerificationLogin />,
                mobile: <MobileVerificationLogin />,
              },
            ],
          },
          {
            path: "confirm",
            element: <Confirm />,
            mobile: <MobileConfirm />,
          },
        ],
      },
      {
        path: "direct-url",
        children: [
          { path: ":directUrlCode", element: <DirectURL /> },
          {
            path: "callback",
            element: <></>,
          },
        ],
      },
      {
        path: "copies",
        element: (
          <Receiverlayout>
            <Outlet />
          </Receiverlayout>
        ),
        children: [
          {
            index: true,
            element: <Copies />,
          },
        ],
      },
      {
        path: "certificates",
        element: (
          <Receiverlayout>
            <Outlet />
          </Receiverlayout>
        ),
        mobile: (
          <MobileLayout>
            <Outlet />
          </MobileLayout>
        ),
        children: [
          {
            index: true,
            element: <Certificates />,
            mobile: <MobileCertificates />,
          },
        ],
      },
      {
        path: "download",
        children: [
          { path: "copies", element: <DownloadCopies /> },
          { path: "certificates", element: <DownloadCertificates /> },
        ],
      },
      {
        path: "preview",
        children: [
          {
            path: ":contractId",
            element: <Preview />,
          },
        ],
      },
      {
        path: "*",
        element: <Page404 />,
      },

      {
        path: "invitation/acceptance",
        element: <InvitationAcceptancePage />,
      },
    ],
  },
]);

export default routes;

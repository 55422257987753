import styled from "styled-components";
import * as RadixTooltip from "@radix-ui/react-tooltip";

export const TooltipTrigger = styled(RadixTooltip.Trigger)`
  display: flex;
  height: max-content;
`;

export const TooltipContent = styled(RadixTooltip.Content)`
  width: max-content;
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem;
  background-color: #00000099;
  color: white;
  z-index: 999;
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;

  span {
    font-weight: 600;
  }

  .arrow {
    fill: #00000099;
  }

  .detail {
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;

    li {
      display: flex;
      column-gap: 0.4rem;

      & > span {
        &:first-of-type {
          margin-top: 0.2rem;
          width: 1.6rem;
          height: 1.6rem;
          font-size: 0.8rem;
          font-weight: 600;
          line-height: 1.2rem;
          letter-spacing: -0.03em;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          background-color: #191919;
        }

        &:last-of-type {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 2rem;
          letter-spacing: -0.05em;
          white-space: pre-wrap;
        }
      }
    }
  }
`;

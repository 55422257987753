import React from "react";
import { Viewer } from "@toast-ui/react-editor";

import "@toast-ui/editor/dist/toastui-editor-viewer.css";

interface MarkdownViewerProps {
  markdown: string;
}

const MarkdownViewer = ({ markdown }: MarkdownViewerProps) => {
  return <Viewer initialValue={markdown}></Viewer>;
};

export default MarkdownViewer;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getStatistics, getHistory, getCredits } from "apis/api";
import { Organization } from "models/Organization";
import { PaginationRequest } from "models/Api";
export const apiQueryKey = createQueryKeys("api", {
  statistics: (id?: Organization["uuid"]) => ({
    queryKey: [id],
    queryFn: () => {
      if (!id) return;
      return getStatistics(id);
    },
  }),
  history: (id?: Organization["uuid"], options?: PaginationRequest) => ({
    queryKey: [id, options],
    queryFn: () => {
      if (!id) return;
      return getHistory(id, options);
    },
  }),
  credits: (id?: Organization["uuid"]) => ({
    queryKey: [id],
    queryFn: () => {
      if (!id) return;
      return getCredits(id);
    },
  }),
});

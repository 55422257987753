import Button from "components/Common/Button/Button";
import { Body2Regular } from "components/TextStyle";
import useModal from "hooks/useModal";
import React from "react";
import styled from "styled-components";
import WithDrawOrganizationModal from "./WithDrawOrganizationModal";

interface OrganizationItemProps {
  index: number;
  title: string;
  id: string;
  isMyOrganization: boolean;
}

const OrganizationItem = ({
  index,
  title,
  id,
  isMyOrganization,
}: OrganizationItemProps) => {
  const { openModal, closeModal, isOpen } = useModal();

  return (
    <>
      {isOpen && <WithDrawOrganizationModal closeModal={closeModal} id={id} />}
      <OrganizationItemLayout>
        <div>
          <Body2Regular className="organization-item-index">
            {index}
          </Body2Regular>
          <Body2Regular className="organization-item-title">
            {title}
          </Body2Regular>
        </div>
        {!isMyOrganization && (
          <Button colorType="tertiary" size="small" onClick={openModal}>
            탈퇴하기
          </Button>
        )}
      </OrganizationItemLayout>
    </>
  );
};

const OrganizationItemLayout = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;

  > div {
    display: flex;
  }

  > button {
    margin-right: 4rem;
  }

  .organization-item-index {
    color: var(--primary-600);
    padding: 1.6rem 2.4rem;
  }

  .organization-item-title {
    padding: 1.6rem 2.4rem;
  }
`;

export default OrganizationItem;

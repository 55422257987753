import React, { useMemo } from "react";
import styled from "styled-components";
import {
  Body1SemiBold,
  Body3Regular,
  Body3SemiBold,
  CaptionRegular,
} from "components/TextStyle";
import useNotificationList from "hooks/queries/notification/useNotificationList";
import { useInView } from "react-intersection-observer";
import { format, isToday, isYesterday } from "date-fns";
import { NotificationList } from "models/Notification";
import { Link } from "react-router-dom";
import { NOTIFICATION_TYPE_MAP } from "config/notification";

interface NotificationGroups {
  오늘: NotificationList["contents"];
  어제: NotificationList["contents"];
  "2일 전": NotificationList["contents"];
  "이번 주": NotificationList["contents"];
}

const AlertDashboardMobilePage = () => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useNotificationList();
  const { ref, inView } = useInView();
  React.useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const groupedNotifications = useMemo(() => {
    if (!data) return;

    const allNotifications = data.pages.flatMap(
      (page) => page.notificationList.contents
    );

    const grouped = allNotifications.reduce<NotificationGroups>(
      (acc, notification) => {
        const date = new Date(notification.createdAt);
        const daysDiff = Math.floor(
          (new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
        );

        if (isToday(date)) {
          acc["오늘"].push(notification);
        } else if (isYesterday(date)) {
          acc["어제"].push(notification);
        } else if (daysDiff === 2) {
          acc["2일 전"].push(notification);
        } else if (daysDiff >= 3 && daysDiff <= 7) {
          acc["이번 주"].push(notification);
        }

        return acc;
      },
      {
        오늘: [],
        어제: [],
        "2일 전": [],
        "이번 주": [],
      }
    );

    return grouped;
  }, [data]);

  if (!groupedNotifications || !data) return null;

  return (
    <AlertDashboardLayout>
      <Body1SemiBold>
        모든 알림 ({data.pages[0].notificationList.totalElements})
      </Body1SemiBold>

      {(
        Object.entries(groupedNotifications) as [
          keyof NotificationGroups,
          NotificationList["contents"]
        ][]
      ).map(
        ([dateGroup, notifications]) =>
          notifications.length > 0 && (
            <div key={dateGroup} className="alert-group">
              <Body3SemiBold>{dateGroup}</Body3SemiBold>
              <AlertList>
                {notifications.map((notification, index) => (
                  <Link
                    to={
                      NOTIFICATION_TYPE_MAP[notification.notificationType]?.link
                    }
                    key={notification.notificationUuid}
                  >
                    <AlertListItem
                      title={
                        NOTIFICATION_TYPE_MAP[notification.notificationType]
                          ?.text
                      }
                      content={notification.content}
                      date={format(notification.createdAt, "yyyy.MM.dd")}
                    />
                    {index < notifications.length - 1 && <hr />}
                  </Link>
                ))}
              </AlertList>
            </div>
          )
      )}

      {hasNextPage && (
        <LoadingIndicator ref={ref}>
          {isFetchingNextPage ? "로딩 중..." : "더 보기"}
        </LoadingIndicator>
      )}
    </AlertDashboardLayout>
  );
};

export default AlertDashboardMobilePage;

const LoadingIndicator = styled.div`
  text-align: center;
  padding: 20px;
  font-weight: bold;
  color: var(--text-secondary);
`;

const AlertList = styled.ul`
  background-color: var(--bg-dark);
  display: flex;
  gap: 2rem;
  flex-direction: column;
  border-radius: 0.5rem;

  > hr:last-child {
    display: none;
  }
`;

const AlertDashboardLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
  padding: 3rem;

  .alert-group {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
`;

interface AlertListItemProps {
  title: string;
  content: string;
  date: string;
}

const AlertListItem = ({ title, content, date }: AlertListItemProps) => {
  return (
    <AlertListItemLayout>
      <Body3SemiBold>{title}</Body3SemiBold>
      <Body3Regular>{content}</Body3Regular>
      <CaptionRegular>{date}</CaptionRegular>
    </AlertListItemLayout>
  );
};

const AlertListItemLayout = styled.li`
  background-color: var(--bg-white);
  padding: 2.4rem;
  border-radius: 1.2rem;

  > :first-child {
    color: var(--text-middle-emphasis);
    margin-bottom: 1.2rem;
  }

  > :last-child {
    color: var(--text-secondary);
    margin-top: 1.2rem;
  }
`;

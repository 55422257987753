import { getUser } from "apis/user";
import { redirect } from "react-router-dom";

const AuthOnlyLoader = async () => {
  try {
    await getUser();
    return null;
  } catch (err) {
    return redirect("/auth/sign-in");
  }
};

export default AuthOnlyLoader;

import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useContractsInputs = (contractUuid: string) => {
  return useQuery({
    ...queries.contract.inputs(contractUuid),
    enabled: !!contractUuid,
  });
};

export default useContractsInputs;

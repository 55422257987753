import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as RightArrowIcon } from "assets/common/24px/icon_right_default.svg";
import { ReactComponent as SearchIcon } from "assets/common/24px/icon_search.svg";
import NoticeNavigation from "components/Mobile/Notice/NoticeNavigation";
import useNoticeListQuery from "hooks/queries/notice/useNoticeListQuery";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Body2SemiBold } from "components/TextStyle";

const NoticeDashboardPage = () => {
  const [keyword, setKeyword] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const { data } = useNoticeListQuery(keyword);

  const handleSearchInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setKeyword(inputRef.current?.value || "");
    }
  };
  console.log(data);

  return (
    <NoticeDashboardLayout>
      <NoticeNavigation />
      <NoticeListLayout>
        <SearchInputLayout>
          <div className="search-input">
            <input
              ref={inputRef}
              type="text"
              placeholder="검색어를 입력하세요"
              onKeyDown={handleSearchInput}
            />
            <SearchIcon />
          </div>
        </SearchInputLayout>
        {data?.noticeList?.contents.length ? (
          data?.noticeList?.contents?.map((notice) => (
            <React.Fragment key={notice.title}>
              <Link to={`/notice/dashboard/${notice.noticeUuid}`}>
                <NoticeListItem>
                  <div className="notice-list-title">
                    <Body2SemiBold>
                      [{notice.noticeType}] {notice.title}
                    </Body2SemiBold>
                    <RightArrowIcon />
                  </div>
                  <time>{format(notice.date, "yyyy.MM.dd")}</time>
                </NoticeListItem>
              </Link>
            </React.Fragment>
          ))
        ) : (
          <div className="none">공지사항이 없습니다.</div>
        )}
      </NoticeListLayout>
    </NoticeDashboardLayout>
  );
};

export default NoticeDashboardPage;

const NoticeDashboardLayout = styled.div`
  padding: 3rem;

  .none {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 6rem 0;
  }
`;

const SearchInputLayout = styled.div`
  .search-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.4rem 2rem;
    margin-top: 2rem;

    border-radius: 0.5rem;
    border: 1px solid var(--stroke-light);
    background-color: var(--bg-white);

    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: -0.03em;

    input::placeholder {
      color: var(--text-tertiary);
    }
  }

  svg {
    stroke: var(--icon-secondary);
    width: 2rem;
    height: 2rem;
  }
`;

const NoticeListLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoticeListItem = styled.div`
  margin-top: 3.2rem;
  cursor: pointer;

  .notice-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.4rem;

    > svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  time {
    color: var(--text-secondary);
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: -0.03em;
    text-decoration-skip-ink: none;
  }
`;

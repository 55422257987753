import styled from "styled-components";

export const ResizableDot = styled.div<{ color: string; scale: number }>`
  position: absolute;
  width: ${({ scale }) => `${scale * 1}rem`};
  height: ${({ scale }) => `${scale * 1}rem`};
  border: ${({ scale, color }) => `${0.2 * scale}rem solid ${color}`};
  border-radius: 100%;
  background-color: white;

  &.top {
    top: ${({ scale }) => `-${scale * 0.5}rem`};

    &.left {
      cursor: nw-resize;
    }

    &.right {
      cursor: ne-resize;
    }
  }

  &.left {
    left: ${({ scale }) => `-${scale * 0.5}rem`};
  }

  &.right {
    right: ${({ scale }) => `-${scale * 0.5}rem`};
  }

  &.bottom {
    bottom: ${({ scale }) => `-${scale * 0.5}rem`};

    &.left {
      cursor: sw-resize;
    }

    &.right {
      cursor: se-resize;
    }
  }
`;

import { DropdownItemContainer } from "components/Common/Dropdown/DropdownStyledComponents";
import styled from "styled-components";

export const SupportContainer = styled.form`
  display: flex;
  width: 100%;
  max-width: 114rem;
  gap: 8rem;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;

  .inputContainer {
    display: flex;
    gap: 8rem;
    width: 100%;
  }

  button {
    width: 24rem;
  }
`;

export const SupportContentContainer = styled.div`
  display: flex;
  gap: 3.2rem;
  flex-direction: column;
  width: 100%;

  .checkboxContainer {
    display: flex;
    gap: 1.2rem;
    margin-top: -1.8rem;

    .checkboxText {
      color: var(--grey-600);

      a {
        cursor: pointer;
        text-decoration-line: underline;
      }
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  ${DropdownItemContainer} {
    width: calc(100% + 0.2rem);
  }
`;

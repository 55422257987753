import { useRef, useState } from "react";
import { Stage as StageRefType } from "konva/lib/Stage";
import konvaToImage from "utils/konvaToImage";
import resizeImage from "utils/resizeImage";

const useImageStamp = () => {
  const [contrast, setContrast] = useState(0);
  const [rotation, setRotation] = useState<number>(0);
  const stageRef = useRef<StageRefType | null>(null);

  const rotateRight = () => {
    setRotation((prevRotation) => {
      const newRotation = (prevRotation - 90) % 360;
      return newRotation === 360 ? 0 : newRotation;
    });
  };

  const resetEdit = () => {
    setContrast(0);
    setRotation(0);
  };

  const createStamp = async () => {
    if (!stageRef?.current) return;

    const scaleSize = resizeImage(
      {
        width: stageRef.current.width(),
        height: stageRef.current.height(),
      },
      {
        width: 300,
        height: 300,
      }
    );

    const file = await konvaToImage(stageRef.current, {
      width: 300,
      height: 300,
      ...scaleSize,
      contrast: contrast,
    });

    return file;
  };

  return {
    contrast,
    setContrast,
    rotation,
    setRotation,
    stageRef,
    rotateRight,
    resetEdit,
    createStamp,
  };
};

export default useImageStamp;

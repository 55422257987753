import { useBoundaryInView } from "components/Common/BoundaryInView/BoundaryInViewProvider";
import { PaymentDashboardPlan } from "pages/setting/payment/dashboard";
import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as LogoIcon } from "assets/common/icon_logo.svg";
import { Header1 } from "components/TextStyle";
import { device } from "components/styles";

const PlanPage = () => {
  const { setActiveSection } = useBoundaryInView();

  useEffect(() => {
    setActiveSection("/plan");
  }, []);

  return (
    <PlanPageLayout>
      <PlanPageContent>
        <Header1 className="plan-header">
          <LogoIcon /> 요금제
        </Header1>
        <PaymentDashboardPlan />
      </PlanPageContent>
    </PlanPageLayout>
  );
};

export default PlanPage;

const PlanPageLayout = styled.section`
  padding: 0rem 22.1rem 11rem 22.1rem;
  background-color: var(--grey-200);
  min-height: calc(100vh - var(--header-height));
  container-type: inline-size;

  .plan-header {
    width: fit-content;
    margin: 4rem 0rem;
  }
`;

const PlanPageContent = styled.div`
  margin: 0 auto;

  @media (${device.md}) {
    padding: 0rem 12rem;
  }

  @media (${device.sm}) {
    padding: 0rem 3.4rem;
  }

  @media (${device.xs}) {
    padding: 0rem 3.8rem;
    //TODO: 디자이너에게 받은 값으로 교체 필요
  }
`;

import { Board } from "components/Common/Board/Board";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as RightArrowIcon } from "assets/common/24px/icon_right_default.svg";
import { ReactComponent as SearchIcon } from "assets/common/24px/icon_search.svg";
import NoticeNavigation from "components/Notice/NoticeNavigation";
import useNoticeListQuery from "hooks/queries/notice/useNoticeListQuery";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const NoticeDashboardPage = () => {
  const [keyword, setKeyword] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const { data } = useNoticeListQuery(keyword);

  const handleSearchInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setKeyword(inputRef.current?.value || "");
    }
  };

  return (
    <>
      <NoticeNavigation />
      <NoticeDashboardLayout>
        <NoticeListLayout>
          <SearchInputLayout>
            <div className="search-input">
              <SearchIcon style={{}} />
              <input
                ref={inputRef}
                type="text"
                placeholder="검색어를 입력하세요"
                onKeyDown={handleSearchInput}
              />
            </div>
            <hr />
          </SearchInputLayout>
          {data?.noticeList.contents.length ? (
            data?.noticeList.contents.map((notice) => (
              <React.Fragment key={notice.title}>
                <Link to={`/notice/dashboard/${notice.noticeUuid}`}>
                  <NoticeListItem>
                    <div className="notice-list-title">
                      <h2>
                        [{notice.noticeType}] {notice.title}
                      </h2>
                      <RightArrowIcon />
                    </div>
                    <time>{format(notice.date, "yyyy.MM.dd")}</time>
                  </NoticeListItem>
                </Link>
                <hr />
              </React.Fragment>
            ))
          ) : (
            <div className="none">공지사항이 없습니다.</div>
          )}
        </NoticeListLayout>
      </NoticeDashboardLayout>
    </>
  );
};

export default NoticeDashboardPage;

const NoticeDashboardLayout = styled(Board)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0;

  .none {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 6rem 0;

    color: var(--Text-Color-Text-Default, #171d23);
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3rem; /* 136.364% */
    letter-spacing: -0.66px;
  }
`;

const SearchInputLayout = styled.div`
  margin-bottom: 4.4rem;

  .search-input {
    height: 7rem;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    width: 100%;
    margin-bottom: 1.6rem;
  }

  input {
    height: 100%;
    font-size: 3.2rem;
  }
  svg {
    stroke: var(--stroke-dark);
    width: 4rem;
    height: 4rem;
  }
`;

const NoticeListLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5.4rem;
  max-width: 108rem;
  width: 100%;

  hr {
    width: 100%;
    height: 1px;
    background-color: var(--stroke-light);
    border: none;
    margin: 0;
  }

  > hr:last-child {
    display: none;
  }
`;

const NoticeListItem = styled.div`
  width: 100%;
  cursor: pointer;

  .notice-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2rem;

    h2 {
      margin: 0;

      color: var(--Text-Color-Text-Default, #171d23);
      font-size: 2.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 3rem; /* 136.364% */
      letter-spacing: -0.66px;
    }

    > svg {
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  time {
    color: var(--Text-Color-Text-Secondary, #666f7b);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.54px;
  }
`;

import React, {
  forwardRef,
  Dispatch,
  SetStateAction,
  InputHTMLAttributes,
} from "react";
import styled from "styled-components";
import { Body1Regular, Body3Regular } from "components/TextStyle";

export interface DefaultInputType {
  isError?: boolean;
  errorMessage?: string;
  isDisabled?: boolean;
  value?: string | number;
  setValue?: Dispatch<SetStateAction<string>> | ((param: string) => void);
  addOn?: string;
  length?: boolean;
  width?: string;
  maxLength?: number;
}

export type InputProps = DefaultInputType &
  InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      isError = false,
      errorMessage,
      isDisabled = false,
      type = "text",
      value,
      setValue,
      addOn,
      length,
      maxLength,
      width,
      ...props
    },
    ref
  ) => {
    return (
      <InputContainer
        style={{
          width: width,
        }}
      >
        <div
          style={{
            position: "relative",
            width: width,
          }}
        >
          <InputItem
            $isError={isError}
            type={type}
            disabled={isDisabled}
            value={value}
            onChange={(e) => setValue && setValue(e.target.value)}
            ref={ref}
            maxLength={maxLength}
            $isAddOn={!!addOn}
            {...props}
          />
          {addOn && <InputAddon>{addOn}</InputAddon>}
        </div>
        {isError && (
          <Body3Regular className="errorMsg">{errorMessage}</Body3Regular>
        )}
        {length && (
          <Body1Regular className="length">
            {String(value)?.length}/{maxLength}자
          </Body1Regular>
        )}
      </InputContainer>
    );
  }
);

Input.displayName = "Input";

export default Input;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.6rem;
  position: relative;

  .errorMsg {
    color: var(--red-500);
  }

  .length {
    position: absolute;
    right: 1.4rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-secondary);
  }
`;

const InputItem = styled.input<{ $isError: boolean; $isAddOn: boolean }>`
  border-radius: 0.5rem;
  border: ${(props) =>
    props.$isError
      ? "1px solid var(--red-500) !important"
      : "1px solid var(--grey-300)"};
  background: var(--bg-white);
  padding: 1.2rem 1.4rem;
  box-sizing: border-box;
  width: 100%;
  height: 4.8rem;
  color: #171d23;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: -0.48px;

  padding: ${({ $isAddOn }) =>
    $isAddOn ? "1.2rem 4.4rem 1.2rem 1.4rem" : "1.2rem 1.4rem"};

  &::placeholder {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
    color: var(--grey-500);
  }

  &:not(:disabled):hover {
    border: 1px solid var(--grey-400);
  }

  &:not(:disabled):focus {
    border: 1px solid var(--primary-500);
  }

  &:disabled {
    background-color: var(--grey-10);
    color: var(--grey-400);
  }
`;

const InputAddon = styled(Body1Regular)`
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
`;

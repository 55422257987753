import { isAxiosError } from "axios";
import TextArea from "components/Common/Input/TextArea";
import ActionModal from "components/Common/Modal/ActionModal";
import useOrganizationsDeleteMutation from "hooks/mutations/organization/useOrganizationsDeleteMutation";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface FormData {
  reason: string;
}

interface WithDrawOrganizationModalProps {
  closeModal: () => void;
  id: string;
}

const WithDrawOrganizationModal = ({
  closeModal,
  id,
}: WithDrawOrganizationModalProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>();

  const { mutate } = useOrganizationsDeleteMutation();

  const handleWithDraw = (value: FormData) => {
    mutate(
      {
        id: id,
        withdrawReason: value.reason,
      },
      {
        onSuccess: () => {
          closeModal();
        },
        onError: (err) => {
          if (isAxiosError(err)) {
            // Axios 에러인 경우 처리
            const status = err.response?.status;
            if (status === 400) {
              toast("내 조직은 탈퇴할 수 없습니다.");
              closeModal();
            } else {
              toast("오류가 발생했습니다.");
            }
          } else {
            toast("알 수 없는 오류가 발생했습니다.");
          }
        },
      }
    );
  };

  return (
    <ActionModal
      title="초대받은 조직 탈퇴"
      handleModalClose={closeModal}
      handleCancelButton={closeModal}
      handleConfirmButton={handleSubmit(handleWithDraw)}
      disableCloseWithOutsideClickOrEsc
    >
      <TextArea
        placeholder="탈퇴 사유를 입력해주세요"
        maxLength={100}
        {...register("reason", {
          required: "탈퇴 사유를 입력해주세요",
        })}
        isError={errors.reason?.message ? true : false}
        rows={5}
        _style={{ resize: "none" }}
      />
    </ActionModal>
  );
};

export default WithDrawOrganizationModal;

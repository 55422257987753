import React from "react";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import ProfileIcon from "assets/Header/icon_profile.svg";
import styled from "styled-components";
import {
  Body1SemiBold,
  Body2Regular,
  Body3Regular,
} from "components/TextStyle";
import Button from "components/Common/Button/Button";
import useUserDetailQuery from "hooks/queries/user/useUserDetailQuery";
import { Link } from "react-router-dom";

const UserDropDownMenu = () => {
  const { data } = useUserDetailQuery();

  return (
    <DropDownRoot>
      <DropDownTrigger asChild>
        <ProfileButton>
          <img
            width={36}
            height={36}
            src={data?.profileImageUrl ?? ProfileIcon}
            alt="profile"
            style={{
              borderRadius: "50%",
            }}
          />
          <Body3Regular>{data?.name}</Body3Regular>
        </ProfileButton>
      </DropDownTrigger>
      <DropDownContent align="center">
        <div className="user-drop-down-profile">
          <img
            width={36}
            height={36}
            src={data?.profileImageUrl ?? ProfileIcon}
            alt="profile"
            style={{
              borderRadius: "50%",
            }}
          />
          <div>
            <Body1SemiBold>{data?.name}</Body1SemiBold>
            <Body2Regular>{data?.email}</Body2Regular>
          </div>
        </div>
        <Link to="/profile/information">
          <Button colorType="tertiary" size="large">
            내 정보
          </Button>
        </Link>
        <Link to="/profile/invited-organization">
          <Button colorType="primary" size="large">
            내 조직
          </Button>
        </Link>
      </DropDownContent>
    </DropDownRoot>
  );
};

export default UserDropDownMenu;

const ProfileButton = styled.button`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const DropDownRoot = styled(Dropdown.Root)`
  position: relative;
  padding: 1.1rem 1.4rem;

  outline: none;
`;

const DropDownTrigger = styled(Dropdown.Trigger)`
  position: relative;
  padding: 1.1rem 1.4rem;
  display: flex;
  white-space: nowrap;
`;

const DropDownContent = styled(Dropdown.Content)`
  background: var(--bg-white);
  box-shadow: 0rem 1rem 2rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  height: fit-content;
  padding: 2rem;
  width: 36rem;
  z-index: 1;

  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  .user-drop-down-profile {
    display: flex;
    gap: 1rem;
  }

  button {
    width: 100%;
  }
`;

import React from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { TooltipContent, TooltipTrigger } from "./styles";

export default function Tooltip({
  align,
  side,
  trigger,
  children,
}: {
  align?: "center" | "end" | "start";
  side?: "bottom" | "top" | "right" | "left";
  trigger?: React.ReactNode;
  children?: React.ReactNode;
}) {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={0}>
        <TooltipTrigger className="trigger">{trigger}</TooltipTrigger>
        <RadixTooltip.Portal>
          <TooltipContent align={align} side={side}>
            {children}
            <RadixTooltip.Arrow className="arrow" />
          </TooltipContent>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}

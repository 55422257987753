import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import useNoticeDetailQuery from "hooks/queries/notice/useNoticeDetailQuery";
import { ReactComponent as CloseIcon } from "assets/common/32px/icon_close_large.svg";
import { format } from "date-fns";
import MarkdownViewer from "./MarkdownViewer";
import { SubTitle } from "components/TextStyle";

const NoticeDashboardDetailMobilePage = () => {
  const { pathname } = useLocation();
  const id = pathname.split("/").pop() ?? "";
  //TODO: 추후 페이지 접속 예외처리 loader단에서 필요
  const navigate = useNavigate();

  const { data } = useNoticeDetailQuery({ id });
  const notice = data?.notice;

  if (!notice) return;

  return (
    <NoticeDetailLayout>
      <NavContainer>
        <CloseIcon onClick={() => navigate(-1)} />
      </NavContainer>
      <TitleHeader>
        <SubTitle>{notice.title}</SubTitle>
        <time>{format(notice.date, "yyyy.MM.dd")}</time>
      </TitleHeader>
      <MarkdownViewer markdown={notice.content} />
    </NoticeDetailLayout>
  );
};

export default NoticeDashboardDetailMobilePage;

const NoticeDetailLayout = styled.div`
  padding: 3rem;

  time {
    color: var(--text-secondary);
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: -0.03em;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -1rem -2rem 1rem -2rem;

  svg {
    path {
      stroke: var(--icon-secondary);
    }
  }
`;

const TitleHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 3rem;
`;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  getPaymentsHistory,
  getPaymentsPaymentMethodAll,
  getPaymentsProfile,
  getPaymentsServicePlan,
  getPaymentsServicePlanFree,
  getPaymentsSubscriptionsExistence,
  getPaymentsTossPaymentsBilling,
  getPaymentsUsagePeriod,
  getStandardAvailability,
} from "apis/payment";

export const paymentQueryKey = createQueryKeys("payment", {
  plan: {
    queryKey: null,
    queryFn: getPaymentsServicePlan,
  },
  free: (organizationUuid?: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => {
      if (!organizationUuid) return;
      return getPaymentsServicePlanFree(organizationUuid);
    },
  }),
  method: (organizationUuid?: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => {
      if (!organizationUuid) return;
      return getPaymentsPaymentMethodAll(organizationUuid);
    },
  }),
  history: (params: GetPaymentsHistoryReq) => ({
    queryKey: [params],
    queryFn: () => {
      if (!params.organizationUuid) return;
      return getPaymentsHistory(params);
    },
  }),
  profile: (organizationUuid?: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => {
      if (!organizationUuid) return;
      return getPaymentsProfile(organizationUuid);
    },
  }),
  period: {
    queryKey: null,
    queryFn: getPaymentsUsagePeriod,
  },
  tossBilling: (organizationUuid?: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => {
      if (!organizationUuid) return;
      return getPaymentsTossPaymentsBilling(organizationUuid);
    },
  }),
  existence: (organizationUuid?: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => {
      if (!organizationUuid) return;
      return getPaymentsSubscriptionsExistence(organizationUuid);
    },
  }),
  standardAvailabilityCheck: (organizationUuid: string) => ({
    queryKey: [organizationUuid],
    queryFn: () => {
      if (!organizationUuid) return;
      return getStandardAvailability(organizationUuid);
    },
  }),
});

import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useStandardAvailabilityCheck = (organizationUuid: string) => {
  return useQuery({
    ...queries.payment.standardAvailabilityCheck(organizationUuid),
    enabled: !!organizationUuid,
  });
};

export default useStandardAvailabilityCheck;

import React, { useState } from "react";
import { Document } from "react-pdf";

interface PdfViewerProps {
  file: string;
  children: (index: number) => React.ReactNode;
}

const PdfPreviewer: React.FC<PdfViewerProps> = ({ file, children }) => {
  const [numPages, setNumPages] = useState<number>(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  return (
    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from({ length: numPages }, (_, index) => children(index + 1))}
    </Document>
  );
};

export default PdfPreviewer;

import { useQuery } from "@tanstack/react-query";
import { queries } from "config/queryKey";

const useContactGroupsContactsAllQuery = (organizationUuid?: string) => {
  return useQuery({
    ...queries.contact.list(organizationUuid),
    enabled: !!organizationUuid,
  });
};

export default useContactGroupsContactsAllQuery;

import BoundaryInView from "components/Common/BoundaryInView/BoundaryInView";
import React from "react";
import { Page } from "react-pdf";
import styled from "styled-components";

interface MainPdfPreviewItemProps {
  index: number;
  rootRef: React.RefObject<HTMLDivElement>;
}

const MainPdfPreviewItem = ({ index, rootRef }: MainPdfPreviewItemProps) => {
  return (
    <MainPdfPreviewItemLayout>
      <BoundaryInView id={index.toString()} rootRef={rootRef}>
        <Page
          pageNumber={index}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </BoundaryInView>
    </MainPdfPreviewItemLayout>
  );
};

export default MainPdfPreviewItem;

const MainPdfPreviewItemLayout = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
  margin-bottom: 10px;
`;

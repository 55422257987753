import { useParamState } from "@yoonhaemin-lib/use-param-state";

type TemplateSearchType = "ALL" | "CREATED_USER_NAME" | "TEMPLATE_NAME";

interface useTemplateSearchProps {
  initialState?: {
    searchType: TemplateSearchType;
    keyword?: string;
    page?: string;
  };
}

const useTemplateSearch = ({
  initialState = {
    searchType: "ALL",
    keyword: undefined,
    page: "1",
  },
}: useTemplateSearchProps = {}) => {
  const [searchOptions, setSearchOptions] = useParamState<{
    searchType: TemplateSearchType;
    keyword?: string;
    page?: string;
  }>(initialState);

  return [searchOptions, setSearchOptions] as const;
};

export default useTemplateSearch;

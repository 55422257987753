import { forwardRef } from "react";
import Input, { InputProps } from "../Input/Input";
import React from "react";
import { ReactComponent as CalenderIcon } from "assets/common/20px/icon_calendar.svg";
import styled from "styled-components";

export interface CustomDatePickerInputProps extends InputProps {
  onClick?: () => void;
  value?: string;
  isIcon?: boolean;
}

const CustomDatePickerInput = forwardRef<
  HTMLInputElement,
  CustomDatePickerInputProps
>(({ onClick, value, isIcon = true, ...args }, ref) => {
  return (
    <Layout>
      <Input ref={ref} onClick={onClick && onClick} value={value} {...args} />
      {isIcon && <CalenderIcon onClick={onClick && onClick} />}
    </Layout>
  );
});

const Layout = styled.div`
  position: relative;

  input {
    padding-right: 6rem;
  }
  > svg {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;

CustomDatePickerInput.displayName = "CustomDatePickerInput";
export default CustomDatePickerInput;
